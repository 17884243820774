export const formatPrice = (amount: number): string => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(amount);
};

export const formatDate = (invalidDate: string) => {
  const year = invalidDate.slice(invalidDate.lastIndexOf("/") + 1);
  const dayAndMonth = invalidDate
    .slice(0, invalidDate.lastIndexOf("/"))
    .split("/")
    .reverse()
    .join("/");
  return `${dayAndMonth}/${year}`;
};
