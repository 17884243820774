import { getToken } from "./localstorage";
import {
  carBrands,
  carSpecifications,
  CreateCarDTO,
  UpdateCarDTO,
  SearchParams,
} from "./../models/car/dtos";
import { axiosService } from "./axios";
import { Car, CarResults, Deactivated } from "./../models/car/types";
import { ValidateInfo, DocInsuranceCover } from "./../models/car/types";
import { Destination } from "../models/destination/types";

export const getCar = async (id: string | undefined) => {
  try {
    const response = await axiosService.get<Car>(`/cars/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCarsByState = async (
  params: SearchParams
): Promise<CarResults[] | [] | undefined> => {
  try {
    const response = await axiosService.post<CarResults[] | []>(
      `/cars/search`,
      params
    );
    if (response.status !== 200) return [];
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getDestinationsById = async (
  id: number
): Promise<Destination | undefined> => {
  try {
    const response = await axiosService.get(`/destination/search/${id}`);
    if (response.status !== 200) return;

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCarsByUser = async (): Promise<Car[] | undefined> => {
  try {
    const response = await axiosService.get<[Car]>(`/cars/index/user`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createCar = async (CarDTO: CreateCarDTO) => {
  try {
    const data = await axiosService.post("/cars/addcar", CarDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCar = async (id: number, CarDTO: UpdateCarDTO) => {
  try {
    const data = await axiosService.patch<Car>(`/cars/${id}`, CarDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCar = async (id: string) => {
  try {
    const data = await axiosService.delete<Car>(`/car/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCarDocumentStatus = async (id: number, type: string) => {
  const payload =
    type === "vehicleLicense"
      ? { docVehicleLicense: ValidateInfo.NOT_UPLOADED }
      : { docInsuranceCover: DocInsuranceCover.NOT_UPLOADED };
  try {
    const response = await axiosService.patch<Car>(`/cars/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getBrand = async (): Promise<carBrands[] | undefined> => {
  try {
    const response = await axiosService.post<carBrands[]>(`/cars/brands`, {});

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getType = async (carSpecs: carSpecifications) => {
  try {
    const response = await axiosService.post<string[]>("/cars/types", carSpecs);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getYear = async (
  carSpecs: carSpecifications
): Promise<string[] | undefined> => {
  try {
    const response = await axiosService.post<string[]>("/cars/years", carSpecs);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getVersion = async (carSpecs: carSpecifications) => {
  try {
    const response = await axiosService.post<string[]>(
      "/cars/versions",
      carSpecs
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAmis = async (carSpecs: carSpecifications) => {
  try {
    const response = await axiosService.post<Car>("/cars/amis", carSpecs, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateDeactivation = async (updateData: {
  id: number;
  status: Deactivated;
}) => {
  try {
    const data = await axiosService.patch("/cars/deactivated", updateData, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getDestinations = async () => {
  try {
    const response = await axiosService.get<Destination[]>("/destination/all");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
