import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import a_carsImages from "../../../../assets/images/a_cars";
import carsImages from "../../../../assets/images/car";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ModalMap } from "../../../../components/modals/map/component";
import Toast from "../../../../components/toast/component";
import { NumericFormat } from "react-number-format";
import { useEditCarPrice } from "./useEditCarPrice";
import { ExtraAddress } from "../addCarPrice/extraAddress/ExtraAddress";
import { Trans } from "react-i18next";
import { numberSeparator } from "../../../helpers/textHandler";
import { FormikProvider } from "formik";
import { BlockedDates } from "../addCarPrice/blockedDates/BlockedDates";
import { isValidHostCOwnInsurance } from "../../../helpers/status";

export const EditCarPrice = () => {
  const {
    t,
    msg,
    car,
    profit,
    openAlert,
    setOpenAlert,
    formikEditPrice,
    inputPricedProps,
    hostCommercial,
    setMapOpen,
    isMapOpen,
    handleExtraAddressCounter,
    handleHomeDelivery,
    cost,
    homeDeliveryCost,
  } = useEditCarPrice();

  return (
    <>
      <FormikProvider value={formikEditPrice}>
        {msg && (
          <Toast open={openAlert} setOpen={setOpenAlert} type={msg.type}>
            {msg.text}
          </Toast>
        )}
        <div className="price-disponibility" id="third_step">
          <div className="form-container">
            <div className="info-container">
              <h2 data-testid="price-disponibility-title" className="title">
                {t("edit.price_disponibility.edit_price")}
              </h2>
              <h2>{t("edit.price_disponibility.choose_price")}</h2>
            </div>
            <div className="form">
              <Accordion className="file-container">
                <AccordionSummary
                  data-testid="price-accordion"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <p className="option-title">
                    {t("add.car_price.choose_price_and_availability.price")}
                  </p>
                </AccordionSummary>
                <AccordionDetails className="drop-options">
                  <div className="price-text-container">
                    <p>
                      {t(
                        "add.car_price.choose_price_and_availability.car_rental_per_day"
                      )}
                    </p>
                  </div>
                  <div className="day-discount">
                    <div className="price-d">
                      <label className="label-price">
                        {t(
                          "add.car_price.choose_price_and_availability.price_per_day.title"
                        )}
                      </label>
                      <div className="input-price-button">
                        <NumericFormat
                          allowNegative={false}
                          className="input-price"
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          customInput={TextField}
                          value={parseFloat(formikEditPrice.values.price || "")}
                          onValueChange={(value) =>
                            formikEditPrice.setFieldValue(
                              "price",
                              value.floatValue?.toString()
                            )
                          }
                          {...inputPricedProps}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="price-text-container d-flex align-items-center flex-column">
                    <Trans>
                      <div className="percentage">
                        {t(
                          "add.car_price.choose_price_and_availability.comission_percentage",
                          {
                            percentage: `${parseInt(car?.commission || "0")}%`,
                          }
                        )}
                        <Chip
                          label={`$ ${numberSeparator(
                            +profit.toFixed(2)
                          )} / día`}
                          className="chip-per-day"
                        />
                      </div>
                    </Trans>
                    <p className="comission-information">
                      <span>
                        {t(
                          "add.car_price.choose_price_and_availability.comission"
                        )}
                      </span>
                      <a className="more-details">
                        {t(
                          "add.car_price.choose_price_and_availability.more_details"
                        )}
                      </a>
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              {hostCommercial && isValidHostCOwnInsurance(hostCommercial) ? (
                <Accordion className="file-container">
                  <AccordionSummary
                    data-testid="insurance-accordion"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <p className="option-title">
                      {t("add.car_price.insurance_and_security_deposit.title")}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails className="drop-options">
                    <div className="insurance-text-container">
                      <p>
                        {t(
                          "add.car_price.insurance_and_security_deposit.enter_insurance_values"
                        )}
                      </p>
                    </div>
                    <div className="insurance-inputs">
                      <div className="charge-container">
                        <label>
                          {t(
                            "add.car_price.insurance_and_security_deposit.insurance"
                          )}
                        </label>
                        <TextField
                          id="txtCharge"
                          className="charge"
                          value={formikEditPrice.values.insuranceCharge}
                          onChange={formikEditPrice.handleChange(
                            "insuranceCharge"
                          )}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                        <p>
                          {t(
                            "add.car_price.insurance_and_security_deposit.insurance_notice"
                          )}
                        </p>
                      </div>
                      <div className="deposit-amount-container">
                        <label>
                          {t(
                            "add.car_price.insurance_and_security_deposit.down_payment"
                          )}
                        </label>
                        <TextField
                          id="txtDepositAmount"
                          className="depositAmount"
                          type="number"
                          value={formikEditPrice.values.depositAmount}
                          onChange={formikEditPrice.handleChange(
                            "depositAmount"
                          )}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                        <p>
                          {t(
                            "add.car_price.insurance_and_security_deposit.down_payment_notice"
                          )}
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : null}
              <Accordion className="file-container">
                <AccordionSummary
                  data-testid="dates-accordion"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <p className="option-title">
                    {t("add.car_price.availability.title")}
                  </p>
                </AccordionSummary>
                <AccordionDetails className="drop-options" id="disponibility">
                  <BlockedDates carId={car?.id.toString() || "0"} />
                </AccordionDetails>
              </Accordion>
              <Accordion className="file-container">
                <AccordionSummary
                  data-testid="extra-address-accordion"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <p className="option-title">
                    {t("add.car_price.delivery_return.title")}
                  </p>
                </AccordionSummary>
                <AccordionDetails className="drop-options">
                  <div className="ubication">
                    <h3>{t("add.car_price.delivery_return.address.title")}</h3>
                    {formikEditPrice.values.mainAddress && (
                      <>
                        <p className="direction">
                          {formikEditPrice.values.mainAddress.formatted_address}
                        </p>
                        <TextField
                          className="direction-des"
                          id="main_direction"
                          disabled
                          value={
                            formikEditPrice.values.mainAddress
                              .formatted_address ?? ""
                          }
                          type="text"
                        />
                        <button
                          type="button"
                          className="btn btn-orange"
                          onClick={() =>
                            setMapOpen((prev) => ({
                              ...prev,
                              status: true,
                            }))
                          }
                          disabled={isMapOpen.lat == 0 && isMapOpen.lng == 0}
                        >
                          <img
                            className="ubication-icon"
                            src={carsImages.map}
                            alt=""
                          />
                          <p>{t("add.car_price.delivery_return.map")}</p>
                        </button>
                      </>
                    )}
                    <div className="enable-check">
                      <img
                        className=""
                        id="enable_extra"
                        src={
                          formikEditPrice.values.homeDelivery.enable
                            ? a_carsImages.checked
                            : a_carsImages.checkEmpty
                        }
                        alt=""
                      />
                      <p>{t("add.car_price.delivery_return.enable_option")}</p>
                      <img
                        className="info-icon"
                        src={a_carsImages.info}
                        alt=""
                      />
                    </div>
                    <span>
                      {t("add.car_price.delivery_return.not_exact_ubication")}
                    </span>
                    <span className="enable-more-directions">
                      {t("add.car_price.delivery_return.disable_option")}
                    </span>
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className="extra-option">
                    <h3>
                      {t("add.car_price.delivery_return.additional_options")}
                    </h3>
                    <div
                      id="extra_direction_container1"
                      className="d-flex mb-3 flex-wrap w-100"
                    >
                      {Object.keys(
                        formikEditPrice.values.extraAddresses || []
                      ).map((value, item) => (
                        <div className="mb-3 mt-3 w-100" key={item}>
                          <p className="extra-option-title">
                            {t("add.car_price.delivery_return.extra_option")}{" "}
                            {item + 1}
                          </p>
                          <div className="d-flex extra-address-container">
                            <ExtraAddress key={item} index={item} />
                          </div>
                          <p className="no-charge-recommendation">
                            <ElectricBoltIcon
                              fontSize="inherit"
                              sx={{ marginRight: "4px", color: "#ffab03" }}
                            />
                            {t(
                              "add.car_price.delivery_return.no_charge_recommendation"
                            )}
                          </p>
                          <div className="dropdown-divider"></div>
                        </div>
                      ))}
                    </div>
                    <div className="add-container">
                      <button
                        type="button"
                        id="add_direction"
                        disabled={
                          Object.keys(
                            formikEditPrice.values.extraAddresses || []
                          ).length === 4
                        }
                        onClick={() => handleExtraAddressCounter()}
                        className="btn btn-add-direction"
                      >
                        <p>
                          {t("add.car_price.delivery_return.add_direction")}
                        </p>
                        <img
                          className="plus-icon"
                          src={a_carsImages.plus}
                          alt=""
                        />
                      </button>
                      <img src={a_carsImages.info} alt="" />
                    </div>
                    <h3>
                      {t("add.car_price.delivery_return.delivery_service")}
                    </h3>
                    <div className="enable-home-delivery">
                      <div className="enable-check">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                formikEditPrice.values.homeDelivery.enable
                              }
                              color="aqua"
                              onChange={(e) => handleHomeDelivery(e)}
                            />
                          }
                          data-testid="delibery-checkbox"
                          label={"Habilitar entrega a domicilio"}
                        />
                        <img src={a_carsImages.info} alt="" />
                      </div>
                    </div>
                    <div className="add-price" style={{ bottom: "0px" }}>
                      <span>
                        {t("add.car_price.delivery_return.add_price")}
                        <img src={a_carsImages.info} alt="" />
                      </span>
                      <div id="delibery_home_price" className="delibery-price">
                        <NumericFormat
                          allowNegative={false}
                          id="txtpriceD"
                          className="delibery-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          customInput={TextField}
                          value={
                            formikEditPrice.values.homeDelivery?.price ?? "0"
                          }
                          onChange={(e) =>
                            formikEditPrice.setFieldValue("homeDelivery", {
                              ...formikEditPrice.values.homeDelivery,
                              price: e.target.value,
                            })
                          }
                          disabled={
                            !formikEditPrice.values.homeDelivery?.enable || cost
                          }
                        />
                        <div className="enable-check">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="aqua"
                                checked={cost}
                                onChange={(e) => homeDeliveryCost(e)}
                                disabled={
                                  !formikEditPrice.values.homeDelivery?.enable
                                }
                              />
                            }
                            data-testid="no-cost-delivery"
                            label={"Sin costo de entrega"}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="no-charge-recommendation mt-3">
                      <ElectricBoltIcon
                        fontSize="inherit"
                        sx={{ marginRight: "4px", color: "#ffab03" }}
                      />
                      {t(
                        "add.car_price.delivery_return.no_charge_recommendation"
                      )}
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="btn-container save-container" id="save_container">
          <p className="reminder">{t("edit.dont_forget")}</p>
          <button
            type="button"
            id="save_first"
            onClick={() => formikEditPrice.handleSubmit()}
            className="btn btn-blue"
          >
            {t("edit.save_changes")}
          </button>
        </div>
        <ModalMap
          lat={isMapOpen.lat}
          lng={isMapOpen.lng}
          completeAddress={isMapOpen.completeAddress}
          isOpen={isMapOpen.status}
          setOpen={() => setMapOpen((prev) => ({ ...prev, status: false }))}
        />
      </FormikProvider>
    </>
  );
};
