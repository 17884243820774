import { useContext, useEffect, useRef, useState } from "react";
import navbarImages from "../../assets/images/navbar/index";
import { Avatar, Badge, Chip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import "./AdminNavbar.scss";
import useProfileData from "../profileData/useProfileData";
import { DomainContext } from "../../providers/domainProvider";
import { capitalize } from "../../lib/helpers/textHandler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/windowSize";
import MenuIcon from "@mui/icons-material/Menu";
import { StickySidebar } from "../stickySidebar/StickySidebar";
import { AuthContext } from "../../providers/authProvider";
import CircleIcon from "@mui/icons-material/Circle";
import { AdminSidebar } from "../adminSidebar/AdminSidebar";
import { NotificationsModal } from "../modals/notifications/Notifications";
import { Link } from "react-router-dom";
import { handleClickOutside } from "../../lib/helpers/dropdownBoxActions";

interface Notification {
  user: string;
  information: string;
  status: "seen" | "new";
  time: string;
}

export const notifications: Notification[] = [];

export const AdminNavbar = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const image = useRef<SVGSVGElement>(null);
  const { imageProfile } = useProfileData();
  const { settings } = useContext(DomainContext);
  const [showAdminDropdown, setShowAdminDropdown] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const { t } = useTranslation(["navbar", "routes"]);
  const windowSize = useWindowSize();
  const auth = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (image.current) {
      image.current.style.transform = showSideBar
        ? `rotate(${-90}deg)`
        : `rotate(${0}deg)`;
    }
  }, [showSideBar]);

  useEffect(() => {
    const handleClickOutsideEvent = (event: any) => {
      if (ref.current && handleClickOutside(ref, event)) {
        setShowNotifications(false);
      }
    };

    if (ref.current) {
      document.addEventListener("click", handleClickOutsideEvent, true);
    }

    return () => {
      document.removeEventListener("click", handleClickOutsideEvent, true);
    };
  }, [ref, showNotifications]);

  return (
    <>
      <nav className="admin-navbar">
        <div className="admin-navbar__container">
          <MenuIcon
            ref={image}
            onClick={() => setShowSideBar((prev) => !prev)}
            className="admin-navbar__button__image"
          />
          <LinkTo to={"https://romio.mx/"} />
          <div className="admin-navbar__company">
            <div
              className="admin-navbar__company__container"
              onClick={() => setShowAdminDropdown((prev) => !prev)}
            >
              <Avatar
                className="admin-navbar__company__image"
                alt="Remy Sharp"
                src={imageProfile?.url ? imageProfile.url : navbarImages.bLogin}
              />
              <p className="admin-navbar__company__text">
                {capitalize(settings.name)}
              </p>
              <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />
            </div>
            {showAdminDropdown && (
              <div className="admin-navbar__dropdown">
                <Link
                  to={`/${t("settings", { ns: "routes" })}/${t("my_company", {
                    ns: "routes",
                  })}`}
                  onClick={() => setShowAdminDropdown(false)}
                  className="admin-navbar__dropdown__links"
                >
                  {t("admin_navbar.menu")}
                </Link>
                <Link
                  to={`/${t("settings", { ns: "routes" })}/${t("plans", {
                    ns: "routes",
                  })}`}
                  onClick={() => setShowAdminDropdown(false)}
                  className="admin-navbar__dropdown__links"
                >
                  {t("admin_navbar.plans")}
                  <Chip
                    label={t("navbar:admin_sidebar.work_in_progress")}
                    className="admin-navbar__dropdown__status"
                  />
                </Link>
                <Link
                  to={`/${t("settings", { ns: "routes" })}/${t("equipments", {
                    ns: "routes",
                  })}`}
                  onClick={() => setShowAdminDropdown(false)}
                  className="admin-navbar__dropdown__links"
                >
                  {t("admin_navbar.teams")}
                  <Chip
                    label={t("navbar:admin_sidebar.work_in_progress")}
                    className="admin-navbar__dropdown__status"
                  />
                </Link>
                <div className="dropdown-divider"></div>
                <div
                  className="admin-navbar__dropdown__logout"
                  onClick={auth.logout}
                >
                  <img src={navbarImages.logoutAdmin} alt="" />
                  <p>{t("admin_navbar.logout")}</p>
                </div>
              </div>
            )}
          </div>
          <div className="admin-navbar__notifications__container">
            <Badge
              className="admin-navbar__notifications"
              badgeContent={0}
              color="primary"
            >
              <NotificationsIcon
                color="action"
                onClick={() => setShowNotifications((prev) => !prev)}
              />
            </Badge>
            {showNotifications && windowSize > 987 && (
              <div className="admin-navbar__notifications__dropdown">
                <div className="admin-navbar__notifications__dropdown__title_container">
                  <h4 className="admin-navbar__notifications__dropdown__title">
                    {t("admin_navbar.notifications")}
                  </h4>
                  <span>{t("admin_navbar.see_all")}</span>
                </div>
                <div className="admin-navbar__all__notification__container">
                  <Notification notifications={notifications} />
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      {windowSize < 987 && (
        <AdminSidebar
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
      )}
      {windowSize > 987 && <StickySidebar />}
      {windowSize < 987 && (
        <NotificationsModal
          ref={ref}
          setOpen={() => setShowNotifications(false)}
          isOpen={showNotifications}
        />
      )}
    </>
  );
};

export const LinkTo = ({ to }: { to: string }) => {
  return (
    <div
      className="admin-navbar__link"
      onClick={() => window.open(to, "_blank")}
    >
      <img
        className="admin-navbar__link__logo"
        src={navbarImages.romioIcon}
        alt=""
      />
      <p>Ir a Romio</p>
      <img
        src={navbarImages.open}
        alt="open new tab"
        className="admin-navbar__link__open"
      />
    </div>
  );
};

export const Notification = ({
  notifications,
}: {
  notifications: Notification[];
}) => {
  const { t } = useTranslation(["navbar", "routes"]);

  if (!notifications.length) {
    return (
      <div className="admin-navbar__notification__container__empty">
        <NotificationsIcon
          color="action"
          className="admin-navbar__notification__container__empty__icon"
        />
        <h4>{t("admin_navbar.empty_notifications")}</h4>
        <p>{t("admin_navbar.notifications_click")}</p>
      </div>
    );
  }

  return (
    <>
      {notifications.map((notification, index) => (
        <div className="admin-navbar__notification__container">
          <div key={notification.user} className="admin-navbar__notification">
            {notification.status !== "seen" && (
              <CircleIcon
                fontSize="inherit"
                className="admin-navbar__notification__status"
                sx={{
                  color:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #B72446 0%, #A21398 100%)",
                }}
              />
            )}
            <div className="admin-navbar__notification__info__container">
              <div className="admin-navbar__notification__user">
                <p className="admin-navbar__notification__user__name">
                  {notification.user}
                </p>
                <span className="admin-navbar__notification__user__time">
                  {notification.time}
                </span>
              </div>
              <p className="admin-navbar__notification__information">
                {notification.information}
              </p>
            </div>
          </div>
          {notifications.length - 1 !== index && (
            <div className="dropdown-divider"></div>
          )}
        </div>
      ))}
    </>
  );
};
