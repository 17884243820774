import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import { homeFilter } from "../components/navHomeFilter/form";
import dayjs from "dayjs";

export const useTopFilter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["home", "routes"]);
  const queryClient = useQueryClient();

  const fetchHomeFilter = async (values: homeFilter) => {
    const params = {
      destinationId: values.destinationId?.toString() || "0",
      state: values.state || "",
      latitud: values.location.latitud.toString(),
      longitud: values.location.longitud.toString(),
      dateInitial: dayjs(values.dateInitial).format("DD/MM/YYYY"),
      hourInitial: values.hourInitial,
      dateEnd: dayjs(values.dateEnd).format("DD/MM/YYYY"),
      hourEnd: values.hourEnd,
      commercialName: values.commercialName || "",
    };

    const allParams = {
      ...params,
      experiences: "000000000",
      type: "0123456",
      transmission: "01",
      distance: "50",
    };

    navigate({
      pathname: "/" + t("results", { ns: "routes" }),
      search: `?${createSearchParams(allParams)}`,
    });

    queryClient.resetQueries(["results"]);
  };

  return fetchHomeFilter;
};
