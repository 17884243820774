export interface Address {
  id: number;
  carId: number;
  status: AddressStatus;
  type: AddressType;
  comercialName: string;
  completeAddress: string;
  intNumber?: string;
  number?: string;
  street: string;
  suburb: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  price?: string;
  placeId: string;
}

export enum AddressStatus {
  DEACTIVATED,
  ACTIVE,
}

export enum AddressType {
  MAIN_ADDRESS,
  EXTRA_ADDRESS,
  HOME_DELIVERY_ADDRESS,
  DRIVER_DELIVERY_ADDRESS,
}
