export interface Insurance {
  id: number;
  carId: number;
  status: InsuranceStatus;
  type: InsuranceType;
  charge: string;
  marketValue: string;
  depositAmount?: string;
  lastUpdate: string;
  customerPrice: string;
  result?: string;
}

export enum InsuranceStatus {
  DEACTIVATED,
  ACTIVE,
}

export enum InsuranceType {
  QUALITAS,
  HDI,
  HOST_INSURANCE,
}
