import { ChangeEvent, useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { useFormikContext } from "formik";
import { CarForm, CarPriceHookProps } from "../../../../views/car/add/types";
import { getHost } from "../../../../services/host";
import { useQuery } from "react-query";
import { getAddressesByCarID } from "../../../../services/address";
import { AddressType } from "../../../../models/address/types";
import { inputPricedProps } from "../editCarPrice/types";
import { getCar } from "../../../../services/car";
import {
  formatAddressFromDBToGoogle,
  getObjectOfAddresses,
} from "../../../helpers/addressHandler";
import { getInsuranceByCarId } from "../../../../services/insurance";

export const useAddCarPrice = ({ formErrors, carId }: CarPriceHookProps) => {
  const t = useContext(TranslationContext);
  const { data: host } = useQuery(["hostC"], () => getHost());
  const { data: car } = useQuery(["car"], () => getCar(carId));
  const [profit, setProfit] = useState<number>(0);
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const [cost, setCost] = useState<boolean>(true);

  const { data: addressCar } = useQuery(
    ["addresses", carId],
    () => getAddressesByCarID(parseInt(carId || "0")),
    {
      enabled: !!carId,
    }
  );

  const { data: insurance } = useQuery(
    ["insurance", carId],
    () => getInsuranceByCarId(parseInt(carId || "0")),
    {
      enabled: !!carId,
    }
  );

  // Count extra address available (max 4)
  const handleExtraAddressCounter = () => {
    if (
      Object.keys(values.extraAddresses).length >= 4 ||
      !values.extraAddresses
    )
      return;
    setFieldValue("extraAddresses", {
      ...values.extraAddresses,
      [Object.keys(values.extraAddresses).length]: {
        price: "",
      },
    });
  };

  useEffect(() => {
    car && setFieldValue("commission", car.commission);
  }, [car]);

  useEffect(() => {
    if (values.price && values.commission && values.commission !== "") {
      setProfit(+values.price - +values.price * (+values.commission / 100));
    }
  }, [values.price, car]);

  // Add new value when checked
  const handleHomeDelivery = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("homeDelivery", {
      ...values.homeDelivery,
      enable: event.target.checked,
      price: event.target.checked ? values.homeDelivery?.price : "",
    });
  };

  // Set to "" when its price is disabled
  const homeDeliveryCost = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFieldValue("homeDelivery", {
        ...values.homeDelivery,
        price: "",
      });
    }
    setCost(event.target.checked);
  };

  // Set input props
  const inputPricedProps: inputPricedProps = {
    variant: "outlined",
    error: formErrors?.includes("price"),
    label: t(
      "add.car_price.choose_price_and_availability.price_per_day.placeholder"
    ),
    required: true,
    id: "price_day",
  };

  // When get car id call function
  useEffect(() => {
    if (addressCar) {
      const mainAddress = addressCar.find(
        ({ type }) => type === AddressType.MAIN_ADDRESS
      );
      mainAddress &&
        setFieldValue("mainAddress", formatAddressFromDBToGoogle(mainAddress));

      const selectedExtraAddresses = addressCar.filter(
        ({ type }) => type === 1
      );

      setFieldValue(
        "extraAddresses",
        getObjectOfAddresses(selectedExtraAddresses)
      );

      const homeDelivery = addressCar.find(({ type }) => type === 2);

      setFieldValue("homeDelivery", {
        price: homeDelivery ? homeDelivery.price : "0",
        enable: homeDelivery ? homeDelivery.status === 1 : true,
      });
      setCost(homeDelivery?.price === undefined || homeDelivery?.price === "");
    }
  }, [addressCar]);

  useEffect(() => {
    if (insurance) {
      setFieldValue("depositAmount", insurance.depositAmount);
      setFieldValue("insuranceCharge", insurance.charge);
    }
  }, [insurance]);

  return {
    t,
    profit,
    values,
    setFieldValue,
    inputPricedProps,
    handleExtraAddressCounter,
    handleHomeDelivery,
    cost,
    homeDeliveryCost,
    host,
  };
};
