import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { EditDateBlock } from "../lib/components/carTools/editCarPrice/types";
import { isDateAvailable } from "../lib/helpers/dateHandler";

export const useBlockDates = ({
  blockDates,
  setDates,
}: {
  blockDates: EditDateBlock[];
  setDates: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
}) => {
  const handleBlockDates = (newValue: DateRange<Dayjs>) => {
    if (!newValue[0] || !newValue[1]) return true;
    if (!isDateAvailable(newValue, blockDates)) {
      setTimeout(() => setDates([null, null]), 500);
      return false;
    }

    return true;
  };

  return { handleBlockDates };
};
