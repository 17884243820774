import { useState, useEffect, useContext } from "react";
import {
  fileSrc,
  getFileDetailsFromPath,
  listFiles,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { getUserId } from "../../../../services/localstorage";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { useFormikContext } from "formik";
import { CarForm } from "../../../../views/car/add/types";
import { replaceIndex } from "../../../helpers/textHandler";
import { SelectChangeEvent } from "@mui/material";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function useAddCarDetails(carId: string | number) {
  const [goal, setGoal] = useState<string>("0");
  const [frequencyUse, setFrequencyUse] = useState<string>("0");
  const [frequencyShare, setFrequencyShare] = useState<string>("0");
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const userId = getUserId();
  const [circulationCardFile, setCirculationCardFile] = useState<
    fileSrc | undefined
  >();
  const [insurancePolicyFile, setInsurancePolicyFile] = useState<
    fileSrc | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get dinamically the image circulationCardFile
  useEffect(() => {
    (async () => {
      try {
        const result = await listFiles(
          `/${uploadioEnv}/user-${userId}/car-${carId}`
        );
        if (!result || !result.items || result.items.length == 0) return;

        // search the circulation id file
        const circulationFileIndex = result.items.findIndex(
          (f: { filePath?: string }) => {
            if (!f.filePath) return false;
            return f.filePath.includes("tarjeta_de_circulación");
          }
        );
        if (circulationFileIndex !== -1) {
          await getFileDetailsFromPath(
            result.items[circulationFileIndex].filePath,
            setCirculationCardFile
          );
        }

        // search the insurance policy file
        const insurancePolicyFileIndex = result.items.findIndex(
          (f: { filePath?: string }) => {
            if (!f.filePath) return false;
            return f.filePath.includes("poliza_de_seguro");
          }
        );

        if (insurancePolicyFileIndex !== -1) {
          await getFileDetailsFromPath(
            result.items[insurancePolicyFileIndex].filePath,
            setInsurancePolicyFile
          );
        }
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // When any QA dropdown updates
  const handleQA = (event: SelectChangeEvent<string>, question: string) => {
    switch (question) {
      case "goal":
        setFieldValue(
          "questionnaireAnswers",
          replaceIndex(values.questionnaireAnswers || "", 0, event.target.value)
        );
        setGoal(event.target.value);
        break;
      case "frequencyUse":
        setFieldValue(
          "questionnaireAnswers",
          replaceIndex(values.questionnaireAnswers || "", 1, event.target.value)
        );
        setFrequencyUse(event.target.value);
        break;
      case "frequencyShare":
        setFieldValue(
          "questionnaireAnswers",
          replaceIndex(values.questionnaireAnswers || "", 2, event.target.value)
        );
        setFrequencyShare(event.target.value);
        break;
    }
  };

  // Show correct status icon for the files
  const setStatusIcon = (type: "insurance" | "circulationCard") => {
    const item =
      type === "insurance" ? insurancePolicyFile : circulationCardFile;
    if (!!item) {
      return (
        <HistoryToggleOffIcon
          sx={{ color: "#ffab03", margin: "16px 6px 0 0" }}
        />
      );
    }
    return (
      <WarningAmberIcon sx={{ color: "#ffab03", margin: "16px 6px 0 0" }} />
    );
  };

  // Show correct status icon for the serial number
  const setStatusSerialNumberIcon = () => {
    if (values.status === 0 && values.niv === "") {
      return (
        <WarningAmberIcon sx={{ color: "#ffab03", margin: "16px 6px 0 0" }} />
      );
    }

    return (
      <HistoryToggleOffIcon sx={{ color: "#ffab03", margin: "16px 6px 0 0" }} />
    );
  };

  return {
    t,
    values,
    frequencyShare,
    setStatusIcon,
    setStatusSerialNumberIcon,
    setFieldValue,
    handleQA,
    goal,
    frequencyUse,
    circulationCardFile,
    setCirculationCardFile,
    insurancePolicyFile,
    setInsurancePolicyFile,
    isLoading,
  };
}
