import { useTranslation } from "react-i18next";
import carImages from "../../../../assets/images/a_cars/index";
import "./styles.scss";
import { useNavigate, Link } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { encryptId } from "../../../helpers/encrypt";

type AddCarCompletedProps = {
  carId: string;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setCreatedCarId: Dispatch<SetStateAction<string | null>>;
};

const AddCarCompleted = ({
  carId,
  setActiveStep,
  setCreatedCarId,
}: AddCarCompletedProps) => {
  const { t } = useTranslation(["car", "routes"]);
  const navigate = useNavigate();

  return (
    <div className="add-car-completed d-flex justify-content-center align-items-center flex-column">
      <Link
        to={`/${t("manage", { ns: "routes" })}/${t("my_cars", {
          ns: "routes",
        })}`}
        className="top-control d-flex"
      >
        <img src={carImages.arrowOrange} />
        <span>{t("add.completed_car_view.back_button")}</span>
      </Link>
      <div className="main d-flex flex-column justify-content-center align-items-center">
        <img src={carImages.addedSuccessfully} alt="car image" />
        <h4>{t("add.completed_car_view.title")}</h4>
        <p>{t("add.completed_car_view.text")}</p>
        <div className="btn-container">
          <Link
            className="btn-aqua btn-preview"
            to={{
              pathname: `/${t("details", { ns: "routes" })}/${encryptId(
                carId || "0"
              )}`,
              search: "?preview",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("add.completed_car_view.preview_button")}
          </Link>
          <button
            className="btn-orange"
            onClick={() => {
              setActiveStep(0);
              setCreatedCarId(null);
              // Reset car id value
              navigate(
                `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                  ns: "routes",
                })}/${t("upload_car", { ns: "routes" })}`
              );
            }}
          >
            {t("add.completed_car_view.add_car_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCarCompleted;
