import { createContext, Dispatch, SetStateAction } from "react";

export interface SortActions {
  setSortBy: Dispatch<SetStateAction<string>>;
}

export const SortContext = createContext<SortActions>({
  setSortBy: () => {
    //
  },
});
