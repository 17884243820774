import blackStage from "./black_stage.svg";
import carReservations from "./car_reservations.png";
import car2Reservations from "./car2_reservations.png";
import cardRun from "./card_run.png";
import checkVerified from "./check_verified.png";
import checkedStage from "./checked_stage.svg";
import hdi from "./hdi.png";
import info from "./info.svg";
import insurance from "./insurance.png";
import qualitas from "./qualitas.png";
import responsibilities from "./responsibilities.png";
import tips from "./tips.png";
import time from "./time.png";
import warning from "./warning.svg";

export default {
  blackStage,
  carReservations,
  car2Reservations,
  cardRun,
  checkVerified,
  checkedStage,
  hdi,
  info,
  time,
  insurance,
  qualitas,
  responsibilities,
  tips,
  warning,
};
