import apple from "./apple.png";
import check_radio from "./check_radio.svg";
import coupon from "./coupon.png";
import complete_profile from "./complete_profile.png";
import email from "./email.png";
import empty_radio from "./empty_radio.svg";
import empty_star from "./empty_star.svg";
import exp_user from "./exp_user.png";
import facebook from "./facebook.png";
import google from "./google.png";
import info_needed from "./info_needed.png";
import search_coupons from "./search_coupons.png";
import star from "./star.svg";
import tripCompleteProfile from "./trip_complete_profile.png";
import user from "./user.png";

export default {
  apple,
  check_radio,
  coupon,
  complete_profile,
  email,
  empty_radio,
  empty_star,
  exp_user,
  facebook,
  google,
  info_needed,
  search_coupons,
  star,
  tripCompleteProfile,
  user,
};
