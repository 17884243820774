export interface FileValidated {
  file: File;
  valid: boolean;
  id: number | string | undefined;
  errors?: string[];
}

export interface DocumentStatus {
  bankStatementComments: string;
  proofOfAddressComments: string;
  docProofOfAddress: SingleDocStatus;
  docBankStatement: SingleDocStatus;
}

export enum SingleDocStatus {
  notUploaded,
  inRevision,
  approved,
  retry,
}

export enum KycStatus {
  NOT_STARTED,
  PENDING,
  SUCCESS,
  FAIL,
}

export interface UploadedFileItem {
  filePath: string;
  fileUrl: string;
  lastModified: number;
  size: number;
  type: string;
}

export enum DocExpirationStatus {
  valid,
  aboutToExpire,
  expired,
}
