import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useQuery } from "react-query";
import { Card } from "../../../models/card/types";
import { getRegisteredCards } from "../../../services/card";
import {
  getDefaultCarPayDetails,
  getNewCarPayDetails,
  getPaymentIntent,
} from "../../../services/payment";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { getTrip } from "../../../services/trip";
import { useSearchParams } from "react-router-dom";

type SelectedPlan = {
  count: number;
  interval: string;
  type: string;
};

export default function useCreateReservation({
  newCardUsed,
  setSelectedCard,
  setOpenAlert,
  selectedCardId,
  tripId,
}: {
  newCardUsed: boolean;
  setSelectedCard: (cardItem: Card) => void;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  selectedCardId?: string;
  tripId?: string;
}) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<string | null>(null);
  const [cardPlanes, setCardPlanes] = useState([]);
  const [cardSelectedPlan, setCardSelectedPlan] = useState<SelectedPlan | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const { data: cards } = useQuery(["cards"], getRegisteredCards);
  const { data: trip } = useQuery(["trip"], () => getTrip(tripId), {
    enabled: !!tripId,
  });
  const { t } = useTranslation(["driverReservation"]);
  const [search] = useSearchParams();

  // Confirm payment request
  const confirmReservation = async (tripCode: string) => {
    setErrorMsg(null);
    try {
      if (!paymentIntent) return;
      const payload = !!cardSelectedPlan
        ? {
            tripCode: tripCode,
            paymentIntentId: paymentIntent,
            selectedPlan: {
              count: cardSelectedPlan.count,
              interval: cardSelectedPlan.interval,
              type: cardSelectedPlan.type,
            },
          }
        : {
            tripCode: tripCode,
            paymentIntentId: paymentIntent,
          };

      const response = await getPaymentIntent(payload);
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(
          error.response?.data.message === "User Insurance Data Incomplete"
            ? t("create.error_msg_complete")
            : error.response?.data.message
        );
        return error;
      }
    }
  };

  // Set default card to pay and get payment options details
  useEffect(() => {
    if (newCardUsed) return;
    if (cards) {
      const [defaultCardItem] = cards?.cards?.filter(
        (item: Card) => item.isDefault
      );
      setSelectedCard(defaultCardItem);
    }
  }, [cards]);

  useEffect(() => {
    if (!trip) return;

    if (trip.stripePayId === undefined || trip.stripePayId === "") {
      getDefaultCardPaymentInfo();
    } else {
      setPaymentIntent(trip.stripePayId);
    }
  }, [trip]);

  // Get payment information
  const getDefaultCardPaymentInfo = async () => {
    if (!tripId) return;
    let response;
    try {
      response = await getDefaultCarPayDetails(tripId);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.response?.data.message);
        setOpenAlert(true);
        return error;
      }
    }
    if (!response) return;
    setPaymentIntent(response.paymentId);
    if (response.plans?.length > 0) {
      setCardPlanes(response.plans);
    } else {
      setCardPlanes([]);
      setCardSelectedPlan(null);
    }
    setLoading(false);
  };

  // Get payment options for selected card
  useEffect(() => {
    getNewCardPaymentInfo();
  }, [selectedCardId]);

  const getNewCardPaymentInfo = async () => {
    setLoading(true);
    const paymentId = search.get("payment_intent");
    if (!selectedCardId || !paymentIntent || paymentId) return;
    // Get new payment details
    const response = await getNewCarPayDetails(paymentIntent, selectedCardId);
    if (!response) return;
    if (response.plans?.length > 0) {
      setCardPlanes(response.plans);
    } else {
      setCardPlanes([]);
      setCardSelectedPlan(null);
    }
    setLoading(false);
    return response.data;
  };

  return {
    setErrorMsg,
    confirmReservation,
    errorMsg,
    getDefaultCardPaymentInfo,
    getNewCardPaymentInfo,
    cardPlanes,
    setCardSelectedPlan,
    loading,
    paymentIntent,
  };
}
