import { createContext, Dispatch, SetStateAction } from "react";

export interface CustomizedState {
  state: string;
  startDate: string;
  endDate: string;
}

export interface TypeFilter {
  "0": boolean;
  "1": boolean;
  "2": boolean;
  "3": boolean;
  "4": boolean;
  "5": boolean;
  "6": boolean;
}

export interface ExperiencesFilter {
  "0": boolean;
  "1": boolean;
  "2": boolean;
  "3": boolean;
  "4": boolean;
  "5": boolean;
  "6": boolean;
  "7": boolean;
  "8": boolean;
}

export interface Filters {
  transmission: { 0: boolean; 1: boolean };
  type: TypeFilter;
  experiences: ExperiencesFilter;
  address: boolean;
  numBags?: number;
  numSeats?: number;
  depositAmount?: boolean;
  ecologyCar?: boolean;
  price: number[];
  distance: number;
}

export const FilterContext = createContext<
  [Filters, Dispatch<SetStateAction<Filters>>]
>([
  {
    transmission: { "0": true, "1": true },
    type: {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
    },
    experiences: {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
    },
    address: false,
    price: [100, 10000],
    distance: 50,
    ecologyCar: false,
    depositAmount: false,
  },
  {} as Dispatch<SetStateAction<Filters>>,
]);

export const mockFilters: Filters = {
  type: {
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
  },
  experiences: {
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
    "5": true,
    "6": true,
    "7": true,
    "8": true,
  },
  address: true,
  price: [100, 10000],
  distance: 50,
  ecologyCar: false,
  depositAmount: false,
  transmission: { 0: true, 1: true },
};
export interface resultParams {
  state: string;
  latitud: string;
  commercialName: string;
  longitud: string;
  dateInitial: string;
  dateEnd: string;
  hourInitial: string;
  hourEnd: string;
  transmission: string;
  experiences: string;
  numBags?: number | null;
  numSeats?: number | null;
  destinationId?: number;
  type: string;
  address?: string;
  ecologyCar?: string;
  depositAmount?: string;
  priceLowerBound: number;
  priceUpperBound: number;
  distance: number;
}

export const mockResultParams: resultParams = {
  state: "",
  latitud: "",
  longitud: "",
  dateInitial: "",
  commercialName: "",
  dateEnd: "",
  hourInitial: "",
  hourEnd: "",
  experiences: "000000000",
  type: "012345",
  transmission: "01",
  destinationId: 0,
  priceLowerBound: 0,
  priceUpperBound: 10000,
  distance: 50,
};

export const MAXPrice = "10000";
export const MINPrice = "0";
export const MINDistance = "50";
