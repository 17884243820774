import { useContext, useState, useEffect } from "react";
import {
  fileSrc,
  getFileDetailsFromPath,
  listFiles,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { getUserId } from "../../../../services/localstorage";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { SelectChangeEvent } from "@mui/material";
import { getCar, updateCar } from "../../../../services/car";
import { useFormik } from "formik";
import { editQASchema } from "../editCarPrice/form";
import { msgProps } from "../editCarPrice/types";
import { replaceIndex } from "../../../helpers/textHandler";
import { useQuery } from "react-query";
import { DocInsuranceCover, ValidateInfo } from "../../../../models/car/types";
import { UpdateDetailsCarDTO } from "../../../../models/car/dtos";
import { QA } from "./types";

export const useEditCarDetails = (carId: string) => {
  const { data: car } = useQuery(["car", carId], () => getCar(carId), {
    enabled: !!carId,
  });
  const t = useContext(TranslationContext);
  const [msg, setMsg] = useState<msgProps>({
    text: "",
    type: "error",
  });
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [deleted, setDeleted] = useState({
    vehicleLicense: false,
    insuranceCover: false,
  });
  const userId = getUserId();
  const [circulationCardFile, setCirculationCardFile] = useState<
    fileSrc | undefined
  >();
  const [insurancePolicyFile, setInsurancePolicyFile] = useState<
    fileSrc | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get dinamically the image circulationCardFile
  useEffect(() => {
    (async () => {
      try {
        const result = await listFiles(
          `/${uploadioEnv}/user-${userId}/car-${carId}`
        );
        if (!result || !result.items || result.items.length == 0) return;

        // search the circulation id file
        const circulationFileIndex = result.items.findIndex(
          (f: { filePath?: string }) => {
            if (!f.filePath) return false;
            return f.filePath.includes("tarjeta_de_circulación");
          }
        );
        if (circulationFileIndex !== -1) {
          await getFileDetailsFromPath(
            result.items[circulationFileIndex].filePath,
            setCirculationCardFile
          );
        }

        // search the insurance policy file
        const insurancePolicyFileIndex = result.items.findIndex(
          (f: { filePath?: string }) => {
            if (!f.filePath) return false;
            return f.filePath.includes("poliza_de_seguro");
          }
        );

        if (insurancePolicyFileIndex !== -1) {
          await getFileDetailsFromPath(
            result.items[insurancePolicyFileIndex].filePath,
            setInsurancePolicyFile
          );
        }
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (car) {
      // Set prev values of the fields
      formikEditQA.setFieldValue(
        "questionnaireAnswers",
        car.questionnaireAnswers
      );
      formikEditQA.setFieldValue("docInsuranceCover", car.docInsuranceCover);
      formikEditQA.setFieldValue("docVehicleLicense", car.docVehicleLicense);
      formikEditQA.setFieldValue("hasGPS", car.hasGPS);
      formikEditQA.setFieldValue("offerGPS", car.offerGPS);
      formikEditQA.setFieldValue("niv", car.niv);
    }
  }, [car]);

  // When submit send data
  const fetchEditQA = async (values: UpdateDetailsCarDTO) => {
    try {
      // Update document status and QA
      updateCar(parseInt(carId ? carId : "0") || 0, {
        docInsuranceCover: setUploadInsuranceCover(
          values.docInsuranceCover || DocInsuranceCover.NOT_UPLOADED
        ),
        docVehicleLicense: setUploadLicence(
          values.docVehicleLicense || ValidateInfo.NOT_UPLOADED
        ),
        questionnaireAnswers: values.questionnaireAnswers,
        hasGPS: values.hasGPS,
        offerGPS: values.offerGPS,
        niv: values.niv,
      });
      setMsg({
        text: t("edit.price_disponibility.success.update_car"),
        type: "success",
      });
    } catch (error) {
      setMsg({
        text: t("edit.price_disponibility.errors.update_car"),
        type: "error",
      });
    }
    setOpenAlert(true);
  };

  // Handle status of the insurance cover
  const setUploadInsuranceCover = (docInsuranceCover: DocInsuranceCover) => {
    switch (true) {
      case insurancePolicyFile == undefined:
        return DocInsuranceCover.NOT_UPLOADED;
      case insurancePolicyFile != undefined &&
        docInsuranceCover != DocInsuranceCover.VALIDATED:
        return DocInsuranceCover.PENDING;
      default:
        return docInsuranceCover;
    }
  };

  // Handle status of the license
  const setUploadLicence = (docVehicleLicense: ValidateInfo) => {
    switch (true) {
      case circulationCardFile == undefined:
        return ValidateInfo.NOT_UPLOADED;
      case circulationCardFile != undefined &&
        docVehicleLicense != ValidateInfo.VALIDATED:
        return ValidateInfo.PENDING;
      default:
        return docVehicleLicense;
    }
  };

  // When any QA dropdown updates
  const handleQA = (
    event: SelectChangeEvent<string>,
    question: "goal" | "frequencyUse" | "frequencyShare"
  ) => {
    if (!formikEditQA.values.questionnaireAnswers) return;

    // Update specific QA value
    formikEditQA.setFieldValue(
      "questionnaireAnswers",
      replaceIndex(
        formikEditQA.values.questionnaireAnswers,
        QA[question],
        event.target.value
      )
    );
  };

  // Initial form
  const formikEditQA = useFormik<UpdateDetailsCarDTO>({
    initialValues: {
      questionnaireAnswers: "",
      docInsuranceCover: 0,
      docVehicleLicense: 0,
      hasGPS: 0,
      offerGPS: 0,
      niv: "",
    },
    validationSchema: editQASchema,
    onSubmit: fetchEditQA,
  });

  return {
    t,
    car,
    circulationCardFile,
    setCirculationCardFile,
    insurancePolicyFile,
    setInsurancePolicyFile,
    isLoading,
    msg,
    openAlert,
    setOpenAlert,
    setDeleted,
    deleted,
    carId,
    handleQA,
    formikEditQA,
  };
};
