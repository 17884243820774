import {
  DatePosition,
  CreateDateDTO,
  UpdateDateDTO,
} from "./../models/date/dtos";
import { axiosService } from "./axios";
import { Dates } from "./../models/date/types";
import { getToken } from "./localstorage";
import { AxiosError } from "axios";

export const getDateByCarID = async (carId: number) => {
  const response = await axiosService.get<Dates[]>(`/date/${carId}`);

  return response.data || [];
};

export const createDate = async (DateDTO: CreateDateDTO) => {
  try {
    const data = await axiosService.post<Dates>("/date/", DateDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message) {
      return error;
    }
  }
};

export const updateDate = async (
  DateDTO: UpdateDateDTO,
  DatePosition: DatePosition
) => {
  try {
    const data = await axiosService.patch<Dates>(
      `/date/${DatePosition.id}`,
      DateDTO
    );
    return { data, index: DatePosition.index };
  } catch (error) {
    console.error(error);
  }
};

export const deleteDate = async (id: string) => {
  try {
    const data = await axiosService.delete<Dates>(`/date/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message) {
      return error;
    }
  }
};
