import { Uploader } from "uploader";
import { Upload } from "upload-js";
import axios from "axios";
import { SetStateAction } from "react";
import { imageUpload } from "../views/car/show/types";

export const uploadioEnv = process.env.REACT_APP_UPLOAD_IO_ENV;
const uploadClientId = process.env.REACT_APP_UPLOAD_CLIENT_ID;

// For react
const fileUploader = Uploader({
  apiKey: process.env.REACT_APP_UPLOAD_IO || "free",
  // debug: true,
});

// Vanilla Uploader
export const upload = Upload({
  apiKey: process.env.REACT_APP_UPLOAD_IO || "free",
  // debug: true,
});

export const listFiles = async (dirPath: string) => {
  try {
    const { data } = await axios.get(
      `https://api.bytescale.com/v2/accounts/${uploadClientId}/folders/list`,
      {
        params: {
          folderPath: dirPath,
          includeFiles: true,
        },
        headers: {
          Accept: "application/json",
        },
        auth: {
          username: "apikey",
          password: process.env.REACT_APP_UPLOAD_IO_ADMIN || "free",
        },
      }
    );
    return data;
  } catch (_) {
    return null;
  }
};

export const getFileDetailsIfExist = async (
  filePath: string,
  filePathList: string
) => {
  const ifExist = await listFiles(filePathList);
  const exist = ifExist.items.some(
    (item: imageUpload) => item.filePath === filePath
  );
  if (exist) {
    try {
      const { data } = await axios.get(
        `https://api.bytescale.com/v2/accounts/${uploadClientId}/files/details`,
        {
          params: {
            filePath,
          },
          headers: {
            Accept: "application/json",
          },
          auth: {
            username: "apikey",
            password: process.env.REACT_APP_UPLOAD_IO_ADMIN || "free",
          },
        }
      );
      return data;
    } catch (_) {
      return;
    }
  }
};

export const deleteFile = async (filePath: string) => {
  try {
    const { data } = await axios.delete(
      `https://api.bytescale.com/v2/accounts/${uploadClientId}/files`,
      {
        params: {
          filePath: filePath,
        },
        auth: {
          username: "apikey",
          password: process.env.REACT_APP_UPLOAD_IO_ADMIN || "free",
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const updateFile = async (
  filePath: string,
  file: File,
  type: string
) => {
  try {
    const { data } = await axios.post(
      `https://api.bytescale.com/v2/accounts/${uploadClientId}/uploads/binary?filePath=${filePath}`,
      file,
      {
        headers: {
          "Content-Type": type,
          Authorization: `Bearer ${process.env.REACT_APP_UPLOAD_IO_ADMIN}`,
        },
        auth: {
          username: "apikey",
          password: process.env.REACT_APP_UPLOAD_IO_ADMIN || "free",
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export type fileSrc = {
  url: string;
  mime: string;
};

export const getFileDetailsFromPath = async (
  path: string,
  setter: (value: SetStateAction<fileSrc | undefined>) => void
) => {
  const regex = /^(.*)\/[^\/]*$/;
  const match = path.match(regex) || "";
  const response = await getFileDetailsIfExist(path, match[1]);
  if (!response) return;
  // The file exists
  setter({
    url: response.fileUrl + "?version=" + new Date().toISOString(),
    mime: response.mime,
  });
};

export default fileUploader;
