export const statusList = [
  "",
  "pending",
  "next",
  "active",
  "finished",
  "refused",
  "refused",
  "cancel",
  "cancel",
  "cancel",
];
