import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { ChangeEvent, useContext, useEffect } from "react";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { CarForm } from "../../../../../views/car/add/types";
import { Accesories } from "../../../../../models/car/types";
import { getCar } from "../../../../../services/car";
import { useQuery } from "react-query";
import { setCharAt } from "../../../../helpers/textHandler";

export const AccesoriesList = ({ carId }: { carId: string }) => {
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const { data: car } = useQuery(["car"], () => getCar(carId));

  useEffect(() => {
    if (car) {
      setFieldValue("characteristicsList", car.characteristicsList);
    }
  }, [car]);

  // Set new accesories value
  const handleAccesories = (
    event: ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    const newCharacteristicsList = setCharAt(
      values.characteristicsList || Accesories.join(""),
      position,
      event.target.checked ? "1" : "0"
    );
    setFieldValue("characteristicsList", newCharacteristicsList);
  };

  // Set accesories that are active
  const handleActiveChar = (position: number) => {
    const categories = values.characteristicsList?.split("") || [""];
    return categories[position] === "1";
  };

  return (
    <div className="accesories-container" id="accesories">
      <div className="only-accesories">
        <div className="accesories-content">
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 0)}
                color="balety"
                checked={handleActiveChar(0)}
              />
            }
            label={t("add.car_documentation.accessories.electric/hybrid")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 1)}
                color="balety"
                checked={handleActiveChar(1)}
              />
            }
            label={t("add.car_documentation.accessories.convertible")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 2)}
                color="balety"
                checked={handleActiveChar(2)}
              />
            }
            label={t("add.car_documentation.accessories.leather_seats")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 3)}
                color="balety"
                checked={handleActiveChar(3)}
              />
            }
            label={t("add.car_documentation.accessories.solar_roof")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 4)}
                color="balety"
                checked={handleActiveChar(4)}
              />
            }
            label={t("add.car_documentation.accessories.gps")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                onChange={(e) => handleAccesories(e, 5)}
                checkedIcon={<CheckCircleIcon />}
                color="balety"
                checked={handleActiveChar(5)}
              />
            }
            label={t("add.car_documentation.accessories.pets_welcome")}
          />
        </div>
        <div className="accesories-content">
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 6)}
                color="balety"
                checked={handleActiveChar(6)}
              />
            }
            label={t("add.car_documentation.accessories.usb_connection")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 7)}
                color="balety"
                checked={handleActiveChar(7)}
              />
            }
            label={t("add.car_documentation.accessories.bluetooth")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 8)}
                color="balety"
                checked={handleActiveChar(8)}
              />
            }
            label={t("add.car_documentation.accessories.car_play")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 9)}
                color="balety"
                checked={handleActiveChar(9)}
              />
            }
            label={t("add.car_documentation.accessories.android_auto")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 10)}
                color="balety"
                checked={handleActiveChar(10)}
              />
            }
            label={t("add.car_documentation.accessories.aux_input")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 11)}
                color="balety"
                checked={handleActiveChar(11)}
              />
            }
            label={t("add.car_documentation.accessories.air_conditioning")}
          />
        </div>
        <div className="accesories-content">
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 12)}
                color="balety"
                checked={handleActiveChar(12)}
              />
            }
            label={t("add.car_documentation.accessories.panoramic_ceiling")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 13)}
                color="balety"
                checked={handleActiveChar(13)}
              />
            }
            label={t("add.car_documentation.accessories.bike_rack")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 14)}
                color="balety"
                checked={handleActiveChar(14)}
              />
            }
            label={t("add.car_documentation.accessories.4wd")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 15)}
                color="balety"
                checked={handleActiveChar(15)}
              />
            }
            label={t("add.car_documentation.accessories.child_seat")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 16)}
                color="balety"
                checked={handleActiveChar(16)}
              />
            }
            label={t("add.car_documentation.accessories.seat_heater")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 17)}
                color="balety"
                checked={handleActiveChar(17)}
              />
            }
            label={t(
              "add.car_documentation.accessories.electric_seat_adjustment"
            )}
          />
        </div>
        <div className="accesories-content">
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 18)}
                color="balety"
                checked={handleActiveChar(18)}
              />
            }
            label={t("add.car_documentation.accessories.power_windows")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 19)}
                color="balety"
                checked={handleActiveChar(19)}
              />
            }
            label={t("add.car_documentation.accessories.video_player")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 20)}
                color="balety"
                checked={handleActiveChar(20)}
              />
            }
            label={t("add.car_documentation.accessories.cd_player")}
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onChange={(e) => handleAccesories(e, 21)}
                color="balety"
                checked={handleActiveChar(21)}
              />
            }
            label={t("add.car_documentation.accessories.satellite_radio")}
          />
        </div>
      </div>
    </div>
  );
};
