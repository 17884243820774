import { User, UserAccountStatus, UserStatus } from "../../models/user/types";
import { DocumentStatus, KycStatus } from "../../views/profile/types";

// Get only car photos
export const isCarImage = (url: string) => {
  return url.split("/")[4].slice(0, -2) == "car_photo";
};

// Handle permitions to create reservation
export const isDisabled = (
  userData: User | undefined,
  metamapStatus: DocumentStatus | null | undefined
) => {
  if (!userData || !metamapStatus) return true;

  // Conditions to be able to rent
  return !(
    (userData.status === UserAccountStatus.ACTIVE ||
      userData.status === UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL) &&
    userData.docOfficialDocuments === KycStatus.SUCCESS &&
    userData.docProofOfAddress === 2 &&
    userData.isBlocked === UserStatus.VALID
  );
};
