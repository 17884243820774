import { useTranslation } from "react-i18next";
import helpImages from "../../../../assets/images/help";
import "./styles.scss";

export const ResponsibilitiesBanner = () => {
  const { t } = useTranslation(["driverReservation"]);
  return (
    <div
      className="responsibilities-container-banner"
      onClick={() =>
        window.open(
          "https://ayuda.balety.com/articulos/responsabilidades-de-huesped",
          "_blank"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="responsibilities-summary">
        <div className="card">
          <div className="image-container">
            <img
              src={helpImages.responsabilitiesBanner}
              alt="qualitas"
              className="card-image"
            />
          </div>
          <div className="card-body">
            <h4 className="title">
              {t("create.responsibilities_summary.title")}
            </h4>
            <p className="main-information">
              {t("create.responsibilities_summary.main_information")}
            </p>
            <div className="know-more">
              <a>
                {t("create.know_more")}
                <img src={helpImages.arrowRightBlue} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
