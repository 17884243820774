import { useTranslation } from "react-i18next";
import authenticationImages from "../../../assets/images/authentication";
import "./styles.scss";
import { useFormik } from "formik";
import { resetPasswordForm, resetPasswordSchema } from "./form";
import { useProgressBar } from "../../../hooks/progress";
import { useState } from "react";
import { updatePassword } from "../../../services/forgotPassword";
import { sha256 } from "js-sha256";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPasswordForm = () => {
  const { t } = useTranslation(["forgotPassword", "routes"]);
  const [password, setPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get("token");

  const { setPasswordStrengh, getActiveColor, message, progress } =
    useProgressBar();

  const fetchResetPassword = async (values: resetPasswordForm) => {
    if (!token) return;
    const response = await updatePassword({
      newPassword: sha256(values.password),
      token,
    });

    if (response && response.status === 200) {
      navigate(
        "/" +
          t("results", {
            ns: "routes",
          })
      );
    }
  };

  const formikResetPassword = useFormik<resetPasswordForm>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: fetchResetPassword,
  });

  const styles = {
    SendButton: {
      backgroundColor:
        formikResetPassword.isValid && formikResetPassword.dirty
          ? "#f76f8e"
          : "#828282",
    },
  };

  return (
    <div className="reset-password d-flex justify-content-center align-items-center flex-column">
      <div className="main d-flex flex-column justify-content-center align-items-center">
        <h4 className="title">{t("reset_password.title")}</h4>
        <p className="subtitle">{t("reset_password.subtitle")}</p>
        <div className="form-container">
          <div className="input-form">
            <label className="password-label">
              {t("reset_password.password.label")}
            </label>
            <div className="input-group">
              <input
                name="password"
                id="txtpassword"
                data-testid="password-input"
                style={{
                  borderColor:
                    formikResetPassword.errors.password &&
                    formikResetPassword.touched.password
                      ? "var(--Semantic-Colors-Error)"
                      : "var(--Utility-Colors-Secondary-Background)",
                }}
                className="password-input"
                placeholder={t("reset_password.password.placeholder")}
                onChange={(e) => {
                  formikResetPassword.setFieldValue("password", e.target.value);
                  setPasswordStrengh(e.target.value);
                }}
                onBlur={formikResetPassword.handleBlur("password")}
                value={formikResetPassword.values.password}
                type={password ? "text" : "password"}
              />
              <div
                id="eye_password"
                className="input-group-addon"
                style={{
                  borderColor:
                    formikResetPassword.errors.password &&
                    formikResetPassword.touched.password
                      ? "var(--Semantic-Colors-Error)"
                      : "var(--Utility-Colors-Secondary-Background)",
                }}
              >
                <img
                  src={
                    password
                      ? authenticationImages.eye
                      : authenticationImages.crossEye
                  }
                  onClick={() => setPassword((password) => !password)}
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                id="progress"
                style={{
                  width: progress,
                  backgroundColor: getActiveColor(message),
                  transition: "width 2s",
                }}
              ></div>
            </div>
          </div>
          <div className="input-form">
            <label className="confirm-password-label">
              {t("reset_password.confirm_password.label")}
            </label>
            <div className="input-group">
              <input
                name="confirmPassword"
                style={{
                  borderColor:
                    formikResetPassword.errors.confirmPassword &&
                    formikResetPassword.touched.confirmPassword
                      ? "var(--Semantic-Colors-Error)"
                      : "var(--Utility-Colors-Secondary-Background)",
                }}
                className="confirm-password-input"
                placeholder={t("reset_password.confirm_password.placeholder")}
                onChange={formikResetPassword.handleChange("confirmPassword")}
                onBlur={formikResetPassword.handleBlur("confirmPassword")}
                type={confirmPassword ? "text" : "password"}
                value={formikResetPassword.values.confirmPassword}
              />
              <div
                className="input-group-addon"
                style={{
                  borderColor:
                    formikResetPassword.errors.confirmPassword &&
                    formikResetPassword.touched.confirmPassword
                      ? "var(--Semantic-Colors-Error)"
                      : "var(--Utility-Colors-Secondary-Background)",
                }}
              >
                <img
                  src={
                    confirmPassword
                      ? authenticationImages.eye
                      : authenticationImages.crossEye
                  }
                  onClick={() => setConfirmPassword(!confirmPassword)}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-send"
              style={styles.SendButton}
              onClick={() => formikResetPassword.handleSubmit()}
            >
              {t("reset_password.confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
