import always from "./always.webp";
import checkInsurance from "./check_insurance.svg";
import clarity from "./clarity.webp";
import community from "./community.png";
import currentLocation from "./current_location.svg";
import destination from "./destination.svg";
import easyRent from "./easy_rent.webp";
import earnMoney from "./earn_money.webp";
import emptyHeart from "./empty_heart.svg";
import fairPrices from "./fair_prices.webp";
import facebook from "./facebook.svg";
import firstTestimony from "./first_testimony.webp";
import gps from "./gps.png";
import insurance from "./insurance.webp";
import main from "./main.webp";
import mainMobile from "./main_mobile.webp";
import share from "./share.png";
import safeTravel from "./safe_travel.webp";
import securityAndTrust from "./security_and_trust.webp";
import secondTestimony from "./second_testimony.webp";
import steps from "./steps.webp";
import stepsMobile from "./steps_mobile.webp";
import thirdTestimony from "./third_testimony.webp";
import totalAssistance from "./total_assistance.webp";
import transparency from "./transparency.webp";
import trustedCommunity from "./trusted_community.webp";
import tips from "./tips.png";
import twitter from "./twitter.svg";
import unFour from "./un_four.png";
import unOne from "./un_one.png";
import unPrevDes from "./un_prev_des.svg";
import unStar from "./un_star.svg";
import unThree from "./un_three.png";
import unTwo from "./un_two.png";
import upArrow from "./up_arrow.svg";
import whatsapp from "./whatsapp.svg";

export default {
  always,
  checkInsurance,
  clarity,
  community,
  currentLocation,
  destination,
  easyRent,
  earnMoney,
  emptyHeart,
  fairPrices,
  facebook,
  firstTestimony,
  gps,
  insurance,
  main,
  mainMobile,
  share,
  safeTravel,
  securityAndTrust,
  secondTestimony,
  steps,
  stepsMobile,
  thirdTestimony,
  totalAssistance,
  transparency,
  trustedCommunity,
  twitter,
  tips,
  unFour,
  unOne,
  unPrevDes,
  unStar,
  unThree,
  unTwo,
  upArrow,
  whatsapp,
};
