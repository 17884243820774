import * as Yup from "yup";
import {
  CarStatus,
  CarType,
  Deactivated,
  Transmission,
  ValidateInfo,
  DocInsuranceCover,
} from "../../../models/car/types";
import { CarForm } from "./types";

export const carSchema: Yup.SchemaOf<CarForm> = Yup.object().shape({
  userId: Yup.number().required(),
  brand: Yup.string().required(),
  model: Yup.string().required(),
  version: Yup.string().required(),
  year: Yup.string().required(),
  plate: Yup.string(),
  niv: Yup.string(),
  transmission: Yup.mixed().oneOf(Object.values(Transmission)).nullable(),
  type: Yup.mixed().oneOf(Object.values(CarType)).required().nullable(),
  numSeats: Yup.number().nullable(),
  price: Yup.string(),
  questionnaireAnswers: Yup.string(),
  characteristicsList: Yup.string(),
  status: Yup.mixed().oneOf(Object.values(CarStatus)),
  description: Yup.string(),
  rules: Yup.string(),
  commission: Yup.string(),
  isReadyByHost: Yup.mixed().oneOf(Object.values(Deactivated)),
  extraAddresses: Yup.object(),
  mainAddress: Yup.object().shape({
    addressComponents: Yup.object(),
    formatted_address: Yup.string(),
    geometry: Yup.object().shape({
      location: Yup.object().shape({
        lat: Yup.string(),
        lng: Yup.string(),
      }),
      viewport: Yup.object().shape({
        Cb: Yup.object().shape({ lo: Yup.number(), hi: Yup.number() }),
        Va: Yup.object().shape({ lo: Yup.number(), hi: Yup.number() }),
      }),
    }),
    html_attributions: Yup.array(),
    place_id: Yup.string(),
  }),
  insuranceCharge: Yup.string(),
  depositAmount: Yup.string(),
  homeDelivery: Yup.object().shape({
    enable: Yup.boolean(),
    price: Yup.string(),
  }),
  numBags: Yup.number().nullable(),
  isEcoFriendly: Yup.number().nullable(),
  experiences: Yup.string(),
  docInsuranceCover: Yup.mixed().oneOf(Object.values(ValidateInfo)),
  docVehicleLicense: Yup.mixed().oneOf(Object.values(DocInsuranceCover)),
  blockDates: Yup.array().notRequired(),
  hasGPS: Yup.number(),
  offerGPS: Yup.number(),
});
