import { useState } from "react";

export const useProgressBar = () => {
  const [progress, setProgress] = useState("0%");
  const [message, setMessage] = useState("");

  const setPasswordStrengh = (password: string) => {
    const strengthChecks = {
      length: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = password.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(password);
    strengthChecks.hasLowerCase = /[a-z]+/.test(password);
    strengthChecks.hasDigit = /[0-9]+/.test(password);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(password);

    const verifiedList = Object.values(strengthChecks).filter((value) => value);

    const strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";

    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);
  };

  const getActiveColor = (type: string) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };

  return {
    progress,
    message,
    getActiveColor,
    setPasswordStrengh,
  };
};
