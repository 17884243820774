import { Alert, Snackbar } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface ToastProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type: "success" | "error" | "warning";
  children: string | null;
}

const Toast = ({ open, setOpen, type, children }: ToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={20000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        onClose={() => setOpen(false)}
        severity={type}
        data-testid="toast"
      >
        {type === "error" ? `Error: ${children}` : children}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
