import { placeComponentCrash } from "../../views/home/types";
import { addressComponentEdit } from "./../components/carTools/editCarModel/form";
import { AddressStatus, AddressType } from "../../models/address/types";
import { createAddress } from "../../services/address";
import {
  addressComponent,
  addressComponents,
  placeComponents,
} from "../../views/home/types";
import Geocode from "react-geocode";
import { formatAddressCommercialName } from "./addressHandler";
import { isPointInPolygon } from "geolib";
import { Coordinates, Destination } from "../../models/destination/types";

export const findState = (place: Array<addressComponent>) => {
  const components_by_type: addressComponents = {};
  place.map((component: addressComponent) => {
    components_by_type[component.types[0]] = component;
  });

  return (
    components_by_type["administrative_area_level_1"]?.long_name || "Jalisco"
  );
};

export const findLatitudLongitud = (place: placeComponentCrash) => {
  return {
    latitud: place.geometry.location.lat(),
    longitud: place.geometry.location.lng(),
  };
};

interface ComponentsByTypeProps {
  [index: string]: string;
}

export const getAllAddressComponents = (
  place: addressComponent[] | addressComponentEdit[]
) => {
  if (!place) return;
  const components_by_type: ComponentsByTypeProps = {};
  place.map((component: addressComponent | addressComponentEdit) => {
    components_by_type[component.types[0]] = component.long_name || "";
  });

  return components_by_type;
};

export const getNewAddress = async (
  carId: number,
  price: number,
  address: placeComponents
) => {
  if (!address.address_components) return;
  const addressItem = getAllAddressComponents(address.address_components);

  if (!addressItem) return;

  try {
    const city = addressItem.locality || "";
    const state = addressItem.administrative_area_level_1 || "";
    const country = addressItem.country || "";
    const zipCode = addressItem.postal_code || "";
    const name = address.name || "";
    const comercialName = formatAddressCommercialName({
      name,
      zipCode,
      country,
      state,
      city,
    });
    const response = await createAddress({
      carId: carId,
      status: AddressStatus.ACTIVE,
      type: AddressType.DRIVER_DELIVERY_ADDRESS,
      comercialName,
      completeAddress: address.formatted_address || "",
      intNumber: "0",
      number: addressItem.street_number || "0",
      street: addressItem.route || "",
      suburb: addressItem.sublocality_level_1 || "",
      city,
      state,
      country,
      zipCode,
      latitude: address?.geometry?.location?.lat
        ? address?.geometry?.location?.lat.toString()
        : "0",
      longitude: address?.geometry?.location?.lng
        ? address?.geometry?.location?.lng.toString()
        : "0",
      placeId: address.place_id || "0",
      price: price.toString(),
    });

    return response;
  } catch (error) {
    console.error("Error creating new address:", error);
  }
};

export const findByLatitudLongitud = async (lat: string, lng: string) => {
  try {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS || "");
    const response: { results: placeComponentCrash[] } =
      await Geocode.fromLatLng(lat, lng);
    return response.results[0];
  } catch (error) {
    console.error(error);
  }
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const handleAutocompleteInput = (place: any) => {
  const addressItem = getAllAddressComponents(place.address_components);

  if (!addressItem) return place.formatted_address;

  const city = addressItem.locality || "";
  const state = addressItem.administrative_area_level_1 || "";
  const country = addressItem.country || "";
  const zipCode = addressItem.postal_code || "";
  const name = place.name || "";
  const comercialName = formatAddressCommercialName({
    name,
    zipCode,
    country,
    state,
    city,
  });

  return comercialName;
};

export const getDestination = ({
  destinations,
  point,
}: {
  destinations: Destination[];
  point: Coordinates;
}) => {
  return destinations.filter((destination) => {
    return isPointInPolygon(point, destination.coordinates);
  });
};
