import addImage from "./add_image.svg";
import anfitrionComercial from "./anfitrion_comercial.png";
import carSignup from "./car_signup.png";
import change from "./change.png";
import checkGreen from "./check_green.svg";
import check from "./check.svg";
import comercialSignup from "./comercial_signup.png";
import completeProfile from "./complete_profile.png";
import crossEye from "./cross_eye.svg";
import deleteImage from "./delete_image.png";
import depositAccount from "./deposit_account.png";
import edit from "./edit.svg";
import eye from "./eye.svg";
import forgotten_password from "./forgotten_password.png";
import forget from "./forget.png";
import info from "./info.svg";
import invite from "./invite.png";
import password from "./password.png";
import payMethod from "./pay_method.png";
import reloadImage from "./reload_image.png";
import shareCar from "./share_car.png";
import signup from "./signup.png";
import profileDefault from "./profile.png";

export default {
  addImage,
  anfitrionComercial,
  carSignup,
  change,
  checkGreen,
  check,
  comercialSignup,
  completeProfile,
  crossEye,
  deleteImage,
  depositAccount,
  edit,
  eye,
  forget,
  forgotten_password,
  info,
  invite,
  password,
  payMethod,
  reloadImage,
  shareCar,
  signup,
  profileDefault,
};
