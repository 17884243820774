import homeImages from "../../../../../assets/images/home/h_homepage/index";
import { useTranslation } from "react-i18next";
import "./ProtectGPSBanner.scss";
import { useWindowSize } from "../../../../../hooks/windowSize";
import helpImages from "../../../../../assets/images/help";

export const ProtectGPSBanner = () => {
  const { t } = useTranslation(["car"]);
  const windowSize = useWindowSize();

  return (
    <div
      className="tips-container-banner"
      onClick={() =>
        window.open(
          "https://ayuda.balety.com/articulos/protege-tu-auto-con-gps",
          "_blank"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="tips-summary">
        <div className="card">
          <div className="image-container">
            <img src={homeImages.gps} alt="tips" className="card-image" />
          </div>
          <div className="card-body">
            <h4 className="title">{t("index.banners.gps.title")}</h4>
            {windowSize > 1108 && (
              <p className="main-information">
                {t("index.banners.gps.main_information")}
              </p>
            )}

            <div className="know-more">
              <a>
                {t("index.banners.know_more")}
                <img src={helpImages.arrowRightBlue} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
