//import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Modal } from "@mui/material";
import { useContext, useState, Dispatch, SetStateAction } from "react";
import orderImages from "../../../assets/images/order";
import { TranslationContext } from "../../../views/driverReservation/show/component";
import "./styles.scss";
import { LeftFilter } from "../../../lib/components/resultsTools/leftFilter/component";
import { resultParams } from "../../../views/results/types";
import useResults from "../../../hooks/results/useResults";
import { FilterContext } from "../../../views/results/types";
import { SortDropdown } from "../../../lib/components/resultsTools/sortDropdown/component";
import navbarImages from "../../../assets/images/navbar";

export const FilterCar = ({
  params,
  setParams,
  counter,
  setPage,
}: {
  params: resultParams;
  setParams: Dispatch<SetStateAction<resultParams>>;
  counter: string;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  const t = useContext(TranslationContext);
  const [open, setOpen] = useState<boolean>(false);
  const [filters] = useContext(FilterContext);
  const { handleSubmit, loading } = useResults(params, setPage);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="filter-car">
      <span className="resultsM">
        {t("counter")} {counter}
      </span>
      <Button onClick={handleOpen} className="btn btn-order">
        <img src={orderImages.list} alt="" />
        <p>{t("filter.sort_and_filter")}</p>
      </Button>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="modal-content-order">
          <div className="modal-header modal-filter-header">
            <Button
              type="button"
              onClick={handleOpen}
              className="btn btn-orange btn-Filter"
            >
              <img src={navbarImages.crossBlack} alt="Close" />
            </Button>
            <h2>{t("filter.sort_and_filter")}</h2>
            <span className="hidden">{t("filter.erase")}</span>
          </div>
          <div className="modal-body">
            <SortDropdown />
            <LeftFilter
              params={params}
              setParams={setParams}
              setPage={setPage}
            />
          </div>
          <div className="modal-footer">
            <Button
              type="button"
              onClick={() => {
                handleOpen();
                handleSubmit(filters, setParams);
              }}
              disabled={loading}
              className="btn btn-orange btn-Filter"
            >
              {t("filter.apply")}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
