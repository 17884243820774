import { FC } from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTranslation } from "react-i18next";

export const ReasonCancel: FC<ModalProps> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation(["modals"]);
  const navigate = useNavigate();

  return (
    <Modal show={isOpen} className="reason-cancel-modal">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setOpen;
            navigate(
              "/" +
                t("trips", {
                  ns: "routes",
                })
            );
          }}
        >
          <img src={navbarImages.cross} alt="" />
        </button>
        <h2>{t("reason_cancel.title")}</h2>
      </div>
      <div className="modal-body">
        <div className="reasons-modal-container" id="reasons">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="balety"
                />
              }
              label={t("reason_cancel.no_need_car")}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="balety"
                />
              }
              label={t("reason_cancel.search_option")}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="balety"
                />
              }
              label={t("reason_cancel.bigger_car")}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="balety"
                />
              }
              label={t("reason_cancel.change_plans")}
            />
          </div>
        </div>
        <p className="text-left another-reason bold">
          {t("reason_cancel.other")}
        </p>
        <textarea
          className="comment-box"
          placeholder={t("reason_cancel.type_here")}
        ></textarea>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-orange"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() =>
            navigate(
              "/" +
                t("trips", {
                  ns: "routes",
                })
            )
          }
        >
          <p>{t("reason_cancel.send")}</p>
        </button>
      </div>
    </Modal>
  );
};
