import { Dispatch, SetStateAction } from "react";
import { Modal, Button } from "@mui/material";
import "./styles.scss";
import { useTranslation } from "react-i18next";

type CarOptionsProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type?: string;
  action?: () => void;
  isLoading: boolean;
  isEmpty: boolean;
};

const CarOptions = ({
  open,
  setOpen,
  type,
  action,
  isLoading,
  isEmpty,
}: CarOptionsProps) => {
  const { t } = useTranslation(["car"]);

  const setContent = (textType: "text" | "title" | "boldText") => {
    if (isEmpty && type === "delete") {
      return textType === "text"
        ? t("index.modals.options.can_not_delete_text")
        : textType === "boldText"
        ? ""
        : t("index.modals.options.can_not_delete_title");
    } else if (type === "delete") {
      return textType === "text"
        ? t("index.modals.options.delete_text")
        : textType === "boldText"
        ? t("index.modals.options.delete_bold_text")
        : t("index.modals.options.delete_title");
    } else if (type === "pause") {
      return textType === "text"
        ? t("index.modals.options.pause_text")
        : textType === "boldText"
        ? t("index.modals.options.pause_bold_text")
        : t("index.modals.options.pause_title");
    } else if (type === "pause-remove") {
      return textType === "text"
        ? t("index.modals.options.remove_pause_text")
        : textType === "boldText"
        ? t("index.modals.options.remove_pause_bold_text")
        : t("index.modals.options.remove_pause_title");
    }
  };

  if (!type) return null;
  return (
    <Modal
      open={open}
      className={"car-options-modal"}
      aria-labelledby=""
      aria-describedby=""
      onClose={() => setOpen(false)}
    >
      <div
        className={
          type === "delete" && isEmpty
            ? "car-options-container empty"
            : "car-options-container"
        }
      >
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => setOpen(false)}
          ></button>
        </div>
        <h4>{setContent("title")}</h4>
        {setContent("boldText") ? (
          <p>
            {setContent("text")} <span>{setContent("boldText")}</span>
          </p>
        ) : (
          <p>{setContent("text")}</p>
        )}
        {(type === "pause" || type === "pause-remove" || !isEmpty) &&
          action && (
            <div className="btns">
              <Button
                onClick={() => setOpen(false)}
                className="outlined"
                data-testid="cancel-delete-car"
              >
                {t("index.modals.options.cancel")}
              </Button>
              <Button onClick={action} disabled={isLoading} className="mt-2">
                {t("index.modals.options.continue")}
              </Button>
            </div>
          )}
      </div>
    </Modal>
  );
};

export default CarOptions;
