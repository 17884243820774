import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import orderImages from "../../../../assets/images/order";
import { SortContext } from "./types";

export const SortDropdown = () => {
  const { t } = useTranslation(["results"]);
  const { setSortBy } = useContext(SortContext);

  return (
    <FormControl className="form__select-sort">
      <InputLabel id="sort_by" data-testid="sort_by">
        {t("filter.sort_by.title")}
      </InputLabel>
      <Select
        labelId="sort_by"
        id="sort_by"
        defaultValue={1}
        placeholder=""
        className="dropdown"
        MenuProps={{
          PaperProps: {
            sx: { borderRadius: "8px", border: "none" },
          },
        }}
        sx={{ backgroundColor: "#ECF7F8" }}
      >
        <MenuItem
          key={1}
          value={1}
          className="sort-list"
          onClick={() => setSortBy("RECENT")}
        >
          {t("filter.sort_by.recent")}
        </MenuItem>

        <MenuItem
          key={2}
          value={2}
          className="sort-list"
          onClick={() => setSortBy("MODEL")}
        >
          {t("filter.sort_by.last_models")}
        </MenuItem>
        <MenuItem
          key={3}
          value={3}
          className="sort-list"
          onClick={() => setSortBy("LESS_MORE_PRICE")}
        >
          {t("filter.sort_by.less_more")}
          <img src={orderImages.sortUp} className="icon-sort mr-1" />
        </MenuItem>
        <MenuItem
          key={4}
          value={4}
          className="sort-list"
          onClick={() => setSortBy("MORE_LESS_PRICE")}
        >
          {t("filter.sort_by.more_less")}
          <img src={orderImages.sortDown} className="icon-sort mr-1" />
        </MenuItem>
        <MenuItem
          key={5}
          value={5}
          className="sort-list"
          onClick={() => setSortBy("DISTANCE")}
        >
          {t("filter.sort_by.distance")}
          <img src={orderImages.sortDown} className="icon-sort mr-1" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
