import { getToken } from "./localstorage";
import { axiosService, moffinService } from "./axios";
import { SingleDocStatus } from "../views/profile/types";
import { MoffinUrl } from "../components/profileDocuments/types";

export const updateDocumentStatus = async (
  status: SingleDocStatus,
  type: "bankStatement" | "proofOfAddress"
) => {
  const payload =
    type === "bankStatement"
      ? { docBankStatement: status }
      : { docProofOfAddress: status };
  try {
    const response = await axiosService.patch(
      "/account/updatemanualdocstatus",
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMoffin = async (redirectURL: string) => {
  try {
    const { data } = await moffinService.post<MoffinUrl>(
      "query/id_validation/jumio",
      {
        successURL: redirectURL + "/kyc-success",
        failURL: redirectURL + "/kyc-success",
      }
    );

    return data;
  } catch (error) {
    return null;
  }
};
