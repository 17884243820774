import { FC } from "react";
import { Trip } from "../../../../models/trip/types";
import { Car } from "../../../../models/car/types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useWindowSize } from "../../../../hooks/windowSize";
import { TripImage } from "../tripImage/TripImage";
import { CarSpecs } from "../../carTools/carForm/cardInfo/CardInfo";
import { AddressText } from "../addressText/AddressText";

export const RentTable: FC<{ trip: Trip; car: Car }> = ({ trip, car }) => {
  const { t } = useTranslation(["driverReservation", "car"]);
  const windowSize = useWindowSize();

  if (windowSize < 1008) {
    return (
      <div className="card rent-info">
        <div className="card-header">
          <h2>{t("create.your_reservation")}</h2>
        </div>
        <div className="card-body">
          <p className="car-name txtCar">
            {car.brand + " " + car.model + " " + car.year}
          </p>
          {car && <TripImage car={car} />}
          {car && <CarSpecs car={car} className="create-trip" />}
          <div className="un-container-date">
            <div className="un-desde">
              <label className="w-100">{t("create.since")}</label>
              <div className="input-container">
                <div className="input-text mb-0">
                  <p className="txtPickUpDate">
                    {dayjs(trip.pickUpDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="dropdown-divider"></div>
                <div className="input-text mb-0">
                  <p className="txtPickUpTime">
                    {dayjs(trip.pickUpTime, "HH:mm:ss").format("hh:mm A")}
                  </p>
                </div>
              </div>
            </div>
            <div className="un-hasta">
              <label>{t("create.until")}</label>
              <div className="input-container">
                <div className="input-text mb-0">
                  <p className="txtReturnDate">
                    {dayjs(trip.returnDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="dropdown-divider"></div>
                <div className="input-text">
                  <p className="txtReturnTime">
                    {dayjs(trip.returnTime, "HH:mm:ss").format("hh:mm A")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 delibery pickup">
            <label>{t("create.delivery_devolution.delivery")}</label>
            <AddressText addressId={trip.pickUpAddressId} />
          </div>
          <div className="col-sm-12 col-md-12 delibery devolution">
            <label>{t("create.delivery_devolution.devolution")}</label>
            <AddressText addressId={trip.returnAddressId} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rent-info-des">
      <div className="card-header">
        <h2 data-testid="trip-title">{t("create.rent")}</h2>
      </div>
      <div className="card-body">
        <div className="card-politics">
          <p className="car-name txtCar">
            {car && car.brand + " " + car.model + " " + car.year}
          </p>
          {car && <TripImage car={car} />}
          {car && <CarSpecs car={car} className="create-trip" />}
        </div>
        <div className="fields-container">
          <div className="un-container-date d-flex justify-content-between">
            <div className="un-desde">
              <label className="w-100">{t("create.since")}</label>
              <div className="input-container">
                <div className="input-text mb-0">
                  <p className="txtPickUpDate">
                    {dayjs(trip.pickUpDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="dropdown-divider"></div>
                <div className="input-text mb-0">
                  <p className="txtPickUpTime">
                    {dayjs(trip.pickUpTime, "HH:mm:ss").format("hh:mm A")}
                  </p>
                </div>
              </div>
            </div>
            <div className="un-hasta">
              <label>{t("create.until")}</label>
              <div className="input-container">
                <div className="input-text mb-0">
                  <p className="txtReturnDate">
                    {dayjs(trip.returnDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="dropdown-divider"></div>
                <div className="input-text">
                  <p className="txtReturnTime">
                    {dayjs(trip.returnTime, "HH:mm:ss").format("hh:mm A")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="delibery-container">
            <div className="delibery pickup">
              <label>{t("create.delivery_devolution.delivery")}</label>
              <AddressText addressId={trip.pickUpAddressId} />
            </div>
            <div className="delibery devolution">
              <label>{t("create.delivery_devolution.devolution")}</label>
              <AddressText addressId={trip.returnAddressId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
