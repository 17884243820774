import navbarImages from "../../assets/images/navbar/index";

export interface adminRoutesContent {
  title: string;
  image: string;
  active: boolean;
  routes: route[];
  open: boolean;
}

export interface route {
  name: string;
  route: string;
  active: boolean;
  icon?: string;
  isExternal?: boolean;
  workInProgress?: boolean;
  subroute?: route[];
}

export const adminRoutes: adminRoutesContent[] = [
  {
    title: "home",
    image: navbarImages.home,
    routes: [{ name: "", route: "/admin", active: false }],
    active: false,
    open: false,
  },
  {
    title: "manage",
    image: navbarImages.swap,
    routes: [
      {
        name: "cars",
        route: "/administra/autos",
        active: false,
      },
      { name: "clients", route: "/administra/clientes", active: false },
      {
        name: "reservations",
        route: "/administra/reservaciones",
        active: false,
      },
      {
        name: "calendar",
        route: "/administra/calendario",
        active: false,
        workInProgress: true,
      },
      {
        name: "progress",
        route: "/administra/progreso",
        active: false,
        workInProgress: true,
      },
      {
        name: "branches",
        route: "/administra/sucursales",
        active: false,
        workInProgress: true,
      },
      {
        name: "groups",
        route: "/administra/grupos",
        active: false,
        workInProgress: true,
      },
    ],
    active: false,
    open: false,
  },
  {
    title: "sell",
    image: navbarImages.cash,
    routes: [
      {
        name: "my_portal",
        route: "/vende/mi-portal",
        active: false,
        workInProgress: true,
      },
      { name: "chat", route: "/vende/chat", active: false },
      {
        name: "discounts",
        route: "/vende/descuentos",
        active: false,
        workInProgress: true,
      },
      {
        name: "down_payment",
        route: "/vende/anticipos",
        active: false,
        workInProgress: true,
      },
      {
        name: "policies",
        route: "nose",
        active: false,
        icon: navbarImages.downArrow,
        workInProgress: true,
        subroute: [
          {
            name: "cancellations",
            route: "/vende/politicas/cancelaciones",
            active: false,
            workInProgress: true,
          },
          {
            name: "extensions",
            route: "/vende/politicas/extensiones",
            active: false,
            workInProgress: true,
          },
          {
            name: "modifications",
            route: "/vende/politicas/modificaciones",
            active: false,
            workInProgress: true,
          },
          {
            name: "penalties",
            route: "/vende/politicas/penalidades",
            active: false,
            workInProgress: true,
          },
        ],
      },
      {
        name: "products",
        route: "/vende/productos-adicionales",
        active: false,
        workInProgress: true,
      },
      {
        name: "payouts",
        route: "/vende/payouts",
        active: false,
        workInProgress: true,
      },
    ],
    active: false,
    open: false,
  },
  {
    title: "protect",
    image: navbarImages.shield,
    routes: [
      {
        name: "kyc",
        route: "/protege/kyc",
        active: false,
        workInProgress: true,
      },
      { name: "insurances", route: "/protege/seguros", active: false },
      { name: "gps", route: "/protege/gps", active: false },
    ],
    active: false,
    open: false,
  },
  {
    title: "grow",
    image: navbarImages.shop,
    routes: [
      {
        name: "romio",
        route: "",
        active: false,
        icon: navbarImages.downArrow,
        workInProgress: true,
        subroute: [
          {
            name: "informative",
            route: "/crece/romio",
            active: false,
            workInProgress: true,
          },
          {
            name: "listed_cars",
            route: "/crece/romio/autos-listados",
            active: false,
            workInProgress: true,
          },
          {
            name: "go_romio",
            route: "https://romio.mx/",
            active: false,
            isExternal: true,
            icon: navbarImages.open,
            workInProgress: true,
          },
        ],
      },
      {
        name: "other_marketplaces",
        route: "/crece/otros-marketplaces",
        active: false,
        workInProgress: true,
      },
    ],
    active: false,
    open: false,
  },
  {
    title: "ai",
    image: navbarImages.ai,
    routes: [
      {
        name: "internal_resources",
        route: "/ia/recursos-internos",
        active: false,
        workInProgress: true,
      },
      {
        name: "sales_agent",
        route: "/ia/agente-de-ventas",
        active: false,
        workInProgress: true,
      },
      {
        name: "customer_service",
        route: "/ia/atencion-a-clientes",
        active: false,
        workInProgress: true,
      },
      {
        name: "business_intelligence",
        route: "/ia/inteligencia-de-negocio",
        active: false,
        workInProgress: true,
      },
    ],
    active: false,
    open: false,
  },
  {
    title: "settings",
    image: navbarImages.settings,
    routes: [
      {
        name: "my_company",
        route: "/ajustes/mi-empresa",
        active: false,
      },
      {
        name: "plans_and_payment",
        route: "/ajustes/planes",
        active: false,
        workInProgress: true,
      },
      {
        name: "equipments",
        route: "/ajustes/equipo",
        active: false,
        workInProgress: true,
      },
    ],
    active: false,
    open: false,
  },
];
