import { useFormikContext } from "formik";
import { RefObject, useContext, useEffect } from "react";
import { CarForm } from "../../../../views/car/add/types";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { placeComponentCrash } from "../../../../views/home/types";
import Geocode from "react-geocode";
import { getAddressesByCarID } from "../../../../services/address";
import { AddressType } from "../../../../models/address/types";
import { formatAddressFromDBToGoogle } from "../../../helpers/addressHandler";
import { useQuery } from "react-query";

export const useAddCarImportantData = ({ carId }: { carId: string }) => {
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const defaultProps = {
    center: {
      lat: 20.706165599669397,
      lng: -103.40390591631164,
    },
    zoom: 10,
  };
  const { data: addressCar } = useQuery(
    ["addresses", carId],
    () => getAddressesByCarID(parseInt(carId || "0")),
    {
      enabled: !!carId,
    }
  );

  const handleCurrentLocation = ({
    autocompleteInput,
  }: {
    autocompleteInput: RefObject<HTMLInputElement>;
  }) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS || "");
      Geocode.fromLatLng(
        position.coords.latitude.toString(),
        position.coords.longitude.toString()
      ).then(
        (response) => {
          const firstResult = response.results[0];
          const res = {
            ...firstResult,
            name: firstResult.formatted_address,
            geometry: { ...firstResult.geometry, viewport: "" },
          };
          setFieldValue("mainAddress", res);
          if (autocompleteInput.current) {
            autocompleteInput.current.value = firstResult.formatted_address;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const handleAddressFormat = (place: placeComponentCrash) => {
    setFieldValue("mainAddress", {
      ...place,
      geometry: {
        ...place.geometry,
        location: {
          lat: place.geometry.location.lat().toString(),
          lng: place.geometry.location.lng().toString(),
        },
      },
    });
  };

  // When get car id call function
  useEffect(() => {
    if (addressCar) {
      const mainAddress = addressCar.find(
        ({ type }) => type === AddressType.MAIN_ADDRESS
      );
      mainAddress &&
        setFieldValue("mainAddress", formatAddressFromDBToGoogle(mainAddress));
    }
  }, [addressCar]);

  return {
    t,
    values,
    defaultProps,
    handleAddressFormat,
    handleCurrentLocation,
  };
};
