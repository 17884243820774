import aCheck from "./a_check.svg";
import adminHelp from "./admin_help.svg";
import arrow from "./arrow.svg";
import arrowLeft from "./arrow_left.svg";
import arrowRight from "./arrow_right.svg";
import ai from "./ai.svg";
import balety from "./balety.svg";
import baletyIcon from "./balety_icon.svg";
import calendar from "./calendar.svg";
import cash from "./cash.svg";
import comment from "./comment.svg";
import daltonseminuevos from "./daltonseminuevos.png";
import daltonseminuevosIcon from "./daltonseminuevos.png";
import expandAll from "./expand_all.svg";
import heart from "./heart.svg";
import help from "./help.svg";
import home from "./home.svg";
import hostEclipse from "./host_status.svg";
import logo from "./balety_logo.png";
import burger from "./burger.svg";
import burgerWhite from "./burger_white.svg";
import downArrow from "./down_arrow.svg";
import cross from "./cross.svg";
import crossOrange from "./cross_orange.svg";
import key from "./key.svg";
import login from "./login.svg";
import loginUser from "./login_user.svg";
import logoOrange from "./logo_orange.png";
import logout from "./logout.svg";
import logoutAdmin from "./logout.png";
import messages from "./messages.svg";
import money from "./money.svg";
import myCars from "./my_cars.svg";
import oMessages from "./o_messages.svg";
import oLogin from "./o_login.svg";
import open from "./open.svg";
import settings from "./settings.svg";
import shield from "./shield.svg";
import shop from "./shop.svg";
import swap from "./swap.svg";
import seminuevosvanguardia from "./seminuevosvanguardia.png";
import seminuevosvanguardiaIcon from "./seminuevosvanguardia_icon.png";
import seminuevosplasencia from "./seminuevosplasencia.png";
import seminuevosplasenciaIcon from "./seminuevosplasencia_icon.jpeg";
import signup from "./signup.svg";
import triangle from "./triangle.svg";
import logoHorizontalWhite from "./logo_horizontal_white.svg";
import logoDegradedOrange from "./logo_horizontal_degraded.svg";
import minLogo from "./min_logo_white.svg";
import minLogoOrange from "./min_logo_orange.svg";
import powerLogo from "./power_logo.svg";
import progress from "./progress.svg";
import romio from "./logo_romio_black.svg";
import nicolirentacar from "./nicolirentacar.webp";
import nicolirentacarIcon from "./nicolirentacar.webp";
import burgerBlack from "./burger_black.svg";
import crossBlack from "./cross_black.svg";
import bMessages from "./b_messages.svg";
import bLogin from "./b_login.svg";
import romioIcon from "./romio_icon.svg";

export default {
  adminHelp,
  aCheck,
  arrow,
  arrowLeft,
  arrowRight,
  ai,
  balety,
  baletyIcon,
  cash,
  logo,
  burger,
  burgerWhite,
  downArrow,
  cross,
  crossOrange,
  calendar,
  comment,
  daltonseminuevos,
  daltonseminuevosIcon,
  expandAll,
  heart,
  help,
  home,
  hostEclipse,
  key,
  login,
  loginUser,
  logoOrange,
  logout,
  logoutAdmin,
  messages,
  oLogin,
  open,
  oMessages,
  minLogo,
  minLogoOrange,
  money,
  myCars,
  nicolirentacar,
  nicolirentacarIcon,
  triangle,
  settings,
  shield,
  shop,
  swap,
  seminuevosvanguardia,
  seminuevosvanguardiaIcon,
  seminuevosplasencia,
  seminuevosplasenciaIcon,
  signup,
  logoHorizontalWhite,
  logoDegradedOrange,
  powerLogo,
  progress,
  romio,
  burgerBlack,
  crossBlack,
  bMessages,
  bLogin,
  romioIcon,
};
