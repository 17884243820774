import dark from "./dark.svg";
import facebook from "./facebook_footer.svg";
import help from "./help.svg";
import instagram from "./instagram_footer.svg";
import linkedin from "./linkedin_footer.svg";
import phone from "./phone.svg";
import mail from "./mail.svg";
import mexico from "./mexico.png";
import twitter from "./twitter_footer.svg";
import usa from "./usa.png";
import whatsapp from "./whatsapp_footer.svg";
import facebookBlack from "./facebook_footer_black.svg";
import helpBlack from "./help_black.svg";
import instagramBlack from "./instagram_footer_black.svg";
import light from "./light.svg";
import linkedinBlack from "./linkedin_footer_black.svg";
import phoneBlack from "./phone_black.svg";
import mailBlack from "./mail_black.svg";
import twitterBlack from "./twitter_footer_black.svg";
import whatsappBlack from "./whatsapp_footer_black.svg";

export default {
  dark,
  facebook,
  help,
  instagram,
  light,
  linkedin,
  phone,
  mail,
  mexico,
  twitter,
  usa,
  whatsapp,
  facebookBlack,
  helpBlack,
  instagramBlack,
  linkedinBlack,
  phoneBlack,
  mailBlack,
  twitterBlack,
  whatsappBlack,
};
