import carImages from "../../../../assets/images/car";
import UploadDropZone from "../../../../components/uploadDropZone/component";
import { useEditCarDetails } from "./useEditCarDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CarStatus,
  ConfirmedGPS,
  DocInsuranceCover,
  HasGPS,
  OfferGPS,
  StatusInsuranceCover,
  StatusVehicleLicense,
  ValidateInfo,
} from "../../../../models/car/types";
import DeleteEditCarFileButton from "../../editCarTools/deleteEditCarFileBtn/component";
import Toast from "../../../../components/toast/component";
import { useParams } from "react-router-dom";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

const EditCarDetails = () => {
  const { carId } = useParams();
  const {
    t,
    car,
    circulationCardFile,
    setCirculationCardFile,
    insurancePolicyFile,
    setInsurancePolicyFile,
    isLoading,
    msg,
    openAlert,
    setOpenAlert,
    setDeleted,
    deleted,
    handleQA,
    formikEditQA,
  } = useEditCarDetails(carId || "");

  return (
    <>
      {msg && (
        <Toast open={openAlert} setOpen={setOpenAlert} type={msg.type}>
          {msg.text}
        </Toast>
      )}
      <div className="model-ubication" id="first_step">
        <div className="form-container">
          <div className="info-container">
            <h2 data-testid="model-and-ubication-title" className="title">
              {t("edit.documentation.documentation_title")}
            </h2>
            <h2>{t("edit.documentation.documentation_subtitle")}</h2>
          </div>
          <div className="form">
            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                className="d-flex justify-content-between accordion-summary"
                expandIcon={<ExpandMoreIcon />}
              >
                <div className="m-2">
                  {(() => {
                    switch (car?.status) {
                      case CarStatus.INCOMPLETE:
                        return <img src={carImages.not_uploaded} alt="" />;
                      case CarStatus.REJECTED:
                        return <img src={carImages.refused} alt="" />;
                      case CarStatus.PENDING:
                        return <img src={carImages.pending} alt="" />;
                      case CarStatus.APPROVED:
                        return <img src={carImages.validated} alt="" />;
                    }
                  })()}
                </div>
                <p className="option-title">
                  {t("add.car_security.serial_number.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="serial-number" id="card_file">
                <div className="serial-info">
                  <p>{t("add.car_security.serial_number.info")}</p>
                  <a className="extra-info-p">
                    {t("add.car_security.serial_number.how_to_find")}
                  </a>
                </div>
                <div className="serial-container-input">
                  <label className="serial-label">
                    {t("add.car_security.serial_number.title")}
                  </label>
                  {car?.status === CarStatus.APPROVED ? (
                    <p className="generic-text marca">{car?.niv}</p>
                  ) : (
                    <TextField
                      className="serial-number-input"
                      onChange={(e) =>
                        formikEditQA.setFieldValue("niv", e.target.value.trim())
                      }
                      value={formikEditQA.values.niv}
                      placeholder={t(
                        "add.car_security.serial_number.placeholder"
                      )}
                    />
                  )}
                </div>
                {(() => {
                  switch (car?.status) {
                    case CarStatus.INCOMPLETE:
                      return (
                        <p className="insurance-recommendation">
                          <ElectricBoltIcon
                            fontSize="inherit"
                            sx={{ marginRight: "4px", color: "#ffab03" }}
                          />
                          <span>
                            {t(
                              "edit.car_security.serial_number.status.initial"
                            )}
                          </span>
                        </p>
                      );
                    case CarStatus.REJECTED:
                      return (
                        <span>
                          {t("edit.car_security.serial_number.status.blocked")}
                        </span>
                      );
                    case CarStatus.PENDING:
                      return (
                        <span>
                          {t(
                            "edit.car_security.serial_number.status.verification"
                          )}
                        </span>
                      );
                    case CarStatus.APPROVED:
                      return (
                        <span>
                          {t("edit.car_security.serial_number.status.verified")}
                        </span>
                      );
                  }
                })()}
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="license-accordion"
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
              >
                <div className="m-2">
                  {(() => {
                    switch (car?.docVehicleLicense) {
                      case ValidateInfo.NOT_UPLOADED:
                        return <img src={carImages.not_uploaded} alt="" />;
                      case ValidateInfo.REFUSED:
                        return <img src={carImages.refused} alt="" />;
                      case ValidateInfo.PENDING:
                        return <img src={carImages.pending} alt="" />;
                      case ValidateInfo.VALIDATED:
                        return <img src={carImages.validated} alt="" />;
                    }
                  })()}
                </div>
                <p className="option-title">
                  {t("edit.car_security.circulation_card")}
                </p>
                {car?.docVehicleLicense == ValidateInfo.VALIDATED &&
                  car?.statusVehicleLicense != StatusVehicleLicense.VALID && (
                    <div className="ms-3 my-auto">
                      <DeleteEditCarFileButton
                        type="vehicleLicense"
                        setDeleted={setDeleted}
                        deleted={deleted}
                        carId={carId || ""}
                      />
                    </div>
                  )}
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <div className="document-generic-input">
                  <p>
                    {t("edit.car_security.circulation_card_revision_status")}
                  </p>
                  {!isLoading && (
                    <>
                      <UploadDropZone
                        allow="file,image"
                        fileDetails={{
                          url: circulationCardFile?.url || "",
                          type: circulationCardFile?.mime || "",
                        }}
                        path={{
                          fileName: "tarjeta_de_circulación",
                          folderName: `/car-${carId}`,
                        }}
                        showRemove={
                          car?.docVehicleLicense != ValidateInfo.VALIDATED
                        }
                        onUpload={(url, mime) =>
                          setCirculationCardFile({ url, mime })
                        }
                        onDelete={() => setCirculationCardFile(undefined)}
                        deletedFile={deleted?.vehicleLicense || false}
                      />
                    </>
                  )}
                  <div className="verifying holder">
                    {(() => {
                      switch (car?.docVehicleLicense) {
                        case ValidateInfo.NOT_UPLOADED:
                          return (
                            <span>
                              <p className="alert-error">
                                {t(
                                  "edit.details.alert-circulation-card.not_uploaded"
                                )}
                              </p>
                            </span>
                          );
                        case ValidateInfo.REFUSED:
                          return (
                            <span>
                              <p className="alert-error">
                                {t(
                                  "edit.details.alert-circulation-card.refused"
                                )}
                              </p>
                            </span>
                          );
                        case ValidateInfo.PENDING:
                          return (
                            <p className="alert-wait">
                              {t("edit.details.alert-circulation-card.pending")}
                            </p>
                          );
                        case ValidateInfo.VALIDATED:
                          return (
                            <p className="alert-verified">
                              {t(
                                "edit.details.alert-circulation-card.validated"
                              )}
                            </p>
                          );
                      }
                    })()}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="insurance-cover-accordion"
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
              >
                <div className="m-2">
                  {(() => {
                    switch (car?.docInsuranceCover) {
                      case DocInsuranceCover.NOT_UPLOADED:
                        return <img src={carImages.not_uploaded} alt="" />;
                      case DocInsuranceCover.REFUSED:
                        return <img src={carImages.refused} alt="" />;
                      case DocInsuranceCover.PENDING:
                        return <img src={carImages.pending} alt="" />;
                      case DocInsuranceCover.VALIDATED:
                        return <img src={carImages.validated} alt="" />;
                    }
                  })()}
                </div>
                <p className="option-title">
                  {t("edit.car_security.policy_cover")}
                </p>
                {car?.docInsuranceCover == DocInsuranceCover.VALIDATED &&
                  car?.statusInsuranceCover != StatusInsuranceCover.VALID && (
                    <div className="ms-3 my-auto">
                      <DeleteEditCarFileButton
                        type="insuranceCover"
                        setDeleted={setDeleted}
                        deleted={deleted}
                        carId={carId || ""}
                      />
                    </div>
                  )}
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <div className="document-generic-input">
                  <p>{t("edit.car_security.add_insurance")}</p>
                  {!isLoading && (
                    <>
                      <UploadDropZone
                        allow="file,image"
                        fileDetails={{
                          url: insurancePolicyFile?.url || "",
                          type: insurancePolicyFile?.mime || "",
                        }}
                        path={{
                          fileName: "poliza_de_seguro",
                          folderName: `/car-${carId}`,
                        }}
                        showRemove={
                          car?.docInsuranceCover != DocInsuranceCover.VALIDATED
                        }
                        onUpload={(url, mime) =>
                          setInsurancePolicyFile({ url, mime })
                        }
                        onDelete={() => setInsurancePolicyFile(undefined)}
                        deletedFile={deleted?.insuranceCover || false}
                      />
                    </>
                  )}
                  <div className="verifying holder">
                    {(() => {
                      switch (car?.docInsuranceCover) {
                        case DocInsuranceCover.NOT_UPLOADED:
                          return (
                            <span>
                              <p className="alert-error">
                                {t(
                                  "edit.details.alert-circulation-card.not_uploaded"
                                )}
                              </p>
                            </span>
                          );
                        case DocInsuranceCover.REFUSED:
                          return (
                            <span>
                              <p className="alert-error">
                                {t(
                                  "edit.details.alert-circulation-card.refused"
                                )}
                              </p>
                            </span>
                          );
                        case DocInsuranceCover.PENDING:
                          return (
                            <p className="alert-wait">
                              {t("edit.details.alert-circulation-card.pending")}
                            </p>
                          );
                        case DocInsuranceCover.VALIDATED:
                          return (
                            <p className="alert-verified">
                              {t(
                                "edit.details.alert-circulation-card.validated"
                              )}
                            </p>
                          );
                      }
                    })()}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="d-flex justify-content-between accordion-summary"
                id="drop_arrow"
              >
                <div className="m-2">
                  {(() => {
                    switch (car?.confirmedGPS) {
                      case ConfirmedGPS.FALSE:
                        return <img src={carImages.pending} alt="" />;
                      case ConfirmedGPS.TRUE_JEST:
                        return <img src={carImages.validated} alt="" />;
                      case ConfirmedGPS.TRUE_RESSER:
                        return <img src={carImages.validated} alt="" />;
                    }
                  })()}
                </div>
                <p className="option-title">
                  {t("edit.car_security.gps.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="gps-inputs" id="card_file">
                <div className="gps-info">
                  <p>{t("edit.car_security.gps.must")}</p>
                  <p>{t("edit.car_security.gps.info")}</p>
                  <p className="extra-info-p">
                    {t("edit.car_security.gps.extra_info")}
                    <a href="">
                      {t("edit.car_security.gps.extra_info_responsabilities")}
                    </a>
                  </p>
                </div>
                <div className="generic-gps-dropdown">
                  <label>{t("edit.car_security.gps.has_system.title")}</label>
                  {car?.confirmedGPS === ConfirmedGPS.FALSE ? (
                    <FormControl fullWidth>
                      <Select
                        labelId="sistem"
                        id="sistem"
                        className="gps-dropdown"
                        onChange={(e) => {
                          formikEditQA.setFieldValue(
                            "hasGPS",
                            parseInt(e.target.value)
                          );
                        }}
                        placeholder={t(
                          "edit.car_security.gps.has_system.placeholder"
                        )}
                        value={
                          formikEditQA.values.hasGPS === 1 ||
                          formikEditQA.values.hasGPS === 0
                            ? "1"
                            : formikEditQA.values.hasGPS?.toString() ?? ""
                        }
                      >
                        <MenuItem value={""} disabled>
                          {t("edit.car_security.gps.has_system.placeholder")}
                        </MenuItem>
                        <MenuItem value={"1"}>
                          {t("edit.car_security.gps.has_system.no")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {t("edit.car_security.gps.has_system.yes")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="generic-text marca underline-text">
                      {formikEditQA.values.hasGPS === HasGPS.TRUE
                        ? t("edit.car_security.gps.has_system.yes")
                        : t("edit.car_security.gps.has_system.no")}
                    </p>
                  )}
                </div>
                <div className="generic-gps-dropdown">
                  <label>{t("edit.car_security.gps.device.title")}</label>
                  {car?.confirmedGPS === ConfirmedGPS.FALSE ? (
                    <FormControl fullWidth>
                      <Select
                        labelId="device"
                        id="device"
                        className="gps-dropdown"
                        onChange={(e) => {
                          formikEditQA.setFieldValue(
                            "offerGPS",
                            parseInt(e.target.value)
                          );
                        }}
                        placeholder={t(
                          "edit.car_security.gps.has_system.placeholder"
                        )}
                        value={formikEditQA.values.offerGPS?.toString() ?? ""}
                      >
                        <MenuItem value={""} disabled>
                          {t("edit.car_security.gps.has_system.placeholder")}
                        </MenuItem>
                        <MenuItem value={"0"}>
                          {t("edit.car_security.gps.device.no")}
                        </MenuItem>
                        <MenuItem value={"1"}>
                          {t("edit.car_security.gps.device.yes")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="generic-text marca underline-text">
                      {formikEditQA.values.offerGPS === OfferGPS.NOT_INTERESTED
                        ? t("edit.car_security.gps.device.no")
                        : t("edit.car_security.gps.device.yes")}
                    </p>
                  )}
                </div>
                {car?.confirmedGPS !== ConfirmedGPS.FALSE && (
                  <div className="generic-gps-dropdown">
                    <label>
                      {t("edit.car_security.gps.contracted_service.title")}
                    </label>
                    <p className="generic-text marca underline-text">
                      {car?.confirmedGPS === ConfirmedGPS.TRUE_JEST
                        ? t("edit.car_security.gps.contracted_service.jest")
                        : t("edit.car_security.gps.contracted_service.resser")}
                    </p>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="goals-accordion"
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
              >
                <p className="option-title">{t("add.car_price.goals.title")}</p>
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <div className="goals">
                  <div className="goal-container">
                    <label>{t("add.car_price.goals.what_is_your_goal?")}</label>
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        labelId="own-goal"
                        id="own-goal"
                        data-testid="own-goal"
                        className="dropdown"
                        onChange={(e) => handleQA(e, "goal")}
                        value={
                          formikEditQA.values.questionnaireAnswers
                            ? formikEditQA.values.questionnaireAnswers[0]
                            : "0"
                        }
                      >
                        <MenuItem value={"0"} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={"1"}>
                          {t("add.car_price.goals.generate_additional_income")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {t("add.car_price.goals.cover_car_payments")}
                        </MenuItem>
                        <MenuItem value={"3"}>
                          {t("add.car_price.goals.expand_current_business")}
                        </MenuItem>
                        <MenuItem value={"4"}>
                          {t("add.car_price.goals.primary_income_of_income")}
                        </MenuItem>
                        <MenuItem value={"5"}>
                          {t("add.car_price.goals.other")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="goal-container">
                    <label>{t("add.car_price.how_often.title")}</label>
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        labelId="frequency-use"
                        id="frequency-use"
                        data-testid="frequency-use"
                        className="dropdown"
                        onChange={(e) => handleQA(e, "frequencyUse")}
                        value={
                          formikEditQA.values.questionnaireAnswers
                            ? formikEditQA.values.questionnaireAnswers[1]
                            : "0"
                        }
                      >
                        <MenuItem value={"0"} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={"1"}>
                          {t("add.car_price.how_often.Never")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {t("add.car_price.how_often.uno_two_week")}
                        </MenuItem>
                        <MenuItem value={"3"}>
                          {t("add.car_price.how_often.three_four_week")}
                        </MenuItem>
                        <MenuItem value={"4"}>
                          {t("add.car_price.how_often.fifth_six_week")}
                        </MenuItem>
                        <MenuItem value={"5"}>
                          {t("add.car_price.how_often.everyday")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="goal-container">
                    <label>{t("add.car_price.often_share_car.title")}</label>
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        labelId="frequency-share"
                        id="frequency-share"
                        data-testid="frequency-share"
                        className="dropdown"
                        onChange={(e) => handleQA(e, "frequencyShare")}
                        value={
                          formikEditQA.values.questionnaireAnswers
                            ? formikEditQA.values.questionnaireAnswers[2]
                            : "0"
                        }
                      >
                        <MenuItem value={"0"} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={"1"}>
                          {t("add.car_price.often_share_car.not_know_yet")}
                        </MenuItem>
                        <MenuItem value={"2"}>
                          {t("add.car_price.often_share_car.rarely")}
                        </MenuItem>
                        <MenuItem value={"3"}>
                          {t("add.car_price.often_share_car.few_times")}
                        </MenuItem>
                        <MenuItem value={"4"}>
                          {t("add.car_price.often_share_car.frequent")}
                        </MenuItem>
                        <MenuItem value={"5"}>
                          {t("add.car_price.often_share_car.very_frequent")}
                        </MenuItem>
                        <MenuItem value={"6"}>
                          {t("add.car_price.often_share_car.always")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="btn-container save-container" id="save_container">
        <p className="reminder">{t("edit.dont_forget")}</p>
        <button
          type="button"
          id="save_first"
          onClick={() => formikEditQA.handleSubmit()}
          className="btn btn-blue"
        >
          {t("edit.save_changes")}
        </button>
      </div>
    </>
  );
};

export default EditCarDetails;
