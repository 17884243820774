import { ChangeEvent, useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getCar, updateCar } from "../../../../services/car";
import { editDocumentationForm } from "../../../../views/car/edit/types";
import { useFormik } from "formik";
import { editDocumentationSchema } from "./form";
import { msgProps } from "../editCarPrice/types";
import { Accesories } from "../../../../models/car/types";

export const useEditCarDocumentation = () => {
  const t = useContext(TranslationContext);

  // Default suggested rules
  const SUGGESTED_RULES = `- ${t(
    "add.car_documentation.rules.suggested_rules.no_pets"
  )} 🐕\n- ${t(
    "add.car_documentation.rules.suggested_rules.no_smoking"
  )} 🚬\n- ${t(
    "add.car_documentation.rules.suggested_rules.no_eating"
  )} 🍕\n- ${t(
    "add.car_documentation.rules.suggested_rules.clean_car"
  )} ✨\n- ${t("add.car_documentation.rules.suggested_rules.tank")} ⛽️`;

  const [msg, setMsg] = useState<msgProps>({
    text: "",
    type: "error",
  });
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { carId } = useParams();

  const { data: car } = useQuery(["car", carId], () => getCar(carId), {
    enabled: !!carId,
  });

  // Make requests when safe
  const fetchEditDocumentation = async (values: editDocumentationForm) => {
    try {
      if (!car?.id) return;

      // Update only editable car data
      const reponse = await updateCar(car?.id, {
        characteristicsList: values.characteristicsList,
        rules: values.rules,
        description: values.description,
      });

      if (reponse?.status === 200) {
        queryClient.invalidateQueries(["car"]);
      }

      setMsg({
        text: t("edit.price_disponibility.success.update_car"),
        type: "success",
      });
    } catch (error) {
      setMsg({
        text: t("edit.price_disponibility.errors.update_car"),
        type: "error",
      });
    }
    setOpenAlert(true);
  };

  // List of characteristics
  const [characteristics, setCharacteristics] = useState<string[]>(Accesories);

  // Edit documentation form
  const formikEditDocumentation = useFormik<editDocumentationForm>({
    initialValues: {
      characteristicsList: "",
      rules: "",
      description: "",
    },
    validationSchema: editDocumentationSchema,
    onSubmit: fetchEditDocumentation,
  });

  // Set accesories selected on a string
  const handleAccesories = (
    event: ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    characteristics[position] = event.target.checked ? "1" : "0";
    setCharacteristics(characteristics);
    formikEditDocumentation.setFieldValue(
      "characteristicsList",
      characteristics.join("")
    );
  };

  // Set previous car data
  useEffect(() => {
    if (!car) return;
    formikEditDocumentation.setFieldValue(
      "rules",
      car.rules !== "" ? car.rules : SUGGESTED_RULES
    );
    formikEditDocumentation.setFieldValue("description", car.description);

    formikEditDocumentation.setFieldValue(
      "characteristicsList",
      car.characteristicsList
    );

    if (car.characteristicsList) {
      setCharacteristics(car.characteristicsList.split(""));
    }
  }, [car]);

  return {
    t,
    msg,
    openAlert,
    setOpenAlert,
    handleAccesories,
    formikEditDocumentation,
    car,
    carId,
  };
};
