import { currentLocation, Destination, DestinationStatus } from "./types";

export const mockDestination: Destination = {
  id: 1,
  name: "",
  coordinates: [{ lat: 21.0, lng: -103.123 }],
  status: DestinationStatus.ACTIVE,
  destinationMainAddress: { lat: 21.0, lng: -103.123 },
};

export const mockCurrentLocation: currentLocation = {
  state: "",
  lat: "",
  lng: "",
};
