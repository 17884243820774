import { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Car,
  CarStatus,
  Deactivated,
  StatusInsuranceCover,
  StatusVehicleLicense,
  ValidateInfo,
} from "../../models/car/types";
import { TranslationContext } from "../../views/driverReservation/show/component";
import "../../views/car/index/styles.scss";
import {
  getTransmission,
  getType,
  getIsEcofriendly,
} from "../../views/car/index/types";
import images from "../../assets/images/a_cars/index";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Chip, Button, Grid } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CarMoreOptions from "../carMoreOptions/component";
import CarStatusInfo from "../carStatusInfo/component";
import CarDefaultImage from "../carDefaultImage/component";

export const CarsCard = (car: Car) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const t = useContext(TranslationContext);

  const circleIconColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED ||
      car.status === CarStatus.INCOMPLETE
      ? "#EB5757 !important"
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? "#67E23B !important"
      : "#FFAB03 !important"
  );

  const circleLabel = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? t("index.car_status.disabled")
      : car.status === CarStatus.INCOMPLETE
      ? t("index.car_status.incomplete")
      : car.status === CarStatus.APPROVED &&
        car.docVehicleLicense === ValidateInfo.VALIDATED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED &&
        car.isReadyByHost === Deactivated.READY
      ? t("index.car_status.active")
      : t("index.car_status.not_active")
  );

  const transmissionValue = useRef(getTransmission(car.transmission));
  const ecofriendlyValue = useRef(getIsEcofriendly(car.isEcoFriendly));
  const typeValue = useRef(getType(car.type));

  if (!car) return null;

  return (
    <>
      <li className="list-group-item">
        <div style={{ fontSize: "6px" }}>
          <Chip
            icon={
              <CircleIcon
                fontSize="inherit"
                sx={{
                  color: circleIconColor.current,
                }}
              />
            }
            label={circleLabel.current}
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
              background: "white",
              height: "24px",
              zIndex: 9,
            }}
          />
        </div>
        <CarDefaultImage car={car} height={145} />
        <Grid container spacing={1}>
          <Grid item xs={4} className="mx-3 py-3">
            <div className="reservation-text">
              <h2>{`${car.brand} ${car.model} ${car.year}`}</h2>
              <p>
                {t("index.plates")} {car.plate}
              </p>
            </div>
            <CarStatusInfo {...car} />
          </Grid>
          <Grid item xs={4} className="car-data">
            <div className="d-flex">
              <div className="reservation-text">
                <h2 data-testid="type-label" className="blue-text">
                  {t("index.type")}
                </h2>
                <p>{typeValue.current}</p>
              </div>
              <div className="reservation-text">
                <h2 data-testid="price-label" className="blue-text">
                  {t("index.price")}
                </h2>
                <p>
                  ${parseInt(car.price) < 0 ? "0" : car.price}
                  {t("index.price_day")}
                </p>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="d-flex car-data-item">
                <img
                  src={images?.transmission}
                  alt="transmission icon"
                  style={{
                    width: "1em",
                    height: "1em",
                    fontSize: "1em",
                    marginRight: "6px",
                  }}
                />
                <p>{transmissionValue.current}</p>
              </div>
              <div className="d-flex  car-data-item">
                <img
                  src={images?.ecoType}
                  alt="ecological icon"
                  style={{
                    width: "1em",
                    height: "1em",
                    fontSize: "1em",
                    marginRight: "6px",
                  }}
                />
                <p>{ecofriendlyValue.current}</p>
              </div>
              <div className="d-flex car-data-item">
                <PermIdentityOutlinedIcon sx={{ marginRight: "4px" }} />
                <p>{car.numSeats && car.numSeats < 0 ? 1 : car.numSeats}</p>
              </div>
              <div className="d-flex  car-data-item">
                <img
                  src={images?.bag}
                  alt="bag icon"
                  style={{
                    width: "1em",
                    height: "1em",
                    fontSize: "1em",
                    marginRight: "6px",
                  }}
                />
                <p>{car.numBags}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} className="btn-container">
            {car.status !== CarStatus.INCOMPLETE ? (
              <Link
                to={
                  `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                    ns: "routes",
                  })}/${t("edit", { ns: "routes" })}/` + car.id
                }
                className="btn btn-orange"
                data-testid="edit-button"
              >
                {t("index.view_edit")}
              </Link>
            ) : (
              <Button
                onClick={() =>
                  navigate(
                    `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                      ns: "routes",
                    })}/${t("upload_car", { ns: "routes" })}`,
                    {
                      state: {
                        step: car?.step === 0 ? 1 : car?.step,
                        carId: car?.id,
                      },
                    }
                  )
                }
                className="btn btn-orange"
                data-testid="complete-button"
              >
                {t("index.complete_car")}
              </Button>
            )}
            <Button
              className="btn btn-orange btn-blue"
              data-testid="show-options-button"
              aria-haspopup="true"
              aria-describedby="popover-more-options"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {t("index.more_options")}
            </Button>
          </Grid>
        </Grid>

        <CarMoreOptions
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          car={car}
        />
      </li>
    </>
  );
};

export const CarsCardMobile = (car: Car) => {
  const t = useContext(TranslationContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const circleIconColor = useRef(
    car.isReadyByHost === Deactivated.DISABLED ||
      car.status === CarStatus.INCOMPLETE
      ? "#EB5757 !important"
      : car.status === CarStatus.APPROVED &&
        car.statusInsuranceCover !== StatusInsuranceCover.EXPIRED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED
      ? "#67E23B !important"
      : "#FFAB03 !important"
  );

  const circleLabel = useRef(
    car.isReadyByHost === Deactivated.DISABLED
      ? "Pausado"
      : car.status === CarStatus.INCOMPLETE
      ? "Incompleto"
      : car.status === CarStatus.APPROVED &&
        car.statusInsuranceCover !== StatusInsuranceCover.EXPIRED &&
        car.statusVehicleLicense !== StatusVehicleLicense.EXPIRED
      ? "Activo"
      : "Inactivo"
  );

  if (!car) return null;

  return (
    <>
      <li className="list-group-item" key={car.id}>
        <div style={{ fontSize: "6px" }}>
          <Chip
            icon={
              <CircleIcon
                fontSize="inherit"
                sx={{
                  color: circleIconColor.current,
                }}
              />
            }
            label={circleLabel.current}
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
              background: "white",
              height: "24px",
              zIndex: 3,
            }}
          />
        </div>
        <CarDefaultImage car={car} height={180} />
        <div className="mx-3 pt-3">
          <div className="reservation-text">
            <h2>{`${car.brand} ${car.model} ${car.year}`}</h2>
            <p>
              {t("index.plates")} {car.plate}
            </p>
          </div>
        </div>
        <div className="car-data">
          <div className="d-flex" style={{ padding: "6px" }}>
            <div className="reservation-text">
              <h2 data-testid="type-label" className="blue-text">
                {t("index.type")}
              </h2>
              <p>{getType(car.type)}</p>
            </div>
            <div className="reservation-text">
              <h2 data-testid="price-label" className="blue-text">
                {t("index.price")}
              </h2>
              <p>
                ${parseInt(car.price) < 0 ? "0" : car.price}{" "}
                {t("index.price_day")}
              </p>
            </div>
          </div>
          <div className="d-flex my-3 align-items-center">
            <div className="d-flex car-data-item">
              <img
                src={images?.transmission}
                alt="transmission icon"
                style={{
                  width: "1em",
                  height: "1em",
                  fontSize: "1em",
                  marginRight: "6px",
                }}
              />
              <p>{getTransmission(car.transmission)}</p>
            </div>
            <div className="d-flex car-data-item">
              <img
                src={images?.ecoType}
                alt="ecological icon"
                style={{
                  width: "1em",
                  height: "1em",
                  fontSize: "1em",
                  marginRight: "6px",
                }}
              />
              <p>{getIsEcofriendly(car.isEcoFriendly)}</p>
            </div>
            <div className="d-flex  car-data-item">
              <PermIdentityOutlinedIcon sx={{ marginRight: "4px" }} />
              <p>{car.numSeats && car.numSeats < 0 ? 1 : car.numSeats}</p>
            </div>
            <div className="d-flex  car-data-item">
              <img
                src={images?.bag}
                alt="bag icon"
                style={{
                  width: "1em",
                  height: "1em",
                  fontSize: "1em",
                  marginRight: "6px",
                }}
              />
              <p>{car.numBags}</p>
            </div>
          </div>
          <CarStatusInfo {...car} />
        </div>
        <div className="btn-container btns">
          {car.status !== CarStatus.INCOMPLETE ? (
            <Link
              to={
                `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                  ns: "routes",
                })}/${t("edit", { ns: "routes" })}/` + car.id
              }
              className="btn btn-orange"
              data-testid="show-edit-button-mobile"
            >
              {t("index.view_edit")}
            </Link>
          ) : (
            <Button
              onClick={() =>
                navigate(
                  `/${t("manage", { ns: "routes" })}/${t("my_cars", {
                    ns: "routes",
                  })}/${t("upload_car", { ns: "routes" })}`,
                  {
                    state: {
                      step: car?.step === 0 ? 1 : car?.step,
                      carId: car?.id,
                    },
                  }
                )
              }
              className="btn btn-orange"
              data-testid="show-complete-button"
            >
              {t("index.complete_car")}
            </Button>
          )}
          <Button
            className="btn btn-orange btn-blue"
            aria-haspopup="true"
            aria-describedby="popover-more-options"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {t("index.more_options_short")}
          </Button>
        </div>

        <CarMoreOptions
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          car={car}
        />
      </li>
    </>
  );
};
