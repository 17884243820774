import * as Yup from "yup";
import {
  DocPolicyStatus,
  HostForm,
  TypeInsurance,
} from "../../models/host/types";

export const hostCommercialSchema: Yup.SchemaOf<HostForm> = Yup.object().shape({
  comercialName: Yup.string().required(),
  businessName: Yup.string().required(),
  rfc: Yup.string().required(),
  legalAddress: Yup.string().required(),
  insurance: Yup.mixed().oneOf(Object.values(TypeInsurance)),
  rentPoliciesStatus: Yup.mixed()
    .oneOf(Object.values(DocPolicyStatus))
    .test(function () {
      if (
        this.parent.insurance == TypeInsurance.HOST_INSURANCE &&
        this.parent.rentPoliciesStatus == DocPolicyStatus.NOT_UPLOADED
      )
        return false;

      return true;
    }),
});
