import { axiosService } from "./axios";
import { getToken } from "./localstorage";
import { Coupon } from "../models/coupon/types";
import { couponDTO } from "../models/coupon/dto";

export const addGeneralCoupon = async (coupon: couponDTO) => {
  try {
    const response = await axiosService.post<Coupon>(
      `/wallet/redeemcoupongeneral`,
      coupon,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addCoupon = async (coupon: couponDTO) => {
  try {
    const response = await axiosService.post(`/wallet/redeemcoupon`, coupon, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const cancelCoupon = async (coupon: couponDTO) => {
  try {
    const response = await axiosService.post(`/wallet/cancelcoupon`, coupon, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCoupons = async () => {
  try {
    const response = await axiosService.get<Coupon[]>(`/wallet/listcoupon`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
