import navbarImages from "../../../../assets/images/navbar";
import { useContext } from "react";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { useFormikContext } from "formik";
import { CarForm } from "../../../../views/car/add/types";
import UploaderCarGallery from "../../../../components/uploaderCarGallery/component";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { AccesoriesList } from "./accesoriesList/AccesoriesList";

/**
 * ADD CAR DETAILS - step 3: car images, accessories, description and rules
 */
export const AddCarDocumentation = ({
  carId,
  formErrors,
}: {
  carId: string;
  formErrors: string[] | undefined;
}) => {
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm>();

  return (
    <div className="documentation" id="second_step">
      <div className="form-container">
        <div className="info-container">
          <h2 data-testid="documentation-title">
            {t("add.car_documentation.details")}
          </h2>
          <h3>{t("add.car_documentation.characteristics_and_description")}</h3>
        </div>
        <div className="form">
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex align-center p-16 accordion-summary"
              id="drop_arrow3"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_documentation.car_pictures")}
              </p>
            </AccordionSummary>
            {/* Images uploader container */}
            <AccordionDetails className="drop-options" id="images">
              {/* File Uploader */}
              <div
                className="document-generic-input d-flex justify-content-center align-items-center flex-column"
                style={{ width: "100%" }}
              >
                {carId && (
                  <UploaderCarGallery carId={parseInt(carId)} editionMode />
                )}
              </div>
              <div className="information">
                <li>{t("add.car_documentation.warning_images.order")}</li>
                <li>{t("add.car_documentation.warning_images.ratio")}</li>
                <li>{t("add.car_documentation.warning_images.min_one")}</li>
                <li>{t("add.car_documentation.warning_images.upload")}</li>
                <li>
                  <a href="">
                    {t("add.car_documentation.warning_images.guide")}
                  </a>
                </li>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow4"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_documentation.accessories.title")}
              </p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="accesories">
              <AccesoriesList carId={carId} />
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow5"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_documentation.description.title")}
              </p>
            </AccordionSummary>
            <div className="drop-options" id="description">
              <textarea
                name=""
                id="txtDescription"
                className={`message-box ${
                  formErrors?.includes("description") ? "input-error" : ""
                }`}
                value={values.description}
                onChange={(e) => {
                  setFieldValue("description", e.target.value);
                }}
                placeholder={t("add.car_documentation.description.placeholder")}
                maxLength={5000}
              ></textarea>
              <p className="max-text">{t("add.car_documentation.max_words")}</p>
            </div>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="drop_arrow6"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_documentation.rules.title")}
              </p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="rules">
              <textarea
                name=""
                id={"txtRules"}
                className={`message-box ${
                  formErrors?.includes("rules") ? "input-error" : ""
                }`}
                value={values.rules}
                onChange={(e) => {
                  setFieldValue("rules", e.target.value);
                }}
                maxLength={5000}
                minLength={200}
              ></textarea>
              <p className="max-text">{t("add.car_documentation.max_words")}</p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
