import boxCheck from "./box_check.svg";
import accesories from "./accesories.png";
import boxEmpty from "./box_empty.svg";
import left from "./left.svg";
import list from "./list.svg";
import moon from "./moon.svg";
import oDownArrow from "./o_down_arrow.svg";
import right from "./right.svg";
import search from "./search.png";
import sortDown from "./sort_down.svg";
import sortUp from "./sort_up.svg";
import sun from "./sun.svg";
import x from "./x.svg";
import ecoType from "./eco_type.png";

export default {
  accesories,
  boxCheck,
  boxEmpty,
  left,
  list,
  moon,
  oDownArrow,
  right,
  search,
  sortDown,
  sortUp,
  sun,
  x,
  ecoType,
};
