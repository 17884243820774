import dayjs from "dayjs";
import React, { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import authenticationImages from "../../../assets/images/authentication";
import { Trip, TripStatus } from "../../../models/trip/types";
import { getCar } from "../../../services/car";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../services/fileUploader";
import { getTripByDriverID } from "../../../services/trip";
import { getUserByID } from "../../../services/user";
import { TranslationContext } from "../show/component";
import { statusList } from "../show/types";
import "./styles.scss";
import Loading from "../../../components/loading/component";
import { TripCard } from "../../../components/tripCards/TripCard";
import { Helmet } from "react-helmet";

export const DriverReservations = () => {
  const { t } = useTranslation(["driverReservation", "routes"]);
  const { data: tripList, isLoading } = useQuery(["trips"], () =>
    getTripByDriverID()
  );

  const trips = useMemo(() => {
    if (tripList) {
      return tripList.sort(
        (firstTrip, secondTrip) => secondTrip.id - firstTrip.id
      );
    }
  }, [tripList]);

  if (isLoading) {
    return <Loading height="25vh" />;
  }

  if (!trips) {
    return <EmptyView />;
  }

  const tripActive = trips.filter(
    (trip) =>
      trip.status === TripStatus.SEND_REQUEST_RESERVATION ||
      trip.status === TripStatus.ACCEPT_RESERVATION ||
      trip.status === TripStatus.ACTIVE_RESERVATION ||
      trip.status === TripStatus.PAYED_NOT_AUTHORIZED
  );

  const tripReservation =
    tripActive.length !== 0 ? (
      tripActive.map((trip, index) => (
        <TripCard
          trip={trip}
          key={trip.id + "-" + index + trip.tripCode}
          userType="host"
        />
      ))
    ) : (
      <EmptyView />
    );

  return (
    <>
      <Helmet>
        <title>{t("seo.index.title")}</title>
        <meta name="description" content={t("seo.index.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/huespedes/reservaciones"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/huespedes/reservaciones"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/huespedes/reservaciones"
        />
      </Helmet>
      <article className="reservation-container">
        <div id="reservationCards" className="join-container">
          <div className="title-container">
            <h1 data-testid="driver-reservations-title">
              {t("index.reservations")}
            </h1>
          </div>
          <div className="card-trip">
            <ul className="list-group list-group-flush" data-testid="car-list">
              {tripReservation}
            </ul>
          </div>
        </div>

        <div className="reservation-card">
          <div className="title-rental">
            <h3>{t("index.rental_history")}</h3>
          </div>
          <div className="card-trip">
            <ul className="list-group list-group-flush" data-testid="car-list">
              {isLoading ? (
                <Loading height="60vh" />
              ) : (
                trips.map((trip, index) => (
                  <React.Fragment key={trip.id + "-" + index + trip.tripCode}>
                    {trip.status === TripStatus.FINISH_RESERVATION ||
                    trip.status === TripStatus.CANCEL_REQUEST_HUESPED ||
                    trip.status === TripStatus.REFUSE_REQUEST_ANFITRION ||
                    trip.status === TripStatus.CANCEL_RESERVATION_HUESPED ||
                    trip.status === TripStatus.CANCEL_RESERVATION_ANFITRION ||
                    trip.status === TripStatus.CANCEL_RESERVATION_BALETY ? (
                      <TripCard trip={trip} userType="host" />
                    ) : null}
                  </React.Fragment>
                ))
              )}
            </ul>
          </div>
        </div>
      </article>
    </>
  );
};

const EmptyView = () => {
  const { t } = useTranslation(["driverReservation"]);
  return (
    <div className="empty-trip">
      <div className="empty-container">
        <img
          src={authenticationImages.comercialSignup}
          className="empty_cars"
        />
        <h4 className="empty-text" data-testid="no-reservations-title">
          {t("index.no_reservations.title")}
        </h4>
        <Link
          className="btn empty-btn-orange"
          data-testid="no-reservations-rent-car"
          to={
            "/" +
            t("results", { ns: "routes" }) +
            "?destinationId=0&state=&latitud=20.6595382&longitud=-103.3494376&dateInitial=09%2F08%2F2023&hourInitial=12%3A00&dateEnd=12%2F08%2F2023&hourEnd=12%3A00&experiences=000000000&type=012345&transmission=01"
          }
        >
          {t("index.no_reservations.rent_car")}
        </Link>
      </div>
    </div>
  );
};

interface TripListProps {
  trip: Trip;
  userType: string;
}

export const TripList: FC<TripListProps> = ({
  trip,
  userType,
}): JSX.Element | null => {
  const t = useContext(TranslationContext);
  const navigate = useNavigate();
  const userId = userType == "driver" ? trip.hostId : trip.driverUserId;

  const { data: carImages } = useQuery(["image", trip.carId], () =>
    getFileDetailsIfExist(
      `/${uploadioEnv}/user-${userId}/car-${trip.carId}/car_photo_1`,
      `/${uploadioEnv}/user-${userId}/car-${trip.carId}`
    )
  );

  const handleRoute = () => {
    userType == "driver"
      ? navigate("/" + t("trips", { ns: "routes" }) + "/" + trip.id)
      : navigate(
          `/${t("manage", { ns: "routes" })}/${t("trips", { ns: "routes" })}/` +
            trip.id
        );
  };

  const { data: car } = useQuery(["car", trip.carId], () =>
    getCar(trip.carId.toString())
  );
  const { data: host } = useQuery(["host", userId], () => getUserByID(userId));

  if (!car) return null;

  return (
    <li className="list-group-item">
      <img
        className="car-img car"
        src={carImages.fileUrl.replace("raw", "thumbnail-list")}
        alt="Card image cap"
      />
      <div className="reservation-text">
        <h2>{car?.brand + " " + car?.model + " " + car?.year}</h2>
        <p>
          {t("index.plates")}
          {" " + car?.plate}
        </p>
        <button
          type="button"
          className={`btn btn-status status-` + trip.status}
        >
          {trip.status ? t("index.status." + statusList[trip.status]) : ""}
        </button>
      </div>
      <div className="reservation-text">
        <h2>{userType === "driver" ? t("index.host") : t("index.driver")}</h2>
        <p>{host?.name}</p>
      </div>
      <div className="reservation-text">
        <h2>{t("index.since")}</h2>
        <p>{dayjs(trip.pickUpDate, "DD-MM-YYYY").format("DD/MM/YYYY")}</p>
        <p>{dayjs(trip.pickUpTime, "HH:mm:ss").format("HH:mm")}</p>
      </div>
      <div className="reservation-text">
        <h2>{t("index.until")}</h2>
        <p>{dayjs(trip.returnDate, "DD-MM-YYYY").format("DD/MM/YYYY")}</p>
        <p>{dayjs(trip.returnTime, "HH:mm:ss").format("HH:mm")}</p>
      </div>
      <a className="btn btn-orange" onClick={() => handleRoute()}>
        {t("index.details")}
      </a>
    </li>
  );
};

export const MobileList: FC<TripListProps> = ({ trip }) => {
  const t = useContext(TranslationContext);

  const { data: carImages } = useQuery(["image", trip.carId], () =>
    getFileDetailsIfExist(
      `/${uploadioEnv}/user-${trip.hostId}/car-${trip.carId}/car_photo_1`,
      `/${uploadioEnv}/user-${trip.hostId}/car-${trip.carId}`
    )
  );

  const { data: car } = useQuery(["car", trip.carId], () =>
    getCar(trip.carId.toString())
  );

  if (!car) return null;

  return (
    <>
      <li className="list-group-item">
        <img
          className="car-img car"
          src={carImages.fileUrl.replace("raw", "thumbnail-list")}
          alt="Card image cap"
        />
        <div className="reservation-text">
          <h2>{car.brand + " " + car.model + " " + car.year}</h2>
          <p>
            {dayjs(trip.pickUpDate, "DD-MM-YYYY").format("DD/MM/YYYY") +
              " - " +
              dayjs(trip.returnDate, "DD-MM-YYYY").format("DD/MM/YYYY")}
          </p>
          <button
            type="button"
            className={`btn btn-status status-` + trip.status}
          >
            {trip.status ? t("index.status." + statusList[trip.status]) : ""}
          </button>
        </div>
      </li>
      <div className="dropdown-divider"></div>
    </>
  );
};
