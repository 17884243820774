import { useEffect } from "react";
import i18n from "../i18n";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DriverRoutes,
  HostRoutes,
  Lng,
  formatRoute,
} from "../lib/helpers/routesHandler";

export const useLanguage = () => {
  const { t } = useTranslation(["routes"]);
  const navigate = useNavigate();

  const checkCorrectLng = (
    specificUrl: string
  ): { lngStatus: Lng; lng: string } => {
    if (
      DriverRoutes("es").includes(specificUrl) ||
      HostRoutes("es").includes(specificUrl)
    ) {
      if (i18n.language !== "es") return { lngStatus: Lng.WRONGLNG, lng: "es" };

      return { lngStatus: Lng.TRUE, lng: "es" };
    }

    if (
      DriverRoutes("en").includes(specificUrl) ||
      HostRoutes("en").includes(specificUrl)
    ) {
      if (i18n.language !== "en") return { lngStatus: Lng.WRONGLNG, lng: "en" };

      return { lngStatus: Lng.TRUE, lng: "en" };
    }

    return { lngStatus: Lng.FALSE, lng: "" };
  };

  useEffect(() => {
    const specificUrl = formatRoute();
    const hasCorrectLng = checkCorrectLng(specificUrl);

    // if any matches navigate to homepage
    if (hasCorrectLng.lngStatus === Lng.FALSE) {
      i18n.changeLanguage("es");
      navigate(t("root", { ns: "routes" }) + t("not_found", { ns: "routes" }));
      return;
    }

    if (hasCorrectLng.lngStatus === Lng.WRONGLNG) {
      i18n.changeLanguage(hasCorrectLng.lng);
      return;
    }
  }, [location.pathname]);
};
