import {
  AddressTripPosition,
  CreateAddressTripDTO,
  UpdateAddressTripDTO,
} from "./../models/addressTrip/dtos";
import { axiosService } from "./axios";
import { AddressTrip } from "./../models/addressTrip/types";

export const getAddressTrip = async (addressId: number | undefined) => {
  try {
    const response = await axiosService.get<AddressTrip>(
      `/addressTrip/${addressId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createAddressTrip = async (
  addressTripDTO: CreateAddressTripDTO
) => {
  try {
    const data = await axiosService.post<AddressTrip>(
      "/addressTrip/",
      addressTripDTO
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateAddressTrip = async (
  addressTripDTO: UpdateAddressTripDTO,
  addressTripPosition: AddressTripPosition
) => {
  try {
    const data = await axiosService.patch<AddressTrip>(
      `/addressTrip/${addressTripPosition.id}`,
      addressTripDTO
    );
    return { data, index: addressTripPosition.index };
  } catch (error) {
    console.error(error);
  }
};

export const deleteAddressTrip = async (id: string, index: number) => {
  try {
    const data = await axiosService.delete<AddressTrip>(`/addressTrip/${id}`);
    return { data, index };
  } catch (error) {
    console.error(error);
  }
};
