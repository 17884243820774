import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";

type CountdownContextType = {
  remainingSeconds: number;
  setRemainingSeconds: React.Dispatch<React.SetStateAction<number>>;
  resetTimer: () => void;
};

const CountdownContext = createContext<CountdownContextType | null>(null);

type CountdownProviderProps = {
  children: React.ReactNode;
};

export const CountdownProvider = ({ children }: CountdownProviderProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState(() => {
    const storedValue = localStorage.getItem("remainingSeconds");
    return storedValue ? parseInt(storedValue, 10) : 60;
  });

  useEffect(() => {
    localStorage.setItem("remainingSeconds", remainingSeconds.toString());
  }, [remainingSeconds]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (remainingSeconds <= 0) {
      intervalId = null;
      return;
    }

    if (remainingSeconds > 0) {
      intervalId = setInterval(() => {
        setRemainingSeconds((seconds) => {
          if (seconds <= 0) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            clearInterval(intervalId!);
            intervalId = null;
          }
          return Math.max(seconds - 1, 0);
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      localStorage.setItem("remainingSeconds", remainingSeconds.toString());
    };
  }, [remainingSeconds]);

  const resetTimer = () => {
    setRemainingSeconds(60);
  };

  return (
    <CountdownContext.Provider
      value={{ remainingSeconds, setRemainingSeconds, resetTimer }}
    >
      {children}
    </CountdownContext.Provider>
  );
};

export const useCountdown = () => {
  const context = useContext(CountdownContext);

  if (!context) {
    throw new Error("useCountdown must be used within a CountdownProvider");
  }

  return context;
};

type CountdownTimerProps = {
  shouldReset: boolean;
};

export const CountdownTimer = ({ shouldReset }: CountdownTimerProps) => {
  const { remainingSeconds, resetTimer } = useCountdown();
  const prevShouldResetRef = useRef<boolean>(shouldReset);
  const { t } = useTranslation(["verification"]);

  useEffect(() => {
    if (shouldReset != prevShouldResetRef.current) resetTimer();

    prevShouldResetRef.current = shouldReset;
  }, [shouldReset]);

  // Format the remaining time as mm:ss
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <div className="counter">
      <p>{t("wait")}</p>
      {formattedTime}
    </div>
  );
};
