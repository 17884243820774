import { Badge, FormControl, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import authenticationImages from "../../../assets/images/authentication";
import { Trip } from "../../../models/trip/types";
import { getTripByHostID } from "../../../services/trip";
import { TranslationContext } from "../../driverReservation/show/component";
import "./styles.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useWindowSize } from "../../../hooks/windowSize";
import reservationImages from "../../../assets/images/reservations";
import { ResponsibilitiesHostBanner } from "../../../lib/components/hostReservationTools/responsibilitiesHostBanner/ResponsibilitiesHostBanner";
import { isValidHostCOwnInsurance } from "../../../lib/helpers/status";
import { getHostByUserId, getHostExistByUserId } from "../../../services/host";
import { getUserId } from "../../../services/localstorage";
import { TripCard } from "../../../components/tripCards/TripCard";
import { Helmet } from "react-helmet";

interface statusListProps {
  active: statusAttr;
  next: statusAttr;
  pending: statusAttr;
  history: statusAttr;
}

interface statusAttr {
  label: string;
  status: number[];
  count: number;
}

export const HostReservations = () => {
  const [tripFilter, setTripFilter] = useState<Trip[]>();
  const [statusTripFilter, setStatusTripFilter] = useState<number[]>([3]);
  const [tab, setTab] = useState(0);
  const window = useWindowSize();
  const userId = getUserId();
  const [statusList, setStatusList] = useState({
    active: { label: "Activas", status: [3], count: 0 },
    next: { label: "Próximas", status: [2], count: 0 },
    pending: { label: "Pendientes", status: [1], count: 0 },
    history: { label: "Historial", status: [4, 5, 6, 7, 8, 9], count: 0 },
  });

  const { t } = useTranslation(["driverReservation", "hostReservation"]);
  const { data: tripList, isSuccess } = useQuery(["trips"], () =>
    getTripByHostID()
  );

  const trips = useMemo(() => {
    if (tripList) {
      return tripList.sort(
        (firstTrip, secondTrip) => secondTrip.id - firstTrip.id
      );
    }
  }, [tripList]);

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", userId],
    () => getHostExistByUserId(userId || ""),
    {
      enabled: !!userId,
    }
  );

  const existHost = isHostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", userId],
    () => getHostByUserId(userId || ""),
    {
      enabled: existHost,
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleStatusCounter = (status: number[]) => {
    if (!isSuccess || !trips) return 0;
    return trips.filter((trip) => {
      if (!trip.status) return;
      return status.includes(trip.status);
    }).length;
  };

  useEffect(() => {
    if (isSuccess && trips) {
      setStatusList((prev) => {
        Object.entries(prev).forEach(([key, value]) => {
          prev[key as keyof statusListProps] = {
            ...value,
            count: handleStatusCounter(value.status),
          };
        });
        return prev;
      });
    }
  }, [trips]);

  useEffect(() => {
    if (isSuccess && trips) {
      setTripFilter(
        trips.filter((trip) => {
          if (!trip.status) return;
          return statusTripFilter.includes(trip.status);
        })
      );
    }
  }, [trips, statusTripFilter]);

  const tripReservation =
    tripFilter && tripFilter?.length !== 0 ? (
      <div className="card">
        <ul className="list-group list-group-flush">
          {tripFilter.map((trip: Trip, index: number) => (
            <TripCard
              trip={trip}
              key={trip.id + "-" + index + trip.tripCode}
              userType="driver"
            />
          ))}
        </ul>
      </div>
    ) : (
      <EmptyView />
    );

  return (
    <>
      <Helmet>
        <title>{t("hostReservation:seo.index.title")}</title>
        <meta
          name="description"
          content={t("hostReservation:seo.index.description")}
        />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/anfitriones/reservaciones"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/anfitriones/reservaciones"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/anfitriones/reservaciones"
        />
      </Helmet>
      <TranslationContext.Provider value={t}>
        <article className="a-reservation-container">
          <Swiper
            slidesPerView={1}
            loop
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="banner-swipper"
          >
            <SwiperSlide>
              <ResponsibilitiesHostBanner
                image={reservationImages.responsibilities}
                mainInformation={t(
                  "hostReservation:index.responsibilities_summary.main_information"
                )}
                title={t(
                  "hostReservation:index.responsibilities_summary.title"
                )}
                link="https://ayuda.balety.com/articulos/responsabilidades-de-anfitrion"
              />
            </SwiperSlide>
            <SwiperSlide>
              {isHostCommercial && hostC && isValidHostCOwnInsurance(hostC) ? (
                <ResponsibilitiesHostBanner
                  image={reservationImages.insurance}
                  mainInformation={t(
                    "hostReservation:index.host_commercial_insurance.main_information"
                  )}
                  title={t(
                    "hostReservation:index.host_commercial_insurance.title"
                  )}
                  link="https://ayuda.balety.com/articulos/anfitrion-explicacion-de-seguro-seguro-propio-de-anfitrion-comercial"
                />
              ) : (
                <ResponsibilitiesHostBanner
                  image={reservationImages.qualitas}
                  mainInformation={t(
                    "hostReservation:index.host_commercial_insurance_qualitas.main_information"
                  )}
                  title={t(
                    "hostReservation:index.host_commercial_insurance_qualitas.title"
                  )}
                  link="https://ayuda.balety.com/articulos/anfitrion-explicacion-del-seguro-con-qualitas"
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              <ResponsibilitiesHostBanner
                image={reservationImages.tips}
                mainInformation={t(
                  "hostReservation:index.host_tips.main_information"
                )}
                title={t("hostReservation:index.host_tips.title")}
                link="https://ayuda.balety.com/articulos/tips-para-ser-un-super-anfitrion"
              />
            </SwiperSlide>
          </Swiper>
          <div id="reservationCards1" className="join-container">
            <div className="title-container">
              <h1 data-testid="host-reservations-title">
                {t("index.reservations")}
              </h1>
            </div>
            {trips && (
              <div className="option-container">
                {window > 1130 ? (
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                  >
                    {Object.entries(statusList).map(([key, value]) => (
                      <Tab
                        className="btn-menu btn"
                        label={
                          <Badge
                            badgeContent={value.count}
                            className="badge-host"
                          >
                            {t("hostReservation:index.status." + key)}
                          </Badge>
                        }
                        key={key + value}
                        onClick={() => setStatusTripFilter(value.status)}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <FormControl fullWidth className="drop-orange">
                    <Select
                      label=""
                      className="dropdown-toogle"
                      defaultValue={0}
                    >
                      {Object.entries(statusList).map(([, value], index) => (
                        <MenuItem
                          key={index}
                          value={index}
                          onClick={() => setStatusTripFilter(value.status)}
                        >
                          <Badge
                            badgeContent={value.count}
                            className="badge-host"
                          >
                            {value.label}
                          </Badge>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            )}
          </div>
          <div className="reservation-card">
            {trips ? tripReservation : <EmptyView />}
          </div>
        </article>
      </TranslationContext.Provider>
    </>
  );
};

const EmptyView = () => {
  const t = useContext(TranslationContext);
  return (
    <>
      <img src={authenticationImages.comercialSignup} className="empty_cars" />
      <h4 className="empty-text" data-testid="host-reservations-title">
        {t("index.no_reservations.title")}
      </h4>
    </>
  );
};
