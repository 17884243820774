import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const VerificationCode: FC<ModalProps> = ({ isOpen, setOpen }) => {
  const [isInCooldown, setIsInCooldown] = useState(() => {
    const storedValue = localStorage.getItem("remainingSeconds");
    return storedValue && storedValue !== "0";
  });
  const { t } = useTranslation(["verification"]);

  useEffect(() => {
    const storedValue = localStorage.getItem("remainingSeconds");
    setIsInCooldown(storedValue && storedValue !== "0");
  }, [isOpen]);

  return (
    <Modal show={isOpen} className="code-request-modal" backdrop="static">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={setOpen}
        >
          <img src={navbarImages.crossBlack} alt="" />
        </button>
        <h2>{isInCooldown ? t("wait_a_minute") : t("check_your_inbox")}</h2>
      </div>
      <div className="modal-body">
        <h3>{isInCooldown ? t("wait_to_arrive") : t("code_sent")}</h3>

        <a href="https://api.whatsapp.com/send/?phone=523318866005&text=Necesito%20ayuda%2C%20no%20me%20llego%20mi%20codigo%20a%20mi%20n%C3%BAmero&type=phone_number&app_absent=0">
          {t("help_i_am_not_receiving")}
        </a>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-orange" onClick={setOpen}>
          {t("understood")}
        </button>
      </div>
    </Modal>
  );
};
