import { FC } from "react";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../types";
import "./styles.scss";
import navbarImages from "../../../assets/images/navbar";
import { useTranslation } from "react-i18next";

interface BlockDateTripProps extends ModalProps {
  setCancelRequest: () => void;
}

export const BlockDatesTrip: FC<BlockDateTripProps> = ({
  isOpen,
  setOpen,
  setCancelRequest,
}) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Modal show={isOpen} className="blocked-dates-trip-modal">
      <div className="modal-header">
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={setOpen}
        >
          <img src={navbarImages.cross} alt="" />
        </button>
        <h2>{t("blocked_dates_trip.title")}</h2>
      </div>
      <div className="modal-body">
        <h3>{t("blocked_dates_trip.subtitle")}</h3>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-red"
          data-toggle="modal"
          onClick={() => {
            setCancelRequest();
            setOpen();
          }}
        >
          <p>{t("blocked_dates_trip.reject_request")}</p>
        </button>
        <button
          type="button"
          className="btn btn-orange"
          data-dismiss="modal"
          aria-label="Close"
          onClick={setOpen}
        >
          <p>{t("blocked_dates_trip.check_reservation")}</p>
        </button>
      </div>
    </Modal>
  );
};
