import { AuthDAO, CompleteAuthDAO } from "../models/auth/types";
import CryptoJS from "crypto-js";
import { UserType } from "../models/user/types";

export const saveToken = (authResponse: AuthDAO) => {
  localStorage.setItem("token", authResponse.token);
};

export const saveIsCompleted = (CompleteAuthResponse: CompleteAuthDAO) => {
  localStorage.setItem("name", CompleteAuthResponse.name);
  localStorage.setItem(
    "userType",
    CryptoJS.AES.encrypt(
      CompleteAuthResponse.userType + "type" || "",
      process.env.REACT_APP_USER_TYPE || ""
    ).toString()
  );
};

export const saveUserId = (authResponse: AuthDAO) => {
  localStorage.setItem("id", authResponse.id);
};

export const saveEmail = (authResponse: AuthDAO) => {
  localStorage.setItem("email", authResponse.email);
};

export const getUserId = () => {
  return localStorage.getItem("id");
};

export const getUserEmail = () => {
  return localStorage.getItem("email");
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const isLogged = () => {
  return (
    !!localStorage.getItem("token") &&
    !!localStorage.getItem("email") &&
    !!localStorage.getItem("id")
  );
};

export const deleteToken = () => {
  localStorage.removeItem("token");
};

export const deleteIdAndEmail = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("id");
};

export const deleteIsCompleted = () => {
  localStorage.removeItem("name");
  localStorage.removeItem("userType");
  localStorage.removeItem("profileImageUrl");
};

export const deleteRemainingSeconds = () => {
  localStorage.removeItem("remainingSeconds");
};

export const updateUserTypeLocal = (userType: UserType) => {
  localStorage.setItem(
    "userType",
    CryptoJS.AES.encrypt(
      userType + "type" || "",
      process.env.REACT_APP_USER_TYPE || ""
    ).toString()
  );
};
