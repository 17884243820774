import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import UploaderCarGallery from "../../../../components/uploaderCarGallery/component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Toast from "../../../../components/toast/component";
import { useEditCarDocumentation } from "./useEditCarDocumentation";
import { activeInListByPosition } from "../../../helpers/textHandler";

export const EditCarDocumentation = () => {
  const {
    t,
    msg,
    openAlert,
    setOpenAlert,
    handleAccesories,
    formikEditDocumentation,
    carId,
  } = useEditCarDocumentation();

  return (
    <>
      {msg && (
        <Toast open={openAlert} setOpen={setOpenAlert} type={msg.type}>
          {msg.text}
        </Toast>
      )}
      <div className="documentation" id="second_step">
        <div className="form-container">
          <div className="info-container">
            <h2 data-testid="documentation-title" className="title">
              {t("edit.details.edit_details")}
            </h2>
            <h2>{t("edit.details.edit_features")}</h2>
          </div>
          <div className="form">
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="pictures-accordion"
                expandIcon={<ExpandMoreIcon />}
              >
                <p className="option-title">
                  {t("add.car_documentation.car_pictures")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <div className="document-generic-input">
                  <UploaderCarGallery carId={carId ? +carId : 0} editionMode />
                </div>
                <div className="information">
                  <li>{t("add.car_documentation.warning_images.order")}</li>
                  <li>{t("add.car_documentation.warning_images.min_one")}</li>
                  <li>{t("add.car_documentation.warning_images.ratio")}</li>
                  <li>{t("add.car_documentation.warning_images.upload")}</li>
                  <li>
                    <a href="">
                      {t("add.car_documentation.warning_images.guide")}
                    </a>
                  </li>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="accesories-accordion"
                expandIcon={<ExpandMoreIcon />}
              >
                <p className="option-title">
                  {t("add.car_documentation.accessories.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <div className="accesories-container" id="accesories">
                  <div className="only-accesories">
                    <div className="accesories-content">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              0,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 0)}
                            color="balety"
                          />
                        }
                        data-testid="electric-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.electric/hybrid"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              1,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 1)}
                            color="balety"
                          />
                        }
                        data-testid="convertible-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.convertible"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              2,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 2)}
                            color="balety"
                          />
                        }
                        data-testid="leather-seats-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.leather_seats"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              3,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 3)}
                            color="balety"
                          />
                        }
                        data-testid="solar-roof-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.solar_roof"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              4,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 4)}
                            color="balety"
                          />
                        }
                        data-testid="gps-checkboxes"
                        label={t("add.car_documentation.accessories.gps")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              5,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 5)}
                            color="balety"
                          />
                        }
                        data-testid="pets-welcome-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.pets_welcome"
                        )}
                      />
                    </div>
                    <div className="accesories-content">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              6,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 6)}
                            color="balety"
                          />
                        }
                        data-testid="usb-connection-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.usb_connection"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              7,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 7)}
                            color="balety"
                          />
                        }
                        data-testid="bluetooth-checkboxes"
                        label={t("add.car_documentation.accessories.bluetooth")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              8,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 8)}
                            color="balety"
                          />
                        }
                        data-testid="car-play-checkboxes"
                        label={t("add.car_documentation.accessories.car_play")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              9,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 9)}
                            color="balety"
                          />
                        }
                        data-testid="android-auto-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.android_auto"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              10,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 10)}
                            color="balety"
                          />
                        }
                        data-testid="aux-input-checkboxes"
                        label={t("add.car_documentation.accessories.aux_input")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              11,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 11)}
                            color="balety"
                          />
                        }
                        data-testid="air-conditioning-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.air_conditioning"
                        )}
                      />
                    </div>
                    <div className="accesories-content">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              12,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 12)}
                            color="balety"
                          />
                        }
                        data-testid="panoramic-ceiling-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.panoramic_ceiling"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              13,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 13)}
                            color="balety"
                          />
                        }
                        data-testid="bike-rack-checkboxes"
                        label={t("add.car_documentation.accessories.bike_rack")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              14,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 14)}
                            color="balety"
                          />
                        }
                        data-testid="4wd-checkboxes"
                        label={t("add.car_documentation.accessories.4wd")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              15,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 15)}
                            color="balety"
                          />
                        }
                        data-testid="child-seat-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.child_seat"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              16,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 16)}
                            color="balety"
                          />
                        }
                        data-testid="seat-heater-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.seat_heater"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              17,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 17)}
                            color="balety"
                          />
                        }
                        data-testid="electric-seat-adjustment-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.electric_seat_adjustment"
                        )}
                      />
                    </div>
                    <div className="accesories-content">
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              18,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 18)}
                            color="balety"
                          />
                        }
                        data-testid="power-windows-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.power_windows"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              19,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 19)}
                            color="balety"
                          />
                        }
                        data-testid="video-player-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.video_player"
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              20,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 20)}
                            color="balety"
                          />
                        }
                        data-testid="cd-player-checkboxes"
                        label={t("add.car_documentation.accessories.cd_player")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={activeInListByPosition(
                              21,
                              formikEditDocumentation.values.characteristicsList
                            )}
                            onChange={(e) => handleAccesories(e, 21)}
                            color="balety"
                          />
                        }
                        data-testid="satellite-radio-checkboxes"
                        label={t(
                          "add.car_documentation.accessories.satellite_radio"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="description-accordion"
                expandIcon={<ExpandMoreIcon />}
              >
                <p className="option-title">
                  {t("add.car_documentation.description.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <textarea
                  name=""
                  id="txtDescription"
                  value={formikEditDocumentation.values.description}
                  onChange={formikEditDocumentation.handleChange("description")}
                  className="message-box"
                  placeholder={t(
                    "add.car_documentation.description.placeholder"
                  )}
                  maxLength={5000}
                ></textarea>
                <p className="max-text">
                  {t("add.car_documentation.max_words")}
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="file-container">
              <AccordionSummary
                data-testid="rules-accordion"
                expandIcon={<ExpandMoreIcon />}
              >
                <p className="option-title">
                  {t("add.car_documentation.rules.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options">
                <textarea
                  name=""
                  id="txtRules"
                  value={formikEditDocumentation.values.rules}
                  onChange={formikEditDocumentation.handleChange("rules")}
                  className="message-box"
                  placeholder={t("add.car_documentation.rules.placeholder")}
                  maxLength={5000}
                  minLength={200}
                ></textarea>
                <p className="max-text">
                  {t("add.car_documentation.max_words")}
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="btn-container save-container" id="save_container">
        <p className="reminder">{t("edit.dont_forget")}</p>
        <button
          type="button"
          id="save_first"
          onClick={() => formikEditDocumentation.handleSubmit()}
          className="btn btn-blue"
        >
          {t("edit.save_changes")}
        </button>
      </div>
    </>
  );
};
