import { SendBirdData, SendBirdMessage } from "../models/user/dtos";
import { axiosService } from "./axios";
import { getToken } from "./localstorage";

export const getSendBirdData = async (): Promise<SendBirdData | undefined> => {
  try {
    const response = await axiosService.get<SendBirdData>(
      `/chat/create/user/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createFirstMessage = async (message: SendBirdMessage) => {
  try {
    const response = await axiosService.post(`/chat/create/chat/`, message, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
