import * as Yup from "yup";

export interface MessageForm {
  message: string;
}

export const modalMessageSchema: Yup.SchemaOf<MessageForm> = Yup.object().shape(
  {
    message: Yup.string().required(),
  }
);
