export interface Destination {
  id: number;
  name: string;
  coordinates: Coordinates[];
  status: DestinationStatus;
  destinationMainAddress: Coordinates;
}

export interface Coordinates {
  lat: number;
  lng: number;
}
export interface CompleteCordinates {
  latitud: number;
  longitud: number;
}

export enum DestinationStatus {
  COMING_SOON,
  ACTIVE,
}

export interface currentLocation {
  state: string;
  lat: string;
  lng: string;
}
