import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import helpImages from "../../../../assets/images/help";
import reservationImages from "../../../../assets/images/reservations";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { Trip } from "../../../../models/trip/types";
import { InsuranceType } from "../../../../models/insurance/types";

export const InsuranceBanner: FC<{
  trip: Trip;
  redirect?: boolean;
}> = ({ trip, redirect = true }) => {
  const { t } = useTranslation(["driverReservation"]);
  const location = useLocation();

  const qualitasPoints = useMemo(
    () => [
      t("create.insurance_summary.civil_liability"),
      t("create.insurance_summary.material_damage"),
      t("create.insurance_summary.total_theft"),
      t("create.insurance_summary.roadside_assistance"),
      t("create.insurance_summary.legal_expenses"),
      t("create.insurance_summary.medical_expenses"),
    ],
    []
  );
  const hdiPoints = useMemo(
    () => [
      t("create.insurance_hdi_summary.civil_liability"),
      t("create.insurance_hdi_summary.material_damage"),
      t("create.insurance_hdi_summary.total_theft"),
      t("create.insurance_hdi_summary.roadside_assistance"),
      t("create.insurance_hdi_summary.legal_expenses"),
      t("create.insurance_hdi_summary.medical_expenses"),
    ],
    []
  );

  if (trip.insuranceCompany === InsuranceType.HOST_INSURANCE) {
    return (
      <div
        className="insurance-container-banner"
        onClick={() =>
          redirect &&
          window.open(
            "https://ayuda.balety.com/articulos/huesped-explicacion-del-seguro-seguro-de-anfitrion-comercial",
            "_blank"
          )
        }
        style={{ cursor: "pointer" }}
      >
        <div className="insurance-summary">
          <div className="card card-h-i">
            <div className="image-container">
              <img
                src={helpImages.insurance}
                alt="qualitas"
                className="card-image"
              />
            </div>
            <div className="card-body">
              <h4 className="title">{t("create.insurance_summary.title")}</h4>
              <p className="main-information">{t("create.contact_host")}</p>
              {location.pathname.includes("huespedes") && (
                <div className="know-more">
                  <a>
                    {t("create.know_more")}
                    <img src={helpImages.arrowRightBlue} alt="" />
                  </a>
                </div>
              )}
            </div>
            <div className="know-more-o">
              <a>
                {t("create.know_more")}
                <img src={helpImages.arrowRightBlue} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (trip.insuranceCompany === InsuranceType.HDI) {
    return (
      <div
        className="insurance-container-banner"
        onClick={() => redirect && window.open("", "_blank")}
        style={{ cursor: "pointer" }}
      >
        <div className="insurance-summary">
          <div className="card">
            <div className="image-container">
              <img
                src={reservationImages.hdi}
                alt="HDI"
                className="card-image"
              />
            </div>
            <div className="card-body">
              <h4 className="title">
                {t("create.insurance_hdi_summary.title")}
              </h4>
              <p className="main-information">
                {t("create.insurance_hdi_summary.main_information")}
              </p>
              <div className="list-left">
                {hdiPoints.slice(0, 3).map((point, index) => (
                  <div className="point" key={point + index}>
                    <img src={helpImages.checkInsurance} alt="" />
                    {point}
                  </div>
                ))}
              </div>
              <div className="list-right">
                {hdiPoints.slice(3, 6).map((point, index) => (
                  <div className="point" key={point + index}>
                    <img src={helpImages.checkInsurance} alt="" />
                    {point}
                  </div>
                ))}
              </div>
              {!location.pathname.includes("huespedes") ? (
                <span className="extra-information">
                  {t("create.insurance_hdi_summary.extra_information")}
                </span>
              ) : (
                <div className="know-more">
                  <a>
                    {t("create.know_more")}
                    <img src={helpImages.arrowRightBlue} alt="" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="insurance-container-banner"
      onClick={() =>
        redirect &&
        window.open(
          "https://ayuda.balety.com/articulos/huesped-explicacion-del-seguro-con-qualitas",
          "_blank"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="insurance-summary">
        <div className="card">
          <div className="image-container">
            <img
              src={reservationImages.qualitas}
              alt="qualitas"
              className="card-image"
            />
          </div>
          <div className="card-body">
            <h4 className="title">{t("create.insurance_summary.title")}</h4>
            <p className="main-information">
              {t("create.insurance_summary.main_information")}
            </p>
            <div className="list-left">
              {qualitasPoints.slice(0, 3).map((point, index) => (
                <div className="point" key={point + index}>
                  <img src={helpImages.checkInsurance} alt="" />
                  {point}
                </div>
              ))}
            </div>
            <div className="list-right">
              {qualitasPoints.slice(3, 6).map((point, index) => (
                <div className="point" key={point + index}>
                  <img src={helpImages.checkInsurance} alt="" />
                  {point}
                </div>
              ))}
            </div>
            {!location.pathname.includes("huespedes") ? (
              <span className="extra-information">
                {t("create.insurance_summary.extra_information")}
              </span>
            ) : (
              <div className="know-more">
                <a>
                  {t("create.know_more")}
                  <img src={helpImages.arrowRightBlue} alt="" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
