import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Switch,
  AccordionSummary,
  Slider,
  AccordionDetails,
  styled,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { useContext, Dispatch, SetStateAction, ChangeEvent } from "react";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { ExperiencesFilter, TypeFilter } from "../../../../views/results/types";
import { MAXPrice, resultParams } from "../../../../views/results/types";
import { Transmission } from "../../../../models/car/types";
import useResults from "../../../../hooks/results/useResults";
import { FilterContext } from "../../../../views/results/types";
import { useExperience } from "../../../../hooks/exp";
import images from "../../../../assets/images/order";
import { useTypes } from "../../../../hooks/car/show/useTypes";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const marks = [
  {
    value: 1,
    label: "1 km",
  },
  {
    value: 100,
    label: "100 km",
  },
];

export const LeftFilter = ({
  params,
  setParams,
  setPage,
}: {
  params: resultParams;
  setParams: Dispatch<SetStateAction<resultParams>>;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  const t = useContext(TranslationContext);
  const EXPERIENCES = useExperience();
  const CAR_TYPES = useTypes();
  const [filters, setFilters] = useContext(FilterContext);
  const { handleSubmit, loading } = useResults(params, setPage);

  const changeRange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    let price: number[];
    let newValue = parseInt(e.target.value.substring(1)) || 0;
    if (newValue >= parseInt(MAXPrice)) {
      newValue = parseInt(MAXPrice);
    }

    setFilters((prevState) => {
      price =
        type === "MIN"
          ? [newValue, prevState.price[1]]
          : [prevState.price[0], newValue];

      return {
        ...prevState,
        price,
      };
    });
  };

  return (
    <div className="filter-container-desktop">
      <div>
        {/* Car transmission type */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p className="option-title" data-testid="transmission-label">
              {t("filter.transmission.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="aqua"
                  checked={filters.transmission[0]}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      transmission: {
                        ...prevState.transmission,
                        [Transmission.AUTOMATIC]: e.target.checked,
                      },
                    }));
                  }}
                />
              }
              data-testid="transmission-automatic"
              label={t("filter.transmission.automatic")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="aqua"
                  checked={filters.transmission[1]}
                  onChange={(e) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      transmission: {
                        ...prevState.transmission,
                        [Transmission.MANUAL]: e.target.checked,
                      },
                    }));
                  }}
                />
              }
              data-testid="transmission-manual"
              label={t("filter.transmission.manual")}
            />
          </AccordionDetails>
        </Accordion>

        {/* Car show options */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="other-filters-label">
              {t("filter.show.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="d-flex justify-content-between align-items-center w-100 pb-2">
              <p className="m-0 pb-1">{t("filter.show.with_home_delibery")}</p>
              <Switch
                checked={filters.address}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    address: e.target.checked,
                  }));
                }}
                color="aqua"
              />
            </Box>
            <Box className="d-flex justify-content-between align-items-center w-100 pb-2">
              <p className="m-0 pb-1">{t("filter.show.without_deposit")}</p>
              <Switch
                checked={filters.depositAmount}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    depositAmount: e.target.checked,
                  }));
                }}
                color="aqua"
              />
            </Box>{" "}
            <Box className="d-flex justify-content-between align-items-center w-100 pb-2">
              <p>
                {t("filter.show.only_ecoligical")}
                <img
                  src={images?.ecoType}
                  alt="ecological type"
                  style={{
                    width: "1em",
                    height: "1em",
                    marginLeft: "8px",
                  }}
                />
              </p>
              <Switch
                checked={filters.ecologyCar}
                onChange={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    ecologyCar: e.target.checked,
                  }));
                }}
                color="aqua"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Car types */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="type-label">
              {t("filter.type.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {CAR_TYPES.map((item) => (
              <div key={item.index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.type[item.index as keyof TypeFilter]}
                      onChange={(e) =>
                        setFilters((prevState) => ({
                          ...prevState,
                          type: {
                            ...prevState.type,
                            [item.index as keyof TypeFilter]: e?.target.checked,
                          },
                        }))
                      }
                      color="aqua"
                    />
                  }
                  data-testid={item.test}
                  label={item.label}
                  sx={{ marginRight: "8px" }}
                />
                <img src={item.src} alt="tipo compacto" />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        {/* Car experiences */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="experiences-label">
              {t("filter.experiences.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {EXPERIENCES.map((item) => (
              <div key={item.index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.experiences[
                          item.index as keyof ExperiencesFilter
                        ]
                      }
                      onChange={(e) => {
                        setFilters((prevState) => ({
                          ...prevState,
                          experiences: {
                            ...prevState.experiences,
                            [item.index as keyof ExperiencesFilter]:
                              e?.target.checked,
                          },
                        }));
                      }}
                      color="aqua"
                    />
                  }
                  label={item.label}
                  sx={{ marginRight: "8px" }}
                />
                <img src={item.src} alt={item.label} />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>

        {/* Car price */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="price-label">
              {t("filter.price.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails className=" price-input">
            <div className="d-flex justify-content-center">
              <Slider
                getAriaLabel={() => "Price range"}
                value={filters.price}
                onChange={(event, newValue) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    price: newValue as number[],
                  }));
                }}
                valueLabelDisplay="on"
                min={0}
                max={10000}
                valueLabelFormat={(value) => <div>&#36;{value}</div>}
                color="aqua"
                style={{ margin: "36px 15px 24px 15px", width: "70%" }}
              />
            </div>
            <div className="d-flex">
              <FormControl variant="standard">
                <label htmlFor="price-input-from">
                  {t("filter.price.from")}
                </label>
                <TextField
                  onChange={(e) => changeRange(e, "MIN")}
                  value={`$${filters.price[0]}`}
                  id="price-input-from"
                  variant="outlined"
                />
              </FormControl>
              <FormControl variant="standard">
                <label htmlFor="price-input-to">{t("filter.price.to")}</label>
                <TextField
                  onChange={(e) => changeRange(e, "MAX")}
                  value={`$${filters.price[1]}`}
                  id="price-input-to"
                  variant="outlined"
                />
              </FormControl>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Car distance */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="distance-label">
              {t("filter.distance.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails className=" price-input">
            <div className="d-flex justify-content-center">
              <Slider
                getAriaLabel={() => "Distance"}
                value={filters.distance}
                onChange={(event, newValue) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    distance: newValue as number,
                  }));
                }}
                min={1}
                max={100}
                valueLabelDisplay="on"
                valueLabelFormat={(value) => <div>{value} km</div>}
                color="aqua"
                marks={marks}
                style={{ margin: "36px 0px 24px 0px" }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Car number of bags and seats */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="option-title" data-testid="price-label">
              {t("filter.seats_and_bags.label")}
            </p>
          </AccordionSummary>
          <AccordionDetails className="bags-seats">
            <div>
              <label className="label">
                {t("filter.seats_and_bags.number_of_seats")}
              </label>
              <FormControl fullWidth>
                <Select
                  labelId="seats_number"
                  id="seats_number"
                  value={filters.numSeats || 0}
                  className="select"
                  onChange={(e) => {
                    typeof e.target.value === "number" &&
                      setFilters((prevState) => ({
                        ...prevState,
                        numSeats: e.target.value as number,
                      }));
                  }}
                >
                  <MenuItem key={0} value={0} selected={true}>
                    {t("filter.seats_and_bags.all")}
                  </MenuItem>
                  {Array.from({ length: 20 }, (_, i) => i + 1).map(
                    (numSeats) => (
                      <MenuItem key={numSeats} value={numSeats}>
                        {`${numSeats} ${t("filter.seats_and_bags.or_more")}`}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <div>
              <label className="label">
                {t("filter.seats_and_bags.number_of_bags")}
              </label>
              <FormControl fullWidth>
                <Select
                  labelId="bags_number"
                  id="bags_number"
                  value={filters.numBags || 0}
                  className="select"
                  onChange={(e) => {
                    typeof e.target.value === "number" &&
                      setFilters((prevState) => ({
                        ...prevState,
                        numBags: e.target.value as number,
                      }));
                  }}
                >
                  <MenuItem key={0} value={0} selected={true}>
                    {t("filter.seats_and_bags.all")}
                  </MenuItem>
                  {Array.from({ length: 15 }, (_, i) => i + 1).map(
                    (numBags) => (
                      <MenuItem key={numBags} value={numBags}>
                        {`${numBags} ${t("filter.seats_and_bags.or_more")}`}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-orange btn-filter"
          style={{ marginBottom: "40px" }}
          onClick={() => handleSubmit(filters, setParams)}
          disabled={loading}
        >
          {t("filter.apply")}
        </button>
      </div>
    </div>
  );
};
