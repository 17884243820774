import * as Yup from "yup";

export interface CouponForm {
  coupon: string;
}

export interface discount {
  type: string;
  quantity: number;
}

export const modalCouponSchema: Yup.SchemaOf<CouponForm> = Yup.object().shape({
  coupon: Yup.string().required(),
});
