import { SetStateAction, Dispatch, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import "./styles.scss";
import { getInsuranceAndRefresh } from "../../../services/insurance";
import { Car } from "../../../models/car/types";
import { useTranslation } from "react-i18next";
import { Host, TypeInsurance } from "../../../models/host/types";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useEditCarDetails } from "../../../lib/components/carTools/editCarDetails/useEditCarDetails";
import { Insurance, InsuranceType } from "../../../models/insurance/types";
import dayjs from "dayjs";
import Loading from "../../loading/component";
import { isValidHostCOwnInsurance } from "../../../lib/helpers/status";
import { useQuery } from "react-query";
import { getHostByUserId } from "../../../services/host";

type CarInsurance = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  car: Car;
  hostCommercial?: Host;
};

// ASK ALDAIR
const CarInsurance = ({ open, setOpen, car, hostCommercial }: CarInsurance) => {
  const [insuranceData, setInsuranceData] = useState<Insurance | null>();
  const { t } = useTranslation(["car"]);
  const { insurancePolicyFile } = useEditCarDetails(car.id.toString() || "");
  const existHost = !!hostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", car.userId],
    () => getHostByUserId(car.userId.toString() || ""),
    {
      enabled: existHost,
    }
  );

  const getInsurance = async () => {
    try {
      const data = await getInsuranceAndRefresh(car.id);
      if (!data) {
        setInsuranceData(null);
        return;
      }
      const date = dayjs().toString();

      setInsuranceData({
        ...data,
        lastUpdate: date,
        depositAmount: data.depositAmount,
      });
    } catch (err) {
      setInsuranceData(null);
    }
  };

  useEffect(() => {
    if (open) {
      getInsurance();
    }
  }, [open]);

  const insuranceInfo =
    insuranceData === undefined ? (
      <Loading height="10vh" />
    ) : insuranceData !== null ? (
      <>
        <div className="car-data-items">
          <div>
            <p>
              {t("index.modals.insurance.data.car")}
              <span>{`${car.brand} ${car.model} ${car.year}`}</span>
            </p>
            <p>
              {t("index.modals.insurance.data.plate")}
              <span>{car.plate}</span>
            </p>
          </div>
          <div>
            {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type !== InsuranceType.QUALITAS ? (
              <>
                <p>
                  {t("index.modals.own_insurance.charge")}
                  <span>
                    {!!insuranceData?.customerPrice
                      ? "GRATIS"
                      : `$${insuranceData?.customerPrice}`}
                  </span>
                </p>
                <p>
                  {t("index.modals.own_insurance.deposit")}
                  <span>
                    {!!insuranceData?.depositAmount
                      ? "0"
                      : `$${insuranceData?.depositAmount}`}
                  </span>
                </p>
                {!!insuranceData?.customerPrice &&
                  !!insuranceData?.depositAmount && (
                    <p>
                      {`${t(
                        "index.modals.own_insurance.fill_insurance_values_message"
                      )} `}
                      <span>
                        {`${t(
                          "index.modals.own_insurance.fill_insurance_values_message_insurance"
                        )} `}
                      </span>
                      {`${t(
                        "index.modals.own_insurance.fill_insurance_values_message_deposit_and"
                      )} `}
                      <span>
                        {`${t(
                          "index.modals.own_insurance.fill_insurance_values_message_deposit"
                        )} `}
                      </span>
                      .
                    </p>
                  )}
              </>
            ) : (
              <>
                <p>
                  {t("index.modals.insurance.data.nominal_value")}
                  <span>
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      maximumSignificantDigits: 6,
                    }).format(parseFloat(insuranceData?.marketValue))}
                  </span>
                </p>
                <p>
                  {t("index.modals.insurance.data.last_update")}
                  <span>{insuranceData?.lastUpdate.toString()}</span>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="list">
          <p>
            {hostCommercial &&
            hostC &&
            isValidHostCOwnInsurance(hostC) &&
            insuranceData.type === InsuranceType.QUALITAS
              ? t("index.modals.own_insurance.title")
              : insuranceData.type === InsuranceType.QUALITAS
              ? t("index.modals.insurance.list.title")
              : t("index.modals.insurance.hdi_list.title")}
          </p>
          {hostCommercial &&
          hostC &&
          isValidHostCOwnInsurance(hostC) &&
          insuranceData.type === InsuranceType.HOST_INSURANCE ? (
            <div className="own-insurance">
              <p>{t("index.modals.own_insurance.message")}</p>
              {insurancePolicyFile && (
                <div
                  style={{ textAlign: "center", display: "flex" }}
                  className="link-insurance"
                >
                  <DescriptionOutlinedIcon />
                  <button onClick={() => window.open(insurancePolicyFile.url)}>
                    {t("index.modals.own_insurance.open_insurance_document")}
                  </button>
                </div>
              )}
            </div>
          ) : insuranceData.type === InsuranceType.QUALITAS ? (
            <ul>
              <li>
                <span>
                  {t("index.modals.insurance.list.material_data_key")}
                </span>
                {t("index.modals.insurance.list.material_data_value")}
              </li>
              <li>
                <span>{t("index.modals.insurance.list.theft_key")}</span>
                {t("index.modals.insurance.list.theft_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.civil_liability_key")}
                </span>
                {t("index.modals.insurance.list.civil_liability_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.medical_expenses_key")}
                </span>
                {t("index.modals.insurance.list.medical_expenses_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.list.legal_expenses_key")}
                </span>
                {t("index.modals.insurance.list.legal_expenses_value")}
              </li>
              <li>
                <span>{t("index.modals.insurance.list.qualitas")}</span>
                {t("index.modals.insurance.list.legal_expenses_value")}
              </li>
              <li>
                <span>{t("index.modals.insurance.list.car_accident_key")}</span>
                $100,000.00
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.material_data_key")}
                </span>
                {t("index.modals.insurance.hdi_list.material_data_value")}
              </li>
              <li>
                <span>{t("index.modals.insurance.hdi_list.theft_key")}</span>
                {t("index.modals.insurance.hdi_list.theft_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.civil_liability_key")}
                </span>
                {t("index.modals.insurance.hdi_list.civil_liability_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.medical_expenses_key")}
                </span>
                {t("index.modals.insurance.hdi_list.medical_expenses_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.legal_expenses_key")}
                </span>
                {t("index.modals.insurance.hdi_list.legal_expenses_value")}
              </li>
              <li>
                <span>{t("index.modals.insurance.hdi_list.qualitas")}</span>
                {t("index.modals.insurance.hdi_list.legal_expenses_value")}
              </li>
              <li>
                <span>
                  {t("index.modals.insurance.hdi_list.car_accident_key")}
                </span>
                $100,000.00
              </li>
            </ul>
          )}
        </div>
        {insuranceData.type === InsuranceType.QUALITAS && (
          <div className="help">{t("index.modals.insurance.help")}</div>
        )}
      </>
    ) : (
      <div>
        <p>{t("index.modals.insurance.empty_message")}</p>
      </div>
    );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="car-insurance-modal"
    >
      <div
        className={
          !insuranceData ? "car-data-empty" : "car-insurance-container"
        }
        style={
          hostCommercial?.insurance === TypeInsurance.HOST_INSURANCE
            ? { alignSelf: "center" }
            : {}
        }
      >
        <div className="d-flex justify-content-end btn-close-container">
          <button
            type="button"
            className="btn-close"
            onClick={() => setOpen(false)}
          ></button>
        </div>
        <div className="car-data">
          <h4>{t("index.modals.insurance.title")}</h4>
          {insuranceInfo}
        </div>
      </div>
    </Modal>
  );
};

export default CarInsurance;
