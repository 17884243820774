import { useFormik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import navbarImages from "../../../assets/images/navbar";
import { User, UserAccountStatus } from "../../../models/user/types";
import {
  updateCodePhone,
  updateUserPhone,
  updateUserStatus,
} from "../../../services/user";
import { ModalProps } from "../types";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Loading from "../../loading/component";

interface modalPhoneForm {
  phone: string;
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const modalPhoneModelSchema: Yup.SchemaOf<modalPhoneForm> = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(),
});

interface modalPhoneProps extends ModalProps {
  user: User;
}

export const ChangePhoneNumberModal: FC<modalPhoneProps> = ({
  user,
  isOpen,
  setOpen,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["modals"]);

  const fetchPhone = async (value: modalPhoneForm) => {
    const phone = "+" + value.phone;
    const response = await updateUserPhone(phone);
    if (!response || response.status != 200) return;

    switch (user.status) {
      case UserAccountStatus.REGISTER_NO_ACTIVE_EMAIL:
        await updateUserStatus(UserAccountStatus.REGISTER_NO_ACTIVE);
        await updateCodePhone();
        break;
      case UserAccountStatus.ACTIVE:
        await updateUserStatus(UserAccountStatus.REGISTER_NO_ACTIVE_PHONE);
        await updateCodePhone();
        break;
      default:
        await updateCodePhone();
        break;
    }
    setOpen();
    queryClient.resetQueries(["profile"]);
  };

  const formikPhoneModal = useFormik<modalPhoneForm>({
    initialValues: {
      phone: "",
    },
    validationSchema: modalPhoneModelSchema,
    onSubmit: fetchPhone,
  });
  return (
    <Modal show={isOpen} className="change-number-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.crossBlack} alt="" />
        </button>
        <h2 id="change_phone_modal_text">{t("change_phone.title")}</h2>
      </div>
      <div className="modal-body" data-testid="phone_input">
        <h3>Número celular</h3>
        <PhoneInput
          country={"mx"}
          specialLabel=""
          countryCodeEditable={false}
          onChange={formikPhoneModal.handleChange("phone")}
          onBlur={formikPhoneModal.handleBlur("phone")}
          value={formikPhoneModal.values.phone}
        />
      </div>
      <div className="modal-footer">
        <button
          id="btn_change_phone"
          type="button"
          onClick={() => {
            if (!formikPhoneModal.isSubmitting) formikPhoneModal.handleSubmit();
          }}
          disabled={formikPhoneModal.isSubmitting}
          className="btn btn-orange"
        >
          {!formikPhoneModal.isSubmitting ? (
            t("change_phone.change")
          ) : (
            <Loading height="1vh" color="#fff" />
          )}
        </button>
      </div>
    </Modal>
  );
};
