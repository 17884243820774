import GoogleMapReact from "google-map-react";
import a_cars from "../../../../assets/images/a_cars";
import navbarImages from "../../../../assets/images/navbar";
import { Marker } from "../carUbication/component";
import Autocomplete from "react-google-autocomplete";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from "@mui/material";
import { useAddCarImportantData } from "./useAddCarImportantData";
import { CarSpecifications } from "./carSpecifications/CarSpecifications";
import { CarCategories } from "./carCategories/CarCategories";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRef } from "react";
import { placeComponentForm } from "../../../../views/car/edit/types";

/**
 * ADD CAR DETAILS - step 2: car more data (transmission, type, number of seats, number of bags, plate and is ecofriendly type),
 * car categories and main address.
 */

export const AddCarImportantData = ({
  formErrors,
  carId,
}: {
  formErrors: string[] | undefined;
  carId: string;
}) => {
  const autocompleteInput = useRef<HTMLInputElement>(null);
  const {
    t,
    values,
    handleAddressFormat,
    handleCurrentLocation,
    defaultProps,
  } = useAddCarImportantData({ carId });

  return (
    <div className="model-ubication" id="first_step">
      <div className="form-container">
        <div className="info-container">
          <h2 data-testid="important-data-title" className="title">
            {t("add.car_model_and_ubication.important_data_about_your_car")}
          </h2>
          <h3>
            {t("add.car_model_and_ubication.select_and_add_important_data")}
          </h3>
          <p>{t("add.car_model_and_ubication.all_fields")}</p>
        </div>
        <form>
          <div className="form">
            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                data-testid="information-accordion"
                className="d-flex justify-content-between accordion-summary"
              >
                <p className="option-title">
                  {t("add.car_model_and_ubication.tell_us_about_your_car")}
                </p>
                <div className="dropdown-divider"></div>
              </AccordionSummary>
              <AccordionDetails className="information">
                <div className="generic-input" data-testid="brand-text">
                  <label className="select-label">
                    {t("add.car_model_and_ubication.brand.title")}
                  </label>
                  <p className="generic-text marca">{values?.brand}</p>
                </div>
                <div className="generic-input" data-testid="model-text">
                  <label className="select-label">
                    {t("add.car_model_and_ubication.model.title")}
                  </label>
                  <p className="generic-text marca">{values?.model}</p>
                </div>
                <div className="generic-input" data-testid="year-text">
                  <label className="select-label">
                    {t("add.car_model_and_ubication.year.title")}
                  </label>
                  <p className="generic-text marca">{values?.year}</p>
                </div>
                <div className="generic-input" data-testid="version-text">
                  <label className="select-label">
                    {t("add.car_model_and_ubication.version.title")}
                  </label>
                  <p className="generic-text marca">{values?.version}</p>
                </div>
                <p className="need-modify-alert">
                  {t("add.car_model_and_ubication.need_modify")}
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                data-testid="information-accordion"
                className="d-flex justify-content-between accordion-summary"
              >
                <p className="option-title">
                  {t("add.car_model_and_ubication.details")}
                </p>
                <div className="dropdown-divider"></div>
              </AccordionSummary>
              <AccordionDetails className="drop-options-important-details">
                <CarSpecifications formErrors={formErrors} />
                <CarCategories />
              </AccordionDetails>
            </Accordion>

            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                className="d-flex justify-content-between accordion-summary"
                id="drop_arrow2"
                expandIcon={
                  <img
                    className="drop-row"
                    src={navbarImages.downArrow}
                    alt=""
                  />
                }
              >
                <p className="option-title" data-testid="address-title">
                  {t("add.car_model_and_ubication.address")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options" id="map">
                <div className="document-generic-input">
                  <div className="d-flex align-items-center">
                    <span id="where" className="where">
                      {t("add.car_model_and_ubication.where_is_located")}
                    </span>
                    <span className="blue-asterisk fs-5">*</span>
                  </div>

                  <div className="direction-input">
                    <Autocomplete
                      id="txtUbication"
                      ref={autocompleteInput}
                      className={`input-text-large ${
                        formErrors?.includes("address") ? "input-error" : ""
                      }`}
                      defaultValue={values.mainAddress?.formatted_address}
                      apiKey={process.env.REACT_APP_GOOGLE_MAPS}
                      options={{
                        componentRestrictions: { country: "mx" },
                        fields: [
                          "geometry",
                          "formatted_address",
                          "place_id",
                          "address_components",
                          "name",
                        ],
                        types: [],
                      }}
                      language="es-419"
                      onPlaceSelected={(place) => handleAddressFormat(place)}
                    />
                    <button
                      type="button"
                      className="btn btn-orange-des"
                      onClick={() => {
                        handleCurrentLocation({ autocompleteInput });
                      }}
                    >
                      <img src={a_cars.ubication} alt="" />
                      {t("add.car_model_and_ubication.my_location")}
                    </button>
                  </div>
                  <div className="map" data-testid="google-map">
                    {!!values.mainAddress ? (
                      <CarLocationMap
                        mainAddress={values.mainAddress}
                        zoom={defaultProps.zoom}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        height="100%"
                        width="100%"
                      />
                    )}
                  </div>
                  <h5>
                    {t(
                      "add.car_model_and_ubication.information_to_register_your_car"
                    )}
                  </h5>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      </div>
    </div>
  );
};

const CarLocationMap = ({
  mainAddress,
  zoom,
}: {
  mainAddress: placeComponentForm;
  zoom: number;
}) => {
  const lat = parseFloat(mainAddress?.geometry?.location?.lat || "0");
  const lng = parseFloat(mainAddress?.geometry?.location?.lng || "0");

  if (!lat || !lng) {
    return <Skeleton variant="rectangular" height="100%" width="100%" />;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS || "" }}
      center={{ lat, lng }}
      defaultZoom={zoom}
    >
      <Marker lat={lat} lng={lng} text="Ubicación principal" />
    </GoogleMapReact>
  );
};
