import * as Yup from "yup";

export interface VerificationForm {
  phoneCode: string;
}

export const verificationSchema: Yup.SchemaOf<VerificationForm> =
  Yup.object().shape({
    phoneCode: Yup.string()
      .length(4, "El codigo debe contener 4 digitos")
      .required("El codigo es requerido"),
  });
