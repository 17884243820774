import { CarResults } from "../../../../models/car/types";
import { FC } from "react";
import CarResultCard from "../../../../components/carResultCard/component";
import { resultParams } from "../../../../views/results/types";

export const ShowResult: FC<{
  page: number;
  cars: CarResults[];
  params: resultParams;
}> = ({ cars, page, params }) => {
  return (
    <>
      {cars
        .slice((page - 1) * 8, 8 * page)
        .map((car: CarResults, index: number) => (
          <CarResultCard car={car} key={`${car.id}-${index}`} params={params} />
        ))}
    </>
  );
};
