import * as Yup from "yup";
import { CompleteUserDTO } from "../../models/user/dtos";

export interface CompleteUserForm extends CompleteUserDTO {
  privacyPolicies: boolean;
  termsAndConditions: boolean;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const completeProfileSchema: Yup.SchemaOf<CompleteUserForm> =
  Yup.object().shape({
    name: Yup.string()
      .min(3, "El nombre debe contener al menos 3 caracteres")
      .required("El nombre es requerido"),
    surName: Yup.string()
      .min(3, "Los apellidos debe contener al menos 3 caracteres")
      .required("Sus apellidos son requeridos"),
    phone: Yup.string()
      .matches(phoneRegExp, "El numero no es valido")
      .min(11, "El numero debe contener minimo 11 caracteres")
      .required("El numero telefonico es requerido"),
    privacyPolicies: Yup.boolean()
      .oneOf([true], "Debes de aceptar las politicas de privacidad")
      .required("Debes de aceptar las politicas de privacidad"),
    termsAndConditions: Yup.boolean()
      .oneOf([true], "Debes de aceptar las politicas de privacidad")
      .required("Debes de aceptar las politicas de privacidad"),
  });
