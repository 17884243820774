import React, { useState, useContext } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import authenticationImages from "../../assets/images/authentication";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { TranslationContext } from "../../views/driverReservation/show/component";
import "./styles.scss";
import useAddCarGalleryUploader from "./useUploaderCarGallery";
import { useTranslation } from "react-i18next";
import Loading from "../loading/component";
interface Props {
  carId: number;
  editionMode?: boolean;
}

export default function UploaderCarGallery({ carId, editionMode }: Props) {
  const { locked, onSortEnd, items, handleRemoveImage, setItems, loading } =
    useAddCarGalleryUploader(carId, Boolean(editionMode));
  const t = useContext(TranslationContext);

  // Dropzone to get the images locally and move it locally
  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: locked,
    accept: {
      "image/*": [],
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 15,
    onDrop: (acceptedFiles) => {
      // get the new file
      const f: { preview: string; name: string; type: string }[] =
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      // Update the array, here we can see how many files exists in order to validate existence
      setItems((prev) => [...prev, ...f]);
    },
  });

  if (loading) return <Loading height="25vh" />;

  return (
    <React.Fragment>
      {/* Sortable drag and drop */}
      <SortableList
        onSortEnd={onSortEnd}
        className="car-list-dd"
        draggedItemClassName="dragged-dd"
      >
        {items.map((item, i) => (
          <SortableItem key={item.preview}>
            <div className="item-text-wrapper-dd">
              <div className="item-wrapper-dd">
                <RemoveDialog
                  callback={() => handleRemoveImage(item.preview)}
                />
                <img
                  className="car-list-dd__img"
                  alt={item.preview}
                  src={item.preview}
                />
              </div>
              <p>carro-{i + 1}</p>
            </div>
          </SortableItem>
        ))}
      </SortableList>

      {/* File uploader */}
      <section className="dropzone-car-dd">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {locked ? (
            <p>{t("add.car_model_and_ubication.delete_images_to_upload")}</p>
          ) : (
            <>
              <button type="button" onClick={open} className="button-dropzone">
                <img src={authenticationImages.addImage} />
                <span>{t("add.car_model_and_ubication.select_file")}</span>
              </button>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
}

interface removeDialogProps {
  callback: () => void;
}

const RemoveDialog = ({ callback }: removeDialogProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["fileUploader"]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img
        className="btn-remove-dd"
        src={authenticationImages.deleteImage}
        alt=""
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("alertRemoveFile")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("cantUndoneRemoveFile")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> {t("cancel")}</Button>
          <Button onClick={callback} autoFocus>
            {t("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
