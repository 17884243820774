import { getToken } from "./localstorage";
import { axiosService } from "./axios";

// Stripe setup intent to add new card
export const getSetupIntent = async () => {
  try {
    const response = await axiosService.get("/wallet/setupintent", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Validate credit card
export const validateCard = async (id: string) => {
  const response = await axiosService.put(
    "/wallet/validate",
    { paymentMethodId: id },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response;
};

// Get user registered cards
export const getRegisteredCards = async () => {
  try {
    const response = await axiosService.get("/wallet/cards", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Set card as default payment method
export const setDefaultCard = async (id: string) => {
  try {
    const response = await axiosService.put(
      "/wallet/setdefault",
      { cardId: id },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

// Detach card from customer
export const detachCard = async (id: string) => {
  try {
    const response = await axiosService.delete("/wallet/detach", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: { paymentMethodId: id },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
