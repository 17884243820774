import { Coupon } from "../../models/coupon/types";
import { Trip } from "../../models/trip/types";
import { discountType } from "./textHandler";

export const tripDiscount = (trip: Trip, coupon: Coupon) => {
  const discount = discountType(coupon);
  const total = parseFloat(trip.totalPrice);

  if (discount.type === "percentage") {
    return total * (discount.quantity / 100);
  } else {
    return discount.quantity;
  }
};
