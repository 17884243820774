import * as Yup from "yup";
import { Auth } from "../../../../models/auth/types";

export const loginSchema: Yup.SchemaOf<Auth> = Yup.object().shape({
  email: Yup.string()
    .email("Escribe un email valido")
    .required("El email es requerido"),
  password: Yup.string()
    .min(3, "La clave debe contener al menos 3 caracteres")
    .required("La contraseña es requerida"),
});
