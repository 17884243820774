import { HostSetting } from "../models/hostSetting/types";
import { axiosService } from "./axios";

export const getHostSettings = async (domain: string) => {
  try {
    const response = await axiosService.post<HostSetting>(
      "/hostsettings/domain",
      { domain }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
