import { FC, useMemo } from "react";
import { AddCouponModal } from "../../../../components/modals/coupons/Coupons";
import { useCouponContainer } from "./useCouponContainer";
import { Trip } from "../../../../models/trip/types";
import { tripDiscount } from "../../../helpers/discountHandler";

export const CouponContainer: FC<{ trip: Trip }> = ({ trip }) => {
  const tripId = trip.id.toString();
  const {
    coupon,
    setCoupon,
    handleCancelCoupon,
    openCoupon,
    setOpenCoupon,
    t,
  } = useCouponContainer(trip);

  const discount = useMemo(
    () => (trip && coupon ? tripDiscount(trip, coupon) : "0"),
    [trip, coupon]
  );

  return (
    <>
      <div className="extra col-sm-12 col-md-12">
        <div className="day-tag">
          <p className="discount">
            {coupon?.namePromotionCode !== "" ? (
              <>
                {t("show.car_form.trip_form.discount")}
                <span className="discount-name">
                  {coupon.namePromotionCode}
                </span>
                <a onClick={handleCancelCoupon}>
                  {t("show.car_form.trip_form.delete_discount")}
                </a>
              </>
            ) : (
              <>
                {t("show.car_form.trip_form.add_discount")}
                <a onClick={() => setOpenCoupon(true)}>
                  {t("show.car_form.trip_form.add_discount_extra")}
                </a>
              </>
            )}
          </p>
        </div>
        <span id="entregaDevolucion">
          {discount !== 0 ? `-$${discount}` : "-"}
        </span>
      </div>
      <AddCouponModal
        isOpen={openCoupon}
        setOpen={setOpenCoupon}
        setCoupon={setCoupon}
        tripId={tripId || ""}
      />
    </>
  );
};
