import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface RemoveFileModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => Promise<void>;
  errorMsg?: string | null;
  loading: boolean;
}

const RemoveFileModal = ({
  open,
  handleClose,
  handleDelete,
  errorMsg,
  loading,
}: RemoveFileModalProps) => {
  const { t } = useTranslation(["fileUploader"]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("alertRemoveFile")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("removeDocumentWarning")}
        </DialogContentText>
      </DialogContent>
      {!!errorMsg && (
        <p style={{ color: "#d02a2a", textAlign: "center" }}>{errorMsg}</p>
      )}
      <DialogActions sx={{ margin: "16px" }}>
        <Button onClick={handleClose}>{t("cancel")}</Button>
        <Button
          onClick={handleDelete}
          autoFocus
          disabled={loading}
          data-testid="delete-image"
        >
          {t("continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveFileModal;
