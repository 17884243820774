import RemoveFileModal from "../../../../components/modals/RemoveFile/component";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserId } from "../../../../services/localstorage";
import { uploadioEnv } from "../../../../services/fileUploader";
import { deleteFile, updateFile } from "../../../../services/fileUploader";
import useEditDocuments from "./useEditCarDocuments";
import { updateCarDocumentStatus } from "../../../../services/car";

type DeletedType = {
  vehicleLicense: boolean;
  insuranceCover: boolean;
};

type DeleteButtonProps = {
  type: "vehicleLicense" | "insuranceCover";
  setDeleted: ({ vehicleLicense, insuranceCover }: DeletedType) => void;
  deleted: DeletedType;
  carId: string;
};

const DeleteEditCarFileButton = ({
  type,
  setDeleted,
  deleted,
  carId,
}: DeleteButtonProps) => {
  const [open, setOpen] = useState(false);
  const userId = getUserId();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const {
    circulationCardFile,
    insurancePolicyFile,
    setEditDocument,
    isLoading,
    setIsLoading,
  } = useEditDocuments(carId);
  const { t } = useTranslation(["profile"]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendDocumentStatus = async (
    type: "vehicleLicense" | "insuranceCover"
  ) => {
    return updateCarDocumentStatus(parseInt(carId), type);
  };

  // Update file: upload new file with 'deleted' tag and delete the old file
  const handleDelete = async () => {
    setIsLoading(true);
    // Official documents: license and id (both front and back)
    const path =
      type === "vehicleLicense" ? "tarjeta_de_circulacion" : "poliza_de_seguro";
    try {
      const item =
        type === "vehicleLicense" ? circulationCardFile : insurancePolicyFile;

      if (!item) {
        setErrorMsg(t("profile_documents.no_files_to_delete"));
        return;
      }
      const response = await fetch(item?.url || "");
      const data = await response.blob();
      const fileData = new File([data], `${path}-deleted-${Date.now()}`, {
        type: item?.mime,
      });
      await updateFile(
        `/${uploadioEnv}/user-${userId}/car-${carId}/${fileData.name}`,
        fileData,
        fileData.type
      );
      setEditDocument("", "", type);
      await deleteFile(`/${uploadioEnv}/user-${userId}/car-${carId}/${path}`);
      await sendDocumentStatus(type);

      setOpen(false);
    } catch (error) {
      setErrorMsg(t("profile_documents.error_deleting_file"));
    } finally {
      setDeleted({ ...deleted, [type]: true });
      setIsLoading(false);
    }
  };

  return (
    <>
      <p onClick={handleClickOpen} className="delete-file-btn">
        {t("profile_documents.delete")}
      </p>
      <RemoveFileModal
        handleClose={handleClose}
        handleDelete={handleDelete}
        open={open}
        errorMsg={errorMsg}
        loading={isLoading}
      />
    </>
  );
};

export default DeleteEditCarFileButton;
