import {
  CreateTripDTO,
  PaymentDTO,
  UpdateTripDTO,
  tripDTO,
} from "./../models/trip/dtos";
import { axiosService } from "./axios";
import { Trip } from "./../models/trip/types";
import { getToken } from "./localstorage";

export const getTrip = async (tripId: string | undefined) => {
  const response = await axiosService.get<Trip>(`/trip/${tripId}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  return response.data;
};

export const getTripByDriverID = async () => {
  try {
    const response = await axiosService.get<Trip[]>(`/trip/driver`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTripByCarID = async (carId: number) => {
  try {
    const response = await axiosService.get<Trip[]>(`/trip/car/${carId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTripByHostID = async () => {
  try {
    const response = await axiosService.get<Trip[]>(`/trip/host`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createTrip = async (TripDTO: CreateTripDTO) => {
  try {
    const data = await axiosService.post<tripDTO>("/trip", TripDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateTrip = async (id: number, TripDTO: UpdateTripDTO) => {
  try {
    const data = await axiosService.patch<Trip>(`/trip/${id}`, TripDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateTripStatus = async (id: number, TripDTO: UpdateTripDTO) => {
  try {
    const data = await axiosService.patch<Trip>(
      `/trip/status`,
      { ...TripDTO, id },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateTripPayId = async (id: number, payId: string) => {
  try {
    const data = await axiosService.patch<Trip>(
      `/trip/stripe`,
      { payId, id },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteTrip = async (id: string, index: number) => {
  try {
    const data = await axiosService.delete<Trip>(`/trip/${id}`);
    return { data, index };
  } catch (error) {
    console.error(error);
  }
};

export const getTripPaymentStatus = async (payment: PaymentDTO) => {
  try {
    const response = await axiosService.post(`/wallet/status`, payment, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
