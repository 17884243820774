import { Checkbox, FormControlLabel } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import carImages from "../../../assets/images/car";
import { AcceptingRequest } from "../../../components/modals/acceptingRequest/component";
import { BlockDatesTrip } from "../../../components/modals/blockedDatesTrip/component";
import { Dates, DateStatus } from "../../../models/date/types";
import { getDateByCarID } from "../../../services/date";
import { updateTripStatus } from "../../../services/trip";
import { TripContext } from "../../../views/driverReservation/show/component";
import { confirmPayment } from "../../../services/payment";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import Toast from "../../../components/toast/component";
import { TooltipIcon } from "../../../components/tooltips/component";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatPrice } from "../../../components/carResultCard/utils";
import { isValidHostCOwnInsurance } from "../../helpers/status";
import { getHostByUserId, getHostExistByUserId } from "../../../services/host";
import "./styles.scss";
import reservationsImages from "../../../assets/images/reservations";
import dayjs from "dayjs";
import { Reject } from "../../../components/modals/reject/component";

export const HostPendingActions = () => {
  const [termsAndUse, setTermsAndUse] = useState<boolean>(false);
  const [leasingContract, setLeasingContract] = useState<boolean>(false);
  const [isCancelRequestOpen, setIsCancelRequestOpen] =
    useState<boolean>(false);
  const [isAcceptingRequestOpen, setIsAcceptingRequestOpen] =
    useState<boolean>(false);
  const [isBlockDatesTripOpen, setIsBlockDatesTripOpen] =
    useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { t, trip, car } = useContext(TripContext);
  const navigate = useNavigate();

  const { data: dates } = useQuery(
    ["dates", car?.id],
    () => getDateByCarID(car?.id || 0),
    {
      enabled: !!car?.id,
    }
  );

  useEffect(() => {
    if (dates && dates.length !== 0) {
      isTripBlocked();
    }
  }, [dates]);

  const isTripBlocked = () => {
    if (!trip?.pickUpDate && !trip?.returnDate) return;

    const blockedDates = dates?.every((date: Dates) => {
      return (
        ((date.dateInitial.toString() < trip?.pickUpDate &&
          date.dateEnd.toString() < trip?.returnDate) ||
          (date.dateInitial.toString() > trip?.pickUpDate &&
            date.dateEnd.toString() > trip?.returnDate)) &&
        (date.status == DateStatus.LOCKED_BY_HOST ||
          date.status == DateStatus.LOCKED_BY_RESERVATION)
      );
    });

    return !blockedDates;
  };

  const acceptTrip = async () => {
    if (!trip?.id || !trip?.stripePayId) return;
    setLoading(true);

    try {
      await confirmPayment(trip?.stripePayId);
      await updateTripStatus(trip?.id, { status: 2 });
      setIsAcceptingRequestOpen(true);
      navigate(
        `/${t("manage", { ns: "routes" })}/${t("trips", { ns: "routes" })}`
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(error.response?.data.message);
        setOpenAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const countDownRemaining = () => {
    const requestDate = dayjs(trip?.sendRequestDate, "DD-MM-YYYY HH:mm:ss");
    const limit = dayjs(requestDate).add(24, "hours");
    const diff = limit.diff(dayjs(), "hours");
    return diff > 0 ? diff : 0;
  };

  return (
    <>
      <Toast open={openAlert} setOpen={setOpenAlert} type="error">
        {errorMsg}
      </Toast>
      <div className="checkboxes-show-host-driver">
        <div className="checkbox-form col-sm-12 col-md-12">
          <FormControlLabel
            control={
              <Checkbox
                value={leasingContract}
                onChange={() => setLeasingContract(!leasingContract)}
                color="balety"
              />
            }
            className="check-terms-form"
            label={
              <div className="label-terms">
                <p>
                  Acepto el{" "}
                  <a
                    target="_blank"
                    href={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
                    }
                  >
                    {t("create.leasing_contract")}
                  </a>
                </p>
              </div>
            }
          />
          {!leasingContract ? (
            <p id="check-error" className="check-error">
              <img src={carImages.cross} />
              <span>{t("create.accept_leasing_contract")}</span>
            </p>
          ) : null}
        </div>
        <div className="checkbox-form col-sm-12 col-md-12">
          <FormControlLabel
            control={
              <Checkbox
                value={termsAndUse}
                onChange={() => setTermsAndUse(!termsAndUse)}
                color="balety"
              />
            }
            className="check-terms-form"
            label={
              <div className="label-terms">
                <p>
                  Acepto los{" "}
                  <a
                    target="_blank"
                    href={
                      "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                    }
                  >
                    {t("create.accept_terms_and_conditions")}
                  </a>
                </p>
              </div>
            }
          />
          {!termsAndUse ? (
            <p id="check-error" className="check-error">
              <img src={carImages.cross} />
              <span>{t("create.accept_terms")}</span>
            </p>
          ) : null}
        </div>
        <div className="btn-des col-sm-12 col-md-12">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => setIsCancelRequestOpen(true)}
            disabled={!(termsAndUse && leasingContract)}
          >
            {t("show.decline", {
              ns: "hostReservation",
            })}
          </button>
          <button
            type="button"
            className="btn btn-green"
            onClick={acceptTrip}
            disabled={loading || !(termsAndUse && leasingContract)}
          >
            {t("show.accept", {
              ns: "hostReservation",
            })}
          </button>
        </div>
        <div className="warning-host-pending">
          <button type="button" className="btn alert-triangle">
            <img src={reservationsImages.time} alt="" />
          </button>
          <div className="text-container">
            <p>
              {t("show.waiting", {
                ns: "hostReservation",
              })}
              <strong>{countDownRemaining()}</strong>
              {t("show.waiting_extra", {
                ns: "hostReservation",
              })}
            </p>
          </div>
        </div>
      </div>
      {/* Change this modal */}
      <Reject
        isOpen={isCancelRequestOpen}
        setOpen={() => setIsCancelRequestOpen(false)}
      />
      <BlockDatesTrip
        setOpen={() => setIsBlockDatesTripOpen(false)}
        isOpen={isBlockDatesTripOpen}
        setCancelRequest={() => setIsCancelRequestOpen(true)}
      />
      <AcceptingRequest
        isOpen={isAcceptingRequestOpen}
        setOpen={() => setIsAcceptingRequestOpen(false)}
      />
    </>
  );
};

export const HostDepositAmountAlert = () => {
  const { t } = useContext(TripContext);

  return (
    <div className="warning  col-sm-12 col-md-12">
      <button type="button" className="btn alert-triangle">
        <img src={carImages.alertTriangle} alt="" />
      </button>
      <div className="text-container">
        <p className="depositAmount">
          {t("show.waiting_to_confirm", { ns: "hostReservation" })}
        </p>
      </div>
    </div>
  );
};

export const Profit = () => {
  const { t, trip } = useContext(TripContext);

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", trip?.hostId],
    () => getHostExistByUserId(trip?.hostId.toString() || ""),
    {
      enabled: !!trip?.hostId,
    }
  );

  const existHost = isHostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", trip?.hostId],
    () => getHostByUserId(trip?.hostId.toString() || ""),
    {
      enabled: existHost,
    }
  );

  return (
    <div className="profit col-sm-12 col-md-12">
      <div className="day-tag">
        <h5>{t("show.your_profit", { ns: "hostReservation" })}</h5>
        <TooltipIcon
          title={
            hostC && isValidHostCOwnInsurance(hostC)
              ? t("car:show.car_form.information_tooltips.gain_own_insurance")
              : t(
                  "car:show.car_form.information_tooltips.gain_qualitas_insurance"
                )
          }
          icon={<InfoOutlinedIcon />}
          classes="tooltip-form"
        />
      </div>
      <p className="gain">
        <span>{formatPrice(parseFloat(trip?.hostTake || "0"))}</span>
        MXN
      </p>
    </div>
  );
};
