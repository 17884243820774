import { useFormikContext } from "formik";
import { useContext, ChangeEvent } from "react";
import { CarForm } from "../../../../../views/car/add/types";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import { useExperience } from "../../../../../hooks/exp";
import { setCharAt } from "../../../../helpers/textHandler";
import { editModelForm } from "../../editCarModel/form";

export const useCarCategories = () => {
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm | editModelForm>();

  // Get images of categories === experiences
  const EXPERIENCES = useExperience();

  // Get text of categories
  const DATA = [
    { text: t("add.car_documentation.categories.ecological"), index: 0 },
    { text: t("add.car_documentation.categories.sports"), index: 1 },
    { text: "Pet Friendly", index: 2 },
    { text: t("add.car_documentation.categories.convertible"), index: 3 },
    { text: "Off road", index: 4 },
    { text: t("add.car_documentation.categories.family"), index: 5 },
    { text: t("add.car_documentation.categories.daily_use"), index: 6 },
    { text: t("add.car_documentation.categories.ready_for_road"), index: 7 },
    { text: t("add.car_documentation.categories.classic"), index: 8 },
  ];

  // Change field value when check any categorie
  const handleCategories = (
    event: ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    const newCategories = setCharAt(
      values.experiences ||
        ["0", "0", "0", "0", "0", "0", "0", "0", "0"].join(""),
      position,
      event.target.checked ? "1" : "0"
    );
    setFieldValue("experiences", newCategories);
  };

  // Show checked when categorie is active
  const handleActiveCategories = (position: number) => {
    const categories = values.experiences?.split("") || [""];
    return categories[position] === "1";
  };

  return {
    t,
    DATA,
    EXPERIENCES,
    handleCategories,
    handleActiveCategories,
  };
};
