import axios from "axios";

export const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  //timeout: 6000,
});

export const moffinService = axios.create({
  baseURL: process.env.REACT_APP_MOFFIN_URL,
  headers: {
    Authorization: "Token " + process.env.REACT_APP_MOFFIN,
  },
});
