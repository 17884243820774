import { useFormik } from "formik";
import { sha256 } from "js-sha256";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import navbarImages from "../../../assets/images/navbar";
import { updateUserPassword } from "../../../services/user";
import authenticationImages from "../../../assets/images/authentication/index";
import { ModalProps } from "../types";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Loading from "../../loading/component";

interface modalPasswordForm {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface PasswordUpdateDTO {
  oldPasword: string;
  newPassword: string;
}

const modalPasswordModelSchema: Yup.SchemaOf<modalPasswordForm> =
  Yup.object().shape({
    password: Yup.string()
      .min(3, "La clave debe contener al menos 3 caracteres")
      .required("La contraseña es requerida"),
    newPassword: Yup.string()
      .min(3, "La clave debe contener al menos 3 caracteres")
      .required("La contraseña es requerida"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Las contraseñas deben de coincidir")
      .required("La confirmacion de la contraseña es requerida"),
  });

export const ChangePasswordNumberModal: FC<ModalProps> = ({
  isOpen,
  setOpen,
}) => {
  const [password, setPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<boolean>(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState<boolean>(false);
  const { t } = useTranslation(["modals"]);
  const queryClient = useQueryClient();

  const fetchPassword = async (values: modalPasswordForm) => {
    const response = await updateUserPassword({
      oldPasword: sha256(values.password),
      newPassword: sha256(values.newPassword),
    });
    if (!response || response.status != 200) return;

    setOpen();
    queryClient.invalidateQueries(["profile"]);
  };

  const formikPasswordModal = useFormik<modalPasswordForm>({
    initialValues: {
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: modalPasswordModelSchema,
    onSubmit: fetchPassword,
  });

  return (
    <Modal show={isOpen} className="change-pass">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.crossBlack} alt="" />
        </button>
        <h2>{t("change_password.title")}</h2>
      </div>
      <div className="modal-body">
        <h3>{t("change_password.password")}</h3>
        <div className="input-form">
          <div className="d-flex input-group">
            <input
              type={password ? "text" : "password"}
              className="number-input modal-change-pass-ConfirmPass"
              placeholder={t("change_password.password")}
              onChange={formikPasswordModal.handleChange("password")}
              onBlur={formikPasswordModal.handleBlur("password")}
              value={formikPasswordModal.values.password}
            />
            <div id="eye_password2" className="input-group-addon">
              <img
                src={
                  password
                    ? authenticationImages.eye
                    : authenticationImages.crossEye
                }
                onClick={() => setPassword((password) => !password)}
                aria-hidden="true"
              />
            </div>
          </div>
          <p>
            {formikPasswordModal.touched.password
              ? formikPasswordModal.errors.password
              : ""}
          </p>
        </div>
        <h3>Nueva contraseña</h3>
        <div className="input-form">
          <div className="d-flex input-group">
            <input
              type={newPassword ? "text" : "password"}
              id="NewPass"
              className="number-input modal-change-pass-NewPass"
              placeholder="Nueva contraseña"
              onChange={formikPasswordModal.handleChange("newPassword")}
              onBlur={formikPasswordModal.handleBlur("newPassword")}
              value={formikPasswordModal.values.newPassword}
            />
            <div id="eye_password2" className="input-group-addon">
              <img
                src={
                  newPassword
                    ? authenticationImages.eye
                    : authenticationImages.crossEye
                }
                onClick={() => setNewPassword((newPassword) => !newPassword)}
                aria-hidden="true"
              />
            </div>
          </div>
          <p>
            {formikPasswordModal.touched.newPassword
              ? formikPasswordModal.errors.newPassword
              : ""}
          </p>
        </div>
        <h3>Repetir contraseña</h3>
        <div className="input-form">
          <div className="d-flex input-group">
            <input
              id="ConfirmNewPass"
              className="number-input modal-change-pass-ConfirmNewPass"
              placeholder="Repetir contraseña"
              type={confirmNewPassword ? "text" : "password"}
              onChange={formikPasswordModal.handleChange("confirmNewPassword")}
              onBlur={formikPasswordModal.handleBlur("confirmNewPassword")}
              value={formikPasswordModal.values.confirmNewPassword}
            />
            <div id="eye_password2" className="input-group-addon">
              <img
                src={
                  confirmNewPassword
                    ? authenticationImages.eye
                    : authenticationImages.crossEye
                }
                onClick={() =>
                  setConfirmNewPassword(
                    (confirmNewPassword) => !confirmNewPassword
                  )
                }
                aria-hidden="true"
              />
            </div>
          </div>
          <p>
            {formikPasswordModal.touched.confirmNewPassword
              ? formikPasswordModal.errors.confirmNewPassword
              : ""}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <button
          id="btn_change_password"
          type="button"
          className="btn btn-orange"
          disabled={formikPasswordModal.isSubmitting}
          onClick={() => {
            if (!formikPasswordModal.isSubmitting)
              formikPasswordModal.handleSubmit();
          }}
        >
          {!formikPasswordModal.isSubmitting ? (
            <p>{t("change_password.change")}</p>
          ) : (
            <Loading height="1vh" color="#fff" />
          )}
        </button>
      </div>
    </Modal>
  );
};
