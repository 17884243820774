import { useState } from "react";
import { createCar, updateCar } from "../../../services/car";
import { AxiosError } from "axios";
import { CreateCarDTO, UpdateCarDTO } from "../../../models/car/dtos";
import { CarForm } from "./types";
import { useQuery } from "react-query";
import { getUser } from "../../../services/user";
import { FormikErrors } from "formik";
import {
  createAddress,
  updateAddress,
  getAddressesByCarID,
  deleteAddress,
} from "../../../services/address";
import { AddressStatus, AddressType } from "../../../models/address/types";
import { useTranslation } from "react-i18next";
import { createDate, deleteDate, getDateByCarID } from "../../../services/date";
import { DateStatus } from "../../../models/date/types";
import { CarStatus } from "../../../models/car/types";
import { formatAddressGoogleToDB } from "../../../lib/helpers/addressHandler";
import {
  getInsuranceByCarId,
  updateInsurance,
} from "../../../services/insurance";
import { InsuranceType } from "../../../models/insurance/types";
import dayjs from "dayjs";

const useCar = ({ carId }: { carId?: number }) => {
  const [createdCarId, setCreatedCarId] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { data: user } = useQuery(["profile"], getUser);
  const { t } = useTranslation(["car"]);

  // Create car
  const sendCarData = async (values: CreateCarDTO) => {
    const data = {
      brand: values.brand,
      model: values.model,
      year: values.year,
      version: values.version,
      userId: user?.id || 0,
      step: 1,
    };
    try {
      const response = await createCar(data);
      const { message } = response?.data;
      const id = message.split(" ")[message.split(" ").length - 1];
      setCreatedCarId(id);
      setErrorMsg(null);
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.message) {
        setErrorMsg(error.response?.data.message);
        return null;
      }
      setErrorMsg(t("add.error_create_car"));
      return null;
    }
  };

  // Update created car and set addresses
  const updateCarData = async ({
    values,
    step,
    mainAddress,
    completed,
  }: {
    values: CarForm;
    step: number;
    mainAddress?: { enable: boolean };
    completed?: boolean;
  }) => {
    if (!createdCarId && !carId) return;

    const carIdData = createdCarId ? parseInt(createdCarId) : carId ? carId : 0;

    const resAddresses = (await getAddressesByCarID(carIdData)) ?? [];
    // Send main address
    if (!!mainAddress && (step === 1 || step === 3)) {
      const isEnable = mainAddress.enable
        ? AddressStatus.ACTIVE
        : AddressStatus.DEACTIVATED;

      if (values.mainAddress?.address_components) {
        const mainAddress = formatAddressGoogleToDB(
          values.mainAddress,
          isEnable
            ? AddressType.HOME_DELIVERY_ADDRESS
            : AddressType.MAIN_ADDRESS,
          carIdData,
          values.homeDelivery?.price ?? ""
        );

        try {
          const existMainAddress = resAddresses?.find(
            (item) => item.type === AddressType.MAIN_ADDRESS
          );
          // If is main address
          if (isEnable === 0 && mainAddress) {
            if (existMainAddress) {
              await updateAddress(existMainAddress.id, mainAddress);
            } else {
              await createAddress(mainAddress);
            }
          }

          const existHomeDelivery = resAddresses?.find(
            (item) => item.type === AddressType.HOME_DELIVERY_ADDRESS
          );

          // If is home delivery
          if (isEnable === 1 && mainAddress) {
            const status = values.homeDelivery?.enable ? 1 : 0;
            if (existHomeDelivery) {
              await updateAddress(existHomeDelivery.id, {
                ...mainAddress,
                status,
              });
            } else {
              await createAddress(mainAddress);
            }
          }
        } catch (error) {
          if (error instanceof AxiosError) {
            setErrorMsg(t("add.error_create_main_address"));
          }
        }
      }
    }

    // Send extra addresses
    if (!!values.extraAddresses && step === 3) {
      try {
        // Delete an extra address
        const deletedAddress = (values: CarForm) => {
          const arr: string[] = [];
          Object.entries(values.extraAddresses || {}).forEach(([, value]) => {
            if (value.id) {
              arr.push(value.address.place_id);
            }
          });

          const places = resAddresses.filter(
            (address) =>
              !arr.includes(address.placeId) &&
              address.type === AddressType.EXTRA_ADDRESS
          );

          return places;
        };
        deletedAddress(values).forEach(async (address) => {
          await deleteAddress(address.id.toString());
        });

        // Change address price
        const changePrice = (values: CarForm) => {
          const arr: {
            carId: number;
            price: string;
            placeId: string;
            id: number;
          }[] = [];
          Object.entries(values.extraAddresses || {}).forEach(([, value]) => {
            if (!value.id) return;

            const exactAddress = resAddresses.find(
              (item) =>
                item.id === parseInt(value.id || "0") &&
                item.type === AddressType.EXTRA_ADDRESS
            );

            if (exactAddress && value.price !== exactAddress.price) {
              arr.push({
                carId: exactAddress.carId,
                price: value.price,
                placeId: value.address.place_id,
                id: parseInt(value.id || "0"),
              });
            }
          });

          return arr;
        };

        // Change prices of each address
        changePrice(values).forEach(async (address) => {
          await updateAddress(address.id, {
            carId: address.carId,
            price: address.price,
          });
        });

        const createNewExtraAddresses = (values: CarForm) => {
          return Object.values(values.extraAddresses).filter(
            (item) => !item.id
          );
        };

        createNewExtraAddresses(values).forEach(async (item) => {
          const newExtraAddress = formatAddressGoogleToDB(
            item.address,
            AddressType.EXTRA_ADDRESS,
            carIdData,
            item.price
          );

          if (newExtraAddress) {
            await createAddress(newExtraAddress);
          }
        });
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data.message) {
          setErrorMsg(
            error.response?.data.message || t("add.error_update_car")
          );
          return null;
        }
      }
    }

    // send blocked dates
    if (values.blockDates && step === 3) {
      try {
        const datesStorage = await getDateByCarID(carIdData);

        const deletedDates = (values: CarForm) => {
          const arr: number[] = [];

          values.blockDates?.forEach((date) => {
            if (date.id !== 0) {
              arr.push(date.id);
            }
          });

          const datesToDelete = datesStorage.filter(
            (date) => !arr.includes(date.id)
          );

          return datesToDelete;
        };

        deletedDates(values).forEach(async (date) => {
          await deleteDate(date.id.toString());
        });

        const createNewDate = (values: CarForm) => {
          const newDates = values.blockDates?.filter((date) => date.id === 0);

          return newDates || [];
        };

        createNewDate(values).forEach(async (date) => {
          await createDate({
            carId: carIdData,
            dateInitial: dayjs(date.startDate, "DD/MM/YYYY").format(
              "DD-MM-YYYY"
            ),
            dateEnd: dayjs(date.endDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
            status: DateStatus.LOCKED_BY_HOST,
          });
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          setErrorMsg(t("add.error_create_main_address"));
        }
      }
    }

    try {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        mainAddress: main,
        blockDates: dates,
        homeDelivery: home,
        extraAddresses: extra,
        ...allValues
      } = values;

      // update car data
      const data = completed
        ? {
            ...allValues,
            isCarComplete: completed,
            userId: user?.id || 0,
            status: CarStatus.PENDING,
            id: carIdData,
          }
        : {
            ...allValues,
            status: CarStatus.INCOMPLETE,
            step: step + 1,
            userId: user?.id || 0,
            id: carIdData,
          };

      const response = await updateCar(carIdData, data as UpdateCarDTO);

      if (step === 3) {
        updateHostCarInsurance(carIdData, values);
      }

      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.message) {
        setErrorMsg(error.response?.data.message || t("add.error_update_car"));
        return null;
      }
    }
  };

  const updateHostCarInsurance = async (carIdData: number, values: CarForm) => {
    const insurance = await getInsuranceByCarId(carIdData);
    // host commercial with own insurance

    if (
      insurance?.type === InsuranceType.HOST_INSURANCE &&
      (insurance?.depositAmount !== values.depositAmount ||
        insurance?.charge !== values.insuranceCharge)
    ) {
      const data = {
        ...insurance,
        depositAmount: values.depositAmount,
        charge: values.insuranceCharge || "",
      };
      await updateInsurance(data);
    }
  };

  const validateFields = (
    activeStep: number,
    values: CarForm,
    setFormErrors: (errorArray: string[]) => void,
    errors: FormikErrors<CarForm>
  ) => {
    if (
      activeStep === 0 &&
      (!values.brand || !values.model || !values.year || !values.version)
    ) {
      const key = !values.brand
        ? "brand"
        : !values.model
        ? "model"
        : !values.year
        ? "year"
        : "version";

      const message = !values.brand
        ? t("add.form_validation.brand_required")
        : !values.model
        ? t("add.form_validation.model_required")
        : !values.year
        ? t("add.form_validation.year_required")
        : t("add.form_validation.version_required");

      setErrorMsg(message);
      setFormErrors([...Object.keys(errors), key]);
      return;
    }
    if (
      activeStep === 1 &&
      (!values.mainAddress ||
        !values.numSeats ||
        !values.numBags ||
        values.isEcoFriendly === null ||
        !values.plate ||
        !values.experiences ||
        values.experiences === "000000000" ||
        !values.transmission === null)
    ) {
      const key =
        !values.transmission === null
          ? "transmission"
          : !values.numSeats
          ? "numSeats"
          : !values.numBags
          ? "numBags"
          : !values.plate
          ? "plate"
          : values.isEcoFriendly === null
          ? "isEcoFriendly"
          : !values.experiences || values.experiences === "000000000"
          ? "experiences"
          : "address";
      const message =
        !values.transmission === null
          ? t("add.form_validation.transmission_required")
          : !values.numSeats
          ? t("add.form_validation.number_of_seats_required")
          : !values.numBags
          ? t("add.form_validation.number_of_bags_required")
          : !values.plate
          ? t("add.form_validation.plate_required")
          : values.isEcoFriendly === null
          ? t("add.form_validation.is_ecological_required")
          : !values.experiences || values.experiences === "000000000"
          ? t("add.form_validation.categories_required")
          : t("add.form_validation.address_required");
      setErrorMsg(message);
      setFormErrors([...Object.keys(errors), key]);
      return;
    }
    if (
      activeStep === 2 &&
      (!values.characteristicsList ||
        values.characteristicsList === "0000000000000000000000" ||
        !values.description ||
        !values.rules)
    ) {
      const key =
        !values.characteristicsList ||
        values.characteristicsList === "0000000000000000000000"
          ? "characteristicsList"
          : !values.description || values.description.length < 200
          ? "description"
          : "rules";
      const message =
        !values.characteristicsList ||
        values.characteristicsList === "0000000000000000000000"
          ? t("add.form_validation.accesories_required")
          : !values.description
          ? t("add.form_validation.drescription_required")
          : t("add.form_validation.rules_required");
      setErrorMsg(message);
      setFormErrors([...Object.keys(errors), key]);
      return;
    }
    if (activeStep === 3 && (!values.price || values.price === "0")) {
      setErrorMsg(t("add.form_validation.price_required"));
      setFormErrors([...Object.keys(errors), "price"]);
      return;
    }
    return true;
  };

  return {
    sendCarData,
    updateCarData,
    errorMsg,
    createdCarId,
    setCreatedCarId,
    setErrorMsg,
    validateFields,
  };
};

export default useCar;
