import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { FC, ReactElement, useState } from "react";

export const TooltipIcon: FC<{
  title: string;
  icon: ReactElement;
  classes: string;
}> = ({ title, icon, classes }) => {
  const [open, setOpen] = useState(false);

  const ShowCarToolip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 14,
      fontFamily: "Montserrat",
    },
  }));

  const handleTooltip = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ShowCarToolip
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="top"
      onClick={handleTooltip}
      open={open}
      className={classes}
      title={title}
      arrow
    >
      {icon}
    </ShowCarToolip>
  );
};
