import { InsuranceType } from "../insurance/types";

export interface Trip {
  id: number;
  carId: number;
  hostId: number;
  driverUserId: number;
  type?: TripType;
  status?: TripStatus;
  pickUpAddressId: number;
  pickUpDate: string;
  pickUpTime: string;
  returnAddressId: number;
  returnDate: string;
  returnTime: string;
  policyResult?: string;
  insurancePolicyURL: string;
  carPrice: string;
  insurancePrice: string;
  pickUpReturnPrice: string;
  bookingFeePrice: string;
  promoCode: string;
  discount: string;
  totalPrice: string;
  stripePayId?: string;
  refundAmount: string;
  safetyDepositAmount: string;
  hostTake: string;
  insuranceTake: string;
  paymentProcessorTake: string;
  baletyTake: string;
  insuranceCompany: InsuranceType;
  timeZone: string;
  createDate: string;
  sendRequestDate: string;
  acceptReservationDate: string;
  cancelReservationDate: string;
  tripCode: string;
}

export enum TripType {
  DEACTIVATED = 0,
  ACTIVE = 1,
}

export enum TripStatus {
  CREATE_RESERVATION,
  SEND_REQUEST_RESERVATION,
  ACCEPT_RESERVATION,
  ACTIVE_RESERVATION,
  FINISH_RESERVATION,
  CANCEL_REQUEST_HUESPED,
  REFUSE_REQUEST_ANFITRION,
  CANCEL_RESERVATION_HUESPED,
  CANCEL_RESERVATION_ANFITRION,
  CANCEL_RESERVATION_BALETY,
  PAYED_NOT_AUTHORIZED,
}
