import { Modal } from "react-bootstrap";
import { FC, useState, useEffect, FormEvent } from "react";
import {
  CardElement,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import navbarImages from "../../../assets/images/navbar";
import { Card, CardAddressElement } from "../../../models/card/types";
import useCards from "../../../hooks/cards";
import { useTranslation } from "react-i18next";
import {
  StripeCardElement,
  StripeAddressElementChangeEvent,
} from "@stripe/stripe-js";
import "./styles.scss";
import { Form } from "react-bootstrap/";
import { useQuery, useQueryClient } from "react-query";
import { getUser } from "../../../services/user";
import { setDefaultCard } from "../../../services/card";

interface ModalPropsType {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setSelectedCard?: (data: Card) => void;
  setNewCardUsed?: (newCard: boolean) => void;
  type: "checkout" | "profile";
}

export const AddCardToPay: FC<ModalPropsType> = ({
  isOpen,
  setOpen,
  setSelectedCard,
  setNewCardUsed,
  type,
}) => {
  const { loading, errorMsn, confirmSetup, stripe } = useCards();
  const elements = useElements();
  const { t } = useTranslation(["profile"]);
  const [addressData, setAddressData] = useState<CardAddressElement>();
  const [completeWarning, setCompleteWarning] = useState<string | null>(null);
  const { data: user } = useQuery(["profile"], getUser);
  const queryClient = useQueryClient();

  // Create new card to get data for displaying on Checkout
  const createNewCard = async (card: StripeCardElement) => {
    if (!stripe) return;
    return await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        ...addressData,
        email: user?.email || "",
        phone: user?.phone || "",
      },
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!addressData?.name || !addressData?.address) {
      setCompleteWarning(
        t("profile_boxes.pay_method_modals.error_empty_fields")
      );
      return;
    }

    setCompleteWarning(null);
    const card = elements?.getElement(CardElement);
    if (!stripe || !elements || !card) return;

    let resultCreate;
    let result;

    // Save card to customer
    if (type === "checkout") {
      resultCreate = await createNewCard(card);
      result = await confirmSetup({
        paymentId: resultCreate?.paymentMethod?.id,
        type: "to-pay",
      });
      queryClient.invalidateQueries(["cards"]);
    } else {
      result = await confirmSetup({
        card: elements.getElement(CardElement),
        type: "add",
        billData: {
          ...addressData,
          email: user?.email || "",
          phone: user?.phone || "",
        },
      });
    }

    if (!errorMsn && result?.setupIntent) {
      if (
        type === "checkout" &&
        resultCreate?.paymentMethod &&
        setSelectedCard &&
        setNewCardUsed
      ) {
        const { card } = resultCreate.paymentMethod;
        if (!card) return;
        // Set card display data on checkout
        setSelectedCard({
          id: resultCreate?.paymentMethod?.id,
          isDefault: false,
          expirationDate: `${card?.exp_month}/${card?.exp_year}`,
          last4: card?.last4,
          brand: card?.brand,
          funding: card?.funding,
        });
        await setDefaultCard(resultCreate?.paymentMethod?.id);
        setNewCardUsed(true);
        queryClient.invalidateQueries(["cards"]);
      }
      setOpen(false);
    }
  };

  // Stripe address element handler
  const handleAddress = (e: StripeAddressElementChangeEvent) => {
    const newValue = { ...e.value };
    if (!newValue.address.line2) newValue.address.line2 = "";

    if (e.complete) {
      setAddressData(newValue as CardAddressElement);
      setCompleteWarning(null);
    }
  };

  // Clear fields
  useEffect(() => {
    if (
      elements?.getElement(AddressElement) &&
      elements?.getElement(CardElement)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      elements.getElement(AddressElement)!.clear();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      elements.getElement(CardElement)!.clear();
    }
  }, [isOpen]);

  return (
    <Modal show={isOpen} className="add-pay-modal d-flex align-items-center">
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setOpen(false)}
          >
            <img src={navbarImages.cross} alt="close" />
          </button>
          <h2>{t("profile_boxes.pay_method_modals.add_card")}</h2>
        </div>
        <div className="modal-body">
          <Form.Label className="mt-3 text-start input-label">
            {t("profile_boxes.pay_method_modals.card_input_label")}
          </Form.Label>
          <CardElement className="modal-card-input input mb-3" />
          <AddressElement
            options={{
              mode: "billing",
              autocomplete: {
                mode: "google_maps_api",
                apiKey: process.env.REACT_APP_GOOGLE_MAPS || "",
              },
            }}
            onChange={handleAddress}
          />
        </div>
        {(errorMsn || completeWarning) && (
          <p className="modal-error">{errorMsn || completeWarning}</p>
        )}
        <div className="modal-footer">
          <button
            disabled={!stripe || !elements || loading}
            className="btn btn-orange"
            type="submit"
          >
            {type === "checkout"
              ? t("profile_boxes.pay_method_modals.continue")
              : t("profile_boxes.pay_method_modals.save")}
          </button>
        </div>
      </Form>
    </Modal>
  );
};
