import { Link, useNavigate } from "react-router-dom";
import navbarImages from "../../../../assets/images/navbar/index";
import { useTranslation } from "react-i18next";
import { User } from "../../../../models/user/types";
import a_homepageImages from "../../../../assets/images/home/a_homepage/index";
import "./ProfileAction.scss";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import authenticationImages from "../../../../assets/images/authentication";
import { useWindowSize } from "../../../../hooks/windowSize";
import { AuthContext } from "../../../../providers/authProvider";

export const ProfileLinker = ({ user, ...rest }: { user: User }) => {
  const { t } = useTranslation(["navbar", "routes"]);
  const imageProfile = localStorage.getItem("profileImageUrl");

  return (
    <Link
      className="profile-linker"
      to={t("profile", { ns: "routes" })}
      {...rest}
    >
      <img
        src={
          imageProfile !== "" && imageProfile
            ? imageProfile
            : authenticationImages.profileDefault
        }
        className="user-image"
        alt=""
      />
      <div className="user-info">
        <p className="name">{user?.name + " " + user?.surName}</p>
        <p className="watch">{t("auth_navbar_collapse.watch_profile")}</p>
      </div>
      <img src={navbarImages.arrowRight} alt="arrow" className="arrow-right" />
    </Link>
  );
};

export const CardBecomeHost = () => {
  const { t } = useTranslation(["navbar", "routes"]);
  const windowSize = useWindowSize();

  return (
    <Link
      className={
        windowSize > 991
          ? "card-become-host card-become-host-desktop"
          : "card-become-host"
      }
      to={"/" + t("host", { ns: "routes" })}
    >
      <div className="car-become-host__info">
        <p className="title">{t("auth_navbar_collapse.become_a_host")}</p>
        <p className="info">{t("auth_navbar_collapse.become_a_host_info")}</p>
      </div>
      <img
        src={a_homepageImages.profitsPink}
        alt="a person with a lot of money"
        className="profit-image"
      />
    </Link>
  );
};

export const UserTypeToggle = ({ ...rest }) => {
  const { t } = useTranslation(["navbar", "routes"]);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [userType, setUserType] = useState<"Guest" | "Host">("Guest");
  const [type, setType] = useState(false);

  const handleCheck = (checked: boolean) => {
    setUserType(checked ? "Host" : "Guest");
    setType(checked);

    navigate(
      checked
        ? t("my_cars", { ns: "routes" })
        : "/" +
            t("results", {
              ns: "routes",
            })
    );
  };

  useEffect(() => {
    if (auth.urlType === "driver") {
      setUserType("Guest");
      setType(false);
    }

    if (auth.urlType === "host") {
      setUserType("Host");
      setType(true);
    }
  }, [auth.urlType]);

  const IOSSwitch = styled((props: SwitchProps) => <Switch {...props} />)(
    ({ theme }) => ({
      width: 64,
      height: 34,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "500ms",
        "&.Mui-checked": {
          transform: "translateX(28px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#3CB2B9" : "#3CB2B9",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 30,
        height: 30,
      },
      "& .MuiSwitch-track": {
        borderRadius: 36 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#f76f8e" : "#f76f8e",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    })
  );

  return (
    <div className="user-type-toggle">
      <p
        className="driver"
        style={{ fontWeight: userType === "Guest" ? 700 : 300 }}
      >
        {t("driver")}
      </p>
      <IOSSwitch
        name="switch"
        {...rest}
        onChange={(e) => handleCheck(e.currentTarget.checked)}
        checked={type}
      />
      <p
        className="host"
        style={{ fontWeight: userType === "Host" ? 700 : 300 }}
      >
        {t("host")}
      </p>
    </div>
  );
};
