import {
  Address,
  AddressStatus,
  AddressType,
} from "../../models/address/types";
import { ExtraAddressAdress } from "../../views/car/add/types";
import { placeComponentForm } from "../../views/car/edit/types";
import { getAllAddressComponents } from "./mapsActions";

interface FormatCommercialName {
  name: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export const formatAddressCommercialName = ({
  name,
  zipCode,
  city,
  state,
  country,
}: FormatCommercialName) => {
  return `${name}, ${zipCode} ${city}, ${state}, ${country}`;
};

export const createCommercialName = (
  address: placeComponentForm | ExtraAddressAdress
) => {
  if (address.address_components === undefined) return;

  const addressItem = getAllAddressComponents(address.address_components);

  if (!addressItem) return;

  const city = addressItem.locality || "";
  const state = addressItem.administrative_area_level_1 || "";
  const country = addressItem.country || "";
  const zipCode = addressItem.postal_code || "";
  const name = address.name || "";
  const comercialName = formatAddressCommercialName({
    name,
    zipCode,
    country,
    state,
    city,
  });

  return comercialName;
};

export const getObjectOfAddresses = (addresses: Address[] | undefined) => {
  if (!addresses || addresses.length === 0) return {};

  const allAddress = addresses.reduce(
    (previous, current, index) => ({
      ...previous,
      [index]: {
        address: {
          address_components: [
            { long_name: current.number, types: ["number"] },
            { long_name: current.street, types: ["street"] },
            { long_name: current.suburb, types: ["suburb"] },
            { long_name: current.city, types: ["locality"] },
            {
              long_name: current.state,
              types: ["administrative_area_level_1"],
            },
            { long_name: current.country, types: ["country"] },
            { long_name: current.zipCode, types: ["zipCode"] },
          ],
          name: current.completeAddress,
          formatted_address: current.comercialName,
          place_id: current.placeId,
          geometry: {
            location: {
              lat: current.latitude,
              lng: current.longitude,
            },
          },
        },
        price: current.price,
        id: current.id,
      },
    }),
    {}
  );

  return allAddress;
};

export const formatAddressFromDBToGoogle = (address: Address) => ({
  address_components: [
    { long_name: address.number, types: ["street_number"] },
    { long_name: address.street, types: ["route"] },
    { long_name: address.suburb, types: ["sublocality_level_1"] },
    { long_name: address.city, types: ["locality"] },
    {
      long_name: address.state,
      types: ["administrative_area_level_1"],
    },
    { long_name: address.country, types: ["country"] },
    { long_name: address.zipCode, types: ["postal_code"] },
  ],
  name: address.comercialName,
  formatted_address: address.completeAddress,
  place_id: address.placeId,
  geometry: {
    location: {
      lat: address.latitude,
      lng: address.longitude,
    },
  },
});

export const formatAddressGoogleToDB = (
  item: ExtraAddressAdress | placeComponentForm,
  type: AddressType,
  carId: number,
  price: string
) => {
  if (!item.address_components) return;
  const addressItem = getAllAddressComponents(item.address_components);

  if (!addressItem) return;

  const city = addressItem.locality || "";
  const state = addressItem.administrative_area_level_1 || "";
  const country = addressItem.country || "";
  const zipCode = addressItem.postal_code || "";
  const street = addressItem.route || "";
  const number = addressItem.street_number || "0";
  const suburb = addressItem.sublocality_level_1 || "";
  const name = item.name || "";
  const comercialName = formatAddressCommercialName({
    name,
    zipCode,
    country,
    state,
    city,
  });

  const newExtraAddress = {
    carId,
    status: AddressStatus.ACTIVE,
    type,
    completeAddress: item.formatted_address ?? "",
    placeId: item.place_id ?? "",
    latitude: item.geometry?.location?.lat?.toString() ?? "",
    longitude: item.geometry?.location?.lng?.toString() ?? "",
    price,
    comercialName,
    intNumber: "0",
    number,
    street,
    suburb,
    city,
    state,
    country,
    zipCode,
  };

  return newExtraAddress;
};
