import { useFormik } from "formik";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import authenticationImages from "../../../../assets/images/authentication/index";
import modalsImages from "../../../../assets/images/modals/index";
import navbarImages from "../../../../assets/images/navbar/index";
import { LoginCollapseProps } from "../../../../components/navbar/types";
import { Auth } from "../../../../models/auth/types";
import { loginSchema } from "./form";
import Toast from "../../../../components/toast/component";
import { handleClickOutside } from "../../../helpers/dropdownBoxActions";
import { AuthContext } from "../../../../providers/authProvider";

export const LoginCollapse: FC<LoginCollapseProps> = ({ showLogin, show }) => {
  const logCollapse = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(["navbar"]);
  const [password, setPassword] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const [openAlert, setOpenAlert] = useState(false);

  const formikLogin = useFormik<Auth>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: auth.fetchLogin,
  });

  const appearNavbar = () => {
    if (logCollapse.current) {
      logCollapse.current.style.left = `${(show ? 0 : -1) * 375}px`;
    }
  };

  useEffect(() => {
    appearNavbar();
  }, [show]);

  useEffect(() => {
    if (logCollapse.current) {
      document.addEventListener(
        "click",
        (event) => {
          if (
            handleClickOutside(logCollapse, event) &&
            logCollapse.current?.style.left === "0px"
          )
            appearNavbar();
        },
        true
      );
    }
  }, [logCollapse.current?.style.left]);

  useEffect(() => {
    !!auth.errorMsg && setOpenAlert(true);
  }, [auth.errorMsg]);

  return (
    <>
      <Toast open={openAlert} setOpen={setOpenAlert} type="error">
        {auth.errorMsg}
      </Toast>
      <div
        ref={logCollapse}
        className="log-collapse login-content"
        id="loginContent"
      >
        <div className="d-flex justify-content-start">
          <button
            type="button"
            className="btn arrow-btn"
            id="btn_show"
            style={{ padding: "7px" }}
            onClick={() => showLogin()}
          >
            <img src={navbarImages.arrowLeft} alt="menu" />
            <p>{t("un_auth_navbar_menu.login.menu")}</p>
          </button>
        </div>
        <h3>{t("log_collapse.login.title")}</h3>
        <div className="form">
          <p className="dropdown-item">{t("log_collapse.login.email.label")}</p>
          <input
            id="CorreoM"
            type="email"
            className="log-input mb-4 email-input"
            placeholder={t("log_collapse.login.email.placeholder")}
            onChange={formikLogin.handleChange("email")}
            onBlur={formikLogin.handleBlur("email")}
            value={formikLogin.values.email}
          />
          <p className="dropdown-item">
            {t("log_collapse.login.password.label")}
          </p>
          <div className="d-flex">
            <input
              id="PassM"
              type={password ? "text" : "password"}
              className="log-input pss-input"
              placeholder={t("log_collapse.login.password.placeholder")}
              onChange={formikLogin.handleChange("password")}
              onBlur={formikLogin.handleBlur("password")}
              value={formikLogin.values.password}
            />
            <div id="eye_password" className="input-group-addon">
              <img
                src={
                  password
                    ? authenticationImages.eye
                    : authenticationImages.crossEye
                }
                onClick={() => setPassword((prev) => !prev)}
                aria-hidden="true"
                alt=""
              />
            </div>
          </div>
          <Link
            className="forgot dropdown-item"
            to={t("recover_password", { ns: "routes" })}
          >
            {t("log_collapse.login.forgot_password")}
          </Link>
          <button
            type="button"
            className="btn btn-orange"
            onClick={() => formikLogin.handleSubmit()}
            id="Ilog"
          >
            {t("log_collapse.login.title")}
          </button>
        </div>
        <div className="other-signin">
          <div className="d-flex align-items-center w-100">
            <hr />
            <p className="or">{t("log_collapse.login.enter_via")}</p>
            <hr />
          </div>
          <div className="d-flex flex-column">
            <Link className="btn btn-social" to={t("signup", { ns: "routes" })}>
              <img src={modalsImages.facebook} alt="" />
              {t("log_collapse.login.facebook")}
            </Link>
            <Link className="btn btn-social" to={t("signup", { ns: "routes" })}>
              <img src={modalsImages.google} alt="" />
              {t("log_collapse.login.google")}
            </Link>
            <Link className="btn btn-social" to={t("signup", { ns: "routes" })}>
              <img src={modalsImages.apple} alt="" />
              {t("log_collapse.login.apple")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
