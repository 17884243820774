import GoogleMapReact from "google-map-react";
import { FC, useContext, useState } from "react";
import carImages from "../../../../assets/images/car";
import { useAddressTypes } from "../../../../hooks/address";
import { ShowContext } from "../../../../views/car/show/types";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { addressPrice } from "../../../helpers/textHandler";
import { CardDeliveryPoints } from "./CardDeliveryPoints";

export interface marker {
  text: string;
  lat: number;
  lng: number;
}

export const Marker: FC<marker> = ({ text }) => {
  const [showText, setShowText] = useState<boolean>(false);

  return (
    <div
      style={{
        transform: "translate(-50%, -50%)",
        position: "relative",
      }}
    >
      {showText ? (
        <p
          style={{
            color: "#000",
            position: "absolute",
            bottom: "20px",
            width: "200px",
          }}
        >
          {text}
        </p>
      ) : null}
      <img
        onClick={() => setShowText(!showText)}
        src={carImages.marker}
        alt=""
      />
    </div>
  );
};

export const CarUbication: FC = () => {
  const t = useContext(TranslationContext);
  const { addresses } = useContext(ShowContext);
  const { main, extra } = useAddressTypes(addresses || []);

  return (
    <>
      <div className="ubication-des">
        <h4 data-testid="ubication-title">
          {t("show.car_form.ubication.title")}
        </h4>
        <p className="map-info">
          {t("show.car_form.ubication.map_delivery_and_return")}
        </p>
      </div>

      {main && addresses && (
        <div className="map-container">
          <div className="map" data-testid="google-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_ALL || "",
              }}
              onGoogleApiLoaded={({ map, maps }) =>
                new maps.Circle({
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.3,
                  map,
                  center: {
                    lat: parseFloat(main[0].latitude || "0"),
                    lng: parseFloat(main[0].longitude || "0"),
                  },
                  radius: 225,
                })
              }
              yesIWantToUseGoogleMapApiInternals={true}
              center={{
                lat: parseFloat(main[0].latitude || "0"),
                lng: parseFloat(main[0].longitude || "0"),
              }}
              defaultZoom={15}
            >
              {extra.map((address) => (
                <Marker
                  key={address.id}
                  lat={parseFloat(address.latitude || "0")}
                  lng={parseFloat(address.longitude || "0")}
                  text={`📍 + ${address.comercialName} ${addressPrice(
                    address
                  )}`}
                />
              ))}
            </GoogleMapReact>
          </div>
          <CardDeliveryPoints />
        </div>
      )}
    </>
  );
};
