import { useEffect, useRef } from "react";
import LogRocket from "logrocket";
import ReactGA from "react-ga";
import { User } from "../models/user/types";

const ARE_ANALYTICS_ENABLED = Boolean(
  +(process.env.REACT_APP_ENABLE_ANALYTICS || 0)
);

export default function useAnalytics(user: User | undefined) {
  const klaviyoInit = useRef(false);

  // Load Klaviyo script
  useEffect(() => {
    if (!ARE_ANALYTICS_ENABLED || process.env.NODE_ENV !== "production") return;

    const head = document.querySelector("head");
    const script = document.createElement("script");
    if (!head) return;
    if (!klaviyoInit.current) {
      script.setAttribute("async", "true");
      script.setAttribute("text", "text/javascript");
      script.setAttribute("src", process.env.REACT_APP_KLAVIYO_SCRIPT || "");
      head.appendChild(script);
      klaviyoInit.current = true;
    }
    return () => {
      if (head && head.contains(script)) {
        head.removeChild(script);
      }
    };
  }, []);

  // Remove Facebook Pixel script if not enabled
  useEffect(() => {
    if (ARE_ANALYTICS_ENABLED && process.env.NODE_ENV === "production") return;
    const fbPixelScript = document.querySelector("script#fb-pixel-script");
    const fbPixelChild1 = document.querySelector(
      'script[src~="https://www.google-analytics.com/analytics.js"'
    );
    const fbPixelChild2 = document.querySelector(
      'script[src~="https://connect.facebook.net/en_US/fbevents.js"'
    );
    if (!fbPixelScript) return;
    fbPixelScript.remove();
    if (!fbPixelChild1) return;
    fbPixelChild1.remove();
    if (!fbPixelChild2) return;
    fbPixelChild2.remove();
  }, []);

  // Enable logrocket
  useEffect(() => {
    if (!(user && process.env.NODE_ENV === "production")) return;
    LogRocket.init(process.env.REACT_APP_LOGROCKET || "");
    LogRocket.identify(user.id.toString(), {
      name: `${user.name} ${user.surName}`,
      email: user.email,
      phone: user.phone,
    });

    if (ARE_ANALYTICS_ENABLED) {
      LogRocket.getSessionURL(function (sessionURL) {
        ReactGA.ga("send", {
          hitType: "event",
          eventCategory: "LogRocket",
          eventAction: sessionURL,
        });
      });
    }
  }, [user]);

  // Track page views with Google Analytics
  useEffect(() => {
    if (ARE_ANALYTICS_ENABLED && process.env.NODE_ENV === "production") return;

    const AnalyticsScript = document.querySelector(
      "noscript#google-analytics-noscript"
    );
    const AnalyticsScriptSrc = document.querySelector(
      "script#google-analytics-script"
    );

    if (!AnalyticsScript) return;
    AnalyticsScript.remove();
    if (!AnalyticsScriptSrc) return;
    AnalyticsScriptSrc.remove();
  }, []);
}
