import { useFormik } from "formik";
import { SignupForm, signupSchema } from "../../views/signup/form";
import { login } from "../../services/auth";
import { sha256 } from "js-sha256";
import { createUser } from "../../services/user";
import { CreateUserDTO } from "../../models/user/dtos";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../root";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { DomainContext } from "../../providers/domainProvider";

export const useSignup = () => {
  const { hostComercialId } = useContext(DomainContext);
  const { t } = useTranslation(["signup", "routes"]);
  const { setIsLogged, setIsComplete } = useContext(LoginContext);
  const [password, setPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const ERROR_MESSAGE = {
    "User Exist": t("error_validation.email_exist"),
  };

  const fetchSignup = async (values: CreateUserDTO) => {
    try {
      // Create new user and login
      Promise.all([
        await createUser({
          email: values.email,
          password: sha256(values.password),
          hostComercialId,
        }),
        await login({
          email: values.email,
          password: sha256(values.password),
          id: hostComercialId,
        }),
        setIsComplete(false),
        setIsLogged(true),
      ]);

      // redirect to profile route
      navigate("/" + t("complete_profile", { ns: "routes" }));
    } catch (error) {
      if (error instanceof AxiosError) {
        // Error messages depending endpoint response
        const errorMessage: keyof typeof ERROR_MESSAGE =
          error.response?.data.message;
        const message = ERROR_MESSAGE[errorMessage]
          ? ERROR_MESSAGE[errorMessage]
          : error.response?.data.message;
        setErrorMsg(message);
        setOpenAlert(true);
      }
    }
  };

  // Signup form
  const formikSignup = useFormik<SignupForm>({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupSchema,
    onSubmit: fetchSignup,
  });

  return {
    t,
    formikSignup,
    openAlert,
    setOpenAlert,
    errorMsg,
    password,
    setPassword,
    setConfirmPassword,
    confirmPassword,
  };
};
