import { ChangeEvent, useEffect, useState, useCallback } from "react";
import {
  fileSrc,
  uploadioEnv,
  listFiles,
  deleteFile,
  upload,
} from "../../services/fileUploader";
import { getUserId } from "../../services/localstorage";

export default function useProfileData() {
  const userId = getUserId();
  // Profile picture url
  const [imageProfile, setImageProfile] = useState<fileSrc | undefined>();
  // When upload
  const [isUploadingImageProfile, setIsUploading] = useState(false);

  useEffect(() => {
    if (imageProfile) {
      localStorage.setItem("profileImageUrl", imageProfile?.url || "");
    }
  }, [imageProfile]);

  // Get dinamically the image source
  useEffect(() => {
    (async () => {
      // list the files
      const result = await listFiles(`/${uploadioEnv}/user-${userId}`);
      if (!result || !result.items || result.items.length == 0) return;
      // search the profile picture
      const profilePic = result.items.find((f: { filePath?: string }) => {
        if (!f.filePath) return false;
        return f.filePath.includes("foto_perfil");
      });
      // set if found
      profilePic &&
        setImageProfile({
          url:
            profilePic.fileUrl.replace("raw", "profile-picture") +
            "?version=" +
            new Date().toISOString(),
          mime: "image",
        });
    })();
  }, []);

  const handleProfileImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      setIsUploading(true);

      // Delete the file
      try {
        await deleteFile(`/${uploadioEnv}/user-${userId}/foto_perfil`);
      } catch (error) {
        console.error("Error deleting picture profile", error);
        setIsUploading(false);
        return;
      }

      // upload new file
      try {
        const file = event.target.files[0];
        const { fileUrl } = await upload.uploadFile(file, {
          path: {
            fileName: "foto_perfil",
            folderPath: `user-${userId}`,
          },
        });
        setImageProfile({
          url: fileUrl + "?version=" + new Date().toISOString(),
          mime: "image",
        });
      } catch (error) {
        console.error("Error uploading picture profile", error);
      }
      setIsUploading(false);
    },
    []
  );

  return {
    imageProfile,
    handleProfileImage,
    isUploadingImageProfile,
  };
}
