import { useState, useEffect } from "react";
import {
  fileSrc,
  getFileDetailsFromPath,
  listFiles,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { getUserId } from "../../../../services/localstorage";

export default function useHostCommercialInfo() {
  const userId = getUserId();
  const [rentPoliciesFile, setRentPoliciesFile] = useState<
    fileSrc | undefined
  >();

  // Get dinamically the image source
  useEffect(() => {
    (async () => {
      try {
        const result = await listFiles(`/${uploadioEnv}/user-${userId}`);
        if (!result || !result.items || result.items.length == 0) return;
        // search the rent policies file
        const index = result.items.findIndex((f: { filePath?: string }) => {
          if (!f.filePath) return false;
          return f.filePath.includes("politicas_de_renta");
        });

        if (index !== -1) {
          await getFileDetailsFromPath(
            result.items[index].filePath,
            setRentPoliciesFile
          );
        }
      } catch (_) {}
    })();
  }, []);

  return {
    rentPoliciesFile,
    setRentPoliciesFile,
  };
}
