import { useCallback, useEffect, useState } from "react";
import {
  fileSrc,
  getFileDetailsFromPath,
  listFiles,
  uploadioEnv,
} from "../../../../services/fileUploader";
import { getUserId } from "../../../../services/localstorage";
import { UploadedFileItem } from "../../../../views/profile/types";

export default function useEditDocuments(carId: string) {
  const userId = getUserId();
  const [circulationCardFile, setCirculationCardFile] = useState<
    fileSrc | undefined
  >();
  const [insurancePolicyFile, setInsurancePolicyFile] = useState<
    fileSrc | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setEditDocument = useCallback(
    (
      fileUrl: string,
      fileMime: string,
      target: "vehicleLicense" | "insuranceCover"
    ) => {
      const fileSrc = { url: fileUrl, mime: fileMime };
      switch (target) {
        case "vehicleLicense":
          setCirculationCardFile(fileSrc);
          break;
        case "insuranceCover":
          setInsurancePolicyFile(fileSrc);
          break;
      }
    },
    []
  );

  // Get dinamically the image source
  useEffect(() => {
    (async () => {
      try {
        const result = await listFiles(
          `/${uploadioEnv}/user-${userId}/car-${carId}`
        );
        if (!result || !result.items || result.items.length == 0) {
          setIsLoading(false);
          return;
        }

        Promise.all(
          await result.items.map(async (file: UploadedFileItem) => {
            if (!file.filePath) return;
            if (file.filePath.includes("tarjeta_de_circulacion")) {
              await getFileDetailsFromPath(
                file.filePath,
                setCirculationCardFile
              );
            } else if (file.filePath.includes("poliza_de_seguro")) {
              await getFileDetailsFromPath(
                file.filePath,
                setInsurancePolicyFile
              );
            }
          })
        );
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    circulationCardFile,
    insurancePolicyFile,
    setEditDocument,
    isLoading,
    setIsLoading,
  };
}
