import { useQuery } from "react-query";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../../services/fileUploader";
import carImagesIndex from "../../../../assets/images/car/index";
import { Skeleton } from "@mui/material";
import { Trip, TripStatus } from "../../../../models/trip/types";
import { useWindowSize } from "../../../../hooks/windowSize";

const TripDefaultImage = ({ trip, width }: { trip: Trip; width?: number }) => {
  const { data: carImages, isLoading } = useQuery(["image", trip.carId], () =>
    getFileDetailsIfExist(
      `/${uploadioEnv}/user-${trip.hostId}/car-${trip.carId}/car_photo_1`,
      `/${uploadioEnv}/user-${trip.hostId}/car-${trip.carId}`
    )
  );
  const window = useWindowSize();

  return (
    <div className="car-image">
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          height={window > 1130 ? 140 : 180}
          width={width}
        />
      ) : carImages?.fileUrl ? (
        <img
          className="car"
          style={{
            opacity:
              trip.status === TripStatus.SEND_REQUEST_RESERVATION ||
              trip.status === TripStatus.ACCEPT_RESERVATION ||
              trip.status === TripStatus.ACTIVE_RESERVATION
                ? "1"
                : "0.6",
          }}
          alt=""
          src={carImages?.fileUrl.replace("raw", "thumbnail-reservation-index")}
        />
      ) : (
        <div
          style={{
            display: "grid",
            placeContent: "center",
            width: width ? `${width}px` : "315px",
            height: window > 1130 ? "140px" : "180px",
          }}
        >
          <img
            className="car empty-image"
            style={{
              opacity:
                trip.status === TripStatus.SEND_REQUEST_RESERVATION ||
                trip.status === TripStatus.ACCEPT_RESERVATION ||
                trip.status === TripStatus.ACTIVE_RESERVATION
                  ? "1"
                  : "0.6",
            }}
            alt=""
            src={carImagesIndex.defaultCar}
          />
        </div>
      )}
    </div>
  );
};

export default TripDefaultImage;
