import { useTranslation } from "react-i18next";
import helpImages from "../../../../assets/images/help";
import "./styles.scss";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/windowSize";

interface ResponsibilitiesHostBannerProps {
  image: string;
  title: string;
  mainInformation: string;
  link: string;
}

export const ResponsibilitiesHostBanner: FC<
  ResponsibilitiesHostBannerProps
> = ({ image, title, mainInformation, link }) => {
  const { t } = useTranslation(["hostReservation"]);
  const location = useLocation();
  const windowSize = useWindowSize();

  return (
    <div
      className="responsibilities-host-container-banner"
      onClick={() => window.open(link, "_blank")}
      style={{ cursor: "pointer" }}
    >
      <div className="responsibilities-summary">
        <div className="card">
          <div className="image-container">
            <img src={image} alt="qualitas" className="card-image" />
          </div>
          <div className="card-body">
            <h4 className="title">{title}</h4>
            {(windowSize > 1108 ||
              location.pathname !== "/anfitriones/reservaciones") && (
              <p className="main-information">{mainInformation}</p>
            )}
            <div className="know-more">
              <a>
                {t("index.know_more")}
                <img src={helpImages.arrowRightBlue} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
