import images from "../../assets/images/help";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const InProgress = () => {
  const { t } = useTranslation(["inProgress"]);

  return (
    <article className="in-progress-container d-flex justify-content-center align-items-center">
      <div className="content d-flex flex-column align-items-center">
        <h3>{t("in_progress_section")}</h3>
        <p>{t("working_on")}</p>
        <img src={images.underConstruction} alt="in progress" />
      </div>
    </article>
  );
};

export default InProgress;
