import { useEffect, useState } from "react";
import { cancelCoupon } from "../../../../services/coupons";
import { useTranslation } from "react-i18next";
import { Coupon } from "../../../../models/coupon/types";
import { Trip } from "../../../../models/trip/types";
import { useQueryClient } from "react-query";

const CouponInit = {
  namePromotionCode: "",
  expiresAt: "",
  descripcionCoupon: "",
  percentOff: 0,
  amountOff: 0,
};

export const useCouponContainer = (trip: Trip) => {
  const { t } = useTranslation(["car"]);
  const [openCoupon, setOpenCoupon] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Coupon>(CouponInit);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (trip && trip.promoCode !== " ") {
      setCoupon((prev) => ({
        ...prev,
        namePromotionCode: trip.promoCode,
        amountOff: parseInt(trip.discount),
      }));
    }
  }, [trip]);

  const handleCancelCoupon = async () => {
    const response = await cancelCoupon({
      code: coupon?.namePromotionCode || "",
      tripId: trip.id,
    });

    if (response?.status === 200) {
      setCoupon(CouponInit);
      queryClient.invalidateQueries(["coupons"]);
      queryClient.invalidateQueries(["trip"]);
    }
  };

  return {
    coupon,
    setCoupon,
    handleCancelCoupon,
    openCoupon,
    setOpenCoupon,
    t,
  };
};
