import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import a_cars from "../../../../assets/images/a_cars";
import { Marker } from "../carUbication/component";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Toast from "../../../../components/toast/component";
import a_carsImages from "../../../../assets/images/a_cars";
import { useEditCarModel } from "./useEditCarModel";
import {
  CarType,
  IsEcofriendly,
  Transmission,
} from "../../../../models/car/types";
import { useRef } from "react";
import { CarCategories } from "../addCarImportantData/carCategories/CarCategories";
import { FormikProvider } from "formik";

export const EditCarModel = () => {
  const autocompleteInput = useRef<HTMLInputElement>(null);
  const {
    t,
    msg,
    openAlert,
    setOpenAlert,
    car,
    formikEditModel,
    handleCurrentLocation,
    defaultProps,
    handleAddressFormat,
    principal,
  } = useEditCarModel();

  return (
    <>
      {msg && (
        <Toast open={openAlert} setOpen={setOpenAlert} type={msg.type}>
          {msg.text}
        </Toast>
      )}
      <FormikProvider value={formikEditModel}>
        <div className="model-ubication" id="first_step">
          <div className="form-container">
            <div className="info-container">
              <h1 data-testid="model-and-ubication-title" className="title">
                {t("edit.model_and_ubication.view_information")}
              </h1>
              <h2>{t("edit.model_and_ubication.view_information_subtitle")}</h2>
            </div>
            <div className="form">
              <Accordion className="file-container">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <p className="option-title">
                    {t("edit.model_and_ubication.basic_information")}
                  </p>
                  <div className="dropdown-divider"></div>
                </AccordionSummary>
                <AccordionDetails className="information">
                  <div className="generic-input" data-testid="brand-text">
                    <label className="select-label">
                      {t("edit.model_and_ubication.brand.title")}
                    </label>
                    <p className="generic-text marca">{car?.brand}</p>
                  </div>
                  <div className="generic-input" data-testid="model-text">
                    <label className="select-label">
                      {t("edit.model_and_ubication.model.title")}
                    </label>
                    <p className="generic-text marca">{car?.model}</p>
                  </div>
                  <div className="generic-input" data-testid="year-text">
                    <label className="select-label">
                      {t("edit.model_and_ubication.year.title")}
                    </label>
                    <p className="generic-text marca">{car?.year}</p>
                  </div>
                  <div className="generic-input" data-testid="version-text">
                    <label className="select-label">
                      {t("edit.model_and_ubication.version.title")}
                    </label>
                    <p className="generic-text marca">{car?.version}</p>
                  </div>

                  <p className="edit-text-model">
                    {t("edit.model_and_ubication.modify_information")}
                    <a href="" className="ms-2">
                      {t("edit.model_and_ubication.contact_information")}
                    </a>
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion className="file-container">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-testid="information-accordion"
                >
                  <p className="option-title">
                    {t("edit.model_and_ubication.important_details")}
                  </p>
                  <div className="dropdown-divider"></div>
                </AccordionSummary>
                <AccordionDetails className="information">
                  <div
                    className="generic-input"
                    data-testid="transmission-text"
                  >
                    <label className="select-label">
                      {t("add.car_model_and_ubication.transmission.title")}
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="transmission"
                        id="transmission"
                        value={formikEditModel.values.transmission ?? ""}
                        defaultValue={formikEditModel.values.transmission}
                        onChange={(e) => {
                          formikEditModel.setFieldValue(
                            "transmission",
                            e.target.value
                          );
                        }}
                        className="dropdown"
                        label=""
                      >
                        <MenuItem value={""} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={Transmission.AUTOMATIC}>
                          {t(
                            "add.car_model_and_ubication.transmission.automatic"
                          )}
                        </MenuItem>
                        <MenuItem value={Transmission.MANUAL}>
                          {t("add.car_model_and_ubication.transmission.manual")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="generic-input" data-testid="type-text">
                    <label className="select-label">
                      {t("add.car_model_and_ubication.type.title")}
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="type"
                        id="type"
                        value={formikEditModel.values.type ?? ""}
                        onChange={(e) => {
                          formikEditModel.setFieldValue("type", e.target.value);
                        }}
                        className="dropdown"
                      >
                        <MenuItem value={""} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={CarType.COMPACT}>
                          {t(
                            "add.car_model_and_ubication.type.hatchback_compact"
                          )}
                        </MenuItem>
                        <MenuItem value={CarType.SEDAN}>
                          {t("add.car_model_and_ubication.type.cars")}
                        </MenuItem>
                        <MenuItem value={CarType.COUPE}>
                          {t("add.car_model_and_ubication.type.coupe")}
                        </MenuItem>
                        <MenuItem value={CarType.SUV}>
                          {t("add.car_model_and_ubication.type.suv_crossover")}
                        </MenuItem>
                        <MenuItem value={CarType.MINIVAN}>
                          {t("add.car_model_and_ubication.type.minivan")}
                        </MenuItem>
                        <MenuItem value={CarType.VAN}>
                          {t("add.car_model_and_ubication.type.van")}
                        </MenuItem>
                        <MenuItem value={CarType.TRUCK}>
                          {t("add.car_model_and_ubication.type.truck")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="generic-input" data-testid="plate-text">
                    <label className="select-label">
                      {t("add.car_model_and_ubication.plates")}
                    </label>
                    <TextField
                      id="txtPlacas"
                      type="text"
                      value={formikEditModel.values.plate ?? ""}
                      onChange={(e) => {
                        formikEditModel.setFieldValue("plate", e.target.value);
                      }}
                      className="input-text-large"
                    />
                  </div>
                  <div
                    className="generic-input"
                    data-testid="isEcoFriendly-text"
                  >
                    <label className="select-label eco">
                      {t("add.car_model_and_ubication.is_ecological.label")}
                      <img src={a_carsImages.ecoType} /> *
                    </label>
                    <FormControl fullWidth>
                      <Select
                        variant="outlined"
                        labelId="ecological"
                        id="ecological"
                        value={formikEditModel.values.isEcoFriendly ?? ""}
                        onChange={(e) => {
                          formikEditModel.setFieldValue(
                            "isEcoFriendly",
                            e.target.value
                          );
                        }}
                        className="dropdown"
                      >
                        <MenuItem value={""} disabled>
                          {t("add.car_price.choose_an_option")}
                        </MenuItem>
                        <MenuItem value={IsEcofriendly.NEGATIVE}>
                          {t(
                            "add.car_model_and_ubication.is_ecological.negative"
                          )}
                        </MenuItem>
                        <MenuItem value={IsEcofriendly.ELECTRIC}>
                          {t(
                            "add.car_model_and_ubication.is_ecological.electric"
                          )}
                        </MenuItem>
                        <MenuItem value={IsEcofriendly.HYBRID}>
                          {t(
                            "add.car_model_and_ubication.is_ecological.hybrid"
                          )}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="generic-input">
                    <label className="select-label">
                      {t("add.car_model_and_ubication.number_of_seats.title")}
                    </label>
                    <FormControl fullWidth>
                      <Select
                        labelId="seats_number"
                        id="seats_number"
                        value={formikEditModel.values.numSeats || "0"}
                        defaultValue={formikEditModel.values.numSeats || "0"}
                        onChange={(e) => {
                          formikEditModel.setFieldValue(
                            "numSeats",
                            e.target.value
                          );
                        }}
                        className="dropdown"
                        required
                        error={formikEditModel.values.numSeats === 0}
                      >
                        <MenuItem value={0} disabled>
                          {t(
                            "add.car_model_and_ubication.number_of_seats.your_cars_seats"
                          )}
                        </MenuItem>
                        {Array.from({ length: 20 }, (_, i) => i + 1).map(
                          (numSeats) => (
                            <MenuItem key={numSeats} value={numSeats}>
                              {numSeats}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="generic-input">
                    <label className="select-label">
                      {t("add.car_model_and_ubication.number_of_bags.title")}
                    </label>
                    <FormControl fullWidth>
                      <Select
                        placeholder={t(
                          "add.car_model_and_ubication.number_of_bags.your_cars_bags"
                        )}
                        id="bags_number"
                        value={formikEditModel.values.numBags || "0"}
                        defaultValue={formikEditModel.values.numBags || "0"}
                        onChange={(e) => {
                          formikEditModel.setFieldValue(
                            "numBags",
                            e.target.value
                          );
                        }}
                        className="input-bags dropdown"
                        error={formikEditModel.values.numBags === 0}
                        required
                      >
                        <MenuItem value={0} disabled>
                          {t(
                            "add.car_model_and_ubication.number_of_bags.your_cars_bags"
                          )}
                        </MenuItem>
                        {Array.from({ length: 15 }, (_, i) => i + 1).map(
                          (numBags) => (
                            <MenuItem key={numBags} value={numBags}>
                              {numBags}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <CarCategories />
                </AccordionDetails>
              </Accordion>
              <Accordion className="file-container">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  data-testid="ubication-accordion"
                >
                  <p className="option-title">
                    {t("add.car_model_and_ubication.address")}
                  </p>
                </AccordionSummary>
                <AccordionDetails className="drop-options">
                  <div className="document-generic-input">
                    <span id="where" className="where">
                      {t("add.car_model_and_ubication.where_is_located")}
                    </span>
                    <div className="direction-input">
                      <Autocomplete
                        ref={autocompleteInput}
                        id="txtUbication"
                        className="input-text-large"
                        defaultValue={principal}
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS}
                        inputAutocompleteValue={principal}
                        options={{
                          componentRestrictions: { country: "mx" },
                          fields: [
                            "geometry",
                            "formatted_address",
                            "place_id",
                            "address_components",
                            "name",
                          ],
                          types: [],
                        }}
                        language="es-419"
                        onPlaceSelected={(place) => handleAddressFormat(place)}
                      />
                      <button
                        type="button"
                        className="btn btn-orange-des"
                        onClick={() => {
                          handleCurrentLocation({ autocompleteInput });
                        }}
                      >
                        <img src={a_cars.ubication} alt="" />
                        <p>{t("edit.model_and_ubication.ubication")}</p>
                      </button>
                    </div>
                    <div className="map" data-testid="google-map">
                      {!!formikEditModel.values.mainAddress &&
                        !!formikEditModel.values.mainAddress.geometry?.location
                          ?.lat &&
                        !!formikEditModel.values.mainAddress.geometry?.location
                          ?.lng && (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: process.env.REACT_APP_GOOGLE_MAPS || "",
                            }}
                            center={{
                              lat: parseFloat(
                                formikEditModel.values.mainAddress?.geometry
                                  ?.location?.lat
                              ),
                              lng: parseFloat(
                                formikEditModel.values.mainAddress?.geometry
                                  ?.location?.lng
                              ),
                            }}
                            defaultZoom={defaultProps.zoom}
                          >
                            <Marker
                              lat={parseFloat(
                                formikEditModel.values.mainAddress.geometry
                                  .location.lat
                              )}
                              lng={parseFloat(
                                formikEditModel.values.mainAddress.geometry
                                  .location.lng
                              )}
                              text="Ubicación principal"
                            />
                          </GoogleMapReact>
                        )}
                    </div>
                    <h5>
                      {t(
                        "add.car_model_and_ubication.information_to_register_your_car"
                      )}
                    </h5>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="btn-container save-container" id="save_container">
          <p className="reminder">{t("edit.dont_forget")}</p>
          <button
            type="button"
            id="save_first"
            className="btn btn-blue"
            onClick={() => formikEditModel.handleSubmit()}
          >
            {t("edit.save_changes")}
          </button>
        </div>
      </FormikProvider>
    </>
  );
};
