import { AuthDAO, AuthWithRelations } from "../models/auth/types";
import { axiosService } from "./axios";
import {
  getToken,
  saveEmail,
  saveIsCompleted,
  saveToken,
  saveUserId,
} from "./localstorage";

export const login = async (LoginDTO: AuthWithRelations) => {
  const response = await axiosService.post<AuthDAO>(
    "/account/authenticate/",
    LoginDTO
  );

  saveIsCompleted({
    name: response.data.user.name,
    userType: response.data.user.type,
  });
  saveToken(response.data);
  saveUserId(response.data);
  saveEmail(response.data);

  return response.data;
};

export const verification = async (): Promise<number | undefined> => {
  try {
    const response = await axiosService.get("/message", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
