import dayjs from "dayjs";
import { FC } from "react";
import { useQuery } from "react-query";
import authenticationImages from "../../../../../assets/images/authentication";
import i18n from "../../../../../i18n";
import { getUserByID } from "../../../../../services/user";
import "dayjs/locale/es";
import { Skeleton, Tooltip } from "@mui/material";
import reservationImages from "../../../../../assets/images/reservations";
import navbarImages from "../../../../../assets/images/navbar";
import { useTranslation } from "react-i18next";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../../../../services/fileUploader";
import { Trip } from "../../../../../models/trip/types";
import "../hostCard/HostCard.scss";
import { TrustedUser } from "../../../../../models/user/types";

interface DriverCardProps {
  className?: string;
  userId: number;
  trip?: Trip;
}

export const DriverCard: FC<DriverCardProps> = ({
  className,
  userId,
  trip,
}) => {
  const { t } = useTranslation(["car"]);

  const { data: userData, isLoading } = useQuery(
    ["user", userId],
    () => getUserByID(userId),
    {
      enabled: !!userId,
    }
  );

  const { data: driverImage } = useQuery(
    ["driverImage", userId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${userId}/foto_perfil`,
        `/${uploadioEnv}/user-${userId}`
      ),
    {
      enabled: !!userId,
    }
  );

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        className={`user-card ${className}`}
        height={70}
      />
    );
  }

  return (
    <div className={`user-card ${className}`}>
      <div className="user-info-image-container">
        <img
          src={
            driverImage
              ? driverImage.fileUrl.replace("raw", "profile-picture")
              : authenticationImages.profileDefault
          }
          className="user-img"
          alt=""
        />
        <div className="user-info">
          <h3 className="name" id="nombre">
            {userData?.name + " " + userData?.surName}
          </h3>
          {userData?.trustedUser === TrustedUser.TRUE && (
            <div className="host-indicator-container">
              <Tooltip
                title={t("show.car_form.user_tooltip")}
                arrow
                className="user-tooltip"
              >
                <span className="hostC-indicator" data-testid="verified-driver">
                  {t("show.car_form.trusted_user")}{" "}
                </span>
              </Tooltip>
              <img
                src={reservationImages.checkVerified}
                className="check-host"
                alt=""
              />
            </div>
          )}
          <p className="more">
            {t("show.car_form.host_card.create_date")}{" "}
            {dayjs(userData?.createDate, "DD/MM/YYYY")
              .locale(i18n.language)
              .format("MMMM YYYY")}
          </p>
        </div>
      </div>

      {trip && (
        <div className="btn-contact-container">
          <a
            id="contact"
            data-testid="contact-button"
            target="_blank"
            href={`https://wa.me/523318866005?text=Quiero%20hablar%20con%20mi%20Huésped%20-%20${
              userData?.name + " " + userData?.surName
            }%20-%20sobre%20mi%20reservaci%C3%B3n%20${trip.tripCode}`}
            className="btn btn-contact"
          >
            <p>{t("show.contact_bar.share")}</p>
            <img src={navbarImages.messages} alt="" />
          </a>
        </div>
      )}
    </div>
  );
};
