import { useTranslation } from "react-i18next";
import a_carsImages from "../../../../assets/images/a_cars";

export const EmptyState = () => {
  const { t } = useTranslation(["results"]);

  return (
    <div className="empty-state__container">
      <h3 className="title">{t("filter.empty.title")}</h3>
      <p className="text-1">
        {t("filter.empty.empty_results")}
        <br /> {t("filter.empty.modify_filters")}
      </p>
      <img src={a_carsImages.searchCar} />
      <p className="text-2">
        {t("filter.empty.opinion")}{" "}
        <a
          target={"_blank"}
          href={
            "https://api.whatsapp.com/send?phone=5213318866005&text=%C2%A1Hola!%20Me%20interesaria%20ver%20m%C3%A1s%20autos%20y%20m%C3%A1s%20lugares%20en%20romio."
          }
        >
          {t("filter.empty.contact_us")}
        </a>
      </p>
    </div>
  );
};
