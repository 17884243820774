import { ChangeEvent, forwardRef, useId } from "react";
import { useTranslation } from "react-i18next";

interface inputProps {
  handleClick?: () => void;
  handleAutocomplete: (event: ChangeEvent<HTMLInputElement>) => void;
  autocomplete: string;
}

export const InputAutocomplete = forwardRef<any, inputProps>( // eslint-disable-line @typescript-eslint/no-explicit-any
  ({ handleClick, handleAutocomplete, autocomplete }, ref) => {
    const { t } = useTranslation(["home", "routes"]);
    const inputId = useId();

    return (
      <input
        id={inputId}
        className="w-100"
        ref={ref}
        onClick={handleClick}
        onChange={handleAutocomplete}
        value={autocomplete}
        placeholder={t("searchBar.where.placeholder")}
      />
    );
  }
);
