import React, { useContext } from "react";
import { useQuery } from "react-query";
import carImages from "../../../../../assets/images/car";
import { ShowContext } from "../../../../../views/car/show/types";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import { formatTextWithEnters } from "../../../../helpers/textHandler";
import orderImages from "../../../../../assets/images/order";
import {
  getHostByUserId,
  getHostExistByUserId,
} from "../../../../../services/host";
import { isValidHostCOwnInsurance } from "../../../../helpers/status";
import { getInsuranceByCarId } from "../../../../../services/insurance";
import { InsuranceType } from "../../../../../models/insurance/types";

export const CarInformation = () => {
  const t = useContext(TranslationContext);
  const { car, host } = useContext(ShowContext);
  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", host?.id],
    () => getHostExistByUserId(host?.id.toString() || ""),
    {
      enabled: !!host,
    }
  );

  const existHost = isHostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", host?.id],
    () => getHostByUserId(host?.id.toString() || ""),
    {
      enabled: existHost,
    }
  );

  return (
    <>
      <div className="description">
        <h4 data-testid="description-title">
          {t("show.car_form.description")}
        </h4>
        <p
          id="descrption1"
          dangerouslySetInnerHTML={{
            __html: formatTextWithEnters(car?.description),
          }}
          style={{ overflow: "auto", textOverflow: "ellipsis" }}
        />
      </div>
      <div className="accesories">
        <h4 data-testid="accesories-title">
          {t("show.car_form.accesories.title")}
        </h4>
        <Characteristics />
      </div>
      <div className="rules">
        <h4 data-testid="rules-title">{t("show.car_form.rules.title")}</h4>
        <p
          id="reglas"
          dangerouslySetInnerHTML={{
            __html: formatTextWithEnters(car?.rules),
          }}
        />
      </div>
      <div className="perks-container">
        <h4 data-testid="insurance-title">
          {t("show.car_form.comercial_perks.what_includes_reservation")}
        </h4>
        {isHostCommercial && hostC && isValidHostCOwnInsurance(hostC) ? (
          <ComercialPerks />
        ) : (
          <HostPerks />
        )}
      </div>
    </>
  );
};

const Characteristics = () => {
  const t = useContext(TranslationContext);
  const { car } = useContext(ShowContext);

  const carCharacteristics = [
    t("show.car_form.accesories.electric/hybrid"),
    t("show.car_form.accesories.convertible"),
    t("show.car_form.accesories.leather_seats"),
    t("show.car_form.accesories.solar_roof"),
    t("show.car_form.accesories.gps"),
    t("show.car_form.accesories.pets_welcome"),
    t("show.car_form.accesories.usb_connection"),
    t("show.car_form.accesories.bluetooth"),
    t("show.car_form.accesories.car_play"),
    t("show.car_form.accesories.android_auto"),
    t("show.car_form.accesories.aux_input"),
    t("show.car_form.accesories.air_conditioning"),
    t("show.car_form.accesories.panoramic_ceiling"),
    t("show.car_form.accesories.bike_rack"),
    t("show.car_form.accesories.4wd"),
    t("show.car_form.accesories.child_seat"),
    t("show.car_form.accesories.seat_heater"),
    t("show.car_form.accesories.electric_seat_adjustment"),
    t("show.car_form.accesories.power_windows"),
    t("show.car_form.accesories.video_player"),
    t("show.car_form.accesories.cd_player"),
    t("show.car_form.accesories.satellite_radio"),
  ];
  return (
    <>
      {car?.characteristicsList
        ?.split("")
        .map((charac: string, index: number) => (
          <React.Fragment key={charac + index}>
            {charac == "1" ? (
              <div className="charac">
                <img
                  className="accessoriesPreview"
                  src={orderImages.accesories}
                  alt=""
                />
                <h6>{carCharacteristics[index]}</h6>
              </div>
            ) : null}
          </React.Fragment>
        ))}
    </>
  );
};

const ComercialPerks = () => {
  const t = useContext(TranslationContext);

  return (
    <div className="comercial-perks">
      <div className="perks">
        <img src={carImages.comercialPerkUbication} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.comercial_perks.choose_point")}
          </p>
          <span className="extra">
            {t("show.car_form.comercial_perks.choose_point_extra")}
          </span>
        </div>
      </div>
      <div className="perks">
        <img src={carImages.comercialPerkSupport} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.comercial_perks.assistance")}
          </p>
          <span className="extra">
            {t("show.car_form.comercial_perks.assistance_extra")}
          </span>
        </div>
      </div>
      <div className="perks">
        <img src={carImages.comercialPerkInsured} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.comercial_perks.insured_trips")}
          </p>
          <span className="extra">
            {t("show.car_form.comercial_perks.insured_trips_extra")}
          </span>
        </div>
      </div>
    </div>
  );
};

const HostPerks = () => {
  const t = useContext(TranslationContext);
  const { car } = useContext(ShowContext);
  const { data: insuranceCar } = useQuery(
    ["insuranceCar", car?.id],
    () => getInsuranceByCarId(car?.id),
    {
      enabled: !!car,
    }
  );
  return (
    <div className="host-perks">
      <div className="perks">
        <img src={carImages.comercialPerkUbication} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.host_perks.choose_point")}
          </p>
          <span className="extra">
            {t("show.car_form.host_perks.choose_point_extra")}
          </span>
        </div>
      </div>
      <div className="perks">
        <img src={carImages.comercialPerkSupport} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.host_perks.assistance")}
          </p>
          <span className="extra">
            {t("show.car_form.host_perks.assistance_extra")}
          </span>
        </div>
      </div>
      <div className="perks">
        <img src={carImages.comercialPerkInsured} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.host_perks.comprehensive_insurance")}
          </p>
          {insuranceCar?.type == InsuranceType.HDI ? (
            <span className="extra">
              {t("show.car_form.host_perks.comprehensive_insurance_extra_hdi")}
            </span>
          ) : (
            <span className="extra">
              {t("show.car_form.host_perks.comprehensive_insurance_extra")}
            </span>
          )}
        </div>
      </div>
      <div className="perks">
        <img src={carImages.hostPerkCancel} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.host_perks.flexible_cancellations")}
          </p>
          <span className="extra">
            {t("show.car_form.host_perks.flexible_cancellations_extra")}
          </span>
        </div>
      </div>
      <div className="perks">
        <img src={carImages.hostPerkKilometers} />
        <div className="perk-info">
          <p className="perk-name">
            {t("show.car_form.host_perks.unlimited_kilometers")}
          </p>
          <span className="extra">
            {t("show.car_form.host_perks.unlimited_kilometers_extra")}
          </span>
        </div>
      </div>
    </div>
  );
};
