import { getToken } from "./localstorage";
import { axiosService } from "./axios";

export type SelectedPlan = {
  count: number;
  interval: string;
  type: string;
};

// Set payment intent
export const getPaymentIntent = async ({
  tripCode,
  paymentIntentId,
  selectedPlan,
}: {
  tripCode?: string;
  paymentIntentId: string;
  selectedPlan?: SelectedPlan;
}) => {
  const payload = !!selectedPlan
    ? { tripCode, paymentIntentId, selectedPlan }
    : { tripCode, paymentIntentId };

  const response = await axiosService.put("/wallet/payment/confirm", payload, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response;
};

// Confirm payment
export const confirmPayment = async (paymentId: string) => {
  try {
    const response = await axiosService.put(
      "/wallet/capture",
      { paymentId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

// Get card available payment details
export const getDefaultCarPayDetails = async (tripId: string) => {
  const response = await axiosService.post(
    "/wallet/payment/details",
    { tripId },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

export const getNewCarPayDetails = async (
  paymentIntentId: string,
  cardId: string
) => {
  try {
    const response = await axiosService.put(
      "/wallet/payment/update",
      { cardId, paymentIntentId },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
