import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { useFormikContext } from "formik";
import { BlockedDatesProps, CarForm } from "../../../../../views/car/add/types";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "react-query";
import { getDateByCarID } from "../../../../../services/date";
import { DateStatus, Dates } from "../../../../../models/date/types";
import { getDaysArray, isDateAvailable } from "../../../../helpers/dateHandler";
import { editPriceForm } from "../../../../../views/car/edit/types";

export const useBlockedDates = ({ carId }: BlockedDatesProps) => {
  const { values, setFieldValue } = useFormikContext<CarForm | editPriceForm>();
  const [storeDates, setStoreDates] = useState<DateRange<dayjs.Dayjs>>([
    null,
    null,
  ]);
  const [dates, setDates] = useState<DateRange<Dayjs>>([null, null]);
  const matchesMobile = useMediaQuery("(max-width:1000px)");
  const t = useContext(TranslationContext);
  const { data: dateList } = useQuery(
    ["dates", carId],
    () => getDateByCarID(parseInt(carId)),
    {
      enabled: !!carId,
    }
  );

  // Add new dates to the block list
  const handleBlockDates = (newValue: DateRange<Dayjs>) => {
    // Has to be available and it has to choose start and end date
    if (!isDateAvailable(newValue, values.blockDates || [])) return;
    if (!newValue[0] || !newValue[1]) return;

    // Format and get days blocked
    const startDate = newValue[0];
    const endDate = newValue[1];
    const blockedDaysList = getDaysArray(
      startDate.format("DD-MM-YYYY"),
      endDate.format("DD-MM-YYYY")
    );

    // Add new dates in the list
    setFieldValue("blockDates", [
      ...(values.blockDates || []),
      {
        id: 0,
        startDate: startDate.format("DD/MM/YYYY"),
        endDate: endDate.format("DD/MM/YYYY"),
        status: DateStatus.LOCKED_BY_HOST,
        dates: blockedDaysList,
      },
    ]);

    // Clean selection on calendar
    setTimeout(() => setDates([null, null]), 500);
  };

  // On click erase date, loop variable to delete it
  const handleEraseBlockDate = (index: number) => {
    const newBlockDates = values.blockDates?.filter((_, idx) => idx !== index);

    setFieldValue("blockDates", newBlockDates);
  };

  // Set prevs dates
  useEffect(() => {
    if (dateList) {
      handleDisabledDates(dateList);
    }
  }, [dateList]);

  const handleDisabledDates = (disabledDates: Dates[]) => {
    // Map prev data
    const dates = disabledDates
      .filter((date) => !dayjs(date.dateEnd, "DD-MM-YYYY").isBefore(dayjs()))
      .map((date: Dates) => {
        // Make a list with the days blocked
        const blockedDaysList = getDaysArray(date.dateInitial, date.dateEnd);

        // Set prev block dates
        return {
          id: date.id,
          startDate: dayjs(date.dateInitial, "DD-MM-YYYY").format("DD/MM/YYYY"),
          endDate: dayjs(date.dateEnd, "DD-MM-YYYY").format("DD/MM/YYYY"),
          status: date.status,
          dates: blockedDaysList,
        };
      });

    setFieldValue("blockDates", dates);
  };

  return {
    t,
    values,
    storeDates,
    setStoreDates,
    setDates,
    matchesMobile,
    dates,
    handleBlockDates,
    handleEraseBlockDate,
  };
};
