import { Suspense } from "react";
import * as ReactDOM from "react-dom/client";
import { theme } from "./theme/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./i18n";
import "./sentry";
import { ThemeProvider } from "@mui/material";
import "swiper/css/bundle";
import Loading from "./components/loading/component";
import "./variables.scss";
import { LanguageProvider } from "./providers/languageProvider";
import { DomainProvider } from "./providers/domainProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<Loading height="90vh" />}>
          <DomainProvider>
            <LanguageProvider>
              <App />
            </LanguageProvider>
          </DomainProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
);
