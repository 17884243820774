import { useContext, useState, useRef, useMemo } from "react";
import {
  Car,
  CarStatus,
  DocInsuranceCover,
  HasGPS,
  StatusInsuranceCover,
  StatusVehicleLicense,
  ValidateInfo,
} from "../../models/car/types";
import { TranslationContext } from "../../views/driverReservation/show/component";
import "../../views/car/index/styles.scss";
import carImagesIndex from "../../assets/images/car/index";
import { Button, Popover, ListItem, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const DOCUMENTS_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.pending}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  2: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  3: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  4: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
};

const HAS_GPS_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  2: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
};

const CarStatusInfo = (car: Car) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const t = useContext(TranslationContext);

  const DOCUMENTS_STATUS_COLOR = useRef({
    0: "#FFAB03",
    1: "#FFAB03",
    2: "#373A3C;",
    3: "#EB5757",
    4: "#EB5757",
  });

  const DOCUMENTS_STATUS = useRef({
    0: t("index.status.not_uploaded"),
    1: t("index.status.in_revision"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const CAR_STATUS = useRef({
    0: t("index.status.incomplete"),
    1: t("index.status.in_revision"),
    2: t("index.status.approved"),
    3: t("index.status.rejected"),
    4: t("index.status.rejected"),
  });

  const HAS_GPS_STATUS = useRef({
    0: t("index.status.gps.unknown"),
    1: t("index.status.gps.false"),
    2: t("index.status.gps.true"),
  });

  const HAS_GPS_STATUS_COLOR = useRef({
    0: "#FFAB03",
    1: "#EB5757",
    2: "#373A3C",
  });

  const docVehicleLicenseAboutToExpire = useRef(
    car.statusVehicleLicense === StatusVehicleLicense.ABOUT_TO_EXPIRE
  );

  const docVehicleLicenseExpired = useRef(
    car.statusVehicleLicense === StatusVehicleLicense.EXPIRED
  );

  const docInsuranceAboutToExpire = useRef(
    car.statusInsuranceCover === StatusInsuranceCover.ABOUT_TO_EXPIRE
  );

  const docInsuranceExpired = useRef(
    car.statusInsuranceCover === StatusInsuranceCover.EXPIRED
  );

  return (
    <div>
      <Button
        data-testid="car-documents-status"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          width: "275px",
          color: "inherit",
          border: "1px solid #3CB2B9",
          borderRadius: "40px",
          justifyContent: "space-around",
          marginLeft: "6px",
        }}
      >
        <DocumentStatusListItem {...car} />
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ sx: { borderRadius: "16px", width: "275px" } }}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            border: "1px solid #3CB2B9",
            borderRadius: "16px",
          }}
        >
          <ListItem
            onClick={() => setAnchorEl(null)}
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              cursor: "pointer",
              paddingRight: 0,
            }}
          >
            <DocumentStatusListItem {...car} />
            <KeyboardArrowUpIcon
              sx={{
                marginLeft: "12px",
                position: "absolute",
                right: "12px",
                fontSize: "20px",
              }}
            />
          </ListItem>
          <ListItem
            data-testid="car-status"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: DOCUMENTS_STATUS_COLOR.current[car.status],
            }}
          >
            {DOCUMENTS_ICON_STATUS[car.status]}
            {t("index.status.car_info")}: {CAR_STATUS.current[car.status]}
          </ListItem>
          <ListItem
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: docVehicleLicenseAboutToExpire.current
                ? "#FFAB03"
                : docVehicleLicenseExpired.current
                ? "#EB5757"
                : DOCUMENTS_STATUS_COLOR.current[car.docVehicleLicense],
            }}
          >
            {docVehicleLicenseAboutToExpire.current ? (
              <img
                src={carImagesIndex.not_uploaded}
                style={{ marginRight: "6px", fontSize: "14px" }}
              />
            ) : docVehicleLicenseExpired.current ? (
              <img
                src={carImagesIndex.refused}
                style={{ marginRight: "6px", fontSize: "14px" }}
              />
            ) : (
              DOCUMENTS_ICON_STATUS[car.docVehicleLicense]
            )}
            {t("index.status.vehicle_license_document")}:
            {docVehicleLicenseAboutToExpire.current
              ? t("index.status.about_to_expire")
              : docVehicleLicenseExpired.current
              ? t("index.status.expired")
              : DOCUMENTS_STATUS.current[car.docVehicleLicense]}
          </ListItem>
          <ListItem
            data-testid="car-status"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              gap: "5px",
            }}
          >
            <hr style={{ width: "30%" }} />
            {t("index.status.recommended")}
            <hr style={{ width: "30%" }} />
          </ListItem>
          <ListItem
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: docInsuranceAboutToExpire.current
                ? "#FFAB03"
                : docInsuranceExpired.current
                ? "#EB5757"
                : DOCUMENTS_STATUS_COLOR.current[car.docInsuranceCover],
            }}
          >
            {docInsuranceAboutToExpire.current ? (
              <img
                src={carImagesIndex.not_uploaded}
                style={{ marginRight: "6px", fontSize: "14px" }}
              />
            ) : docInsuranceExpired.current ? (
              <img
                src={carImagesIndex.refused}
                style={{ marginRight: "6px", fontSize: "14px" }}
              />
            ) : (
              DOCUMENTS_ICON_STATUS[car.docInsuranceCover]
            )}
            {t("index.status.insurance_docuement")}:
            {docInsuranceAboutToExpire.current
              ? t("index.status.about_to_expire")
              : docInsuranceExpired.current
              ? t("index.status.expired")
              : DOCUMENTS_STATUS.current[car.docInsuranceCover]}
          </ListItem>
          <ListItem
            data-testid="car-status"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: HAS_GPS_STATUS_COLOR.current[car.hasGPS],
            }}
          >
            {HAS_GPS_ICON_STATUS[car.hasGPS]}
            {HAS_GPS_STATUS.current[car.hasGPS]}
          </ListItem>
        </Box>
      </Popover>
    </div>
  );
};

const DocumentStatusListItem = (car: Car) => {
  const t = useContext(TranslationContext);

  const countProblems = useMemo(() => {
    const isInsuranceValid =
      car.docInsuranceCover === DocInsuranceCover.VALIDATED &&
      car.statusInsuranceCover === StatusInsuranceCover.VALID;
    const isVehicleValid =
      car.docVehicleLicense === ValidateInfo.VALIDATED &&
      car.statusVehicleLicense === StatusVehicleLicense.VALID;
    const isCarActive = car.status === CarStatus.APPROVED;
    const isGpsTrue = car.hasGPS === HasGPS.TRUE;

    return [isInsuranceValid, isVehicleValid, isCarActive, isGpsTrue].filter(
      (item) => !item
    ).length;
  }, [car]);

  return (
    <>
      {car.docInsuranceCover === DocInsuranceCover.VALIDATED &&
      car.docVehicleLicense === ValidateInfo.VALIDATED &&
      car.status === CarStatus.APPROVED &&
      car.hasGPS === HasGPS.TRUE ? (
        <div>
          <img
            src={carImagesIndex.validated}
            alt="check"
            aria-hidden="true"
            style={{
              margin: "0px 6px 2px 0px",
              height: "unset",
              width: "unset",
            }}
          />
          {t("index.approved_documents")}
        </div>
      ) : (
        <div>
          <img
            src={carImagesIndex.not_uploaded}
            style={{ margin: "0 6px 2px 0", height: "unset", width: "unset" }}
          />
          {`${countProblems} ${
            countProblems === 1
              ? t("index.problem_in_document")
              : t("index.problems_in_document")
          }
          `}
        </div>
      )}
    </>
  );
};

export default CarStatusInfo;
