import { CircularProgress } from "@mui/material";
import "./styles.scss";

const Loading = ({ height, color }: { height?: string; color?: string }) => {
  return (
    <div
      className="loading-container"
      style={{
        minHeight: height || "50vh",
      }}
    >
      <CircularProgress
        className="progress"
        style={{
          color,
        }}
      />
    </div>
  );
};

export default Loading;
