import { useEffect } from "react";
import { getDestination } from "../lib/helpers/mapsActions";
import { CompleteCordinates, Destination } from "../models/destination/types";
import { FormikErrors } from "formik";
import { homeFilter } from "../components/navHomeFilter/form";

interface UseDestinationProps {
  location: CompleteCordinates;
  destinations: Destination[] | null | undefined;
  setFieldValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<homeFilter>>;
}

export const useDestination = ({
  location,
  destinations,
  setFieldValue,
}: UseDestinationProps) => {
  useEffect(() => {
    if (location && destinations) {
      const { latitud: lat, longitud: lng } = location;
      const hasDestination = getDestination({
        destinations,
        point: { lat, lng },
      });

      // Is an address in destination
      if (hasDestination.length) {
        setFieldValue("destinationId", hasDestination[0].id);
      }
    }
  }, [location, destinations]);
};
