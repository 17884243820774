import { useEffect, useState, useMemo, useCallback } from "react";
import { UploadDropzone } from "react-uploader";
import uploader, { deleteFile, uploadioEnv } from "../../services/fileUploader";
import { getUserId } from "../../services/localstorage";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import authenticationImages from "../../assets/images/authentication";
import "./styles.scss";

const imageTypes = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "image/heic",
  "image/heif",
];

const docTypes = ["application/pdf"];

interface Props {
  /** delete the uploaded file */
  showRemove?: boolean;
  allow?: "image" | "file" | "file,image";

  /** To edit the image */
  crop?: boolean;

  /** File destination */
  path: {
    folderName?: string;
    fileName: string;
  };

  /** custom sizes */
  width?: string;
  height?: string;

  /** preloaded file */
  fileDetails?: {
    url: string;
    type: string;
  };

  /** when file is uploaded */
  onUpload?: (fileUrl: string, fileMime: string) => void;

  /** when file is removed */
  onDelete?: () => void;

  deletedFile?: boolean;
}

export default function UploadDropZone({
  showRemove,
  allow,
  crop,
  path,
  width,
  height,
  fileDetails,
  onUpload,
  onDelete,
  deletedFile,
}: Props) {
  const userId = getUserId();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileMimeType, setFileMimeType] = useState<string>("");

  const { t } = useTranslation(["fileUploader"]);

  const customLocale = useMemo(() => {
    return {
      uploadImage: t("uploadImage"),
      uploadImages: t("uploadImages"),
      uploadFile: `+  ${t("uploadFile")}`,
      uploadFiles: t("uploadFiles"),
      addAnotherFile: t("addAnotherFile"),
      addAnotherImage: t("addAnotherImage"),
      orDragDropFile: "",
      orDragDropFiles: t("orDragDropFiles"),
      orDragDropImage: t("orDragDropImage"),
      orDragDropImages: t("orDragDropImages"),
      maxFilesReached: t("maxFilesReached"),
      maxImagesReached: t("maxImagesReached"),
      unsupportedFileType: t("unsupportedFileType"),
      maxSize: t("maxSize"),
      "error!": t("error"),
      "cancelled!": t("cancelled"),
      "removed!": t("removed"),
      pleaseWait: t("pleaseWait"),
      crop: t("crop"),
      finish: t("finish"),
      next: t("next"),
      skip: t("skip"),
      continue: t("continue"),
      cancel: t("cancel"),
      done: t("done"),
      finishIcon: true,
      of: "of",
      image: t("image"),
      remove: t("remove"),
    };
  }, []);

  // When delete
  const postDelete = useCallback(() => {
    setFileUrl("");
    setFileMimeType("");
    onDelete && onDelete();
  }, [onDelete]);

  // set file url from params
  useEffect(() => {
    setFileUrl(fileDetails?.url || "");
    setFileMimeType(fileDetails?.type || "");
  }, [fileDetails]);

  useEffect(() => {
    if (deletedFile) {
      setFileUrl("");
      setFileMimeType("");
    }
  }, [deletedFile]);

  // If a file is uploaded and it is an image
  if (fileUrl && fileMimeType.includes("image")) {
    return (
      <div className="dz-uploaded">
        <img
          className="image-preview-uploaded"
          src={fileUrl}
          alt={path.fileName}
        />
        {showRemove && <RemoveDialog path={path} callback={postDelete} />}
        <br />
      </div>
    );
  }

  // If a file is uploaded and it is a pdf
  if (fileUrl && fileMimeType.includes("pdf")) {
    return (
      <div className="dz-uploaded">
        <iframe src={fileUrl} height="300px" width="100%" />
        {showRemove && <RemoveDialog path={path} callback={postDelete} />}
        <br />
      </div>
    );
  }

  // else show file upload dropzone
  return (
    <UploadDropzone
      uploader={uploader}
      options={{
        showRemoveButton: true,
        locale: customLocale || undefined,
        mimeTypes:
          allow === "image"
            ? imageTypes
            : allow === "file"
            ? docTypes
            : allow === "file,image"
            ? [...imageTypes, ...docTypes]
            : undefined,
        editor: {
          images: {
            crop: Boolean(crop),
          },
        },
        path: {
          fileName: `${path.fileName}`,
          folderPath: `user-${userId}${path.folderName || ""}`,
        },
      }}
      onUpdate={(response) => {
        // always the first file (because multi is disabled)
        if (onUpload) {
          onUpload(
            response[0].fileUrl + "?version=" + new Date().toISOString(),
            response[0].originalFile.mime
          );
        } else {
          setFileUrl(
            response[0].fileUrl + "?version=" + new Date().toISOString()
          );
          setFileMimeType(response[0].originalFile.mime);
        }
      }}
      width={width || "400px"}
      height={height || "200px"}
    />
  );
}

interface removeDialogProps {
  path: {
    folderName?: string;
    fileName: string;
  };
  callback: () => void;
}

export const RemoveDialog = ({ path, callback }: removeDialogProps) => {
  const userId = getUserId();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["fileUploader"]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <img
        className="btn-remove"
        data-testid="btn-remove-document"
        src={authenticationImages.deleteImage}
        alt=""
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ padding: "16px" }}
      >
        <DialogTitle id="alert-dialog-title">
          {t("alertRemoveFile")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("cantUndoneRemoveFile")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ margin: "8px 16px" }}>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button
            onClick={async () => {
              try {
                await deleteFile(
                  `/${uploadioEnv}/user-${userId}${path.folderName || ""}/${
                    path.fileName
                  }`
                );
                callback();
              } catch (error) {
                console.error("Error deleting file", error);
              }
            }}
            autoFocus
          >
            {t("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
