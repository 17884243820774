import accident from "./accident.png";
import arrowRightBlue from "./arrow_right_blue.png";
import anfitrionComercial from "./anfitrion_comercial.png";
import anfitrionRegular from "./anfitrion_regular.svg";
import articleBackgroundDes from "./article_background_des.png";
import articleBackground from "./article_background.png";
import ask from "./ask.png";
import astronaut from "./astronaut.png";
import checkInsurance from "./check_insurance.svg";
import error404 from "./error404.png";
import kyc_success from "./kyc_success.png";
import helpBackgroundDes from "./help_background_des.png";
import helpBackground from "./help_background.png";
import huesped from "./huesped.png";
import insurance from "./insurance.png";
import min from "./min.svg";
import plus from "./plus.svg";
import responsabilities from "./responsabilities.png";
import responsabilitiesBanner from "./responsibilities_banner.png";
import security from "./security.png";
import start from "./start.png";
import trusted from "./trusted.png";
import underConstruction from "./under_construction.png";
import whatsapp from "./whatsapp.svg";

export default {
  accident,
  arrowRightBlue,
  anfitrionComercial,
  anfitrionRegular,
  articleBackgroundDes,
  articleBackground,
  ask,
  astronaut,
  checkInsurance,
  error404,
  kyc_success,
  helpBackgroundDes,
  helpBackground,
  huesped,
  insurance,
  min,
  plus,
  responsabilities,
  responsabilitiesBanner,
  security,
  start,
  trusted,
  underConstruction,
  whatsapp,
};
