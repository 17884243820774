import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "./CarCarousel.scss";
import { useQuery } from "react-query";
import { useContext } from "react";
import { ShowContext, imageUpload } from "../../../../views/car/show/types";
import { listFiles, uploadioEnv } from "../../../../services/fileUploader";
import { Skeleton } from "@mui/material";
import a_carsImages from "../../../../assets/images/a_cars";
import { isCarImage } from "../../../helpers/carHandler";

export const CarCarousel = () => {
  const { car } = useContext(ShowContext);

  const {
    data: carImages,
    isError,
    isLoading,
  } = useQuery(
    ["images", car?.id],
    () => listFiles(`/${uploadioEnv}/user-${car?.userId}/car-${car?.id}`),
    { enabled: !!car }
  );

  // On loading show skeleton
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        className="skeleton"
        data-testid="carousel"
      />
    );
  }

  // On error show default image
  if (!carImages || isError || (carImages && carImages.items.length === 0)) {
    return (
      <div className="show-car-not-found">
        <img src={a_carsImages.searchCar} data-testid="carousel" />
      </div>
    );
  }

  return (
    <article className="show-car-carousel" data-testid="carousel">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        navigation
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {carImages.items.map((carImage: imageUpload, index: number) => {
          return (
            isCarImage(carImage.filePath) && (
              <SwiperSlide
                style={{ maxHeight: "600px" }}
                key={carImage.lastModified + index}
              >
                <img src={carImage.fileUrl} alt="" className="carousel-image" />
              </SwiperSlide>
            )
          );
        })}
      </Swiper>
    </article>
  );
};
