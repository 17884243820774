import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/i18n/locales/{{ns}}/{{lng}}.json",
    },
    preload: ["es", "en"],
    supportedLngs: ["en", "es"],
    ns: [
      "home",
      "footer",
      "signup",
      "navbar",
      "completeProfile",
      "verification",
      "profile",
      "driverReservation",
      "results",
      "car",
      "hostHome",
      "hostReservation",
      "commercialSignup",
      "commercialHome",
      "routes",
      "modals",
      "forgotPassword",
      "help",
    ],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
