import * as Yup from "yup";
import { TripForm } from "./types";

export const tripSchema: Yup.SchemaOf<TripForm> = Yup.object().shape({
  pickUpPrice: Yup.number().required(),
  pickUpAddressId: Yup.number().required(),
  pickUpType: Yup.number().required(),
  pickUpAddress: Yup.object().test(function () {
    if (
      this.parent.pickUpType == 3 ||
      (Object.keys(this.parent.pickUpAddress).length == 0 &&
        this.parent.pickUpType == 2)
    )
      return false;

    return true;
  }),
  returnPrice: Yup.number().required(),
  returnAddressId: Yup.number().required(),
  returnType: Yup.number().required(),
  returnAddress: Yup.object().test(function () {
    if (
      this.parent.returnType == 3 ||
      (Object.keys(this.parent.returnAddress).length == 0 &&
        this.parent.returnType == 2)
    )
      return false;

    return true;
  }),
  pickUpDate: Yup.object()
    .shape({})
    .required("La fecha de inicio es requerida"),
  pickUpTime: Yup.string()
    .required("La hora de inicio es requerida")
    .test("every-30", "Selecciona ej: '7:00' o '7:30'", function (value) {
      const data = value?.split(":");
      if (!data) return false;
      return data[1] == "30" || data[1] == "00";
    }),
  returnDate: Yup.object()
    .shape({})
    .required("La fecha de termino es requerida"),
  returnTime: Yup.string()
    .required("La hora de termino es requerida")
    .test("every-30", "Selecciona ej: '7:00' o '7:30'", function (value) {
      const data = value?.split(":");
      if (!data) return false;
      return data[1] == "30" || data[1] == "00";
    }),
});
