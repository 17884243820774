import { Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import navbarImages from "../../../assets/images/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import { Card, CardImgIndex } from "../../../models/card/types";
import { getRegisteredCards, setDefaultCard } from "../../../services/card";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import carImages from "../../../assets/images/car";
import { getNewCarPayDetails } from "../../../services/payment";
import { SelectedPlan } from "../../../services/payment";
import { CircularProgress, Chip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
export interface ModalPropsType {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setOpenAdd: (open: boolean) => void;
  setSelectedCard: (cardId: Card) => void;
  paymentIntentId?: string;
}

interface CardsPaymentDetails {
  brand: string;
  funding: string;
  id: string;
  last4: string;
  isDefault: boolean;
  expirationDate: string;
  plans: SelectedPlan[];
}

export const ChangeCardModal: FC<ModalPropsType> = ({
  isOpen,
  setOpen,
  setOpenAdd,
  setSelectedCard,
  paymentIntentId,
}) => {
  const { data: cards, isLoading } = useQuery(["cards"], getRegisteredCards, {
    enabled: isOpen,
  });
  const [search] = useSearchParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["profile"]);
  const [cardsData, setCardsData] = useState<CardsPaymentDetails[]>([]);

  // Open add new card modal
  const handleAddCard = () => {
    setOpenAdd(true);
    setOpen(false);
  };

  // Select card to pay
  const selectCard = async (cardData: Card) => {
    await setDefaultCard(cardData.id);
    setSelectedCard(cardData);
    setOpen(false);
    queryClient.invalidateQueries(["cards"]);
  };

  // Get cards available plans
  const getCardPaymentDetails = async () => {
    const paymentId = search.get("payment_intent");
    if (!cards || !paymentIntentId || paymentId) return;

    const list: CardsPaymentDetails[] = [];
    await Promise.all(
      await cards.cards.map(async (item: Card) => {
        const response = await getNewCarPayDetails(paymentIntentId, item.id);
        if (response) {
          list.push({ ...item, plans: response.plans });
        }
      })
    );
    setCardsData(list);
  };

  useEffect(() => {
    !!cards && !!paymentIntentId && getCardPaymentDetails();
  }, [cards, paymentIntentId]);

  const carImagesIndex = carImages as CardImgIndex;

  return (
    <Modal show={isOpen} className="cards-modal d-flex align-items-center">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setOpen(false)}
          >
            <img src={navbarImages.cross} alt="close" />
          </button>
          <h6>{t("profile_boxes.pay_method_modals.select_card")}</h6>
        </div>
        <div className="modal-body">
          {!isLoading ? (
            cardsData && cardsData.length > 0 ? (
              cardsData.map((item: CardsPaymentDetails) => (
                <div className="card-item" key={item.id}>
                  <div className="card-data-container">
                    <div className="card-data">
                      <img
                        className="img-brand"
                        src={carImagesIndex[item.brand] || carImages.generic}
                      />
                      <p className="card-num">**** **** **** {item.last4}</p>
                      {item.isDefault && (
                        <Chip
                          label="Default"
                          variant="outlined"
                          sx={{
                            color: "#2289e5",
                            backgroundColor: "#d6e8fa",
                            border: "none",
                          }}
                        />
                      )}
                    </div>
                    <p className="card-exp">Vence {item.expirationDate}</p>
                    <div className="card-plans-alert">
                      {item.plans.length > 0 && (
                        <p>
                          {t(
                            "profile_boxes.pay_method_modals.pay_option_available"
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-select"
                    onClick={() => selectCard(item)}
                  >
                    {t("profile_boxes.pay_method_modals.select")}
                  </button>
                </div>
              ))
            ) : (
              <div>
                <p>{t("profile_boxes.pay_method_modals.no_card_found")}</p>
              </div>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className="modal-footer">
          <button className="btn btn-blue" onClick={handleAddCard}>
            {t("profile_boxes.pay_method_modals.add_new")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
