import { AxiosError } from "axios";
import { axiosService } from "./axios";

export const sendRecoverPassword = async (email: string) => {
  try {
    const response = await axiosService.post("/account/email", {
      email,
    });

    return response;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message) {
      return error;
    }
  }
};

interface UpdatePasswordProps {
  newPassword: string;
  token: string;
}

export const updatePassword = async (updatePassword: UpdatePasswordProps) => {
  try {
    const response = await axiosService.post(
      `/account/reset/`,
      { newPassword: updatePassword.newPassword },
      {
        headers: {
          Authorization: `Bearer ${updatePassword.token}`,
        },
      }
    );

    return response;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message) {
      return error;
    }
  }
};
