import { SelectChangeEvent } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { CarForm } from "../../../../views/car/add/types";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { carSpecifications } from "../../../../models/car/dtos";
import { MockCarSpecs } from "../../../../models/car/mockups";
import {
  getBrand,
  getType,
  getVersion,
  getYear,
} from "../../../../services/car";
import { useQuery } from "react-query";

export const useAddCarModel = () => {
  const t = useContext(TranslationContext);
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const [carSpecs, setCarSpecs] = useState<carSpecifications>(MockCarSpecs);

  const { data: brands, isSuccess: brandsSuccess } = useQuery(
    ["brands"],
    getBrand
  );

  const { data: types, isSuccess: typesSuccess } = useQuery(
    ["types", carSpecs.carBrand],
    () => getType(carSpecs),
    {
      enabled: !!carSpecs.carBrand,
    }
  );

  const { data: years, isSuccess: yearsSuccess } = useQuery(
    ["years", carSpecs.carType],
    () => getYear(carSpecs),
    {
      enabled: !!carSpecs.carType,
    }
  );

  const { data: versions, isSuccess: versionsSuccess } = useQuery(
    ["versions", carSpecs.carYear],
    () => getVersion(carSpecs),
    {
      enabled: !!carSpecs.carYear,
    }
  );

  useEffect(() => {
    // Set values of car in state variable
    if (values.brand !== "") {
      setCarSpecs({
        carBrand: values.brand,
        carType: values.model,
        carVersion: values.version,
        carYear: values.year,
      });
    }
  }, [values]);

  const handleCarSpecifications = (
    event: SelectChangeEvent<string>,
    spec: string
  ) => {
    // Set new values on form
    if (spec == "type") {
      setFieldValue("model", event.target.value);
    } else {
      setFieldValue(spec, event.target.value);
    }

    // Format json variable with new car data
    const specFormated =
      "car" + spec.charAt(0).toUpperCase() + spec.substring(1);
    setCarSpecs({ ...carSpecs, [specFormated]: event.target.value });
  };

  return {
    t,
    brandsSuccess,
    values,
    handleCarSpecifications,
    brands,
    types,
    typesSuccess,
    years,
    yearsSuccess,
    versions,
    versionsSuccess,
  };
};
