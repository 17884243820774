import { RefObject, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getAllAddressComponents } from "../../../helpers/mapsActions";
import {
  getAddressesByCarID,
  updateAddress,
} from "../../../../services/address";
import { getCar, updateCar } from "../../../../services/car";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { Address, AddressType } from "../../../../models/address/types";
import { useFormik } from "formik";
import { editModelForm, editModelSchema } from "./form";
import { placeComponentCrash } from "../../../../views/home/types";
import Geocode from "react-geocode";
import { msgProps } from "../editCarPrice/types";
import { formatAddressCommercialName } from "../../../helpers/addressHandler";

export const useEditCarModel = () => {
  const t = useContext(TranslationContext);
  const { carId } = useParams();
  const [principal, setPrincipal] = useState<string>("");
  const [mainAddress, setMainAddress] = useState<Address>();
  const [homeDeliveryAddress, setHomeDeliveryAddress] = useState<Address>();
  const [msg, setMsg] = useState<msgProps>({
    text: "",
    type: "error",
  });
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { data: car, isSuccess: carSuccess } = useQuery(
    ["car", carId],
    () => getCar(carId),
    {
      enabled: !!carId,
    }
  );

  const { data: addressesData } = useQuery(
    ["addresses", car?.id],
    () => getAddressesByCarID(car?.id),
    {
      enabled: carSuccess,
    }
  );

  // Set principal address
  const handlePrincipalAddress = (addressesData: Address[]) => {
    addressesData.map((address: Address) => {
      if (address.type == AddressType.MAIN_ADDRESS) {
        setMainAddress(address);

        // Extract address components to send address information
        formikEditModel.setFieldValue("mainAddress", {
          address_components: [
            {
              long_name: address.city,
              short_name: address.city[0],
              types: ["city"],
            },
          ],
          formatted_address: address.completeAddress,
          geometry: {
            location: {
              lat: address.latitude,
              lng: address.longitude,
            },
          },
          place_id: address.placeId,
          name: address.comercialName,
        });

        // Set input comercial name
        setPrincipal(address.comercialName);
      }

      // Set home delivery address
      if (address.type === AddressType.HOME_DELIVERY_ADDRESS) {
        setHomeDeliveryAddress(address);
      }
    });
  };

  // When address data is get call function
  useEffect(() => {
    if (addressesData && addressesData.length !== 0) {
      handlePrincipalAddress(addressesData);
    }
  }, [addressesData]);

  // When get car information set fields
  useEffect(() => {
    formikEditModel.setFieldValue("numBags", car?.numBags);
    formikEditModel.setFieldValue("numSeats", car?.numSeats);
    formikEditModel.setFieldValue("experiences", car?.experiences);
    formikEditModel.setFieldValue("transmission", car?.transmission);
    formikEditModel.setFieldValue("type", car?.type);
    formikEditModel.setFieldValue("plate", car?.plate);
    formikEditModel.setFieldValue("isEcoFriendly", car?.isEcoFriendly);
  }, [car]);

  // Default zoom props
  const defaultProps = {
    center: {
      lat: 20.706165599669397,
      lng: -103.40390591631164,
    },
    zoom: 10,
  };

  // When save new model send requests
  const fetchEditModel = async (values: editModelForm) => {
    try {
      let response, homeResponse;
      if (!car?.id) return;

      // Update just editable car data
      const responseCar = await updateCar(car.id, {
        experiences: values.experiences,
        transmission: values.transmission,
        type: values.type,
        plate: values.plate,
        isEcoFriendly: values.isEcoFriendly,
        numBags: values.numBags,
        numSeats: values.numSeats,
      });

      // Pass if main address change from previous one
      if (values.mainAddress?.place_id !== mainAddress?.placeId) {
        // Get address items
        const addressItem = getAllAddressComponents(
          values.mainAddress.address_components
        );

        if (!addressItem || !mainAddress || !homeDeliveryAddress) return;

        const city = addressItem.locality || "";
        const state = addressItem.administrative_area_level_1 || "";
        const country = addressItem.country || "";
        const zipCode = addressItem.postal_code || "";
        const name = values.mainAddress?.name || "";

        // Create commercial address format
        const comercialName = formatAddressCommercialName({
          name,
          zipCode,
          country,
          state,
          city,
        });

        // Join new address data
        const newAddress = {
          carId: car.id,
          comercialName,
          completeAddress: values.mainAddress?.formatted_address || "",
          intNumber: "0",
          number: addressItem.street_number || "0",
          street: addressItem.route || "",
          suburb: addressItem.sublocality_level_1 || "",
          city,
          state,
          country,
          zipCode,
          latitude:
            values.mainAddress?.geometry?.location?.lat?.toString() || "0",
          longitude:
            values.mainAddress?.geometry?.location?.lng?.toString() || "0",
          placeId: values.mainAddress?.place_id || "0",
        };

        // Update main address and homedelivery (which is the same address)
        response = await updateAddress(mainAddress.id, {
          ...newAddress,
          status: mainAddress.status,
          type: AddressType.MAIN_ADDRESS,
        });

        homeResponse = await updateAddress(homeDeliveryAddress.id, {
          ...newAddress,
          status: homeDeliveryAddress.status,
          type: AddressType.HOME_DELIVERY_ADDRESS,
        });
      }

      // Update address information when request has status 200
      if (
        response?.status === 200 &&
        homeResponse?.status === 200 &&
        responseCar?.status == 200
      ) {
        queryClient.invalidateQueries(["car", "addresses"]);
      }
      setMsg({
        text: t("edit.price_disponibility.success.update_car"),
        type: "success",
      });
    } catch (error) {
      setMsg({
        text: t("edit.price_disponibility.errors.update_car"),
        type: "error",
      });
    }
    setOpenAlert(true);
  };

  // Initial form
  const formikEditModel = useFormik<editModelForm>({
    initialValues: {
      mainAddress: {
        address_components: [
          {
            long_name: "",
            short_name: "",
            types: ["", ""],
          },
        ],
        formatted_address: "",
        geometry: {
          location: {
            lat: "",
            lng: "",
          },
          viewport: "",
        },
        html_attributions: ["", ""],
        place_id: "",
        name: "",
      },
      transmission: 0,
      type: 0,
      plate: "",
      isEcoFriendly: 0,
      numSeats: 0,
      numBags: 0,
      experiences: "",
    },
    validationSchema: editModelSchema,
    onSubmit: fetchEditModel,
  });

  // Set address data structure
  const handleAddressFormat = (place: placeComponentCrash) => {
    formikEditModel.setFieldValue("mainAddress", {
      ...place,
      geometry: {
        ...place.geometry,
        location: {
          lat: place.geometry.location.lat().toString(),
          lng: place.geometry.location.lng().toString(),
        },
      },
    });
  };

  // Get current location
  const handleCurrentLocation = ({
    autocompleteInput,
  }: {
    autocompleteInput: RefObject<HTMLInputElement>;
  }) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS || "");
      Geocode.fromLatLng(
        position.coords.latitude.toString(),
        position.coords.longitude.toString()
      ).then(
        (response) => {
          const firstResult = response.results[0];
          const res = {
            ...firstResult,
            name: firstResult.formatted_address,
            geometry: { ...firstResult.geometry, viewport: "" },
          };
          formikEditModel.setFieldValue("mainAddress", res);
          setPrincipal(firstResult.formatted_address);
          if (autocompleteInput.current) {
            autocompleteInput.current.value = firstResult.formatted_address;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  return {
    t,
    msg,
    openAlert,
    setOpenAlert,
    car,
    formikEditModel,
    handleCurrentLocation,
    defaultProps,
    handleAddressFormat,
    principal,
  };
};
