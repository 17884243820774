import { Fragment } from "react";
import navbarImages from "../../assets/images/navbar/index";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import "./StickySidebar.scss";
import { useAdminRoutes } from "../../hooks/useAdminRoutes";
import { Chip } from "@mui/material";

export const StickySidebar = () => {
  const { t } = useTranslation(["navbar", "routes"]);

  const {
    activeSection,
    activeDropdown,
    menu,
    selectedSection,
    image,
    setShowSideBar,
  } = useAdminRoutes();

  return (
    <>
      <div className="sticky-sidebar">
        <div className="sticky-sidebar__header">
          <MenuIcon
            ref={image}
            onClick={() => setShowSideBar((prev) => !prev)}
            className="sticky-sidebar__button__image"
          />
          {menu.map((section) => (
            <Fragment key={section.title}>
              {section.title === "settings" && (
                <div className="dropdown-divider"></div>
              )}
              <div
                className={`sticky-sidebar__icons__container ${
                  section.active ? "active" : ""
                }`}
                onClick={() => activeSection(section)}
              >
                <img
                  className="sticky-sidebar__icons"
                  src={section.image}
                  alt="home"
                />
              </div>
            </Fragment>
          ))}
        </div>
        <div className="sticky-sidebar__footer">
          <img
            className="sticky-sidebar__icons"
            src={navbarImages.adminHelp}
            alt="help"
          />
        </div>
      </div>
      {selectedSection.open && selectedSection.title !== "home" && (
        <>
          <div className="sticky-menu">
            <div className="sticky-menu__section_button">
              <h3>{t("navbar:admin_sidebar." + selectedSection.title)}</h3>
              <div className="sticky-menu__buttons__container">
                {selectedSection.routes.map((item) => {
                  if (!item.subroute) {
                    return (
                      <Link
                        key={item.name}
                        to={item.route}
                        className={`sticky-menu__button ${
                          item.active ? "active" : ""
                        }`}
                      >
                        <div className="sticky-menu__button__content">
                          {t("navbar:admin_sidebar.routes." + item.name)}
                          {item.workInProgress && (
                            <Chip
                              label={t("navbar:admin_sidebar.work_in_progress")}
                              className="sticky-menu__status"
                            />
                          )}
                        </div>
                      </Link>
                    );
                  } else {
                    return (
                      <Fragment key={item.name}>
                        <a
                          className={`sticky-menu__button ${
                            item.active ? "active" : ""
                          }`}
                          onClick={() =>
                            activeDropdown(selectedSection, item.name)
                          }
                        >
                          <div className="sticky-menu__button__content">
                            {t("navbar:admin_sidebar.routes." + item.name)}
                            {item.workInProgress && (
                              <Chip
                                label={t(
                                  "navbar:admin_sidebar.work_in_progress"
                                )}
                                className="sticky-menu__status"
                              />
                            )}
                          </div>
                          {item.icon && (
                            <img
                              src={item.icon}
                              alt=""
                              className={`${item.active ? "turn" : ""}`}
                            />
                          )}
                        </a>
                        {item.active &&
                          item.subroute?.map((subroute) => (
                            <div
                              className="sticky-menu__subroute__container"
                              key={subroute.name}
                            >
                              {subroute.active && (
                                <div
                                  className={`sticky-menu__button__list`}
                                ></div>
                              )}
                              {subroute.isExternal ? (
                                <a
                                  href={subroute.route}
                                  key={subroute.name}
                                  target="_blank"
                                  className="sticky-menu__subroute"
                                >
                                  {t(
                                    "navbar:admin_sidebar.routes." +
                                      subroute.name
                                  )}
                                  {subroute.icon && (
                                    <img src={subroute.icon} alt="" />
                                  )}
                                </a>
                              ) : (
                                <Link
                                  to={subroute.route}
                                  key={subroute.name}
                                  className="sticky-menu__subroute"
                                >
                                  {t(
                                    "navbar:admin_sidebar.routes." +
                                      subroute.name
                                  )}
                                  {item.workInProgress && (
                                    <Chip
                                      label={t(
                                        "navbar:admin_sidebar.work_in_progress"
                                      )}
                                      className="sticky-menu__status"
                                    />
                                  )}
                                  {subroute.icon && (
                                    <img src={subroute.icon} alt="" />
                                  )}
                                </Link>
                              )}
                            </div>
                          ))}
                      </Fragment>
                    );
                  }
                })}
              </div>
            </div>
            <img
              src={navbarImages.powerLogo}
              alt="power logo"
              className="power"
            />
          </div>
          <div
            className="black-background"
            onClick={() => setShowSideBar(false)}
          ></div>
        </>
      )}
    </>
  );
};
