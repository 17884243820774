import { FC, useContext, useEffect, useRef } from "react";
import { User, UserType } from "../../../../models/user/types";
import { useTranslation } from "react-i18next";
import { handleClickOutside } from "../../../helpers/dropdownBoxActions";
import {
  CardBecomeHost,
  ProfileLinker,
  UserTypeToggle,
} from "../profileAction/ProfileAction";
import navbarImages from "../../../../assets/images/navbar/index";
import { DomainContext } from "../../../../providers/domainProvider";
import { AuthContext } from "../../../../providers/authProvider";
interface DropdownMenuProps {
  user: User;
  actionClick: () => void;
}

export const DropDownMenu: FC<DropdownMenuProps> = ({ user, actionClick }) => {
  const { settings } = useContext(DomainContext);
  const { t } = useTranslation(["navbar", "routes"]);
  const auth = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && ref.current?.style.display === "block") {
      document.addEventListener(
        "click",
        (event) => {
          if (handleClickOutside(ref, event)) actionClick();
        },
        true
      );
    }
  }, [ref.current]);

  return (
    <div
      id="user-menu2"
      ref={ref}
      style={{ display: "block" }}
      className="dropdown-menu dropdown-menu-right"
    >
      <img className="triangle-menu-right" src={navbarImages.triangle} alt="" />
      {user.type === UserType.DRIVER ? (
        <>
          <ProfileLinker user={user} data-testid="profile" />
          {settings.routes.homeHost && <CardBecomeHost />}
        </>
      ) : (
        <>
          <ProfileLinker user={user} data-testid="profile" />
          <UserTypeToggle data-testid="host-portal" />
        </>
      )}
      <button
        className="btn logout-driver"
        data-testid="logout-driver"
        onClick={auth.logout}
      >
        {t("drop_down_menu.driver.logout")}
      </button>
    </div>
  );
};
