import { createContext } from "react";
import { HostSetting } from "../models/hostSetting/types";
import { getHostSettings } from "../services/hostSettings";
import { useQuery } from "react-query";
import Loading from "../components/loading/component";

export const DomainContext = createContext<HostSetting>({
  id: 0,
  domain: "",
  hostComercialId: 0,
  settings: {
    name: "",
    routes: {},
    social: {},
    payment: { applicationFee: "30", stripeAccount: "" },
  },
});

export const DomainProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const location =
    window.location.origin === "http://localhost:3000"
      ? "https://powerbytest.romio.mx"
      : window.location.origin;

  const { data: hostComercialSettings } = useQuery(
    ["hostComercialSettings"],
    () => getHostSettings(location)
  );

  if (!hostComercialSettings) {
    return <Loading />;
  }

  return (
    <DomainContext.Provider value={hostComercialSettings}>
      {children}
    </DomainContext.Provider>
  );
};
