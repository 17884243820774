import Loading from "../components/loading/component";
import { useLanguage } from "../hooks/language";
import i18n from "../i18n";

export const LanguageProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  useLanguage();
  if (!i18n.language) {
    return <Loading />;
  }
  return <div className="position-relative">{children}</div>;
};
