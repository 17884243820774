import { PasswordUpdateDTO } from "./../components/modals/changePassword/component";
import { getToken } from "./localstorage";
import {
  CompleteUserDTO,
  UpdateUserDTO,
  CreateUserWithRelations,
} from "./../models/user/dtos";
import { axiosService } from "./axios";
import { User, UserAccountStatus, UserType } from "./../models/user/types";
import { UserInsurance } from "../models/userInsurance/types";

export const getUser = async (): Promise<User | undefined> => {
  try {
    const response = await axiosService.get<User>(`/account/user/`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserByID = async (
  id: number | undefined
): Promise<User | undefined> => {
  try {
    const response = await axiosService.get<User>(`/account/user/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createUser = async (UserDTO: CreateUserWithRelations) => {
  try {
    const response = await axiosService.post<User>(
      "/account/register/",
      UserDTO
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (UserDTO: CompleteUserDTO) => {
  try {
    const data = await axiosService.put<CompleteUserDTO>(
      "/account/register/update",
      UserDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserCompleted = async (UserDTO: UpdateUserDTO) => {
  try {
    const data = await axiosService.patch<UpdateUserDTO>(
      `/account/update`,
      UserDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCompleteUser = async (UserDTO: UpdateUserDTO) => {
  try {
    const data = await axiosService.put<User>(
      "/account/register/update",
      UserDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserStatus = async (status: UserAccountStatus) => {
  try {
    const response = await axiosService.patch(
      `/account/updatestatus`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserEmail = async (email: string) => {
  try {
    const response = await axiosService.patch(
      `/account/updateemail`,
      { email: email },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateCodeEmail = async () => {
  try {
    const response = await axiosService.patch(
      `/account/updatecodeemail`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateCodePhone = async () => {
  try {
    const response = await axiosService.get(`/message/newcode`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserPhone = async (phone: string) => {
  try {
    const response = await axiosService.patch(
      `/account/updatephone`,
      { phone: phone },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserPassword = async (
  passwordUpdateDTO: PasswordUpdateDTO
) => {
  try {
    const response = await axiosService.patch(
      `/account/updatepassword`,
      passwordUpdateDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = async (id: string, index: number) => {
  try {
    const data = await axiosService.delete<User>(`/user/${id}`);
    return { data, index };
  } catch (error) {
    console.error(error);
  }
};

export const updateUserType = async (type: UserType) => {
  try {
    const response = await axiosService.patch(
      `/account/updatetype`,
      { type: type.toString() },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserInsuranceData = async () => {
  try {
    const response = await axiosService.get<UserInsurance>(`/userinsurance`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};
