import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import modalsImages from "../../../assets/images/modals";
import "./TripCompleteProfile.scss";
import { useWindowSize } from "../../../hooks/windowSize";

export const TripCompleteProfile = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation(["modals"]);
  const windowSize = useWindowSize();

  return (
    <Modal show={isOpen} className="trip-complete-profile-modal">
      <div className="modal-header">
        <h2 id="change_email_modal_text">{t("trip_complete_profile.title")}</h2>
      </div>
      <div className="modal-body">
        {windowSize > 991 && (
          <img
            src={modalsImages.tripCompleteProfile}
            className="complete-profile-image"
          />
        )}
        <p className="information">{t("trip_complete_profile.incomplete")}</p>
      </div>
      <div className="modal-footer">
        <Link
          className="btn btn-orange"
          to={"/" + t("profile", { ns: "routes" })}
        >
          {t("trip_complete_profile.complete_data")}
        </Link>
      </div>
    </Modal>
  );
};
