export interface Dates {
  id: number;
  carId: number;
  status: DateStatus;
  dateInitial: string;
  dateEnd: string;
}

export enum DateStatus {
  LOCKED_BY_HOST,
  LOCKED_BY_RESERVATION,
}
