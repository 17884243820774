import { Trans } from "react-i18next";
import authenticationImages from "../../assets/images/authentication/index";
import "./Signup.scss";
import Toast from "../../components/toast/component";
import { useProgressBar } from "../../hooks/progress";
import { Helmet } from "react-helmet";
import { useSignup } from "../../hooks/signup/useSignup";
import { useContext } from "react";
import { DomainContext } from "../../providers/domainProvider";

export const SignUp = () => {
  const { settings } = useContext(DomainContext);
  const {
    t,
    formikSignup,
    openAlert,
    setOpenAlert,
    errorMsg,
    password,
    setPassword,
    setConfirmPassword,
    confirmPassword,
  } = useSignup();

  const { setPasswordStrengh, getActiveColor, message, progress } =
    useProgressBar();

  const styles = {
    signupButton: {
      backgroundColor:
        formikSignup.isValid && formikSignup.dirty ? "#f76f8e" : "#828282",
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta property="og:title" content={t("seo.og_title")} />
        <meta property="og:description" content={t("seo.og_description")} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://romio.mx/crear-cuenta" />
        <meta property="og:url" content="https://romio.mx/crear-cuenta" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.og_locale")} />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/crear-cuenta"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/crear-cuenta"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/crear-cuenta"
        />
      </Helmet>
      <div id="login">
        <Toast open={openAlert} setOpen={setOpenAlert} type="error">
          {errorMsg}
        </Toast>
        <article className="signup-container">
          <div className="join-container">
            <div className="title-container">
              <h1 className="title" data-testid="sign-up-title">
                {t("join", { name: settings.name })}
              </h1>
              <p data-testid="slogan">
                <Trans i18nKey="slogan">{t("slogan")}</Trans>
              </p>
            </div>
            <img src={authenticationImages.signup} alt="" />
          </div>
          <div className="signup-form" id="signup_form">
            <form>
              <div className="input-form">
                <label data-testid="email-label">
                  {t("create_account_form.email.label")}
                </label>
                <input
                  name="email"
                  id="txtEmail"
                  data-testid="email-input"
                  style={{
                    borderColor:
                      formikSignup.errors.email && formikSignup.touched.email
                        ? "#FF0000"
                        : "#828282",
                  }}
                  placeholder={t("create_account_form.email.placeholder")}
                  onChange={formikSignup.handleChange("email")}
                  onBlur={formikSignup.handleBlur("email")}
                  value={formikSignup.values.email}
                />
                <p>
                  {formikSignup.touched.email ? formikSignup.errors.email : ""}
                </p>
              </div>
              <div className="input-form">
                <label data-testid="password-label">
                  {t("create_account_form.password.label")}
                </label>
                <div className="input-group" id="show_hide_password">
                  <input
                    name="password"
                    id="txtPswrd"
                    data-testid="password-input"
                    placeholder={t("create_account_form.password.placeholder")}
                    className="form-control"
                    style={{
                      borderColor:
                        formikSignup.errors.password &&
                        formikSignup.touched.password
                          ? "#FF0000"
                          : "#828282",
                    }}
                    type={password ? "text" : "password"}
                    onChange={(e) => {
                      formikSignup.setFieldValue("password", e.target.value);
                      setPasswordStrengh(e.target.value);
                    }}
                    onBlur={formikSignup.handleBlur("password")}
                    value={formikSignup.values.password}
                  />
                  <div
                    id="eye_password"
                    className="input-group-addon"
                    style={{
                      borderColor:
                        formikSignup.errors.confirmPassword &&
                        formikSignup.touched.confirmPassword
                          ? "#FF0000"
                          : "#828282",
                    }}
                  >
                    <img
                      src={
                        password
                          ? authenticationImages.eye
                          : authenticationImages.crossEye
                      }
                      onClick={() => setPassword((password) => !password)}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    id="progress"
                    style={{
                      width: progress,
                      backgroundColor: getActiveColor(message),
                      transition: "width 2s",
                    }}
                  ></div>
                </div>
                <p>
                  {formikSignup.touched.password
                    ? formikSignup.errors.password
                    : ""}
                </p>
              </div>
              <div className="input-form">
                <label data-testid="confirm-password-label">
                  {t("create_account_form.confirm_password.label")}
                </label>
                <div className="input-group" id="show_hide_password2">
                  <input
                    name="confirm-password"
                    placeholder={t(
                      "create_account_form.confirm_password.placeholder"
                    )}
                    className="form-control"
                    id="txtConfPswrd"
                    data-testid="confirm-password-input"
                    type={confirmPassword ? "text" : "password"}
                    onChange={formikSignup.handleChange("confirmPassword")}
                    onBlur={formikSignup.handleBlur("confirmPassword")}
                    value={formikSignup.values.confirmPassword}
                    style={{
                      borderColor:
                        formikSignup.errors.confirmPassword &&
                        formikSignup.touched.confirmPassword
                          ? "#FF0000"
                          : "#828282",
                    }}
                  />
                  <div
                    id="eye_password2"
                    className="input-group-addon"
                    style={{
                      borderColor:
                        formikSignup.errors.confirmPassword &&
                        formikSignup.touched.confirmPassword
                          ? "#FF0000"
                          : "#828282",
                    }}
                  >
                    <img
                      src={
                        confirmPassword
                          ? authenticationImages.eye
                          : authenticationImages.crossEye
                      }
                      onClick={() => setConfirmPassword(!confirmPassword)}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <p>
                  {formikSignup.touched.confirmPassword
                    ? formikSignup.errors.confirmPassword
                    : ""}
                </p>
              </div>
              <div className="button-form">
                <button
                  type="button"
                  id="bttRegisters"
                  className="btn btn-orange"
                  style={styles.signupButton}
                  onClick={() => formikSignup.handleSubmit()}
                >
                  {t("create_account_form.create_account")}
                </button>
              </div>
            </form>
          </div>
        </article>
      </div>
    </>
  );
};
