import dayjs from "dayjs";

export const timeConversion = (time: string) => {
  // Check correct time format and split into components
  let timeSliced: string[] = time.match(
    /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/
  ) || [time];

  if (timeSliced.length > 1) {
    // If time format correct
    timeSliced = timeSliced.slice(1); // Remove full string match value
    timeSliced[5] = +timeSliced[0] < 12 ? " AM" : " PM"; // Set AM/PM
    timeSliced[0] = (+timeSliced[0] % 12 || 12).toString(); // Adjust hours
  }

  return timeSliced.join(""); // return adjusted time or original string
};

export const greenwichToMilitarTime = (hour: string) => {
  return dayjs(hour, "hh:mm A").format("HH:mm");
};
export const militarToGreenwichTime = (hour: string) => {
  return dayjs(hour, "HH:mm").format("hh:mm A");
};
