import { login } from "../services/auth";
import {
  deleteIdAndEmail,
  deleteIsCompleted,
  deleteRemainingSeconds,
  deleteToken,
} from "../services/localstorage";
import { useQueryClient } from "react-query";
import { sha256 } from "js-sha256";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginContext } from "./root";
import { useContext, useEffect, useState } from "react";
import { DomainContext } from "../providers/domainProvider";
import { AuthDTO } from "../models/auth/types";
import { formatRoute, handleRoutes } from "../lib/helpers/routesHandler";
import { UserType } from "../models/user/types";

export const useAuthentication = () => {
  const queryClient = useQueryClient();
  const [urlType, setUrlType] = useState<"driver" | "host" | "">("driver");
  const navigate = useNavigate();
  const { setIsLogged, setIsAuthorized } = useContext(LoginContext);
  const { hostComercialId } = useContext(DomainContext);
  const { t } = useTranslation(["navbar", "routes"]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    const specificUrl = formatRoute();
    const route = handleRoutes(specificUrl);

    if (route) {
      setUrlType(route);
    }
  }, [location.pathname]);

  const ERROR_MESSAGE = {
    "Password Incorrect": t("error_validation.wrong_password"),
    "Email Not Exists": t("error_validation.email_not_exist"),
  };

  const fetchLogin = async (values: AuthDTO) => {
    setErrorMsg(null);
    try {
      // Login on navbar
      const response = await login({
        email: values.email,
        password: sha256(values.password),
        id: hostComercialId,
      });
      setIsLogged(true);
      if (response) {
        response.user.type === UserType.DRIVER_HOST &&
          navigate(
            "/" +
              t("admin", {
                ns: "routes",
              })
          );
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage: keyof typeof ERROR_MESSAGE =
          error.response?.data.message;
        const message = ERROR_MESSAGE[errorMessage]
          ? ERROR_MESSAGE[errorMessage]
          : error.response?.data.message;
        setErrorMsg(message);
      }
    }
  };

  // Logout function to delete all generated data
  const logout = () => {
    deleteIdAndEmail();
    deleteIsCompleted();
    deleteToken();
    deleteRemainingSeconds();
    setIsLogged(false);
    setIsAuthorized(false);
    navigate(
      "/" +
        t("results", {
          ns: "routes",
        })
    );
    queryClient.invalidateQueries("profile");
  };

  return { logout, fetchLogin, urlType, errorMsg };
};
