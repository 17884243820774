import { FC } from "react";
import { useQuery } from "react-query";
import { getAddressTrip } from "../../../../services/addressTrip";
import { AddressTripType } from "../../../../models/addressTrip/types";
import { mainAddressFormat } from "../../../helpers/textHandler";
import { TripStatus } from "../../../../models/trip/types";
import { useTranslation } from "react-i18next";

interface addressProps {
  addressId: number | undefined;
  alert?: boolean;
  tripStatus?: TripStatus;
}

export const AddressText: FC<addressProps> = ({
  addressId,
  alert = true,
  tripStatus = TripStatus.SEND_REQUEST_RESERVATION,
}) => {
  const { t } = useTranslation(["driverReservation"]);
  const { data: addressTrip } = useQuery(
    ["addressTrip", addressId],
    () => getAddressTrip(addressId),
    { enabled: !!addressId }
  );

  if (!addressTrip) {
    return <></>;
  }

  if (
    addressTrip.type == AddressTripType.MAIN_ADDRESS &&
    tripStatus === TripStatus.SEND_REQUEST_RESERVATION
  ) {
    return (
      <>
        <div className="input-text-large">
          <p className="deliberyAddress">{mainAddressFormat(addressTrip)}</p>
          <p
            className="deliberyPrice"
            style={{
              display: `${alert ? "flex" : "none"}`,
            }}
          >
            ${addressTrip.price !== "" ? addressTrip.price : "0"}
          </p>
        </div>
        <div
          className="alert-main-container"
          style={{
            display: `${alert ? "flex" : "none"}`,
          }}
        >
          <p className="alert-main">{t("tools.share_main_direction")}</p>
        </div>
      </>
    );
  }

  return (
    <div className="input-text-large">
      {alert ? (
        <a
          className="deliberyAddress"
          href={`https://www.google.es/maps?q=${addressTrip.latitude},${addressTrip.longitude}`}
          target="_blank"
        >
          {addressTrip.comercialName}
        </a>
      ) : (
        <p className="deliberyAddress">{addressTrip.comercialName}</p>
      )}
      <p
        className="deliberyPrice"
        style={{
          display: `${alert ? "flex" : "none"}`,
        }}
      >
        ${addressTrip.price !== "" ? addressTrip.price : "0"}
      </p>
    </div>
  );
};
