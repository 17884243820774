import React, { useContext } from "react";
import { useAddressTypes } from "../../../../hooks/address";
import { Address } from "../../../../models/address/types";
import { ShowContext } from "../../../../views/car/show/types";
import { addressPrice } from "../../../helpers/textHandler";

export const CardDeliveryPoints = () => {
  const { addresses } = useContext(ShowContext);
  const {
    home: homeAddress,
    main: mainAddress,
    extra: extraAddress,
  } = useAddressTypes(addresses || []);

  const home = homeAddress.map((address: Address) => {
    return (
      <React.Fragment key={address.id}>
        <p>{`⚡️ Otra Ubicación`}</p>
        <strong>{addressPrice(address)}</strong>
      </React.Fragment>
    );
  });

  const extra = extraAddress.map((address: Address) => {
    return (
      <div key={address.id} className="extra-direction">
        <div className="icon-comercial-container">
          📍
          <p>{address.comercialName}</p>
        </div>
        <strong>{addressPrice(address)}</strong>
      </div>
    );
  });

  const main = mainAddress.map((address: Address) => {
    return (
      <React.Fragment key={address.id}>
        <div className="icon-comercial-container">
          🏠
          <p>
            {`${address.suburb} ${address.zipCode}, ${address.city}, ${address.state}, ${address.country} `}
          </p>
        </div>
        <strong>{addressPrice(address)}</strong>
      </React.Fragment>
    );
  });

  return (
    <div className="card-delivery-points">
      <h2>Recoge en la ubicación del coche</h2>
      <div className="main-delibery-container">
        <div className="main-delibery-ubication">{main}</div>
        <span>Te compartiremos la ubicación exacta el momento de la renta</span>
      </div>
      {extra?.length !== 0 && (
        <div className="extra-ubication">
          <h2>Ubicaciones de entrega</h2>
          <div className="extra-delibery-ubication-container">{extra}</div>
        </div>
      )}
      {home?.length !== 0 && (
        <div className="home-delivery-container">
          <div className="home-address">{home}</div>
          <span>Dentro del área de entrega estipulada por el anfitrión</span>
        </div>
      )}
    </div>
  );
};
