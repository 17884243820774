import { useContext, useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUser } from "../../services/user";
import { useAuth } from "../../hooks/auth";
import { getToken } from "../../services/localstorage";
import { useWindowSize } from "../../hooks/windowSize";
import { LoginContext } from "../../hooks/root";

export const useNavbar = () => {
  const { isLogged, setIsLogged } = useContext(LoginContext);
  const [burgerMenuShow, setBurgerMenuShow] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const [clicks, setClicks] = useState<boolean>(false);
  const location = useLocation();
  const { t } = useTranslation(["navbar", "routes"]);
  const { haveToken } = useAuth();

  const { data } = useQuery(["profile"], getUser, {
    enabled: isLogged && !!getToken(),
  });

  const matchSignup = useMatch(
    t("complete_profile", {
      ns: "routes",
    })
  );

  const matchVerify = useMatch(
    t("verify", {
      ns: "routes",
    })
  );

  // On click menu burger close
  useEffect(() => {
    if (burgerMenuShow) setBurgerMenuShow(false);
  }, [clicks]);

  // On change location menu burger close
  useEffect(() => {
    if (burgerMenuShow) setBurgerMenuShow(false);
  }, [location]);

  // Know if a user is logged
  useEffect(() => {
    if (!haveToken) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [haveToken]);

  return {
    t,
    matchSignup,
    matchVerify,
    data,
    windowSize,
    isLogged,
    burgerMenuShow,
    setBurgerMenuShow,
    setClicks,
  };
};
