import * as Yup from "yup";

export interface ForgotPasswordForm {
  email: string;
}

export const forgotSchema: Yup.SchemaOf<ForgotPasswordForm> =
  Yup.object().shape({
    email: Yup.string()
      .email("Escribe un email valido")
      .required("El email es requerido"),
  });
