import { FC, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReasonCancel } from "../reasonCancel/component";
import { ModalProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { updateTripStatus } from "../../../services/trip";
import { TripContext } from "../../../views/driverReservation/show/component";
import { TripStatus } from "../../../models/trip/types";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { ReasonReject } from "../reasonReject/component";
import Loading from "../../loading/component";

export const CancelIncome: FC<ModalProps> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation(["modals"]);
  const { trip } = useContext(TripContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isReasonCancelOpen, setIsReasonCancelOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const cancelTrip = async () => {
    if (!trip?.id) return;
    setIsDisabled(true);
    queryClient.cancelQueries(["trip", "tripDriver"]);

    const newStatus =
      localStorage.getItem("id") === trip.hostId.toString()
        ? TripStatus.CANCEL_RESERVATION_ANFITRION
        : TripStatus.CANCEL_RESERVATION_HUESPED;

    const response = await updateTripStatus(trip?.id, {
      status: newStatus,
    });

    if (response?.status === 204) {
      setIsReasonCancelOpen(true);
    }
    setIsDisabled(false);
  };

  return (
    <>
      <Modal show={isOpen} className="cancel-income-modal">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={setOpen}
          >
            <img src={navbarImages.cross} alt="" />
          </button>
          <h2>{t("cancel_income.title")}</h2>
        </div>
        <div className="modal-body">
          <h3>{t("cancel_income.subtitle")}</h3>
          <a
            href="https://ayuda.balety.com/articulos/penalidad-como-huesped"
            target="_blank"
          >
            {t("cancel_income.cancellation_policies")}
          </a>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => !isDisabled && cancelTrip()}
          >
            {isDisabled ? (
              <Loading height="1vh" color="#fff" />
            ) : (
              <p>{t("cancel_income.yes")}</p>
            )}
          </button>
          <button type="button" className="btn btn-orange" onClick={setOpen}>
            <p>{t("cancel_income.no")}</p>
          </button>
        </div>
      </Modal>
      {localStorage.getItem("id") === trip?.hostId.toString() ? (
        <ReasonReject
          isOpen={isReasonCancelOpen}
          setOpen={() => setIsReasonCancelOpen(false)}
        />
      ) : (
        <ReasonCancel
          isOpen={isReasonCancelOpen}
          setOpen={() => setIsReasonCancelOpen(false)}
        />
      )}
    </>
  );
};
