import {
  CreateInsuranceDTO,
  UpdateInsuranceDTO,
} from "./../models/insurance/dtos";
import { axiosService } from "./axios";
import { Insurance } from "./../models/insurance/types";
import { getToken } from "./localstorage";

export const getInsurance = async () => {
  try {
    const data = await axiosService.get<Insurance>("/insurance/");
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getInsuranceByCarId = async (carId: number | undefined) => {
  try {
    const response = await axiosService.get<Insurance>(
      `/insurance/car/${carId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createInsurance = async (InsuranceDTO: CreateInsuranceDTO) => {
  try {
    const data = await axiosService.post<Insurance>(
      "/insurance/",
      InsuranceDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateInsurance = async (InsuranceDTO: UpdateInsuranceDTO) => {
  try {
    const response = await axiosService.put<Insurance>(
      `/insurance/`,
      InsuranceDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteInsurance = async (id: string, index: number) => {
  try {
    const data = await axiosService.delete<Insurance>(`/insurance/${id}`);
    return { data, index };
  } catch (error) {
    console.error(error);
  }
};

export const getInsuranceAndRefresh = async (carId: number | undefined) => {
  try {
    const response = await axiosService.get<Insurance>(
      `/insurance/updateAndRefresh/${carId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
