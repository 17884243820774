import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import authenticationImages from "../../../../assets/images/authentication";
import { User, UserType } from "../../../../models/user/types";
import { getHostExistByUserId } from "../../../../services/host";

export const HostCallToAction: FC<User> = (user) => {
  const { t } = useTranslation(["profile"]);

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", user.id],
    () => getHostExistByUserId(user.id.toString()),
    {
      enabled: !!user,
    }
  );

  return (
    <div className="anfitrion-call-to-action">
      {user.type === UserType.DRIVER && (
        <div id="anfitrion_card" className="share-car">
          <h2 data-testid="host-call-title">
            {t("profile_boxes.call_to_action.title")}
          </h2>
          <span>{t("profile_boxes.call_to_action.earn_money")}</span>
          <img src={authenticationImages.shareCar} alt="" />
          <Link
            to={"/" + t("host", { ns: "routes" })}
            type="button"
            className="btn btn-convert d-flex align-items-center justify-content-center"
          >
            {t("profile_boxes.call_to_action.become_host")}
          </Link>
        </div>
      )}

      {!isHostCommercial && (
        <div id="anfitrion_comercial_card" className="anfitrion-comercial">
          <h2 data-testid="commercial-host-call-title">
            {t("profile_boxes.call_to_action.become_commercial_host")}
          </h2>
          <span>{t("profile_boxes.call_to_action.car_lot")}</span>
          <img src={authenticationImages.anfitrionComercial} alt="" />
          <Link
            to={"/" + t("commercial_host", { ns: "routes" })}
            className="btn btn-convert d-flex align-items-center justify-content-center"
          >
            {t("profile_boxes.call_to_action.become_commercial_host_btn")}
          </Link>
        </div>
      )}
    </div>
  );
};
