import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import {
  Destination,
  currentLocation,
} from "../../../models/destination/types";
import { locationType } from "../../../components/navHomeFilter/component";
import { FormikErrors } from "formik";
import { homeFilter } from "../../../components/navHomeFilter/form";

interface AutocompleteFilterProps {
  dropdownAction: boolean;
  currentLocation: currentLocation;
  type: locationType;
  destinationSelected: Destination;
  autocomplete: string;
  setAutocompleteInput: Dispatch<SetStateAction<string>>;
  setType: Dispatch<SetStateAction<locationType>>;
  setCurrentLocation: Dispatch<SetStateAction<currentLocation>>;
  setDropdownAction: Dispatch<SetStateAction<boolean>>;
  setFormikValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<homeFilter>>;
}

export const useAutocompleteFilter = ({
  dropdownAction,
  currentLocation,
  type,
  destinationSelected,
  autocomplete,
  setAutocompleteInput,
  setType,
  setCurrentLocation,
  setDropdownAction,
  setFormikValue,
}: AutocompleteFilterProps) => {
  useEffect(() => {
    if (dropdownAction) return;
    const { lat, lng, state } = currentLocation;
    const { destinationMainAddress, name, id } = destinationSelected;

    if (type === "current" && lat && lng && state) {
      setFormikValue("destinationId", 0);
      setFormikValue("location", {
        latitud: lat,
        longitud: lng,
      });
      setAutocompleteInput(currentLocation.state);
    }

    if (type === "destination") {
      setFormikValue("destinationId", id);
      setFormikValue("location", {
        latitud: destinationMainAddress.lat,
        longitud: destinationMainAddress.lng,
      });
      setAutocompleteInput(name);
    }

    if (type == "") {
      setFormikValue("destinationId", 0);
    }
  }, [dropdownAction, currentLocation]);

  const handleAutocomplete = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAutocompleteInput(value);

    if (value.length !== 0) {
      setDropdownAction(false);
      return;
    }

    setType("");
    setCurrentLocation({ lat: "", lng: "", state: "" });
    setDropdownAction(true);
  };

  const handleClick = () => {
    autocomplete.length === 0 && setDropdownAction(true);
  };

  return { handleAutocomplete, handleClick };
};
