export interface Host {
  id: number;
  userId: number;
  status?: HostStatus;
  comercialName: string;
  businessName: string;
  rfc: string;
  legalAddress: string;
  insurance: TypeInsurance;
  rentPoliciesStatus?: DocPolicyStatus;
}

export interface HostForm {
  comercialName: string;
  businessName: string;
  rfc: string;
  legalAddress: string;
  insurance: TypeInsurance;
  rentPoliciesStatus?: DocPolicyStatus;
}

export interface HostCProfileForm {
  comercialName?: string;
  businessName?: string;
  rfc?: string;
  legalAddress?: string;
  insurance?: TypeInsurance;
  rentPoliciesStatus?: DocPolicyStatus;
  policieFile?: number[];
}

export enum HostStatus {
  PENDING,
  VALIDATED,
  REFUSED,
}

export enum TypeInsurance {
  OUR_INSURANCE,
  HOST_INSURANCE,
}

export enum DocPolicyStatus {
  NOT_UPLOADED,
  PENDING,
  VALIDATED,
  REFUSED,
}
