import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import { placeComponentCrash } from "../../../../../views/home/types";
import { modalMap } from "../../editCarPrice/types";
import {
  CarForm,
  ExtraAddressAdress,
  ExtraAddressProps,
} from "../../../../../views/car/add/types";
import { useFormikContext } from "formik";
import { editPriceForm } from "../../../../../views/car/edit/types";

export const useExtraAddress = ({ index }: ExtraAddressProps) => {
  const { setFieldValue, values } = useFormikContext<CarForm | editPriceForm>();
  const [address, setAddress] = useState<ExtraAddressAdress>();
  const [deliveryCost, setDeliberyCost] = useState<boolean>(
    values.extraAddresses[index].price === "0" ||
      values.extraAddresses[index].price === ""
      ? true
      : false
  );
  const t = useContext(TranslationContext);
  const [isMapOpen, setMapOpen] = useState<modalMap>({
    lat: 0,
    lng: 0,
    completeAddress: "",
    status: false,
  });

  // Set to "" when its price is disabled
  const handleDeliveryCost = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (!values.extraAddresses) return;
    setDeliberyCost(event.target.checked);
    setFieldValue("extraAddresses", {
      ...values.extraAddresses,
      [index]: { ...values.extraAddresses[index], price: "" },
    });
  };

  useEffect(() => {
    if (values.extraAddresses[index].price !== undefined) {
      setDeliberyCost(
        values.extraAddresses[index].price === "0" ||
          values.extraAddresses[index].price === ""
      );
    }
  }, [values.extraAddresses[index].price]);

  // Set cost of each extra address
  const handleExtraAddressPrice = (addressPrice: string, index: number) => {
    if (!values.extraAddresses) return;
    setFieldValue("extraAddresses", {
      ...values.extraAddresses,
      [index]: { ...values.extraAddresses[index], price: addressPrice },
    });
  };

  useEffect(() => {
    if (address) {
      setFieldValue("extraAddresses", {
        ...values.extraAddresses,
        [index]: {
          address,
          price: values.extraAddresses[index].price ?? "",
        },
      });
    }
  }, [address]);

  // Set extra address when fill the input
  const handleExtraAddress = (place: placeComponentCrash) => {
    if (!values.extraAddresses) return;
    setAddress({
      ...place,
      geometry: {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        viewport: place.geometry.viewport,
      },
    });
  };

  // Delete addresses from state variable
  const handleDeleteAddress = (index: number) => {
    if (!values.extraAddresses) return;

    const newExtraAddresses = Object.values(values.extraAddresses)
      .filter((_, idx) => idx !== index) // Remove the address at the given index
      .reduce((acc, current, idx) => {
        acc[idx] = current; // Re-index the addresses from 0
        return acc;
      }, {} as typeof values.extraAddresses);
    setFieldValue("extraAddresses", newExtraAddresses);
  };

  return {
    t,
    handleDeleteAddress,
    handleExtraAddress,
    setMapOpen,
    handleExtraAddressPrice,
    deliveryCost,
    handleDeliveryCost,
    isMapOpen,
    values,
  };
};
