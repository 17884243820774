import { forwardRef, useContext } from "react";
import h_homepageImage from "../../../../assets/images/home/h_homepage";
import { DestinationContext } from "./types";
import Geocode from "react-geocode";
import { getAllAddressComponents } from "../../../helpers/mapsActions";
import { mockDestination } from "../../../../models/destination/mockups";
import { DestinationStatus } from "../../../../models/destination/types";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export const DestinationsDropdown = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    setDestinationSelected,
    setDropdownAction,
    destinations,
    setType,
    setCurrentLocation,
  } = useContext(DestinationContext);

  const { t } = useTranslation(["results"]);

  const handleCurrentLocation = () => {
    const success = async (position: GeolocationPosition) => {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS || "");
      const response = await Geocode.fromLatLng(
        position.coords.latitude.toString(),
        position.coords.longitude.toString()
      );

      const addressItems = getAllAddressComponents(
        response.results[0].address_components
      );

      if (!addressItems) return;

      setCurrentLocation({
        state: response.results[0].formatted_address,
        lat: position.coords.latitude.toString(),
        lng: position.coords.longitude.toString(),
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  const handleSelection = async (
    type: "current" | "destination",
    id: number
  ) => {
    if (type === "current") {
      handleCurrentLocation();
      setType("current");
    }

    if (type === "destination") {
      setType("destination");
      const destination = destinations.find(
        (destination) => destination.id === id
      );

      setDestinationSelected(destination || mockDestination);
    }

    setDropdownAction(false);
  };

  const destinationsActive = destinations.filter(
    (destination) => destination.status === DestinationStatus.ACTIVE
  );

  const destinationsSoon = destinations.filter(
    (destination) => destination.status === DestinationStatus.COMING_SOON
  );

  return (
    <div
      ref={ref}
      style={{ display: "block" }}
      className="dropdown-menu dropdown-menu-right destinations"
    >
      <button
        className="btn btn-state"
        onClick={() => handleSelection("current", 0)}
      >
        <img src={h_homepageImage.currentLocation} alt="" className="me-2" />
        {t("filter.location")}
      </button>
      {destinationsActive.map((destination) => (
        <button
          key={destination.id}
          className="btn btn-state"
          onClick={() => handleSelection("destination", destination.id)}
        >
          <img src={h_homepageImage.destination} alt="" className="me-2" />
          {destination.name}
        </button>
      ))}
      <p className="next-destinations">Proximos</p>
      {destinationsSoon.map((destination) => (
        <button
          className="btn btn-state"
          key={destination.id}
          onClick={() => handleSelection("destination", destination.id)}
        >
          <img src={h_homepageImage.destination} alt="" className="me-2" />
          {destination.name}
        </button>
      ))}
    </div>
  );
});
