import { useTranslation } from "react-i18next";
import authenticationImages from "../../../assets/images/authentication";
import "./styles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendRecoverPassword } from "../../../services/forgotPassword";
import Loading from "../../../components/loading/component";

const SendEmailConfirmation = () => {
  const { t } = useTranslation(["forgotPassword", "routes"]);
  const [search] = useSearchParams();
  const email = search.get("email");
  const navigate = useNavigate();

  const resendEmail = async (email: string) => {
    const response = await sendRecoverPassword(email);

    if (response && response.status === 200) {
      navigate(
        "/" +
          t("results", {
            ns: "routes",
          })
      );
    }
  };

  if (!email) {
    return <Loading height="25vh" />;
  }

  return (
    <div className="send-email-password d-flex justify-content-center align-items-center flex-column">
      <div className="main d-flex flex-column justify-content-center align-items-center">
        <img
          src={authenticationImages.password}
          alt="car send-email-image"
          className="send-email-image"
        />
        <h4 className="title">{t("send_email_confirmation.title")}</h4>
        <p className="subtitle">
          {t("send_email_confirmation.subtitle")}
          <strong>{email}</strong>
          {t("send_email_confirmation.subtitle_extra")}
        </p>
        <div className="form-container">
          <div className="button-container">
            <button
              type="button"
              className="btn btn-send"
              onClick={() => resendEmail(email)}
            >
              {t("send_email_confirmation.resend")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmailConfirmation;
