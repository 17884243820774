import { useState } from "react";
import { useTranslation } from "react-i18next";
import authenticationImages from "../../../../assets/images/authentication";
import { CardsModal } from "../../../../components/modals/cardsModal/component";
import { useQuery } from "react-query";
import { getRegisteredCards } from "../../../../services/card";
import { Card, CardImgIndex } from "../../../../models/card/types";
import { AddCardToPay } from "../../../../components/modals/addCardToPay/component";
import carImages from "../../../../assets/images/car";

export const PayMethod = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openCards, setOpenCards] = useState(false);
  const { t } = useTranslation(["profile"]);
  const { data: cards } = useQuery(["cards"], getRegisteredCards, {
    enabled: !openCards,
  });

  const handleAddMethod = () => {
    setOpenAdd(true);
  };

  const carImagesIndex = carImages as CardImgIndex;

  return (
    <>
      <div id="method_account" className="pay-method">
        <img
          className="method-img"
          src={authenticationImages.payMethod}
          alt=""
        />
        {cards && cards.cards.length > 0 ? (
          <h4>{t("profile_boxes.pay_method.title")}</h4>
        ) : (
          <p id="text-bill" className="text-select-card">
            {t("profile_boxes.pay_method.select")}
          </p>
        )}
        {cards && cards?.cards.length > 0 ? (
          <div className="d-flex align-items-center pay-and-change-box">
            {cards.cards.map(
              (item: Card) =>
                item.isDefault && (
                  <div key={item.id} className="pay-and-change">
                    <img
                      className="img-brand"
                      src={carImagesIndex[item.brand] || carImages.generic}
                    />
                    <div className="pay-card">
                      <div className="form-default-card">
                        <strong>**** **** **** {item.last4}</strong>
                      </div>
                    </div>
                  </div>
                )
            )}
            <button
              id="payMethodButton"
              type="button"
              data-toggle="modal"
              data-target="#list_pay_cards"
              className="btn btn-change"
              onClick={() => setOpenCards(true)}
            >
              {t("profile_boxes.pay_method.change")}
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-select btn-add-pay-method"
            data-toggle="modal"
            data-target="#add_pay_card"
            onClick={handleAddMethod}
          >
            {t("profile_boxes.pay_method.add")}
          </button>
        )}
      </div>
      <AddCardToPay isOpen={openAdd} setOpen={setOpenAdd} type="profile" />
      <CardsModal
        isOpen={openCards}
        setOpen={setOpenCards}
        setOpenAdd={setOpenAdd}
      />
    </>
  );
};
