import {
  DocPolicyStatus,
  Host,
  HostStatus,
  TypeInsurance,
} from "../../models/host/types";
import { UserInsurance } from "../../models/userInsurance/types";

export const isValidHostCOwnInsurance = (hostC: Host) => {
  return (
    hostC?.insurance === TypeInsurance.HOST_INSURANCE &&
    hostC.rentPoliciesStatus === DocPolicyStatus.VALIDATED &&
    hostC.status === HostStatus.VALIDATED
  );
};

export const isValidHostC = (hostC: Host) => {
  return (
    hostC.rentPoliciesStatus === DocPolicyStatus.VALIDATED &&
    hostC.status === HostStatus.VALIDATED
  );
};
