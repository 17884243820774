import { ProfileBoxes } from "../../components/profileBoxes/component";
import { ProfileData } from "../../components/profileData/component";
import { InviteFriends } from "../../lib/components/profileBoxesTools/inviteFriends/component";
import "./Profile.scss";
import { useQuery } from "react-query";
import { getUser } from "../../services/user";
import ProfileDocumentation from "../../components/profileDocuments/component";
import { UserStatus } from "../../models/user/types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const { data } = useQuery(["profile"], getUser);
  const { t } = useTranslation(["profile"]);

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta name="robots" content="noindex,follow" />
        <link rel="alternate" hrefLang="es" href="https://romio.mx/mi-perfil" />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/mi-perfil"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/mi-perfil"
        />
      </Helmet>
      <div className="profile">
        <article className="profile-container">
          {data && <ProfileData {...data} />}
          <InviteFriends />
        </article>
        {data?.isBlocked === UserStatus.VALID && (
          <ProfileDocumentation user={data} />
        )}
        {data && <ProfileBoxes {...data} />}
      </div>
    </>
  );
};
