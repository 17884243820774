import { useTranslation } from "react-i18next";
import carTypesImg from "../assets/images/car/index";

export const useExperience = () => {
  const { t } = useTranslation(["results"]);

  return [
    {
      label: t("filter.experiences.ecological"),
      index: "0",
      src: carTypesImg.ecoExp,
      test: "experience-ecological",
    },
    {
      label: t("filter.experiences.sports"),
      index: "1",
      src: carTypesImg.sportExp,
      test: "experience-sports",
    },
    {
      label: t("filter.experiences.pet_friendly"),
      index: "2",
      src: carTypesImg.petExp,
      test: "experience-pet_friendly",
    },
    {
      label: t("filter.experiences.convertible"),
      index: "3",
      src: carTypesImg.convertibleExp,
      test: "experience-convertible",
    },
    {
      label: t("filter.experiences.off_road"),
      index: "4",
      src: carTypesImg.offRoadExp,
      test: "experience-off_road",
    },
    {
      label: t("filter.experiences.family"),
      index: "5",
      src: carTypesImg.familyExp,
      test: "experience-family",
    },
    {
      label: t("filter.experiences.daily_use"),
      index: "6",
      src: carTypesImg.dailyExp,
      test: "experience-daily",
    },
    {
      label: t("filter.experiences.ready_for_road"),
      index: "7",
      src: carTypesImg.roadReadyExp,
      test: "experience-road",
    },
    {
      label: t("filter.experiences.classic"),
      index: "8",
      src: carTypesImg.classicExp,
      test: "experience-classic",
    },
  ];
};
