import footerImages from "../../../../assets/images/footer";
import authenticationImages from "../../../../assets/images/authentication";
import { useTranslation } from "react-i18next";

export const InviteFriends = () => {
  const { t } = useTranslation(["profile"]);

  return (
    <div className="invite-friends">
      <div className="card">
        <div className="card-body">
          <h3 data-testid="invite-title">{t("invite_friends.title")}</h3>
          <p id="CodeFriend">{t("invite_friends.code.share")}</p>
          <img className="inv-img" src={authenticationImages.invite} alt="" />
          <button type="button" className="btn btn-copy">
            {t("invite_friends.code.copy")}
          </button>
          <div className="social-media">
            <a href="https://facebook.com/romio.ridess" target={"_blank"}>
              <img src={footerImages.facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/romio.rides/" target={"_blank"}>
              <img src={footerImages.instagram} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/romio-mx"
              target={"_blank"}
            >
              <img src={footerImages.linkedin} alt="" className="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
