import { CreateAddressDTO } from "./dtos";
import { Address, AddressStatus, AddressType } from "./types";

export const mockAddress: Address = {
  id: 1,
  carId: 33,
  status: AddressStatus.ACTIVE,
  type: AddressType.HOME_DELIVERY_ADDRESS,
  comercialName:
    "Calle Minatitlán 471, Equipamiento Urbano, Colima, Col., México",
  completeAddress:
    "Calle Minatitlán 471, Equipamiento Urbano, Colima, Col., México",
  intNumber: "2",
  number: "29",
  street: "Calle Jesús González Ortega",
  suburb: "Zona Centro",
  city: "Guadalajara",
  state: "Jalisco",
  country: "Mexico",
  zipCode: "44100",
  latitude: "20.6770388",
  longitude: "-103.3505475",
  price: "100",
  placeId: "ChKJ3SZBLPuxKIQRbj4PL5Vz82s",
};

export const mockAddresses = [
  {
    id: 1,
    carId: 1,
    status: 1,
    type: 0,
    comercialName:
      "Domingo Sarmiento 3075, Prados Providencia, Guadalajara, Jalisco, Mexico",
    completeAddress:
      "Domingo Sarmiento 3075, Prados Providencia, 44670 Guadalajara, Jal., Mexico",
    intNumber: "",
    number: "3075",
    street: "Domingo Sarmiento",
    suburb: "Prados Providencia",
    city: "Guadalajara",
    state: "Jalisco",
    country: "Mexico",
    zipCode: "44670",
    latitude: "20.6854362",
    longitude: "-103.3927796",
    placeId: "ChIJkbSTAmiuKIQR_WjF5rkFFdE",
    price: "0",
  },
  {
    id: 2,
    carId: 1,
    status: 1,
    type: 1,
    comercialName:
      "Domingo Sarmiento 3075, Prados Providencia, Guadalajara, Jalisco, Mexico",
    completeAddress:
      "Domingo Sarmiento 3075, Prados Providencia, 44670 Guadalajara, Jal., Mexico",
    intNumber: "",
    number: "3075",
    street: "Domingo Sarmiento",
    suburb: "Prados Providencia",
    city: "Guadalajara",
    state: "Jalisco",
    country: "Mexico",
    zipCode: "44670",
    latitude: "20.6854362",
    longitude: "-103.3927796",
    placeId: "ChIJkbSTAmiuKIQR_WjF5rkFFdE",
    price: "0",
  },
  {
    id: 3,
    carId: 1,
    status: 1,
    type: 2,
    comercialName:
      "Domingo Sarmiento 3075, Prados Providencia, Guadalajara, Jalisco, Mexico",
    completeAddress:
      "Domingo Sarmiento 3075, Prados Providencia, 44670 Guadalajara, Jal., Mexico",
    intNumber: "",
    number: "3075",
    street: "Domingo Sarmiento",
    suburb: "Prados Providencia",
    city: "Guadalajara",
    state: "Jalisco",
    country: "Mexico",
    zipCode: "44670",
    latitude: "20.6854362",
    longitude: "-103.3927796",
    placeId: "ChIJkbSTAmiuKIQR_WjF5rkFFdE",
    price: "0",
  },
  {
    id: 17,
    carId: 1,
    status: 1,
    type: 3,
    comercialName:
      "C. Jesús González Ortega 29, 44100 Guadalajara, Jalisco, Mexico",
    completeAddress:
      "C. Jesús González Ortega 29, Zona Centro, 44100 Guadalajara, Jal., Mexico",
    intNumber: "0",
    number: "29",
    street: "Calle Jesús González Ortega",
    suburb: "Zona Centro",
    city: "Guadalajara",
    state: "Jalisco",
    country: "Mexico",
    zipCode: "44100",
    latitude: "20.6770388",
    longitude: "-103.3505475",
    placeId: "ChIJ3SZBLPuxKIQRbj4PL5Vz82s",
    price: "0",
  },
];

export const mockTestAddress: CreateAddressDTO = {
  carId: 11,
  status: AddressStatus.ACTIVE,
  type: AddressType.MAIN_ADDRESS,
  comercialName:
    "Calle Chirimoyo 5214, Bosques de Manzanilla, Puebla, Pue., México",
  completeAddress:
    "C. Chirimoyo 5214, Bosques de Manzanilla, 72307 Puebla, Pue., México",
  intNumber: "120",
  number: "10",
  street: "Calle Chirimoyo",
  suburb: "Bosques de Manzanilla",
  city: "Heroica Puebla de Zaragoza",
  state: "Puebla",
  country: "Mexico",
  zipCode: "72307",
  latitude: "12.0633092",
  longitude: "-88.149411",
  price: "300",
  placeId: "ChIJXSxJzTauKIQR-C45RYrE8zI",
};
