import dayjs from "dayjs";
import * as Yup from "yup";

export interface homeFilter {
  location: { latitud: number; longitud: number };
  commercialName?: string;
  state?: string;
  dateInitial: dayjs.Dayjs;
  hourInitial: string;
  dateEnd: dayjs.Dayjs;
  hourEnd: string;
  destinationId?: number;
}

export const mockInitialDate = dayjs().add(2, "day");
export const mockEndDate = dayjs().add(5, "day");

export const homeFilterSchema: Yup.SchemaOf<homeFilter> = Yup.object().shape({
  location: Yup.object(),
  state: Yup.string(),
  commercialName: Yup.string(),
  dateInitial: Yup.object()
    .shape({})
    .required("La fecha de inicio es requerida"),
  hourInitial: Yup.string().required("La hora de inicio es requerida"),
  dateEnd: Yup.object().shape({}).required("La fecha de termino es requerida"),
  hourEnd: Yup.string().required("La hora de termino es requerida"),
  destinationId: Yup.number(),
});
