import * as Yup from "yup";
import { CreateUserDTO } from "../../models/user/dtos";

export interface SignupForm extends CreateUserDTO {
  confirmPassword: string;
}

export const signupSchema: Yup.SchemaOf<SignupForm> = Yup.object().shape({
  email: Yup.string()
    .email("Escribe un email valido")
    .required("El email es requerido"),
  password: Yup.string()
    .min(3, "La clave debe contener al menos 3 caracteres")
    .required("La contraseña es requerida"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Las contraseñas deben de coincidir")
    .required("La confirmacion de la contraseña es requerida"),
});
