import addedSuccessfully from "./added_successfully.png";
import arrowOrange from "./arrow_orange.svg";
import checkEmpty from "./check_empty.svg";
import checkStage from "./check_stage.png";
import checked from "./checked.svg";
import erase from "./erase.svg";
import carType from "./car_type.png";
import images_car from "./images_car.png";
import seats from "./seats.svg";
import info from "./info.svg";
import plus from "./plus.svg";
import preview from "./preview.svg";
import returnArrow from "./return.svg";
import searchCar from "./search_car.png";
import secondStageEmpty from "./second_stage_empty.png";
import secondStage from "./second_stage.png";
import thirdStageEmpty from "./third_stage_empty.png";
import thirdStage from "./third_stage.png";
import ubication from "./ubication.svg";
import ecoType from "./eco_type.png";
import transmission from "./transmission.svg";
import bag from "./bag.svg";
import arrowRight from "./arrow-right.svg";

export default {
  addedSuccessfully,
  arrowOrange,
  checkEmpty,
  checkStage,
  checked,
  erase,
  images_car,
  seats,
  info,
  plus,
  carType,
  preview,
  returnArrow,
  searchCar,
  secondStageEmpty,
  secondStage,
  thirdStageEmpty,
  thirdStage,
  ubication,
  ecoType,
  transmission,
  bag,
  arrowRight,
};
