import { CreateAddressDTO, UpdateAddressDTO } from "./../models/address/dtos";
import { axiosService } from "./axios";
import { Address } from "./../models/address/types";
import { getToken } from "./localstorage";
import { AxiosError } from "axios";

export const getAddress = async (addressId: number | undefined) => {
  const response = await axiosService.get<Address>(`/address/${addressId}`);
  return response.data;
};

export const getAddressesByCarID = async (
  carId: number | undefined
): Promise<Address[] | undefined> => {
  try {
    const response = await axiosService.get<Address[]>(`/address/car/${carId}`);
    if (response.status !== 200) return;
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createAddress = async (addressDTO: CreateAddressDTO) => {
  try {
    const data = await axiosService.post<Address>("/address/", addressDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return data.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateAddress = async (
  id: number,
  addressDTO: UpdateAddressDTO
) => {
  try {
    const data = await axiosService.patch<Address>(
      `/address/${id}`,
      addressDTO,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAddress = async (id: string) => {
  try {
    const data = await axiosService.delete<Address>(`/address/${id}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.data.message) {
      return error;
    }
  }
};
