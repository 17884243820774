import { createContext } from "react";
import { useAuthentication } from "../hooks/useAuthentication";
import { FormikHelpers } from "formik";
import { Auth } from "../models/auth/types";

export interface AuthActions {
  fetchLogin: (
    values: Auth,
    formikHelpers: FormikHelpers<Auth>
  ) => void | Promise<void>;
  logout: () => void;
  urlType: string;
  errorMsg: string | null;
}

export const AuthContext = createContext<AuthActions>({
  fetchLogin: async (e) => {
    e;
  },
  logout: () => {
    //do nothing
  },
  urlType: "",
  errorMsg: null,
});

export const AuthProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { logout, fetchLogin, urlType, errorMsg } = useAuthentication();

  return (
    <AuthContext.Provider value={{ fetchLogin, logout, urlType, errorMsg }}>
      {children}
    </AuthContext.Provider>
  );
};
