import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from "react";
import { CarResults } from "../../models/car/types";
import { getCarsByState } from "../../services/car";
import { SearchParams } from "../../models/car/dtos";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Filters, resultParams } from "../../views/results/types";
import { DomainContext } from "../../providers/domainProvider";

export interface ParamsIndex {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [index: string]: any;
}

const useResults = (
  params: resultParams,
  setPage: Dispatch<SetStateAction<number>>
) => {
  const { hostComercialId } = useContext(DomainContext);
  const [cars, setCars] = useState<CarResults[]>();
  const [loading, setLoading] = useState(false);
  const [carsLoading, setCarsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const { t } = useTranslation(["results"]);

  // Submit new params
  const handleSubmit = (
    filters: Filters,
    setParams: Dispatch<SetStateAction<resultParams>>
  ) => {
    setLoading(true);

    // Format each filter
    const formatedExperiences = Object.values(filters.experiences)
      .map((value) => (value ? "1" : "0"))
      .join("");
    const formatedTypes = Object.entries(filters.type)
      .map(([key, value]) => (value ? key : null))
      .filter((item) => item)
      .join("");
    const formatedTransmission = Object.entries(filters.transmission)
      .map(([key, value]) => (value ? key : null))
      .filter((item) => item)
      .join("");

    const formatedDateInitial = dayjs(params.dateInitial, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const formatedDateEnd = dayjs(params.dateEnd, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    const newParams = {
      experiences: formatedExperiences,
      type: formatedTypes,
      address: filters.address ? "1" : "0",
      depositAmount: filters.depositAmount ? "1" : "0",
      ecologyCar: filters.ecologyCar ? "1" : "0",
      transmission: formatedTransmission,
      price: filters.price.join(";"),
      numBags: filters.numBags,
      numSeats: filters.numSeats,
      priceLowerBound: filters.price[0],
      priceUpperBound: filters.price[1],
      distance: filters.distance,
    };

    // Replace or add params
    setParams((prev) => ({
      ...prev,
      ...newParams,
    }));

    // Return if date params doesn't exist
    if (
      !params.dateInitial ||
      !params.dateEnd ||
      !params.hourInitial ||
      !params.hourEnd
    )
      return;

    // Params object
    const searchParams = new URLSearchParams({
      ...params,
      ...newParams,
    } as ParamsIndex);

    // Send new params to function
    getResultCars({
      ...params,
      ...newParams,
      dateInitial: formatedDateInitial,
      dateEnd: formatedDateEnd,
    });

    // Set search params
    setSearchParams(searchParams);
  };

  const getResultCars = useCallback(async (params: resultParams) => {
    setCarsLoading(true);

    // Return if date params doesn't exist
    if (
      !params.dateInitial ||
      !params.dateEnd ||
      !params.hourInitial ||
      params.destinationId === undefined
    )
      return;

    try {
      // Get cars by new params
      const resultCars = await getCarsByState(cleanQueryParams(params));
      // If is null return an empty array
      const carList = resultCars ?? [];
      // Set new car list in state variable
      setCars(carList);
      setPage(1);
    } catch (err) {
      setErrorMsg(t("filter.request_error"));
      setOpenAlert(true);
    } finally {
      setCarsLoading(false);
      setLoading(false);
    }
  }, []);

  const cleanQueryParams = (params: resultParams): SearchParams => {
    let cleanParams = {};

    // Remove unused filters
    Object.entries(params).forEach(([key, value]) => {
      if (key === "numBags") {
        if (value) {
          cleanParams = { ...cleanParams, [key]: value };
        }
      } else if (key === "numSeats") {
        if (value) {
          cleanParams = { ...cleanParams, [key]: value };
        }
      } else if (
        key !== "hourEnd" &&
        key !== "hourInitial" &&
        key !== "longitud" &&
        key !== "latitud" &&
        key !== "price"
      ) {
        cleanParams = { ...cleanParams, [key]: value };
      }
    });

    // Add another params
    cleanParams = {
      ...cleanParams,
      type: params.type.split("").map((item) => parseInt(item)),
      transmission: params.transmission.split("").map((item) => parseInt(item)),
      address: params.address === "0" ? 0 : 1,
      ecologyCar: params.ecologyCar === "0" ? 0 : 1,
      depositAmount: params.depositAmount === "0" ? 0 : 1,
      hostComercialId,
    };

    // Change params if price lower bound change
    if (params.priceLowerBound) {
      cleanParams = {
        ...cleanParams,
        priceLowerBound: params.priceLowerBound,
      };
    }

    // Change params if price upper bound change
    if (params.priceUpperBound) {
      cleanParams = {
        ...cleanParams,
        priceUpperBound: params.priceUpperBound,
      };
    }

    // Change params if lat or lng  change
    if (params.latitud && params.longitud) {
      cleanParams = {
        ...cleanParams,
        coordinate: {
          lat: parseFloat(params.latitud),
          lng: parseFloat(params.longitud),
        },
      };
    }

    return cleanParams as SearchParams;
  };

  return {
    cars,
    setCars,
    handleSubmit,
    loading,
    carsLoading,
    searchParams,
    getResultCars,
    errorMsg,
    openAlert,
    setOpenAlert,
  };
};

export default useResults;
