import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext } from "react";

import { CarForm } from "../../../../../views/car/add/types";
import { TranslationContext } from "../../../../../views/driverReservation/show/component";
import {
  CarType,
  Transmission,
  IsEcofriendly,
} from "../../../../../models/car/types";
import images from "../../../../../assets/images/order";

// Car specifications (transmision / type / seats)
export const CarSpecifications = ({
  formErrors,
}: {
  formErrors: string[] | undefined;
}) => {
  const { setFieldValue, values } = useFormikContext<CarForm>();
  const t = useContext(TranslationContext);

  return (
    <>
      <div className="generic-input">
        <label className="select-label" data-testid="transmission-label">
          {t("add.car_model_and_ubication.transmission.title")}
        </label>
        <FormControl fullWidth>
          <Select
            labelId="transmission"
            id="transmission"
            value={values.transmission}
            defaultValue={values.transmission}
            onChange={(e) => {
              setFieldValue("transmission", e.target.value);
            }}
            className="dropdown"
            label=""
            error={formErrors?.includes("transmission")}
          >
            <MenuItem value={Transmission.AUTOMATIC}>
              {t("add.car_model_and_ubication.transmission.automatic")}
            </MenuItem>
            <MenuItem value={Transmission.MANUAL}>
              {t("add.car_model_and_ubication.transmission.manual")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="generic-input">
        <label className="select-label" data-testid="type-label">
          {t("add.car_model_and_ubication.type.title")}
        </label>
        <FormControl fullWidth>
          <Select
            labelId="type"
            id="type"
            value={values.type}
            onChange={(e) => {
              setFieldValue("type", e.target.value);
            }}
            className="dropdown"
            error={formErrors?.includes("type")}
          >
            <MenuItem value={CarType.COMPACT}>
              {t("add.car_model_and_ubication.type.hatchback_compact")}
            </MenuItem>
            <MenuItem value={CarType.SEDAN}>
              {t("add.car_model_and_ubication.type.cars")}
            </MenuItem>
            <MenuItem value={CarType.COUPE}>
              {t("add.car_model_and_ubication.type.coupe")}
            </MenuItem>
            <MenuItem value={CarType.SUV}>
              {t("add.car_model_and_ubication.type.suv_crossover")}
            </MenuItem>
            <MenuItem value={CarType.MINIVAN}>
              {t("add.car_model_and_ubication.type.minivan")}
            </MenuItem>
            <MenuItem value={CarType.VAN}>
              {t("add.car_model_and_ubication.type.van")}
            </MenuItem>
            <MenuItem value={CarType.TRUCK}>
              {t("add.car_model_and_ubication.type.truck")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="generic-input">
        <label className="select-label">
          {t("add.car_model_and_ubication.plates")}
        </label>
        <TextField
          id="txtPlacas"
          type="text"
          label="Ingresa las placas de tu auto"
          value={values.plate}
          onChange={(e) => {
            setFieldValue("plate", e.target.value);
          }}
          className="input-text-large"
          required
          error={formErrors?.includes("plate")}
        />
      </div>
      <div className="generic-input">
        <label className="select-label">
          {t("add.car_model_and_ubication.is_ecological.label")}
          <img
            src={images?.ecoType}
            alt="ecological type"
            style={{
              width: "1em",
              height: "1em",
              fontSize: "1em",
              marginRight: "6px",
              marginLeft: "6px",
            }}
          />
        </label>
        <FormControl fullWidth>
          <Select
            variant="outlined"
            labelId="ecological"
            id="ecological"
            value={values.isEcoFriendly}
            onChange={(e) => {
              setFieldValue("isEcoFriendly", e.target.value);
            }}
            className="dropdown"
            required
            error={formErrors?.includes("isEcoFriendly")}
          >
            <MenuItem value={0} disabled>
              {t("add.car_price.choose_an_option")}
            </MenuItem>
            <MenuItem value={IsEcofriendly.NEGATIVE}>
              {t("add.car_model_and_ubication.is_ecological.negative")}
            </MenuItem>
            <MenuItem value={IsEcofriendly.ELECTRIC}>
              {t("add.car_model_and_ubication.is_ecological.electric")}
            </MenuItem>
            <MenuItem value={IsEcofriendly.HYBRID}>
              {t("add.car_model_and_ubication.is_ecological.hybrid")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="generic-input">
        <label className="select-label">
          {t("add.car_model_and_ubication.number_of_seats.title")}
        </label>
        <FormControl fullWidth>
          <Select
            labelId="seats_number"
            id="seats_number"
            value={values.numSeats}
            onChange={(e) => {
              setFieldValue("numSeats", e.target.value);
            }}
            className="dropdown"
            required
            error={formErrors?.includes("numSeats")}
          >
            <MenuItem value={0} disabled>
              {t("add.car_price.choose_an_option")}
            </MenuItem>
            {Array.from({ length: 20 }, (_, i) => i + 1).map((numSeats) => (
              <MenuItem key={numSeats} value={numSeats}>
                {numSeats}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="generic-input">
        <label className="select-label">
          {t("add.car_model_and_ubication.number_of_bags.title")}
        </label>
        <FormControl fullWidth>
          <Select
            labelId="bags_number"
            id="bags_number"
            value={values.numBags}
            onChange={(e) => {
              setFieldValue("numBags", e.target.value);
            }}
            className="dropdown"
            required
            error={formErrors?.includes("numBags")}
          >
            <MenuItem value={0} disabled>
              {t("add.car_price.choose_an_option")}
            </MenuItem>
            {Array.from({ length: 15 }, (_, i) => i + 1).map((numBags) => (
              <MenuItem key={numBags} value={numBags}>
                {numBags}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};
