import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBeforeThirdSlash } from "../lib/helpers/routesHandler";
import {
  adminRoutes,
  adminRoutesContent,
  route,
} from "../components/stickySidebar/types";

export const useAdminRoutes = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<adminRoutesContent>(
    adminRoutes[0]
  );
  const [menu, setMenu] = useState<adminRoutesContent[]>(adminRoutes);
  const image = useRef<SVGSVGElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (image.current) {
      image.current.style.transform = showSideBar
        ? `rotate(${-90}deg)`
        : `rotate(${0}deg)`;

      setSelectedSection((prev) => ({
        ...prev,
        active: showSideBar,
        open: showSideBar,
      }));

      document.body.style.overflow = showSideBar ? "hidden" : "auto";
    }
  }, [showSideBar]);

  const activeSection = (section: adminRoutesContent) => {
    setMenu((prev) =>
      prev.map((menuSection) => {
        const isActive = menuSection.title === section.title;
        const updatedSection = {
          ...menuSection,
          active: isActive,
          open: isActive,
        };

        if (isActive) setSelectedSection(updatedSection);
        return updatedSection;
      })
    );

    if (section.title === "home") {
      navigate("/admin");
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  };

  // When find route update to active route
  const updateMenu = (
    title: string,
    activeChildRoute: route,
    activeSubroute?: route
  ) => {
    setMenu((prev) =>
      prev.map((menuSection) => {
        if (menuSection.title === title) {
          const updatedRoutes = menuSection.routes.map((route) => {
            if (route.name === activeChildRoute?.name) {
              return {
                ...route,
                active: true,
                subroute: route.subroute?.map((sub) =>
                  sub.name === activeSubroute?.name
                    ? { ...sub, active: true }
                    : { ...sub }
                ),
              };
            }
            return { ...route };
          });

          const activeSection = {
            ...menuSection,
            routes: updatedRoutes,
            active: true,
          };
          setSelectedSection(activeSection);
          return activeSection;
        }
        return { ...menuSection };
      })
    );
  };

  // Find active route when loads
  const findActiveRoute = () => {
    setShowSideBar(false);
    setMenu(adminRoutes);

    for (const route of adminRoutes) {
      const activeChildRoute = route.routes.find(
        (child) => child.route === getBeforeThirdSlash(location.pathname)
      );

      if (activeChildRoute) {
        updateMenu(route.title, activeChildRoute);
        return;
      }

      for (const child of route.routes) {
        const activeSubroute = child.subroute?.find(
          (sub) => sub.route === location.pathname
        );
        if (activeSubroute) {
          updateMenu(route.title, child, activeSubroute);
          return;
        }
      }
    }

    return null;
  };

  const activeDropdown = (section: adminRoutesContent, name: string) => {
    setMenu((prev) => {
      const updateRoutes = (routes: route[]) =>
        routes.map((route) =>
          route.name === name ? { ...route, active: !route.active } : route
        );

      return prev.map((menuSection) => {
        if (menuSection.title === section.title) {
          const updatedSection = {
            ...menuSection,
            routes: updateRoutes(menuSection.routes),
            active: true,
          };

          setSelectedSection(updatedSection);
          return updatedSection;
        }

        return { ...menuSection };
      });
    });
  };

  useEffect(() => {
    if (location.pathname) {
      findActiveRoute();
    }
  }, [location.pathname]);

  return {
    activeSection,
    activeDropdown,
    menu,
    selectedSection,
    image,
    setShowSideBar,
    showSideBar,
  };
};
