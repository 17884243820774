import { useFormik } from "formik";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  addCoupon,
  addGeneralCoupon,
  getCoupons,
} from "../../../services/coupons";
import { CouponForm, modalCouponSchema } from "./form";
import carImages from "../../../assets/images/car";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import { discountType } from "../../../lib/helpers/textHandler";
import { Coupon } from "../../../models/coupon/types";

export interface CouponProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setCoupon: (coupon: Coupon) => void;
  tripId: string;
}

export const AddCouponModal: FC<CouponProps> = ({
  isOpen,
  setOpen,
  setCoupon,
  tripId,
}) => {
  const { t } = useTranslation(["modals"]);
  const [valid, setValid] = useState<boolean>();
  const queryClient = useQueryClient();

  const { data: coupons } = useQuery(["coupons"], getCoupons);

  const fetchCoupon = async (value: CouponForm) => {
    try {
      const response = await addGeneralCoupon({
        code: value.coupon,
        tripId: parseInt(tripId),
      });

      if (response && response.status === 200) {
        setCoupon(response.data);
        setValid(true);
        setOpen(false);
        queryClient.invalidateQueries(["trip"]);
      } else {
        setValid(false);
      }
    } catch (e) {
      setValid(false);
    }
  };

  const couponSelected = async (coupon: Coupon) => {
    const response = await addCoupon({
      code: coupon.namePromotionCode,
      tripId: parseInt(tripId),
    });

    if (response?.status === 200) {
      setCoupon(coupon);
      setOpen(false);
      queryClient.invalidateQueries(["trip"]);
    }
  };

  const formikCouponModal = useFormik<CouponForm>({
    initialValues: {
      coupon: "",
    },
    validationSchema: modalCouponSchema,
    onSubmit: fetchCoupon,
  });

  const applied =
    valid === undefined ? (
      ""
    ) : valid ? (
      <div className="warning">
        <img src={carImages.validated} className="status" />
        <p className="status-text">{t("coupon.coupon_form.valid")}</p>
      </div>
    ) : (
      <div className="warning">
        <img src={carImages.refused} className="status" />
        <p className="status-text">{t("coupon.coupon_form.invalid")}</p>
      </div>
    );

  return (
    <Modal show={isOpen} className="change-coupon-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={() => setOpen(false)}>
          <img src={navbarImages.cross} alt="" />
        </button>
        <div className="coupon-form-container">
          <div className="input-form">
            <h3 className="coupon-title">{t("coupon.coupon_form.title")}</h3>
            <input
              name="coupon"
              value={formikCouponModal.values.coupon}
              onChange={formikCouponModal.handleChange("coupon")}
              className="coupon-input modal-change-coupon"
              placeholder={t("coupon.coupon_form.placeholder")}
            />
            <button
              className="btn-redeem"
              type="submit"
              onClick={() => formikCouponModal.handleSubmit()}
            >
              {t("coupon.coupon_form.redeem")}
            </button>
          </div>
        </div>
        {applied}
      </div>
      <div className="modal-body">
        <h3 className="title">{t("coupon.index.title")}</h3>
        <div className="dropdown-divider"></div>
        <div className="coupon-index">
          {coupons &&
            coupons.length != 0 &&
            coupons.map((coupon, index) => (
              <CouponContainer
                couponInfo={coupon}
                couponSelected={couponSelected}
                key={index}
              />
            ))}
        </div>
      </div>
    </Modal>
  );
};

export const CouponContainer: FC<{
  couponInfo: Coupon;
  couponSelected: (coupon: Coupon) => Promise<void>;
}> = ({ couponInfo, couponSelected }) => {
  const { t } = useTranslation(["modals"]);

  const discount = discountType(couponInfo);
  return (
    <div className="coupon-container">
      <span className="valid-date">
        {t("coupon.index.expires_at")}
        {dayjs(couponInfo.expiresAt, "DD-MM-YYYY HH:mm:ss").format("DD/MMM/YY")}
      </span>
      <div className="coupon">
        <div className="information">
          <p className="price">
            {(discount.type === "amount" ? "$" : "%") + discount.quantity}
          </p>
          <div className="coupon-prize">
            <p>{couponInfo.namePromotionCode}</p>
            <p>{couponInfo.descripcionCoupon}</p>
          </div>
        </div>
        <div
          className="btn-container"
          onClick={() => couponSelected(couponInfo)}
        >
          <button className="btn-use">{t("coupon.index.apply")}</button>
        </div>
      </div>
    </div>
  );
};
