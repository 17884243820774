import { Helmet } from "react-helmet";
import helpImages from "../../../assets/images/help";
import { useTranslation } from "react-i18next";
import "./kycSuccess.scss";
import { useEffect, useRef } from "react";
import { getUser, updateUserCompleted } from "../../../services/user";
import { useQuery } from "react-query";
import { KycStatus } from "../../profile/types";
import { useSearchParams } from "react-router-dom";

export const KycSuccess = () => {
  const { t } = useTranslation(["help"]);
  const { data: user } = useQuery(["profile"], getUser);
  const ref = useRef<HTMLElement | null>(null);
  const [search] = useSearchParams();

  useEffect(() => {
    if (ref) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [ref]);

  useEffect(() => {
    if (user?.moffinReportId !== 0) {
      const flowStatus = search.get("acquisitionStatus");

      flowStatus === "SUCCESS" &&
        updateUserCompleted({
          docOfficialDocuments: KycStatus.PENDING,
        });
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <section className="kyc-finish" ref={ref}>
        <div className="kyc-information">
          <img src={helpImages.kyc_success} className="kyc-image" />
          <h1 className="title">{t("kyc.finish")}</h1>
          <h2 className="subtitle">{t("kyc.close")}</h2>
        </div>
      </section>
    </>
  );
};
