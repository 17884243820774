import { TrustedUser, User, UserAccountStatus, UserType } from "./types";

interface CompleteUser extends User {
  password: string;
}

export const mockUser: CompleteUser = {
  id: 1,
  type: UserType.DRIVER,
  status: UserAccountStatus.REGISTER_NO_ACTIVE,
  name: "Test user",
  surName: "Test user",
  email: "test2@romio.mx",
  password: "test123",
  phone: "+523121234568",
  codeMail: "BPLUDN",
  codePhone: "4171",
  codeDate: "10/10/2021",
  isLogged: 0,
  lastLogTime: "10/10/2021",
  inviteCode: "1fca232",
  createDate: "10/10/2021",
  stripeCustomerId: "cus_LkUQbUJJBf9KlJ",
  docProofOfAddress: 0,
  docBankStatement: 0,
  isBlocked: 0,
  docOfficialDocuments: 0,
  trustedUser: TrustedUser.FALSE,
};

export const defaultUser: User = {
  id: 1,
  type: UserType.DRIVER,
  status: UserAccountStatus.REGISTER_NO_ACTIVE,
  name: "Test",
  surName: "Test",
  email: "testuser@romio.mx",
  phone: "+523121234567",
  codeMail: "BPLUDN",
  codePhone: "4171",
  codeDate: "10/10/2021",
  isLogged: 0,
  lastLogTime: "10/10/2021",
  inviteCode: "1fca232",
  createDate: "10/10/2021",
  stripeCustomerId: "cus_LkUQbUJJBf9KlJ",
  docProofOfAddress: 0,
  docBankStatement: 0,
  isBlocked: 0,
  docOfficialDocuments: 0,
  trustedUser: TrustedUser.FALSE,
};

export const mockHost: CompleteUser = {
  id: 1,
  type: UserType.DRIVER_HOST,
  status: UserAccountStatus.ACTIVE,
  name: "Test host",
  surName: "Test host",
  email: "testhost@romio.mx",
  password: "test123",
  phone: "+523121234567",
  codeMail: "BPLUDN",
  codePhone: "4171",
  codeDate: "10/10/2021",
  isLogged: 0,
  lastLogTime: "10/10/2021",
  inviteCode: "1fca232",
  createDate: "10/10/2021",
  stripeCustomerId: "cus_LkUQbUJJBf9KlJ",
  docProofOfAddress: 0,
  docBankStatement: 0,
  isBlocked: 0,
  docOfficialDocuments: 0,
  trustedUser: TrustedUser.FALSE,
};
