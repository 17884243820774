import * as Yup from "yup";
import { editPriceForm } from "../../../../views/car/edit/types";
import { UpdateDetailsCarDTO } from "../../../../models/car/dtos";

export const editPriceSchema: Yup.SchemaOf<editPriceForm> = Yup.object().shape({
  price: Yup.string(),
  blockDates: Yup.array().notRequired(),
  extraAddresses: Yup.object(),
  mainAddress: Yup.object().shape({
    address_components: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string(),
          long_name: Yup.string(),
          short_name: Yup.string(),
          types: Yup.array().of(Yup.string()).required(),
        })
      )
      .optional(),
    formatted_address: Yup.string().optional(),
    geometry: Yup.object().shape({
      location: Yup.object().shape({
        lat: Yup.string(),
        lng: Yup.string(),
      }),
      viewport: Yup.object().shape({
        Cb: Yup.object().shape({ lo: Yup.number(), hi: Yup.number() }),
        Va: Yup.object().shape({ lo: Yup.number(), hi: Yup.number() }),
      }),
    }),
    html_attributions: Yup.array(),
    place_id: Yup.string(),
    name: Yup.string(),
  }),
  questionnaireAnswers: Yup.string(),
  insuranceCharge: Yup.string(),
  depositAmount: Yup.string(),
  homeDelivery: Yup.object().shape({
    enable: Yup.boolean().required(),
    price: Yup.string(),
  }),
});

export const editQASchema: Yup.SchemaOf<UpdateDetailsCarDTO> =
  Yup.object().shape({
    questionnaireAnswers: Yup.string(),
    docInsuranceCover: Yup.number(),
    docVehicleLicense: Yup.number(),
    hasGPS: Yup.number(),
    offerGPS: Yup.number(),
    niv: Yup.string(),
  });
