import { useEffect, useState } from "react";
import { getToken } from "../services/localstorage";

export const useAuth = () => {
  const [token, setToken] = useState<string | null>(getToken());

  useEffect(() => {
    const onSetAuthToken = () => {
      setToken(getToken());
    };

    window.addEventListener("storage", onSetAuthToken);
    return () => {
      window.removeEventListener("storage", onSetAuthToken);
    };
  }, []);

  return { haveToken: token !== null, token };
};
