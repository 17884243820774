import { FC } from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "../../../lib/components/carTools/carUbication/component";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ModalMapProps {
  lat: number;
  lng: number;
  completeAddress: string;
  isOpen: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setOpen: () => any;
}

export const ModalMap: FC<ModalMapProps> = ({
  lat,
  lng,
  completeAddress,
  isOpen,
  setOpen,
}) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Modal show={isOpen} className="modal-map">
      <div className="modal-header">
        <h2>{t("map.title")}</h2>
        <button type="button" className="close" onClick={() => setOpen()}>
          <img src={navbarImages.cross} alt="close" />
        </button>
      </div>
      <div className="modal-alert">
        <h3>{t("map.location")}</h3>
      </div>
      <div className="modal-body">
        <div className="map3" data-testid="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS || "",
            }}
            center={{
              lat: lat,
              lng: lng,
            }}
            defaultZoom={15}
          >
            <Marker lat={lat} lng={lng} text={completeAddress} />
          </GoogleMapReact>
        </div>
      </div>
    </Modal>
  );
};
