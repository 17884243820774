import { CarResults } from "./../models/car/types";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDistance } from "geolib";

export const useSort = (data: CarResults[], sortBy: string) => {
  const [sorted, setSorted] = useState<CarResults[]>(data);
  const [search] = useSearchParams();

  useEffect(() => {
    if (data.length > 0 && sortBy) {
      switch (sortBy) {
        case "RECENT":
          setSorted([...recentSort(data)]);
          break;
        case "MODEL":
          setSorted([...modelSort(data)]);
          break;
        case "LESS_MORE_PRICE":
          setSorted([...priceLessMoreSort(data)]);
          break;
        case "MORE_LESS_PRICE":
          setSorted([...priceMoreLessSort(data)]);
          break;
        case "DISTANCE":
          setSorted([...distanceSort(data, search)]);
          break;
        default:
          setSorted([...modelSort(data)]);
          break;
      }
    } else {
      setSorted([]);
    }
  }, [sortBy, data]);

  return { data: sorted };
};

const recentSort = (data: CarResults[]) => {
  return data.sort((car: CarResults, nextCar: CarResults) =>
    car.datetimeCompleted < nextCar.datetimeCompleted
      ? 1
      : car.datetimeCompleted > nextCar.datetimeCompleted
      ? -1
      : 0
  );
};

const recentDrawSort = (car: CarResults, nextCar: CarResults) => {
  return car.datetimeCompleted < nextCar.datetimeCompleted
    ? 1
    : car.datetimeCompleted > nextCar.datetimeCompleted
    ? -1
    : 0;
};

const modelSort = (data: CarResults[]) => {
  return data.sort((car: CarResults, nextCar: CarResults) =>
    car.year < nextCar.year
      ? 1
      : car.year > nextCar.year
      ? -1
      : recentDrawSort(car, nextCar)
  );
};

const priceLessMoreSort = (data: CarResults[]) => {
  return data.sort((car: CarResults, nextCar: CarResults) =>
    parseFloat(car.price) > parseFloat(nextCar.price)
      ? 1
      : parseFloat(car.price) < parseFloat(nextCar.price)
      ? -1
      : recentDrawSort(car, nextCar)
  );
};

const priceMoreLessSort = (data: CarResults[]) => {
  return data.sort((car: CarResults, nextCar: CarResults) =>
    parseFloat(car.price) < parseFloat(nextCar.price)
      ? 1
      : parseFloat(car.price) > parseFloat(nextCar.price)
      ? -1
      : recentDrawSort(car, nextCar)
  );
};

const distanceSort = (data: CarResults[], search: URLSearchParams) => {
  const searchCoordinates = {
    lat: parseFloat(search.get("latitud") || "0"),
    lng: parseFloat(search.get("longitud") || "0"),
  };

  return data.sort((car: CarResults, nextCar: CarResults) => {
    const carDistance = getDistance(
      {
        lat: parseFloat(car.latitude),
        lng: parseFloat(car.longitude),
      },
      searchCoordinates
    );
    const newCarDistance = getDistance(
      {
        lat: parseFloat(nextCar.latitude),
        lng: parseFloat(nextCar.longitude),
      },
      searchCoordinates
    );

    return carDistance > newCarDistance
      ? 1
      : carDistance < newCarDistance
      ? -1
      : 0;
  });
};
