import { FC } from "react";
import PinInput from "react-pin-input";
import "./Verification.scss";
import Toast from "../../components/toast/component";
import {
  CountdownProvider,
  CountdownTimer,
} from "../../components/verifyCounter/component";
import { VerificationCode } from "../../components/modals/verificationCode/component";
import { ChangePhoneNumberModal } from "../../components/modals/changePhone/component";
import { Helmet } from "react-helmet";
import { useVerification } from "../../hooks/verification/useVerification";

export const Verification: FC = () => {
  const {
    t,
    formikVerification,
    isModalOpen,
    openAlert,
    setOpenAlert,
    toastMsg,
    resendCode,
    resetFlag,
    setIsModalOpen,
    data,
    isChangePhoneModalOpen,
    setIsChangePhoneModalOpen,
    setResetFlag,
    queryClient,
    user,
  } = useVerification();

  const styles = {
    verificationButton: {
      backgroundColor:
        formikVerification.isValid && formikVerification.dirty
          ? "#f76f8e"
          : "#828282",
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/verificar-numero"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/verificar-numero"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/verificar-numero"
        />
      </Helmet>
      <article className="verify-container">
        <VerificationCode
          isOpen={isModalOpen}
          setOpen={() => setIsModalOpen(false)}
        />
        <Toast open={openAlert} setOpen={setOpenAlert} type={toastMsg.type}>
          {toastMsg.message}
        </Toast>
        <div className="join-container">
          <div className="title-container">
            <h1 data-testid="title">{t("title")}</h1>
            <p>
              {t("instructions")}
              <span id="numeroVerify">{data?.phone}</span>
            </p>
          </div>
          <button id="ResendC" className="resend-button" onClick={resendCode}>
            {t("resend_code")}
          </button>
        </div>
        <div className="verify-form">
          <div className="input-form">
            <label data-testid="phone-code-label">{t("activation_code")}</label>
            <div className="multy-inputs">
              <PinInput
                length={4}
                initialValue={formikVerification.values.phoneCode}
                type="numeric"
                inputMode="number"
                data-testid="phone-input-verification"
                inputFocusStyle={{ border: "1px solid blue" }}
                autoSelect={true}
                onChange={formikVerification.handleChange("phoneCode")}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
          </div>
          <div>
            <CountdownProvider>
              <CountdownTimer shouldReset={resetFlag} />
            </CountdownProvider>
          </div>
          {formikVerification.errors.phoneCode && (
            <p className="error">{formikVerification.errors.phoneCode}</p>
          )}
          <div className="button-form">
            <button
              type="button"
              id="confirm-btn"
              data-testid="confirm-phone-verification"
              style={styles.verificationButton}
              onClick={() => formikVerification.handleSubmit()}
              className="btn btn-orange"
            >
              {t("confirm")}
            </button>
            <ChangePhoneNumberModal
              isOpen={isChangePhoneModalOpen}
              setOpen={() => {
                setResetFlag(!resetFlag);
                setIsChangePhoneModalOpen(false);
                queryClient.resetQueries(["user"]);
              }}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              user={user!}
            />

            <button
              className="resend-button"
              onClick={() => {
                const storedValue = localStorage.getItem("remainingSeconds");
                if (!storedValue || storedValue !== "0") {
                  setIsModalOpen(true);
                  return;
                }
                setIsChangePhoneModalOpen(true);
              }}
            >
              {t("change_number")}
            </button>
          </div>
        </div>
      </article>
    </>
  );
};
