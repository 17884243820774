import * as Yup from "yup";

export const editModelSchema: Yup.SchemaOf<editModelForm> = Yup.object().shape({
  mainAddress: Yup.object().shape({
    address_components: Yup.array()
      .of(
        Yup.object().shape({
          long_name: Yup.string(),
          short_name: Yup.string(),
          types: Yup.array().of(Yup.string().required()),
        })
      )
      .required(),
    formatted_address: Yup.string().required(),
    geometry: Yup.object().shape({
      location: Yup.object().shape({
        lat: Yup.string().required(),
        lng: Yup.string().required(),
      }),
      viewport: Yup.string(),
    }),
    html_attributions: Yup.array(),
    place_id: Yup.string().required(),
    name: Yup.string().required(),
  }),
  numSeats: Yup.number(),
  numBags: Yup.number(),
  transmission: Yup.number(),
  type: Yup.number(),
  plate: Yup.string(),
  isEcoFriendly: Yup.number(),
  experiences: Yup.string(),
});

export interface editModelForm {
  mainAddress: placeComponentFormEdit;
  numSeats?: number;
  numBags?: number;
  experiences?: string;
  transmission?: number;
  type?: number;
  plate?: string;
  isEcoFriendly?: number;
}

export interface placeComponentFormEdit {
  address_components: addressComponentEdit[];
  formatted_address: string;
  geometry: geometryFormEdit;
  html_attributions?: string[];
  place_id: string;
  name: string;
}

export interface addressComponentEdit {
  long_name?: string;
  short_name?: string;
  types: string[];
}

export interface geometryFormEdit {
  location: { lat: string; lng: string };
  viewport?: string;
}

export interface viewPortFormEdit {
  lo: number;
  hi: number;
}
