import {
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import navbarImages from "../../../../assets/images/navbar";
import a_carsImages from "../../../../assets/images/a_cars";
import { CarPriceProps } from "../../../../views/car/add/types";
import {
  TypeInsurance,
  HostStatus,
  DocPolicyStatus,
} from "../../../../models/host/types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { NumericFormat } from "react-number-format";
import { useAddCarPrice } from "./useAddCarPrice";
import { ExtraAddress } from "./extraAddress/ExtraAddress";
import { BlockedDates } from "./blockedDates/BlockedDates";
import { Trans } from "react-i18next";
import { numberSeparator } from "../../../helpers/textHandler";

/**
 * ADD CAR PRICE - step 4: price, blocked dates, delivery options and extra addresses
 */
export const AddCarPrice = ({
  formErrors,
  setEnableMainAddress,
  carId,
}: CarPriceProps) => {
  const {
    t,
    profit,
    values,
    setFieldValue,
    inputPricedProps,
    handleExtraAddressCounter,
    handleHomeDelivery,
    cost,
    homeDeliveryCost,
    host,
  } = useAddCarPrice({
    formErrors,
    setEnableMainAddress,
    carId,
  });

  return (
    <div className="price-disponibility" id="third_step">
      <div className="form-container">
        <div className="info-container">
          <h2 data-testid="price-disponibility-title">
            {t("add.car_price.price_and_availability")}
          </h2>
          <h3>{t("add.car_price.choose_price_and_availability.title")}</h3>
        </div>
        <div className="form">
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="e_drop_arrow"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_price.choose_price_and_availability.price")}
              </p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="price">
              <div className="price-text-container">
                <p>
                  {t(
                    "add.car_price.choose_price_and_availability.car_rental_per_day"
                  )}
                </p>
              </div>
              <div className="day-discount">
                <div className="price-d">
                  <label className="label-price">
                    {t(
                      "add.car_price.choose_price_and_availability.price_per_day.title"
                    )}
                  </label>
                  <NumericFormat
                    allowNegative={false}
                    className="input-price"
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    customInput={TextField}
                    value={parseFloat(values.price || "")}
                    onValueChange={(value) =>
                      setFieldValue("price", value.floatValue?.toString())
                    }
                    {...inputPricedProps}
                  />
                </div>
              </div>
              <div className="price-text-container d-flex align-items-center flex-column">
                <Trans>
                  <div className="percentage">
                    {t(
                      "add.car_price.choose_price_and_availability.comission_percentage",
                      {
                        percentage: `${parseInt(values?.commission || "0")}%`,
                      }
                    )}
                    <Chip
                      label={`$ ${numberSeparator(+profit.toFixed(2))} / día`}
                      className="chip-per-day"
                    />
                  </div>
                </Trans>
                <p>
                  <span>
                    {t("add.car_price.choose_price_and_availability.comission")}
                  </span>
                  <span className="more-details">
                    {t(
                      "add.car_price.choose_price_and_availability.more_details"
                    )}
                  </span>
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          {host?.insurance === TypeInsurance.HOST_INSURANCE &&
          host?.status === HostStatus.VALIDATED &&
          host?.rentPoliciesStatus === DocPolicyStatus.VALIDATED ? (
            <Accordion className="file-container" defaultExpanded>
              <AccordionSummary
                expandIcon={
                  <img
                    className="drop-row"
                    src={navbarImages.downArrow}
                    alt=""
                  />
                }
                className="d-flex justify-content-between accordion-summary"
                id="e_drop_arrow2"
              >
                <p className="option-title">
                  {t("add.car_price.insurance_and_security_deposit.title")}
                </p>
              </AccordionSummary>
              <AccordionDetails className="drop-options" id="insurance">
                <div className="insurance-text-container">
                  <p>
                    {t(
                      "add.car_price.insurance_and_security_deposit.enter_insurance_values"
                    )}
                  </p>
                </div>
                <div className="insurance-inputs">
                  <div className="charge-container">
                    <label>
                      {t(
                        "add.car_price.insurance_and_security_deposit.insurance"
                      )}
                    </label>
                    <TextField
                      id="txtCharge"
                      className="charge"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={values.insuranceCharge}
                      onChange={(e) => {
                        setFieldValue("insuranceCharge", e.target.value);
                      }}
                    />
                    <p className="insurance-recommendation">
                      <ElectricBoltIcon
                        fontSize="inherit"
                        sx={{ marginRight: "4px", color: "#ffab03" }}
                      />
                      <span>
                        {t(
                          "add.car_price.insurance_and_security_deposit.insurance_notice"
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="deposit-amount-container">
                    <label>
                      {t(
                        "add.car_price.insurance_and_security_deposit.down_payment"
                      )}
                    </label>
                    <TextField
                      id="txtDepositAmount"
                      className="depositAmount"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={values.depositAmount}
                      onChange={(e) => {
                        setFieldValue("depositAmount", e.target.value);
                      }}
                    />
                    <p className="insurance-recommendation">
                      <ElectricBoltIcon
                        fontSize="inherit"
                        sx={{ marginRight: "4px", color: "#ffab03" }}
                      />
                      <span>
                        {t(
                          "add.car_price.insurance_and_security_deposit.down_payment_notice"
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
              className="d-flex justify-content-between accordion-summary"
              id="e_drop_arrow2"
            >
              <p className="option-title">
                {t("add.car_price.availability.title")}
              </p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="disponibility">
              <BlockedDates carId={carId || "0"} />
            </AccordionDetails>
          </Accordion>
          <Accordion className="file-container" defaultExpanded>
            <AccordionSummary
              className="d-flex justify-content-between accordion-summary"
              id="e_drop_arrow3"
              expandIcon={
                <img className="drop-row" src={navbarImages.downArrow} alt="" />
              }
            >
              <p className="option-title">
                {t("add.car_price.delivery_return.title")}
              </p>
            </AccordionSummary>
            <AccordionDetails className="drop-options" id="delivery-ubication">
              <div className="ubication">
                <h3>
                  {t("add.car_price.delivery_return.address.title")}
                  <Tooltip
                    title={t("add.car_price.tooltips.main_address_title")}
                  >
                    <img
                      src={a_carsImages.info}
                      alt="info"
                      style={{ paddingLeft: "6px" }}
                    />
                  </Tooltip>
                </h3>

                {values.mainAddress && (
                  <>
                    <p className="direction">
                      {values.mainAddress?.formatted_address}
                    </p>
                    <TextField
                      className="direction-des"
                      id="main_direction"
                      disabled
                      defaultValue={values.mainAddress?.formatted_address}
                      type="text"
                    />
                  </>
                )}
                <div className="enable-main-address">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="aqua"
                        onChange={(e, checked) => setEnableMainAddress(checked)}
                        // disabled={Object.values(extraAddress).length === 0}
                      />
                    }
                    label={t("add.car_price.delivery_return.enable_option")}
                  />
                  <Tooltip
                    title={t("add.car_price.tooltips.main_address_price")}
                  >
                    <img
                      src={a_carsImages.info}
                      alt="info"
                      style={{ margin: "0 0 2px -10px" }}
                    />
                  </Tooltip>
                </div>
                <span className="main-address-warning">
                  {t(
                    "add.car_model_and_ubication.information_to_register_your_car"
                  )}
                </span>
              </div>
              <div className="extra-option">
                {Object.keys(values.extraAddresses).length > 0 && (
                  <div className="dropdown-divider"></div>
                )}
                <div
                  id="extra_direction_container1"
                  className="d-flex mb-3 flex-wrap"
                >
                  {Object.keys(values.extraAddresses).length !== 0 &&
                    Object.keys(values.extraAddresses).map(
                      (value, item, array) => (
                        <div className="mb-3 mt-3" key={item}>
                          <p className="extra-option-title">
                            {t("add.car_price.delivery_return.extra_option")}{" "}
                            {item + 1}
                            <Tooltip
                              title={t(
                                "add.car_price.tooltips.delivery_return"
                              )}
                            >
                              <img
                                src={a_carsImages.info}
                                alt="info"
                                style={{ padding: "2px 6px" }}
                              />
                            </Tooltip>
                          </p>
                          <div className="d-flex extra-address-container align-items-center">
                            <ExtraAddress key={item} index={item} />
                          </div>
                          <p className="no-charge-recommendation">
                            <ElectricBoltIcon
                              fontSize="inherit"
                              sx={{ marginRight: "4px", color: "#ffab03" }}
                            />
                            {t(
                              "add.car_price.delivery_return.no_charge_recommendation"
                            )}
                          </p>
                          {item !== array.length - 1 && (
                            <div className="dropdown-divider"></div>
                          )}
                        </div>
                      )
                    )}
                </div>
                <div
                  className="dropdown-divider"
                  style={{ marginTop: "40px" }}
                ></div>
                <div className="add-container">
                  <button
                    type="button"
                    id="add_direction"
                    disabled={Object.keys(values.extraAddresses).length === 4}
                    onClick={() => handleExtraAddressCounter()}
                    className="btn btn-add-direction"
                  >
                    <p>{t("add.car_price.delivery_return.add_direction")}</p>
                    <img
                      className="plus-icon"
                      src={a_carsImages.plus}
                      alt="info"
                    />
                  </button>
                  <Tooltip
                    style={{ width: "24px" }}
                    title={t("add.car_price.tooltips.delivery_return")}
                  >
                    <img src={a_carsImages.info} alt="" />
                  </Tooltip>
                </div>
                <div className="w-100">
                  <div className="delivery-subtitle">
                    <h3>
                      {t("add.car_price.delivery_return.delivery_service")}
                    </h3>
                    <Tooltip title={t("add.car_price.tooltips.delivery")}>
                      <img
                        src={a_carsImages.info}
                        alt="info"
                        style={{ marginLeft: "4px" }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex justify-content-between home-delivery">
                    <div className="enable-home-delivery">
                      <div className="enable-check">
                        <FormControlLabel
                          sx={{
                            fontFamily: "font-family-montserrat",
                            margin: 0,
                            fontWeight: 700,
                          }}
                          control={
                            <Checkbox
                              color="aqua"
                              checked={values.homeDelivery?.enable ?? true}
                              onChange={(e) => handleHomeDelivery(e)}
                              sx={{ padding: "0 6px 0 0" }}
                            />
                          }
                          label={t(
                            "add.car_price.delivery_return.enable_home_delivery"
                          )}
                        />
                      </div>
                    </div>
                    <div className="add-price" style={{ bottom: "0px" }}>
                      <span>
                        {t("add.car_price.delivery_return.add_price")}
                        <Tooltip title={t("add.car_price.tooltips.add_price")}>
                          <img
                            src={a_carsImages.info}
                            alt="info"
                            style={{ marginLeft: "4px" }}
                          />
                        </Tooltip>
                      </span>
                      <div id="delibery_home_price" className="delibery-price">
                        <NumericFormat
                          allowNegative={false}
                          id="txtpriceD"
                          className="delibery-input"
                          thousandSeparator={true}
                          decimalScale={2}
                          customInput={TextField}
                          value={values.homeDelivery?.price ?? "0"}
                          onChange={(e) =>
                            setFieldValue("homeDelivery", {
                              ...values.homeDelivery,
                              price: e.target.value,
                            })
                          }
                          disabled={!values.homeDelivery?.enable || cost}
                        />
                        <div className="enable-check enable-check-extra">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="aqua"
                                disabled={!values.homeDelivery?.enable}
                                checked={cost}
                                onChange={(e) => homeDeliveryCost(e)}
                              />
                            }
                            label={t("add.car_price.delivery_return.no_charge")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="no-charge-recommendation mt-3">
                    <ElectricBoltIcon
                      fontSize="inherit"
                      sx={{ marginRight: "4px", color: "#ffab03" }}
                    />
                    {t(
                      "add.car_price.delivery_return.no_charge_recommendation"
                    )}
                  </p>
                </div>
                <div id="extra-divider" className="dropdown-divider"></div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
