import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { FC } from "react";
import useCards from "../../../hooks/cards";
import navbarImages from "../../../assets/images/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import { Card, CardImgIndex } from "../../../models/card/types";
import { useQuery } from "react-query";
import { getRegisteredCards } from "../../../services/card";
import { useTranslation } from "react-i18next";
import carImages from "../../../assets/images/car/index";
import { Chip } from "@mui/material";
export interface ModalPropsType {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setOpenAdd: (open: boolean) => void;
}

export const CardsModal: FC<ModalPropsType> = ({
  isOpen,
  setOpen,
  setOpenAdd,
}) => {
  const { errorMsn, setDefault, deleteCard } = useCards();
  const { data: cards } = useQuery(["cards"], getRegisteredCards, {
    enabled: isOpen,
  });
  const { t } = useTranslation(["profile"]);

  // Open add new card modal
  const handleClick = () => {
    setOpen(false);
    setOpenAdd(true);
  };

  // Set selected card as default
  const setAsDefault = async (cardId: string) => {
    return await setDefault(cardId);
  };

  // Selected option action
  const handleSelect = (e: string | null, cardId: string) => {
    if (e === "set-default") {
      setAsDefault(cardId).then(() => setOpen(false));
    }

    if (e === "delete") {
      deleteCard(cardId).then(() => setOpen(false));
    }
  };

  const carImagesIndex = carImages as CardImgIndex;

  return (
    <Modal show={isOpen} className="cards-modal d-flex align-items-center">
      <div className="cards-content">
        <div className="cards-header">
          <button
            type="button"
            className="close"
            onClick={() => setOpen(false)}
          >
            <img src={navbarImages.cross} alt="close" />
          </button>
          <h2>{t("profile_boxes.pay_method_modals.my_cards")}</h2>
        </div>
        <div className="cards-body">
          {cards &&
            cards.cards?.map((item: Card) => (
              <div className="card-item" key={item.id}>
                <div className="card-data-container">
                  <div className="card-data">
                    <img
                      className="card-brand"
                      src={carImagesIndex[item.brand] || carImages.generic}
                    />
                    <p className="card-num">**** **** **** {item.last4}</p>
                    {item.isDefault && (
                      <Chip
                        label="Default"
                        variant="outlined"
                        sx={{
                          color: "#2289e5",
                          backgroundColor: "#d6e8fa",
                          border: "none",
                        }}
                      />
                    )}
                  </div>
                  <p className="card-exp">Vence {item.expirationDate}</p>
                </div>
                <div className="card-action">
                  <DropdownButton
                    id="dropdown-btn"
                    title="..."
                    onSelect={(e) => handleSelect(e, item.id)}
                  >
                    <Dropdown.Item
                      eventKey="set-default"
                      disabled={item.isDefault}
                    >
                      {t("profile_boxes.pay_method_modals.set_default")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="delete"
                      disabled={cards.cards.length === 1 || item.isDefault}
                    >
                      {t("profile_boxes.pay_method_modals.delete_card")}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            ))}
        </div>
        {errorMsn && <p className="cards-error">{errorMsn}</p>}
        <div className="cards-footer">
          <button className="btn btn-blue" onClick={handleClick}>
            {t("profile_boxes.pay_method_modals.add")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
