import { createContext, Dispatch, SetStateAction } from "react";
import { mockDestination } from "../../../../models/destination/mockups";
import {
  currentLocation,
  Destination,
} from "../../../../models/destination/types";

interface DestinationContextProps {
  setDropdownAction: Dispatch<SetStateAction<boolean>>;
  setDestinationSelected: Dispatch<SetStateAction<Destination>>;
  destinations: Destination[];
  setType: Dispatch<SetStateAction<"current" | "destination" | "">>;
  setCurrentLocation: Dispatch<SetStateAction<currentLocation>>;
}

export const DestinationContext = createContext<DestinationContextProps>({
  setDropdownAction: () => {
    //
  },
  setDestinationSelected: () => {
    //
  },
  destinations: [mockDestination],
  setType: () => {
    //
  },
  setCurrentLocation: () => {
    //
  },
});
