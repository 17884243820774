import alertTriangle from "./alert_triangle.svg";
import arrowLeft from "./arrow_left.svg";
import car from "./car.png";
import comercialPerkInsured from "./comercial_perk_insured.png";
import comercialPerkSupport from "./comercial_perk_support.png";
import comercialPerkUbication from "./comercial_perk_ubication.png";
import cross from "./cross.svg";
import generic from "./generic.svg";
import hostPerkCancel from "./host_perk_cancel.png";
import hostPerkKilometers from "./host_perk_kilometers.png";
import map from "./map.png";
import marker from "./marker.png";
import place from "./place.svg";
import thumbsUp from "./thumbs_up.png";
import not_uploaded from "./not_uploaded.png";
import pending from "./pending.png";
import validated from "./validated.png";
import refused from "./refused.png";
import expirated from "./expirated.svg";
import defaultCar from "./car_default_image.png";
import compactType from "./compacto.svg";
import carType from "./sedan.svg";
import coupeType from "./coupe.svg";
import suvType from "./suv.svg";
import minivanType from "./minivan.svg";
import vanType from "./van.svg";
import truckType from "./truck.svg";
import classicExp from "./classicExp.svg";
import familyExp from "./familyExp.svg";
import convertibleExp from "./convertibleExp.svg";
import ecoExp from "./ecoExp.svg";
import offRoadExp from "./offRoadExp.svg";
import petExp from "./petExp.svg";
import roadReadyExp from "./roadReadyExp.svg";
import dailyExp from "./dailyExp.svg";
import sportExp from "./sportExp.svg";
import where from "./where.png";
import warningTriangle from "./warning_triangle.svg";

export default {
  alertTriangle,
  arrowLeft,
  car,
  comercialPerkInsured,
  comercialPerkSupport,
  comercialPerkUbication,
  cross,
  generic,
  hostPerkCancel,
  hostPerkKilometers,
  map,
  marker,
  place,
  thumbsUp,
  not_uploaded,
  pending,
  validated,
  refused,
  expirated,
  defaultCar,
  compactType,
  carType,
  coupeType,
  suvType,
  minivanType,
  vanType,
  truckType,
  classicExp,
  familyExp,
  convertibleExp,
  ecoExp,
  offRoadExp,
  petExp,
  roadReadyExp,
  dailyExp,
  sportExp,
  where,
  warningTriangle,
};
