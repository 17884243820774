import { FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import images from "../../../../../assets/images/order";
import { useCarCategories } from "./useCarCategories";

export const CarCategories = () => {
  const { t, DATA, EXPERIENCES, handleCategories, handleActiveCategories } =
    useCarCategories();

  return (
    <FormControl fullWidth sx={{ paddingLeft: "12px" }}>
      <div className="mt-4">
        <p className="fw-bolder font-family-montserrat">
          <label data-testid="categories-title">
            {t("add.car_documentation.categories.title")}
          </label>
        </p>
      </div>
      <div className="d-flex exp">
        <div>
          {DATA.slice(0, 5).map((item) => (
            <div className="categorie" key={item.index}>
              <CheckboxItem
                handleChange={handleCategories}
                index={item.index}
                label={item.text}
                key={item.text}
                value={handleActiveCategories}
              />
              <img
                style={{
                  marginRight: "100px",
                }}
                src={EXPERIENCES[item.index].src}
                alt="tipo compacto"
              />
            </div>
          ))}
        </div>
        <div>
          {DATA.slice(5).map((item) => (
            <div className="categorie" key={item.index}>
              <CheckboxItem
                handleChange={handleCategories}
                index={item.index}
                label={item.text}
                key={item.text}
                value={handleActiveCategories}
              />
              <img
                style={{
                  marginRight: "5px",
                }}
                src={EXPERIENCES[item.index].src}
                alt="tipo compacto"
              />
            </div>
          ))}
        </div>
      </div>
    </FormControl>
  );
};

const CheckboxItem = ({
  handleChange,
  index,
  label,
  value,
}: {
  handleChange: (
    event: ChangeEvent<HTMLInputElement>,
    position: number
  ) => void;
  index: number;
  label: string;
  value: (position: number) => boolean;
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid={label + "-checkboxes"}
          icon={<img src={images.boxEmpty} /> || <CheckBoxOutlineBlankIcon />}
          checkedIcon={<img src={images.boxCheck} /> || <CheckBoxIcon />}
          onChange={(e) => handleChange(e, index)}
          color="balety"
          checked={value(index)}
        />
      }
      label={label}
    />
  );
};
