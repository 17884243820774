import { Address } from "../models/address/types";

export const useAddressTypes = (addresses: Address[]) => {
  const main = addresses?.filter(
    (address: Address) => address.type === 0 && address.status === 1
  );

  const extra = addresses?.filter(
    (address: Address) => address.type === 1 && address.status === 1
  );

  const home = addresses?.filter(
    (address: Address) => address.type === 2 && address.status === 1
  );

  return { home, extra, main };
};
