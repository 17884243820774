import carImagesIndex from "../../../../assets/images/car/index";

export const PHONE_EMAIL_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
};

export const OFICIAL_DOCUMENTS_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.pending}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  2: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  3: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  4: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  5: (
    <img
      src={carImagesIndex.expirated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
};

export const ADDRESS_ACCOUNT_ICON_STATUS = {
  0: (
    <img
      src={carImagesIndex.not_uploaded}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  1: (
    <img
      src={carImagesIndex.pending}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  2: (
    <img
      src={carImagesIndex.validated}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
  3: (
    <img
      src={carImagesIndex.refused}
      style={{ marginRight: "6px", fontSize: "14px", width: "14px" }}
    />
  ),
};
