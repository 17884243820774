export const decryptId = (hexFormated: string) => {
  const hex = hexFormated.replace(/-/g, "");
  return (parseInt(hex, 16) - 687_463_858_944 * 100).toString();
};

export const encryptId = (carId: string) => {
  const id = (687_463_858_944 * 100 + parseInt(carId))
    .toString(16)
    .toUpperCase();
  return id.slice(0, 6) + "-" + id.slice(6);
};
