import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";
import { getDaysArray } from "../lib/helpers/dateHandler";
import { Dates } from "../models/date/types";
import { getCar } from "../services/car";
import { getDateByCarID } from "../services/date";
import { EditDateBlock } from "./../lib/components/carTools/editCarPrice/types";
export const useDisableDates = ({
  carId,
  setBlockDates,
  blockDates,
}: {
  setBlockDates: Dispatch<SetStateAction<EditDateBlock[]>>;
  blockDates: EditDateBlock[];
  carId: string;
}) => {
  const { data: car } = useQuery(["car", carId], () => getCar(carId), {
    enabled: !!carId && carId !== "0",
  });

  const { data: disabledDates } = useQuery(
    ["blockedDates", car?.id],
    () => getDateByCarID(car?.id || 0),
    {
      enabled: !!carId,
    }
  );

  useEffect(() => {
    if (disabledDates && disabledDates.length > 0) {
      handleDisabledDates(disabledDates);
    }
  }, [disabledDates]);

  const handleDisabledDates = (disabledDates: Dates[]) => {
    disabledDates.map((date: Dates) => {
      const list = getDaysArray(date.dateInitial, date.dateEnd);
      setBlockDates((prev) => [
        ...prev,
        {
          id: date.id,
          startDate: dayjs(date.dateInitial, "DD-MM-YYYY").format("DD/MM/YYYY"),
          endDate: dayjs(date.dateEnd, "DD-MM-YYYY").format("DD/MM/YYYY"),
          status: date.status,
          dates: list,
        },
      ]);
    });
  };

  return { blockDates };
};
