import { FC } from "react";
import { useQuery } from "react-query";
import { getAddressTrip } from "../../../../services/addressTrip";
import { TripStatus } from "../../../../models/trip/types";

interface addressProps {
  addressId: number | undefined;
  alert?: boolean;
  tripStatus?: TripStatus;
}

export const AddressTextHost: FC<addressProps> = ({
  addressId,
  alert = true,
}) => {
  const { data: addressTrip } = useQuery(
    ["addressTrip", addressId],
    () => getAddressTrip(addressId),
    { enabled: !!addressId }
  );

  if (!addressTrip) {
    return <></>;
  }

  return (
    <div className="input-text-large">
      {alert ? (
        <a
          className="deliberyAddress"
          href={`https://www.google.es/maps?q=${addressTrip.latitude},${addressTrip.longitude}`}
          target="_blank"
        >
          {addressTrip.comercialName}
        </a>
      ) : (
        <p className="deliberyAddress">{addressTrip.comercialName}</p>
      )}
      <p
        className="deliberyPrice"
        style={{
          display: `${alert ? "flex" : "none"}`,
        }}
      >
        ${addressTrip.price}
      </p>
    </div>
  );
};
