import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { ShowContext } from "../../../../../views/car/show/types";
import { useContext } from "react";
import { CarResults } from "../../../../../models/car/types";
import CarResultCard from "../../../../../components/carResultCard/component";
import { mockResultParams } from "../../../../../views/results/types";
import { useQuery } from "react-query";
import { getCarsByState } from "../../../../../services/car";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import "./CarSuggestions.scss";
import { useWindowSize } from "../../../../../hooks/windowSize";
import { DomainContext } from "../../../../../providers/domainProvider";

export const CarSuggestions = ({
  componentColor,
  title,
  arrowColor,
  titlePosition,
}: {
  componentColor: string;
  title: string;
  arrowColor?: string;
  titlePosition?: string;
}) => {
  const { car } = useContext(ShowContext);
  const { hostComercialId } = useContext(DomainContext);
  const [search] = useSearchParams();
  const windowSize = useWindowSize();

  const { data: cars } = useQuery(
    ["cars", car],
    () =>
      getCarsByState({
        destinationId: parseInt(search.get("destinationId") || "0"),
        state: search.get("state") || "Jalisco",
        dateInitial: dayjs().format("YYYY-MM-DD"),
        dateEnd: dayjs().add(1, "day").format("YYYY-MM-DD"),
        transmission: [0, 1],
        type: car ? [car?.type] : [0, 1, 2, 3, 4, 5],
        priceLowerBound: 10,
        priceUpperBound: 10000,
        experiences: "000000000",
        address: 0,
        depositAmount: 0,
        ecologyCar: 0,
        hostComercialId,
      }),
    { enabled: hostComercialId != 0 }
  );

  return (
    <>
      {windowSize && cars && (
        <div className="carousel-suggestions-container">
          <h3 className={`title-${titlePosition}`}>{title}</h3>
          <Swiper
            slidesPerView={
              windowSize < 900 ? 1 : (cars.length || 1) < 3 ? cars.length : 3
            }
            spaceBetween={20}
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            navigation
            modules={[Autoplay, Navigation]}
            className={`swiper-suggestions arrow-${arrowColor}`}
          >
            {cars?.slice(0, 5).map((car: CarResults, index: number) => (
              <SwiperSlide
                key={car.id + index + car.amis}
                style={{ backgroundColor: componentColor }}
              >
                <CarResultCard
                  car={car}
                  key={`${car.id}-${index}`}
                  params={mockResultParams}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
