import { useEffect, useState } from "react";
import { timeConversion } from "../lib/helpers/timeHandler";

export const useHours = (step: number) => {
  const [formatHours, setFormatHours] = useState<string[]>([]);
  const [fullHours, setfullHours] = useState<string[]>([]);

  useEffect(() => {
    const hours = getHoursByStep(step);
    const formatedHours = hours.map((hour) => timeConversion(hour));

    setFormatHours(formatedHours);
    setfullHours(hours);
  }, [step]);

  return { formatHours, fullHours };
};

const getHoursByStep = (step: number) => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60 / step; minutes++) {
      hours.push(
        `${hour < 10 ? "0" + hour : hour}:${
          minutes === 0 ? `00` : step * minutes
        }`
      );
    }
  }

  return hours;
};
