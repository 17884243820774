import i18n from "../../i18n";

export const changeLngRoute = (lng: string) => {
  const path = location.pathname;
  if (lng !== "es") {
    return "/en" + (path === "/" ? "" : path);
  }

  return path.replace("/en", "");
};

export enum Lng {
  TRUE,
  WRONGLNG,
  FALSE,
}

// Host list of routes
export const HostRoutes = (lng: string) => [
  "/" + i18n.t("admin", { ns: "routes", lng }),
  "/" + i18n.t("sell", { ns: "routes", lng }),
  "/" + i18n.t("manage", { ns: "routes", lng }),
  "/" + i18n.t("protect", { ns: "routes", lng }),
  "/" + i18n.t("grow", { ns: "routes", lng }),
  "/" + i18n.t("ai", { ns: "routes", lng }),
  "/" + i18n.t("settings", { ns: "routes", lng }),
];

// Driver list of routes
export const DriverRoutes = (lng: string) => [
  "/" +
    i18n.t("not_found", {
      ns: "routes",
      lng,
    }),
  i18n.t("root", {
    ns: "routes",
    lng,
  }),
  "/" +
    i18n.t("signup", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("complete_profile", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("verify", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("profile", {
      ns: "routes",
      lng,
    }),
  "/" + i18n.t("trips", { ns: "routes", lng }),
  "/" + i18n.t("favorites", { ns: "routes", lng }),
  "/" +
    i18n.t("results", {
      ns: "routes",
      lng,
    }),
  "/" + i18n.t("details", { ns: "routes", lng }),
  "/" + i18n.t("checkout", { ns: "routes", lng }),
  "/" +
    i18n.t("commercial_signup", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("commercial_host", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("host", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("recover_password", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("sent_recover_password", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("reset_password", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("kyc_success", {
      ns: "routes",
      lng,
    }),
  "/" +
    i18n.t("chat", {
      ns: "routes",
      lng,
    }),
];

export const handleRoutes = (specificUrl: string) => {
  // Set a url depending on location

  if (DriverRoutes(i18n.language).includes(specificUrl)) {
    return "driver";
  } else if (HostRoutes(i18n.language).includes(specificUrl)) {
    return "host";
  } else {
    return "";
  }
};

export const formatRoute = () => {
  const path = location.pathname;
  const fullUrl = path.replace("/en/", "/").match(/\/(.*?)(?=\/|$)/);
  let specificUrl = fullUrl ? fullUrl[0] : path;

  if (specificUrl && path.includes("/en/")) {
    specificUrl = "/en" + specificUrl;
  }

  return specificUrl;
};

export const getBeforeThirdSlash = (route: string) => {
  return route.split("/").slice(0, 3).join("/");
};
