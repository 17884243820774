import { FC } from "react";
import navbarImages from "../../../assets/images/navbar";
import "./styles.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ModalAccountNotFoundProps {
  isOpen: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setOpen: () => any;
  email: string;
}

export const ModalAccountNotFound: FC<ModalAccountNotFoundProps> = ({
  isOpen,
  setOpen,
  email,
}) => {
  const { t } = useTranslation(["modals"]);
  const navigate = useNavigate();

  return (
    <Modal show={isOpen} className="not-found-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={setOpen}>
          <img src={navbarImages.crossBlack} alt="close" />
        </button>
        <h2>{t("account_not_found.title")}</h2>
      </div>
      <div className="modal-body">
        <div className="message">
          <p className="subtitle">
            {t("account_not_found.description")}
            <strong>{email}</strong>
            {t("account_not_found.description_extra")}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-orange"
            onClick={() => navigate("/" + t("signup", { ns: "routes" }))}
          >
            {t("account_not_found.create")}
          </button>
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-transparent"
            onClick={setOpen}
          >
            {t("account_not_found.try_again")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
