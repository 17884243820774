import { useTranslation } from "react-i18next";
import authenticationImages from "../../../assets/images/authentication";
import "./styles.scss";
import { useFormik } from "formik";
import { ForgotPasswordForm, forgotSchema } from "./form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { sendRecoverPassword } from "../../../services/forgotPassword";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { ModalAccountNotFound } from "../../../components/modals/accountNotFound/component";

const ForgotPassword = () => {
  const { t } = useTranslation(["forgotPassword"]);
  const navigate = useNavigate();
  const [accountExists, setAccountExists] = useState(true);
  const [currentEmail, setCurrenEmail] = useState("");

  const fetchForgotPassword = async (values: ForgotPasswordForm) => {
    const response = await sendRecoverPassword(values.email);
    setCurrenEmail(values.email);

    if (response && response.status === 200) {
      navigate({
        pathname: "/" + t("sent_recover_password", { ns: "routes" }),
        search: `?${createSearchParams({ email: values.email })}`,
      });
    } else {
      setAccountExists(false);
    }
  };

  const formikForgotPassword = useFormik<ForgotPasswordForm>({
    initialValues: {
      email: "",
    },
    validationSchema: forgotSchema,
    onSubmit: fetchForgotPassword,
  });

  const styles = {
    SendButton: {
      backgroundColor:
        formikForgotPassword.isValid && formikForgotPassword.dirty
          ? "#f76f8e"
          : "#828282",
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta name="robots" content="noindex,follow" />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/recuperar-contrasena"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/recuperar-contrasena"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/recuperar-contrasena"
        />
      </Helmet>
      <div className="forgot-password d-flex justify-content-center align-items-center flex-column">
        <div className="main d-flex flex-column justify-content-center align-items-center">
          <img
            src={authenticationImages.forget}
            alt="car image"
            className="forgot-image"
          />
          <h4 className="title">{t("forgot_password.title")}</h4>
          <p className="subtitle">{t("forgot_password.subtitle")}</p>
          <div className="form-container">
            <div className="input-form">
              <label className="email-label">
                {t("forgot_password.email.label")}
              </label>
              <input
                name="email"
                id="txtEmail"
                data-testid="email-input"
                style={{
                  borderColor:
                    formikForgotPassword.errors.email &&
                    formikForgotPassword.touched.email
                      ? "var(--Semantic-Colors-Error)"
                      : "var(--Utility-Colors-Secondary-Background)",
                }}
                className="email-input"
                placeholder={t("forgot_password.email.placeholder")}
                onChange={formikForgotPassword.handleChange("email")}
                onBlur={formikForgotPassword.handleBlur("email")}
                value={formikForgotPassword.values.email}
              />
            </div>
            <div className="button-container">
              <button
                type="button"
                className="btn btn-send"
                style={styles.SendButton}
                onClick={() => formikForgotPassword.handleSubmit()}
              >
                {t("forgot_password.send")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalAccountNotFound
        email={currentEmail}
        isOpen={!accountExists}
        setOpen={() => setAccountExists(true)}
      />
    </>
  );
};

export default ForgotPassword;
