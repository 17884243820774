export interface AddressTrip {
  id: number;
  carId: number;
  status: AddressTripStatus;
  type: AddressTripType;
  comercialName: string;
  completeAddress: string;
  intNumber?: string;
  number?: string;
  street: string;
  suburb: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  price?: string;
  placeId: string;
}

export enum AddressTripStatus {
  DEACTIVATED,
  ACTIVE,
}

export enum AddressTripType {
  MAIN_ADDRESS,
  EXTRA_ADDRESS,
  HOME_DELIVERY_ADDRESS,
  DRIVER_DELIVERY_ADDRESS,
}
