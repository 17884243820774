import { useState, useEffect } from "react";
import { StripeCardElement } from "@stripe/stripe-js";
import {
  getSetupIntent,
  getRegisteredCards,
  setDefaultCard,
} from "../services/card";
import { useStripe } from "@stripe/react-stripe-js";
import { useQuery } from "react-query";
import { detachCard, validateCard } from "../services/card";
import { Card, AddressData } from "../models/card/types";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

interface FormData {
  name?: string;
  email: string;
  phone: string;
  address?: AddressData;
}

interface ConfirmSetupProps {
  card?: StripeCardElement | null;
  paymentId?: string;
  type: "to-pay" | "add";
  billData?: FormData;
}

const useCards = () => {
  const stripe = useStripe();
  const [errorMsn, setErrorMsn] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: cards } = useQuery(["cards"], getRegisteredCards, {
    enabled: !errorMsn,
  });
  const { t } = useTranslation(["profile"]);

  // Set one card as default if there is none
  useEffect(() => {
    if (
      cards &&
      cards?.cards?.length > 0 &&
      cards?.cards?.every((item: Card) => !item.isDefault)
    ) {
      setDefaultCard(cards.cards[0].id);
    }
  }, [cards]);

  // Detach card from customer payment methods
  const deleteCard = async (id: string) => {
    try {
      const result = await detachCard(id);
      setErrorMsn(null);
      return result?.data;
    } catch (error) {
      setErrorMsn(t("profile_boxes.pay_method_modals.error_delete"));
    }
  };

  // Set card as default payment method
  const setDefault = async (id: string) => {
    try {
      const result = await setDefaultCard(id);
      setErrorMsn(null);
      return result?.data;
    } catch (error) {
      setErrorMsn(t("profile_boxes.pay_method_modals.error_update"));
    }
  };

  const validateCardAsCredit = async (paymentId: string) => {
    try {
      const response = await validateCard(paymentId);
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        let message = "";

        if (error.response?.data.checks) {
          const checks = error.response?.data.checks;
          const checkError = [];

          if (checks.Address1LineCheck === "fail")
            checkError.push("address Line 1");
          if (checks.CvcCheck === "fail") checkError.push("CVC");
          if (checks.AddressPostalCodeCheck === "fail")
            checkError.push("address postal code");

          message = `${error.response?.data.message}: ${checkError.join(
            ", "
          )} failed`;
        } else {
          message = error.response?.data.message;
        }
        setErrorMsn(message);
      }
    }
  };

  // Save customer card as a payment method
  const confirmSetup = async ({
    card,
    paymentId,
    type,
    billData,
  }: ConfirmSetupProps) => {
    setLoading(true);

    if (
      !stripe ||
      (type === "add" && !card) ||
      (type === "to-pay" && !paymentId)
    ) {
      return;
    }

    const data = await getSetupIntent();
    if (!data.clientSecret) {
      setErrorMsn(t("profile_boxes.pay_method_modals.error_add"));
      return;
    }

    // Payment id or element payment info object to save card on stripe
    const paymentData: any = // eslint-disable-line @typescript-eslint/no-explicit-any
      type === "to-pay"
        ? paymentId
        : {
            card: card,
            billing_details: billData,
          };

    // Confirm saving
    if (!paymentData) return;
    const resultCardSetup = await stripe.confirmCardSetup(data.clientSecret, {
      payment_method: paymentData,
    });

    if (resultCardSetup.error) {
      setErrorMsn(resultCardSetup.error.message);
      setLoading(false);
      return;
    }

    // Validate that is a credit card
    const validationResponse = await validateCardAsCredit(
      resultCardSetup.setupIntent.payment_method as string
    );

    if (!validationResponse) {
      setLoading(false);
      return;
    }

    if (
      !cards.cards &&
      typeof resultCardSetup.setupIntent.payment_method === "string"
    ) {
      await setDefault(resultCardSetup.setupIntent.payment_method);
    }

    setErrorMsn(null);
    setLoading(false);
    return resultCardSetup;
  };

  useEffect(() => {
    if (errorMsn) {
      setTimeout(() => {
        setErrorMsn(null);
      }, 8000);
    }
  }, [errorMsn]);

  return {
    errorMsn,
    loading,
    confirmSetup,
    stripe,
    cards,
    setDefault,
    deleteCard,
  };
};

export default useCards;
