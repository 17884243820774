import boxesCarousel from "./boxes_carousel.webp";
import carRulesPerk from "./car_rules_perk.png";
import controlPerk from "./control_perk.png";
import conversation from "./conversation.webp";
import datesPerk from "./dates_perk.png";
import discountPerk from "./discount_perk.png";
import driveCarousel from "./drive_carousel.webp";
import girlCarousel from "./girl_carousel.webp";
import girlCarouselMobile from "./girl_carousel_mobile.webp";
import insurancePerk from "./insurance_perk.png";
import insurance from "./insurance.webp";
import insuranceMobile from "./insurance_mobile.webp";
import listPerk from "./list_perk.png";
import moneyPerk from "./money_perk.png";
import pricePerk from "./price_perk.png";
import firstTestimony from "./first_testimony.webp";
import secondTestimony from "./second_testimony.webp";
import thirdTestimony from "./third_testimony.webp";
import verifiedPerk from "./verified_perk.png";
import profitsPink from "./profits_pink.png";
import redCar from "./red_car.webp";
import redCarMobile from "./red_car_mobile.webp";
import wayPerk from "./way_perk.png";
import romioWorks from "./romio_works.webp";
import romioWorksMobile from "./romio_works_mobile.webp";
import checkInsurance from "./check_insurance.svg";
import lote from "./lote.webp";
import loteMobile from "./lote_mobile.png";
import carouselVector from "./carousel_vector.png";
import speedUpVector from "./speed_up_earnings_vector.png";
import sprinklesVector from "./sprinkles_vector.png";
import communityVector from "./community_vector.png";
import arrowVector from "./arrow_vector.png";
import testimonyOneVector from "./first_testimony_vector.png";
import testimonyOneVectorMobile from "./first_testimony_vector_mobile.png";
import testimonyThreeVector from "./third_testimony_vector.png";
import testimonyThreeVectorMobile from "./third_testimony_vector_mobile.png";

export default {
  boxesCarousel,
  carRulesPerk,
  controlPerk,
  conversation,
  datesPerk,
  discountPerk,
  driveCarousel,
  girlCarousel,
  girlCarouselMobile,
  insurancePerk,
  insurance,
  insuranceMobile,
  listPerk,
  moneyPerk,
  pricePerk,
  firstTestimony,
  secondTestimony,
  thirdTestimony,
  verifiedPerk,
  profitsPink,
  redCar,
  redCarMobile,
  wayPerk,
  romioWorks,
  romioWorksMobile,
  checkInsurance,
  lote,
  loteMobile,
  carouselVector,
  speedUpVector,
  sprinklesVector,
  communityVector,
  arrowVector,
  testimonyOneVector,
  testimonyOneVectorMobile,
  testimonyThreeVector,
  testimonyThreeVectorMobile,
};
