import * as Yup from "yup";

export interface resetPasswordForm {
  password: string;
  confirmPassword: string;
}

export const resetPasswordSchema: Yup.SchemaOf<resetPasswordForm> =
  Yup.object().shape({
    password: Yup.string()
      .min(3, "La clave debe contener al menos 3 caracteres")
      .required("La contraseña es requerida"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Las contraseñas deben de coincidir")
      .required("La confirmacion de la contraseña es requerida"),
  });
