import { forwardRef } from "react";
import { Modal } from "react-bootstrap";
import "./Notifications.scss";
import { useTranslation } from "react-i18next";
import { Notification, notifications } from "../../adminNavbar/AdminNavbar";
import { ModalProps } from "../types";

export const NotificationsModal = forwardRef<HTMLDivElement, ModalProps>(
  ({ isOpen, setOpen }, ref) => {
    const { t } = useTranslation(["navbar"]);

    return (
      <Modal
        show={isOpen}
        className="modal__notifications__dropdown__container"
      >
        <div className="modal__notifications__dropdown" ref={ref}>
          <div className="modal__notifications__dropdown__title_container">
            <h4 className="modal__notifications__dropdown__title">
              {t("admin_navbar.notifications")}
            </h4>
            <span>{t("admin_navbar.see_all")}</span>
          </div>
          <div className="modal__all_notification__container">
            <Notification notifications={notifications} />
          </div>
        </div>
      </Modal>
    );
  }
);
