import { MenuItem, Select, TextField } from "@mui/material";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import navbarImages from "../../assets/images/navbar";
import orderImages from "../../assets/images/order";
import { usePlacesWidget } from "react-google-autocomplete";
import "./styles.scss";
import dayjs, { Dayjs } from "dayjs";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  homeFilter,
  homeFilterSchema,
  mockEndDate,
  mockInitialDate,
} from "../navHomeFilter/form";
import { useFormik } from "formik";
import {
  findByLatitudLongitud,
  findLatitudLongitud,
  findState,
  handleAutocompleteInput,
} from "../../lib/helpers/mapsActions";
import { useHours } from "../../hooks/hours";
import { resultParams } from "../../views/results/types";
import { useTopFilter } from "../../hooks/filter";
import {
  greenwichToMilitarTime,
  timeConversion,
} from "../../lib/helpers/timeHandler";
import { useWindowSize } from "../../hooks/windowSize";
import { DestinationContext } from "../../lib/components/resultsTools/destinationsDropdown/types";
import { DestinationsDropdown } from "../../lib/components/resultsTools/destinationsDropdown/component";
import { useQuery } from "react-query";
import { getDestinations, getDestinationsById } from "../../services/car";
import {
  mockCurrentLocation,
  mockDestination,
} from "../../models/destination/mockups";
import { currentLocation, Destination } from "../../models/destination/types";
import { InputAutocomplete } from "../../lib/components/inputAutocomplete/components";
import { useAutocompleteFilter } from "../../hooks/nav/filters/autocompleteFilter";
import { useDestination } from "../../hooks/destination";
import { EditDateBlock } from "../../lib/components/carTools/editCarPrice/types";
import { useBlockNextHalfHours } from "../../hooks/car/show/blockNextHalfHours";

export const NavFilter: FC<resultParams> = ({
  dateEnd,
  commercialName,
  dateInitial,
  latitud,
  longitud,
  hourEnd,
  hourInitial,
  destinationId,
}) => {
  const { t } = useTranslation(["home", "routes"]);
  const [dates, setDates] = useState<DateRange<Dayjs>>([null, null]);
  const filter = useTopFilter();
  const windowSize = useWindowSize();
  const { formatHours } = useHours(30);
  const [dropdownFilter, setDropdownFilter] = useState<boolean>(false);
  const navFilterCollapse = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocompleteInput] = useState("");
  const [dropdownAction, setDropdownAction] = useState<boolean>(false);
  const { data: destinations } = useQuery(["destinations"], getDestinations);
  const [destinationSelected, setDestinationSelected] =
    useState<Destination>(mockDestination);
  const [type, setType] = useState<"current" | "destination" | "">("");
  const [currentLocation, setCurrentLocation] =
    useState<currentLocation>(mockCurrentLocation);
  const [blockDates, setBlockDates] = useState<EditDateBlock[]>([]);
  const [finalLeftHours, setFinalLeftHours] = useState<string[]>(formatHours);
  const [finalRightHours, setFinalRightHours] = useState<string[]>(formatHours);

  useEffect(() => {
    const setAddressFilter = async () => {
      const addressFilterData = await findByLatitudLongitud(latitud, longitud);
      if (!addressFilterData) return;

      if (destinationId && destinationId !== 0) {
        const destination = await getDestinationsById(destinationId);
        setAutocompleteInput(destination?.name || "");
      } else {
        setAutocompleteInput(
          commercialName || addressFilterData.formatted_address
        );
      }

      formikHomeFilter.setFieldValue("location", { latitud, longitud });
      formikHomeFilter.setFieldValue("destinationId", destinationId);
    };

    if (latitud && longitud) {
      setAddressFilter();
    }
  }, [latitud, longitud, destinationId]);

  useEffect(() => {
    const setInitialAndEndDates = () => {
      if (!blockDates) return;

      // Check in blockdates if are available those dates
      const availableDate = blockDates.some((date) =>
        date.dates.some((days) => days.format("DD/MM/YYYY") === dateInitial)
      );

      if (availableDate) return;

      if (!dateInitial || !dateEnd) {
        setDates([
          dayjs(formikHomeFilter.values.dateInitial),
          dayjs(formikHomeFilter.values.dateEnd),
        ]);
        return;
      }

      // Date initial has to be minor than date end
      // Both dates has to be higher than today
      if (
        dayjs().isAfter(
          dayjs(`${dateInitial} ${hourInitial}`, "DD/MM/YYYY HH:mm")
        ) ||
        dayjs().isAfter(dayjs(`${dateEnd} ${hourEnd}`, "DD/MM/YYYY HH:mm")) ||
        dayjs(dateInitial, "DD/MM/YYYY").isAfter(dayjs(dateEnd, "DD/MM/YYYY"))
      ) {
        setDates([
          dayjs(formikHomeFilter.values.dateInitial),
          dayjs(formikHomeFilter.values.dateEnd),
        ]);
        return;
      }

      formikHomeFilter.setFieldValue(
        "dateInitial",
        dayjs(dateInitial, "DD/MM/YYYY")
      );
      formikHomeFilter.setFieldValue("dateEnd", dayjs(dateEnd, "DD/MM/YYYY"));

      setDates([
        dayjs(dateInitial, "DD/MM/YYYY"),
        dayjs(dateEnd, "DD/MM/YYYY"),
      ]);
    };

    setInitialAndEndDates();
  }, [dateInitial, dateEnd]);

  useEffect(() => {
    const setInitialAndEndHours = () => {
      if (!hourInitial && !hourEnd && !formatHours) return;

      formikHomeFilter.setFieldValue("hourInitial", hourInitial);
      formikHomeFilter.setFieldValue("hourEnd", hourEnd);
    };

    setInitialAndEndHours();
  }, [hourInitial, hourEnd, formatHours]);

  const formatDates = (newValue: DateRange<Dayjs>) => {
    setDates(newValue);
    if (!newValue[0] || !newValue[1]) return;

    const startDate = dayjs(newValue[0]);
    const endDate = dayjs(newValue[1]);

    formikHomeFilter.setFieldValue("dateInitial", startDate);
    formikHomeFilter.setFieldValue("dateEnd", endDate);
  };

  useEffect(() => {
    if (windowSize > 991) {
      setDropdownFilter(false);
    }
  }, [windowSize]);

  const formikHomeFilter = useFormik<homeFilter>({
    initialValues: {
      location: { latitud: 0, longitud: 0 },
      hourEnd: "",
      commercialName: "",
      hourInitial: "",
      dateInitial: mockInitialDate,
      dateEnd: mockEndDate,
    },
    validationSchema: homeFilterSchema,
    onSubmit: (values) => {
      filter(values);
      setDropdownFilter(false);
    },
  });

  useBlockNextHalfHours({
    componentName: "results",
    formatHours,
    setBlockDates,
    pickUpDate: formikHomeFilter.values.dateInitial,
    returnDate: formikHomeFilter.values.dateEnd,
    pickUpTime: formikHomeFilter.values.hourInitial,
    setFinalLeftHours,
    setFinalRightHours,
    setFields: formikHomeFilter.setFieldValue,
  });

  useDestination({
    location: formikHomeFilter.values.location,
    destinations,
    setFieldValue: formikHomeFilter.setFieldValue,
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      formikHomeFilter.setFieldValue("location", findLatitudLongitud(place));
      formikHomeFilter.setFieldValue(
        "commercialName",
        handleAutocompleteInput(place)
      );
      formikHomeFilter.setFieldValue(
        "state",
        findState(place.address_components)
      );
      formikHomeFilter.setFieldValue("destinationId", "0");
      setAutocompleteInput(handleAutocompleteInput(place));
    },
    options: {
      componentRestrictions: { country: "mx" },
      fields: [
        "geometry",
        "formatted_address",
        "place_id",
        "address_components",
        "name",
      ],
      types: [],
    },
    language: "es-419",
  });

  const { ref: mobileRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      formikHomeFilter.setFieldValue("location", findLatitudLongitud(place));
      formikHomeFilter.setFieldValue(
        "commercialName",
        handleAutocompleteInput(place)
      );
      formikHomeFilter.setFieldValue(
        "state",
        findState(place.address_components)
      );
      formikHomeFilter.setFieldValue("destinationId", "0");
      setAutocompleteInput(handleAutocompleteInput(place));
    },
    options: {
      componentRestrictions: { country: "mx" },
      fields: [
        "geometry",
        "formatted_address",
        "place_id",
        "address_components",
        "name",
      ],
      types: [],
    },
  });

  const { handleAutocomplete, handleClick } = useAutocompleteFilter({
    dropdownAction,
    currentLocation,
    type,
    destinationSelected,
    autocomplete,
    setAutocompleteInput,
    setType,
    setCurrentLocation,
    setDropdownAction,
    setFormikValue: formikHomeFilter.setFieldValue,
  });

  const styles = {
    resultsFilterButton: {
      backgroundColor:
        formikHomeFilter.isValid && formikHomeFilter.dirty
          ? "var( --Brand-Colors-Black-color-fix)"
          : "#828282",
    },
  };

  return (
    <div className="nav-filter-container">
      <div className="nav-join" onClick={() => setDropdownFilter(true)}>
        <h2 id="nav_ubication">{autocomplete}</h2>
        <div className="d-flex justify-content-between w-border">
          <p id="nav_dates">{`${dayjs(
            formikHomeFilter.values.dateInitial
          ).format("DD/MM/YYYY")}, ${timeConversion(hourInitial)} · ${dayjs(
            formikHomeFilter.values.dateEnd
          ).format("DD/MM/YYYY")}, ${timeConversion(hourEnd)}`}</p>
          <img
            id="drop_arrow"
            className="drop_arrow"
            src={navbarImages.downArrow}
            alt=""
          />
        </div>
        <div className="dropdown-divider"></div>
      </div>

      <nav
        className="nav-filter"
        id="nav_filter"
        ref={navFilterCollapse}
        style={{ display: dropdownFilter ? "flex" : "none" }}
      >
        <div className="card un-form">
          <div className="card-body">
            <div
              className="d-flex justify-content-end w-100"
              onClick={() => setDropdownFilter(false)}
            >
              <img className="drop_arrow" src={navbarImages.downArrow} alt="" />
            </div>
            <div className="w-100 position-relative d-flex justify-content-center">
              <div className="d-flex flex-column justify-content-center ubication">
                <label data-testid="where-label">
                  {t("searchBar.where.title")}
                </label>
                <InputAutocomplete
                  ref={mobileRef as RefObject<null>}
                  handleClick={handleClick}
                  handleAutocomplete={handleAutocomplete}
                  autocomplete={autocomplete}
                />
              </div>
              {dropdownAction && (
                <DestinationContext.Provider
                  value={{
                    setDropdownAction,
                    setDestinationSelected,
                    destinations: destinations || [mockDestination],
                    setType,
                    setCurrentLocation,
                  }}
                >
                  <DestinationsDropdown />
                </DestinationContext.Provider>
              )}
            </div>
            <div className="un-container-date w-100">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "", end: "" }}
              >
                <DateRangePicker
                  value={dates}
                  inputFormat="DD/MM/YYYY"
                  disablePast
                  className="range-date-picker"
                  shouldDisableDate={(date) =>
                    blockDates.some((dateList) =>
                      dateList.dates.some(
                        (dateItem) =>
                          dayjs(date).format("DD-MM-YYYY") ===
                          dayjs(dateItem).format("DD-MM-YYYY")
                      )
                    )
                  }
                  onChange={(newValue) => {
                    formatDates(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <div className="dates-hours__container">
                      <div className="un-desde">
                        <label className="w-100" data-testid="since-label">
                          {t("searchBar.since")}
                        </label>
                        <div className="dates-hour">
                          <TextField
                            {...startProps}
                            className="date-text-field"
                          />
                          {finalLeftHours.length !== 0 && (
                            <Select
                              labelId="time-left"
                              id="time-left"
                              label=""
                              defaultValue=""
                              value={formikHomeFilter.values.hourInitial}
                              onChange={(event) =>
                                formikHomeFilter.setFieldValue(
                                  "hourInitial",
                                  event.target.value
                                )
                              }
                              className="dropdown"
                            >
                              {finalLeftHours.map((hour) => (
                                <MenuItem
                                  key={greenwichToMilitarTime(hour)}
                                  value={greenwichToMilitarTime(hour)}
                                >
                                  {hour}
                                  {hour === "12:00 AM" && (
                                    <img
                                      src={orderImages.moon}
                                      className="time-icon"
                                    />
                                  )}
                                  {hour === "12:00 PM" && (
                                    <img
                                      src={orderImages.sun}
                                      className="time-icon"
                                    />
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                      </div>
                      <div className="un-hasta">
                        <label
                          data-testid="until-label"
                          className="w-100 until-label"
                        >
                          {t("searchBar.until")}
                        </label>
                        <div className="dates-hour">
                          <TextField
                            {...endProps}
                            className="date-text-field"
                          />
                          {finalRightHours.length !== 0 && (
                            <Select
                              labelId="time-right"
                              id="time-right"
                              value={formikHomeFilter.values.hourEnd}
                              onChange={(event) =>
                                formikHomeFilter.setFieldValue(
                                  "hourEnd",
                                  event.target.value
                                )
                              }
                              className="dropdown"
                            >
                              {finalRightHours.map((hour) => (
                                <MenuItem
                                  key={greenwichToMilitarTime(hour)}
                                  value={greenwichToMilitarTime(hour)}
                                >
                                  {hour}
                                  {hour === "12:00 AM" && (
                                    <img
                                      src={orderImages.moon}
                                      className="time-icon"
                                    />
                                  )}
                                  {hour === "12:00 PM" && (
                                    <img
                                      src={orderImages.sun}
                                      className="time-icon"
                                    />
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
            <button
              style={styles.resultsFilterButton}
              disabled={!(formikHomeFilter.isValid && formikHomeFilter.dirty)}
              className="btn btn-orange filterCar"
              onClick={() => formikHomeFilter.handleSubmit()}
            >
              {t("searchBar.search")}
            </button>
          </div>
        </div>
      </nav>

      <div className="nav-desktop-join">
        <div className="nav-container-join">
          <div className="position-relative">
            <div className="d-flex donde flex-column">
              <label data-testid="where-label" style={{ marginBottom: "10px" }}>
                {t("searchBar.where.title")}
              </label>
              <InputAutocomplete
                ref={ref as RefObject<null>}
                handleClick={handleClick}
                handleAutocomplete={handleAutocomplete}
                autocomplete={autocomplete}
              />
              {formikHomeFilter.touched.state
                ? formikHomeFilter.errors.state
                : ""}
            </div>
            {dropdownAction && (
              <DestinationContext.Provider
                value={{
                  setDropdownAction,
                  setDestinationSelected,
                  destinations: destinations || [mockDestination],
                  setType,
                  setCurrentLocation,
                }}
              >
                <DestinationsDropdown />
              </DestinationContext.Provider>
            )}
          </div>
          <div className="o-container-date">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ start: "", end: "" }}
            >
              <DateRangePicker
                value={dates}
                inputFormat="DD/MM/YYYY"
                disablePast
                shouldDisableDate={(date) =>
                  blockDates.some((dateList) =>
                    dateList.dates.some(
                      (dateItem) =>
                        dayjs(date).format("DD-MM-YYYY") ===
                        dayjs(dateItem).format("DD-MM-YYYY")
                    )
                  )
                }
                onChange={(newValue) => {
                  formatDates(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <div className="nav-desde">
                      <label className="w-100" data-testid="since-label">
                        {t("searchBar.since")}
                      </label>
                      <div className="d-flex align-items-center">
                        <TextField {...startProps} className="datepicker" />
                        {finalLeftHours.length !== 0 && (
                          <Select
                            labelId="time-desktop-left"
                            id="time-desktop-left"
                            label=""
                            defaultValue=""
                            value={formikHomeFilter.values.hourInitial}
                            onChange={(event) =>
                              formikHomeFilter.setFieldValue(
                                "hourInitial",
                                event.target.value
                              )
                            }
                            className="dropdown"
                          >
                            {finalLeftHours.map((hour) => (
                              <MenuItem
                                key={greenwichToMilitarTime(hour)}
                                value={greenwichToMilitarTime(hour)}
                              >
                                {hour}
                                {hour === "12:00 AM" && (
                                  <img
                                    src={orderImages.moon}
                                    className="time-icon"
                                  />
                                )}
                                {hour === "12:00 PM" && (
                                  <img
                                    src={orderImages.sun}
                                    className="time-icon"
                                  />
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                    <div className="nav-hasta">
                      <label data-testid="until-label">
                        {t("searchBar.until")}
                      </label>
                      <div className="d-flex align-items-center">
                        <TextField {...endProps} className="datepicker" />
                        {finalRightHours.length !== 0 && (
                          <Select
                            labelId="time-desktop-right"
                            id="time-desktop-right"
                            label=""
                            defaultValue=""
                            value={formikHomeFilter.values.hourEnd}
                            onChange={(event) =>
                              formikHomeFilter.setFieldValue(
                                "hourEnd",
                                event.target.value
                              )
                            }
                            className="dropdown"
                          >
                            {finalRightHours.map((hour) => (
                              <MenuItem
                                key={greenwichToMilitarTime(hour)}
                                value={greenwichToMilitarTime(hour)}
                              >
                                {hour}
                                {hour === "12:00 AM" && (
                                  <img
                                    src={orderImages.moon}
                                    className="time-icon"
                                  />
                                )}
                                {hour === "12:00 PM" && (
                                  <img
                                    src={orderImages.sun}
                                    className="time-icon"
                                  />
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
            </LocalizationProvider>
          </div>
          <button
            type="button"
            style={styles.resultsFilterButton}
            disabled={!(formikHomeFilter.isValid && formikHomeFilter.dirty)}
            className="btn btn-orange rounded-circle filterCar"
          >
            <img
              src={orderImages.search}
              alt=""
              onClick={() => formikHomeFilter.handleSubmit()}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
