import { FC } from "react";
import { Modal } from "react-bootstrap";
import { ModalDefaultProps } from "../types";
import navbarImages from "../../../assets/images/navbar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import modalsImages from "../../../assets/images/modals";
import "./CompleteProfile.scss";

export const CompleteProfile: FC<ModalDefaultProps> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation(["modals"]);

  return (
    <Modal show={isOpen} className="complete-profile-modal">
      <div className="modal-header">
        <button type="button" className="close" onClick={() => setOpen(false)}>
          <img src={navbarImages.cross} alt="" />
        </button>
        <h2 id="change_email_modal_text">{t("complete_profile.title")}</h2>
      </div>
      <div className="modal-body">
        <p>{t("complete_profile.information")}</p>
        <img
          src={modalsImages.complete_profile}
          className="complete-profile-image"
        />
      </div>
      <div className="modal-footer">
        <Link
          className="btn btn-orange"
          to={"/" + t("profile", { ns: "routes" })}
        >
          {t("complete_profile.go_to_profile")}
        </Link>
      </div>
    </Modal>
  );
};
