import { useTranslation } from "react-i18next";
import carTypesImg from "../../../assets/images/car/index";

export const useTypes = () => {
  const { t } = useTranslation(["results"]);

  return [
    {
      label: t("filter.test_type.compact"),
      index: "0",
      src: carTypesImg.compactType,
      test: "type-Hatchback/compact",
    },
    {
      label: t("filter.test_type.sedan"),
      index: "1",
      src: carTypesImg.carType,
      test: "type-sedan",
    },
    {
      label: t("filter.test_type.coupe"),
      index: "2",
      src: carTypesImg.coupeType,
      test: "type-coupe",
    },
    {
      label: t("filter.test_type.suv/crossover"),
      index: "3",
      src: carTypesImg.suvType,
      test: "type-suv/crossover",
    },
    {
      label: t("filter.test_type.minivan"),
      index: "4",
      src: carTypesImg.minivanType,
      test: "type-muv/utility",
    },
    {
      label: t("filter.test_type.van"),
      index: "5",
      src: carTypesImg.vanType,
      test: "type-van",
    },
    {
      label: t("filter.test_type.truck"),
      index: "6",
      src: carTypesImg.truckType,
      test: "type-truck",
    },
  ];
};
